import * as qs from 'qs'
import toast from 'react-hot-toast'
import { getErrorMessage, displayError, isEmpty } from '../utils/Helpers'
import API from './Api'

export class Helpers {
   private static baseURL = () => process.env.REACT_APP_BASE_URL
   
   public static getBaseApiUrl() {
      return Helpers.baseURL()
   }

   public static get(
      url: string,
      token: string = '',
      params?: any,
      queries?: { [key: string]: any }
   ) {
      // Add toast notification with react-hot-toast  [######]
      let pr: { [key: string]: any } = {}
      if (params) pr.selectedFilterIds = params
      if (queries) pr = { ...pr, ...queries }
      return API.get(url, {
         headers: {
            'Content-Type': 'application/json',
         },
         params: {
            ...pr,
         },
         paramsSerializer: (params) => {
            return qs.stringify(params, {
               encode: false,
            })
         },
         withCredentials: true,
      })

         .then((response: any) => {
            // If response.status is not 0, then it is an error [######]
            this.showErrorMessage(response)
            return response.data
         })
         .catch(function (error) {
            // handle error
            //Trigger notification with error message here [######]
            displayError(error)
         })
   }

   public static post(
      url: string,
      data: any | FormData = '',
      token: string = '',
      responseType: any | undefined = 'json'
   ) {
      // Add toast notification with react-hot-toast  [######]

      return API.post(
         url,
         data instanceof FormData ? data : JSON.stringify(data),
         {
            headers: {
               Authorization: `Bearer ${token}`,
               'Access-Control-Allow-Origin': this.baseURL(),
               'Content-Type': 'application/json',
            },
            responseType,
            withCredentials: true,
         }
      )
         .then((response: any) => {
            // If response.status is not 0, then it is an error [######]
            if (responseType !== 'json') return response.data

            this.showErrorMessage(response)
            return response.data
         })
         .catch(function (error) {
            //Trigger notification with error message here [######]
            displayError(error)
            return error.toJSON()
         })
   }

   public static update(
      url: string,
      key: any,
      data: any | FormData = '',
      token: string = ''
   ) {
      return API.put(
         url + '/' + key,
         data instanceof FormData ? data : JSON.stringify(data),
         {
            headers: {
               Authorization: `Bearer ${token}`,
               'Access-Control-Allow-Origin': this.baseURL(),
               'Content-Type': 'application/json',
            },
            withCredentials: true,
         }
      )
         .then((response: any) => {
            this.showErrorMessage(response)
            return response.data
         })
         .catch(function (error) {
            displayError(error)
         })
   }

   public static delete(url: string, key: any) {
      return API.delete(url + '/' + key, {
         headers: {
            // Authorization: `Bearer ${token}` ,
            'Access-Control-Allow-Origin': this.baseURL(),
            'Content-Type': 'application/json',
         },
         withCredentials: true,
      })
         .then((response: any) => {
            this.showErrorMessage(response)
            return response.data
         })
         .catch(function (error) {
            displayError(error)
         })
   }

   private static getResponseData(response: Response) {
      return response.json()
   }

   private static showErrorMessage(response: any) {
      if (
         !isEmpty(response.data) &&
         typeof response.data === 'object' &&
         response.data.hasOwnProperty('status') &&
         response.data.status !== 0 &&
         response.data.errorCode !== 0
      )
         toast.error(getErrorMessage(response), { id: 'error' })
   }
   public static infoEmail = `info@reg-track.com`
}
