import { makeAutoObservable } from 'mobx'
import { DashboardApi } from './DashboardApi'
import moment from 'moment'
import type { Moment } from 'moment'
import FileSaver from 'file-saver'
import { RangeValue } from './components/charts/CustomRangePicker'

export class DashboardStore {
   constructor() {
      makeAutoObservable(this)
   }

   localeDateFormat: string = 'DD/MM/YYYY'
   dateFormat: string = 'YYYY-MM-DD'
   startDate: moment.Moment = moment()
      .subtract(1, 'days')
      .locale(this.localeDateFormat) // Date
   endDate: moment.Moment = moment().endOf('day').locale(this.localeDateFormat)
   searchValue: string = ''
   isFiltered: boolean = false
   allUserStatistics: any[] = []
   allUserStatisticsFiltered: any[] = []
   isStatisticLoading: boolean = true
   eventsStatistics: any
   isWidgetLoading: boolean = false
   isDailyRulesStatisticsLoading: boolean = false
   isBreakdownStatisticsLoading: boolean = false
   dailyRulesStatistics: any[] = []
   breakdownStatistics: any[] = []
   dueAllocationStatistics: any = {
      count: 0,
      data: [],
   }
   isDueAllocationStatistics: boolean = false
   isExportToExcelLoading: boolean = false
   admins: any[] = []
   isGetEventLoading: boolean = false
   events: any[] = []
   isExportEventsToExcelLoading: boolean = false

   clearState() {
      this.allUserStatistics = []
      this.allUserStatisticsFiltered = []
      this.eventsStatistics = []
      this.dailyRulesStatistics = []
      this.breakdownStatistics = []
      this.dueAllocationStatistics = {
         count: 0,
         data: [],
      }
      this.isDailyRulesStatisticsLoading = false
      this.isBreakdownStatisticsLoading = false
      this.isDueAllocationStatistics = false
      this.isExportToExcelLoading = false
   }

   clearSearchBox() {
      this.searchValue = ''
      this.isFiltered = false
   }

   setSearchValue(value: string) {
      this.searchValue = value
   }

   setUserStatistics(rules: any[]) {
      this.allUserStatistics = rules
   }

   setUserStatisticsFiltered(rules: any[]) {
      this.allUserStatisticsFiltered = rules
   }

   userStatisticsModalVisible: boolean = false
   setUserStatisticsModalVisible(val: boolean) {
      this.userStatisticsModalVisible = val
   }

   setCalanderDate(startDate: moment.Moment, endDate: moment.Moment) {
      this.startDate = startDate.locale(this.localeDateFormat)
      this.endDate = endDate.locale(this.localeDateFormat)
      this.getAllUserStatistics()
   }

   getEventsStatistics(
      startDate: Moment | null,
      endDate: Moment | null,
      admin: string | null | undefined
   ) {
      this.isWidgetLoading = true
      if (startDate == null) startDate = moment().subtract(7, 'days')
      if (endDate == null) endDate = moment()

      DashboardApi.getEventsStatistics(
         startDate.format(this.dateFormat),
         endDate.format(this.dateFormat),
         admin
      )
         .then((result: any) => {
            this.isWidgetLoading = false
            this.eventsStatistics = result.eventsStatistics
         })
         .catch((err) => {
            this.isWidgetLoading = false
         })
   }

   getDailyRulesData(
      startDate: Moment | null,
      endDate: Moment | null,
      changeType: string,
      admin: string | null | undefined
   ) {
      this.isDailyRulesStatisticsLoading = true
      if (startDate == null) startDate = moment().subtract(7, 'days')
      if (endDate == null) endDate = moment()

      DashboardApi.getDailyRulesData(
         startDate.format(this.dateFormat),
         endDate.format(this.dateFormat),
         changeType,
         admin
      )
         .then((result: any) => {
            this.isDailyRulesStatisticsLoading = false
            this.dailyRulesStatistics = result.dailyRulesStatistics
         })
         .catch((err) => {
            this.isDailyRulesStatisticsLoading = false
         })
   }

   getBreakdownStatistics(
      startDate: Moment | null,
      endDate: Moment | null,
      breakdown: string,
      admin: string | null | undefined
   ) {
      this.isBreakdownStatisticsLoading = true
      if (startDate == null) startDate = moment().subtract(7, 'days')
      if (endDate == null) endDate = moment()

      DashboardApi.getBreakdownStatistics(
         startDate.format(this.dateFormat),
         endDate.format(this.dateFormat),
         breakdown,
         admin
      )
         .then((result: any) => {
            this.isBreakdownStatisticsLoading = false
            this.breakdownStatistics = result.breakdownStatistics
         })
         .catch((err) => {
            this.isBreakdownStatisticsLoading = false
         })
   }

   getDueAllocationStatistics(
      startDate: Moment | null,
      endDate: Moment | null,
      option: string,
      admin: string | null | undefined
   ) {
      this.isDueAllocationStatistics = true
      if (startDate == null) startDate = moment().subtract(7, 'days')
      if (endDate == null) endDate = moment()

      DashboardApi.getDueAllocationStatistics(
         startDate.format(this.dateFormat),
         endDate.format(this.dateFormat),
         option,
         admin
      )
         .then((result: any) => {
            this.isDueAllocationStatistics = false
            this.dueAllocationStatistics = result.dueAllocationStatistic
         })
         .catch((err) => {
            this.isDueAllocationStatistics = false
         })
   }

   getAllUserStatistics() {
      // this.isLoading = true
      this.isStatisticLoading = true

      DashboardApi.getAllUserStatistics(
         this.startDate.format('YYYY-MM-DD'),
         this.endDate.format('YYYY-MM-DD')
      )
         .then((result: any) => {
            this.isStatisticLoading = false
            // this.setUserStatisticsModalVisible(true)

            this.allUserStatistics = result.userStatistics
         })
         .catch((err) => {
            // Show error message.
            this.isStatisticLoading = false
         })
   }

   getDefaultPreferences = () => {
      DashboardApi.getPreferences(
         '/settings/GetPreferences/' + localStorage.getItem('userId')
      )
         .then((result) => {
            this.localeDateFormat =
               result.preferences.languagePreference === 'en-US'
                  ? 'MM/DD/YYYY'
                  : 'DD/MM/YYYY'
         })
         .catch((err) => {})
   }

   getAdminUsers = () => {
      DashboardApi.getAdminUsers()
         .then((result) => {
            this.admins = result.users
         })
         .catch((err) => {})
   }

   exportEventsToExcel = (
      eventType: string,
      dates: RangeValue,
      admin: string | null | undefined
   ) => {
      this.isExportEventsToExcelLoading = true
      const startDate =
         dates != null && dates.length > 1
            ? dates[0]
            : moment().startOf('day').subtract(1, 'months')
      const endDate =
         dates != null && dates.length > 1 ? dates[1] : moment().startOf('day')

      DashboardApi.exportEventsToExcel(
         eventType,
         startDate!.format(this.dateFormat),
         endDate!.format(this.dateFormat),
         admin
      )
         .then((result) => {
            this.isExportEventsToExcelLoading = false
            FileSaver.saveAs(new Blob([result.blob]), result.fileName)
         })
         .catch((err) => {
            this.isExportEventsToExcelLoading = false
         })
   }

   exportDashboardToExcel = (
      dates: RangeValue,
      admin: string | null | undefined
   ) => {
      this.isExportToExcelLoading = true
      const startDate =
         dates != null && dates.length > 1
            ? dates[0]
            : moment().startOf('day').subtract(1, 'months')
      const endDate =
         dates != null && dates.length > 1 ? dates[1] : moment().startOf('day')

      DashboardApi.exportToExcel(
         startDate!.format(this.dateFormat),
         endDate!.format(this.dateFormat),
         admin
      )
         .then((result) => {
            this.isExportToExcelLoading = false
            FileSaver.saveAs(new Blob([result.blob]), result.fileName)
         })
         .catch((err) => {
            this.isExportToExcelLoading = false
         })
   }

   getEvents = (
      eventType: string,
      startDate: Moment | null,
      endDate: Moment | null,
      admin: string | null | undefined
   ) => {
      this.isGetEventLoading = true
      if (startDate == null) startDate = moment().subtract(7, 'days')
      if (endDate == null) endDate = moment()

      DashboardApi.getEvents(
         eventType,
         startDate.format(this.dateFormat),
         endDate.format(this.dateFormat),
         admin
      )
         .then((result: any) => {
            this.isGetEventLoading = false
            this.events = result.dailyRules
         })
         .catch((err) => {
            this.isGetEventLoading = false
         })
   }
}
