import { observer } from 'mobx-react'
import { ReactComponent as Department } from '../assets/department.svg'
import React, { useEffect, useState } from 'react'
import Tree from 'react-d3-tree'
import { useStore } from '../context/index'
import { Button, Modal } from 'antd'

interface IOrganisationalChart {
   initialDepth: number
}

const OrganisationalChart = observer((props: IOrganisationalChart) => {
   const { MainLayoutStore } = useStore()

   const nodeSize = {
      x: 140,
      y: 140,
   }

   const translate = {
      x: 450,
      y: 200,
   }
   const [
      showOrganisationalChartModal,
      setShowOrganisationalChartModal,
   ] = useState(false)

   const onOrgChartClick = () => {
      setShowOrganisationalChartModal(true)
      MainLayoutStore.getOrganisationGraph()
   }

   const footerButtons = [
      <Button
         key="back"
         style={{
            backgroundColor: 'rgb(250, 140, 22)',
            borderColor: 'rgb(250, 140, 22)',
            borderRadius: '10px',
            color: 'white',
         }}
         onClick={(e: any) => setShowOrganisationalChartModal(false)}
      >
         Ok
      </Button>,
   ]

   return (
      <>
         <Modal
            title={'Organisational structure'}
            open={showOrganisationalChartModal}
            onCancel={(e: any) => setShowOrganisationalChartModal(false)}
            footer={footerButtons}
            destroyOnClose={true}
            width={1024}
            bodyStyle={{ height: '500px' }}
            style={{ marginTop: '-5%' }}
         >
            <div
               id="treeWrapper"
               style={{
                  marginLeft: '-30%',
                  // marginTop: '15%',
                  width: '87em',
                  height: '34em',
               }}
            >
               <Tree
                  data={MainLayoutStore.orgChart}
                  orientation="horizontal"
                  leafNodeClassName="root-leaf"
                  branchNodeClassName="root-branch"
                  rootNodeClassName="root-node"
                  zoom={0.8}
                  nodeSize={nodeSize}
                  translate={translate}
                  initialDepth={props.initialDepth}
               />
            </div>
         </Modal>
         <Button
            type="link"
            onClick={() => onOrgChartClick()}
            style={{
               color: '#fa8c16',
               borderRadius: '10px',
            }}
         >
            <Department />
            <span style={{ marginLeft: '4%' }}>Organisational Chart</span>
         </Button>
      </>
   )
})

export default OrganisationalChart
