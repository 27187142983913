import { observer } from 'mobx-react' // Or "mobx-react".
import React, { useState } from 'react'
import { useStore } from '../../../context/index'
import { Table, Row, Button, Form, Col, Space, Select } from 'antd'
import { Item, EditableCellProps } from '../../../types/Types'
import { Input, Popconfirm } from 'antd'
import {
   DeleteOutlined,
   EditOutlined,
   SaveOutlined,
   CloseCircleOutlined,
   PlusCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const { Option } = Select

export interface EditableTableProps {
   deleteMessage: string
   firstColumnTitle: string
   secondColumnTitle?: string
   buttonName: string
   isDraggable: boolean
}

const EditableTable = observer((props: EditableTableProps) => {
   const [form] = Form.useForm()
   const { DepartmentsStore } = useStore()
   const [editingKey, setEditingKey] = useState(0)
   const isEditing = (record: Item) => record.key === editingKey
   const isNewRow = (record: Item) => record.key > 0 && record.key < 1
   const [disable, setDisable] = useState(false)
   const [updateExistingRow, setUpdateExistingRow] = useState(false)
   const history = useHistory()

   const editRow = (record: Partial<Item> & { key: React.Key }) => {
      form.setFieldsValue({
         businessEntity: record.businessEntity.name,
         name: record.name,
         username: record.user.name + ' ' + record.user.surname,
      })
      setEditingKey(record.key)
      setUpdateExistingRow(true)
   }

   // TODO : Call Save or Update Api
   const saveRow = async (key: React.Key, isNewRow: any) => {
      try {
         const row = (await form.validateFields()) as any
         let userIndex = DepartmentsStore.users.findIndex(
            (e: any) => e.id === row.username
         )
         let businessEntityIndex = DepartmentsStore.businessEntities.findIndex(
            (e: any) => e.id === row.businessEntity
         )
         let user = DepartmentsStore.users[userIndex]
         let businessEntity =
            DepartmentsStore.businessEntities[businessEntityIndex]

         const newData = [...DepartmentsStore.pageData]
         const index = newData.findIndex((item) => item.key === key)

         if (index > -1) {
            // Update or New Item Save
            const item = newData[index]
            item.name = row.name
            if (user !== undefined) item.user = user
            if (businessEntity !== undefined)
               item.businessEntity = businessEntity

            DepartmentsStore.updatePageData(newData) // Improvements TODO : remove this line. So, save to Database first and use response values on the fron-end side.

            setEditingKey(-1)
            setDisable(false)

            DepartmentsStore.save(item, updateExistingRow, history)
         }
      } catch (errInfo) {
         console.error('Validate Failed:', errInfo)
      }
   }

   const cancelRow = async (record: any) => {
      if (record.name === '' && record.username === '') {
         const newData = [...DepartmentsStore.pageData]
         newData.pop()
         DepartmentsStore.updatePageData(newData)
      }
      setEditingKey(0)
      setDisable(false)
   }

   const deleteRow = (record: Item) => {
      DepartmentsStore.delete(record)
   }

   const addNewRow = () => {
      const newKey = Math.random()
      const newRow = {
         key: newKey,
         name: '',
         user: { id: newKey, name: '', surname: '' },
         businessEntity: { id: newKey, name: '' },
      }

      const newData = [...DepartmentsStore.pageData, newRow]
      DepartmentsStore.updatePageData(newData)
      setEditingKey(newKey)
      setDisable(true)
      setUpdateExistingRow(false)
      form.setFieldsValue({
         key: newKey,
         name: '',
         businessEntity: '',
         username: '',
      })
   }

   const userOptions = DepartmentsStore.users?.map((item: any) => {
      return {
         value: item.id,
         label: `${item.name} ${item.surname}`,
      }
   })

   const businessEntityOptions = DepartmentsStore.businessEntities?.map(
      (item: any) => {
         return {
            value: item.id,
            label: item.name,
         }
      }
   )

   // If Cells is editing then show input boxes,
   const EditableCell: React.FC<EditableCellProps> = ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
   }) => {
      const inputNode =
         inputType === 'dropdown' ? (
            dataIndex === 'username' ? (
               <Select
                  style={{ width: '100' }}
                  placeholder="Select a person"
                  options={userOptions}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                     (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                  }
               />
            ) : (
               <Select
                  showSearch
                  // style={{ width: 150 }}
                  placeholder="Select a business entity"
                  optionFilterProp="children"
                  options={businessEntityOptions}
                  filterOption={(input, option) =>
                     (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                  }
               />
            )
         ) : (
            <Input />
         )
      return (
         <td {...restProps}>
            {editing ? (
               <Form.Item
                  name={dataIndex}
                  style={{ margin: 0 }}
                  rules={[
                     ({ getFieldValue }) => ({
                        validator(_, value) {
                           return DepartmentsStore.checkInput(_, value, record)
                        },
                     }),
                  ]}
               >
                  {inputNode}
               </Form.Item>
            ) : (
               children
            )}
         </td>
      )
   }

   let columns = [
      {
         title: 'Group',
         dataIndex: 'group',
         width: '20%',
         editable: false,
         render: (item: any, record: any) => (
            <>{record.businessEntity?.group?.description} </>
         ),
      },
      {
         title: 'Business Entity',
         dataIndex: 'businessEntity',
         width: '11%',
         editable: true,
         render: (item: any, record: any) => (
            <>{record.businessEntity?.name} </>
         ),
      },
      {
         title: props.firstColumnTitle,
         dataIndex: 'name',
         width: '11%',
         editable: true,
      },
      {
         title: props.secondColumnTitle,
         dataIndex: 'username',
         width: '11%',
         align: 'center',
         editable: true,
         render: (item: any, record: any) => (
            <>{record.user.name + ' ' + record.user.surname} </>
         ),
      },
      {
         title: 'Operation',
         dataIndex: 'operation',
         align: 'center',
         width: '5%',
         render: (_: any, record: any) => {
            const editable = isEditing(record)
            return editable ? (
               <Space>
                  <Button
                     type="link"
                     icon={<SaveOutlined />}
                     onClick={() => saveRow(record.key, isNewRow(record))}
                     style={{
                        marginTop: '-3%',
                        // backgroundColor: '#ffa940',
                        color: '#fa8c16',
                        borderRadius: '10px',
                        width: '95px',
                     }}
                  >
                     Save{' '}
                  </Button>
                  <Button
                     type="link"
                     icon={<CloseCircleOutlined />}
                     onClick={(e) => cancelRow(record)}
                     style={{
                        marginLeft: '5%',
                        marginTop: '-3%',
                        // backgroundColor: '#8c8c8c',
                        color: '#8c8c8c',
                        borderRadius: '10px',
                     }}
                  >
                     Cancel
                  </Button>
               </Space>
            ) : (
               <Space>
                  <Button
                     type="link"
                     icon={<EditOutlined />}
                     onClick={() => editRow(record)}
                     style={{
                        marginTop: '-3%',
                        // backgroundColor: '#73d13d',
                        color: '#52c41a',
                        borderRadius: '10px',
                        width: '95px',
                     }}
                  >
                     Edit{' '}
                  </Button>
                  <Popconfirm
                     title={props.deleteMessage}
                     okText="Yes"
                     cancelText="No"
                     okButtonProps={{
                        type: 'default',
                        style: { color: '#ff4d4f', backgroundColor: 'white' },
                     }}
                     cancelButtonProps={{
                        type: 'primary',
                     }}
                     onConfirm={(event) => deleteRow(record)}
                  >
                     <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{
                           marginLeft: '5%',
                           marginTop: '-3%',
                           // backgroundColor: '#ff4d4f',
                           color: '#ff4d4f',
                           borderRadius: '10px',
                        }}
                     >
                        Delete
                     </Button>
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]

   // !!!Think again on the onCell,
   const mergedColumns = columns
      .filter((e) => e.title !== '')
      .map((col: any) => {
         if (!col.editable) {
            return col
         }
         return {
            ...col,
            onCell: (record: any) => ({
               record,
               inputType:
                  col.dataIndex === 'username' ||
                  col.dataIndex === 'businessEntity'
                     ? 'dropdown'
                     : 'text',
               dataIndex: col.dataIndex,
               title: col.title,
               editing: isEditing(record),
            }),
         }
      })

   return (
      <>
         <Row style={{ marginTop: '0.5%' }}>
            <Form form={form} component={false}>
               <Table
                  // TODO: Add row Key
                  style={{ width: '70%', marginLeft: '15%' }}
                  components={{
                     body: {
                        cell: EditableCell,
                     },
                  }}
                  bordered
                  dataSource={
                     DepartmentsStore.isFiltered
                        ? DepartmentsStore.pageDataFiltered
                        : DepartmentsStore.pageData
                  }
                  columns={mergedColumns}
                  rowClassName="editable-row"
               />
            </Form>
         </Row>
         <br />
         <Row>
            <Col span={9}></Col>

            <Col span={15}>
               <Button
                  onClick={addNewRow}
                  disabled={disable}
                  icon={<PlusCircleOutlined />}
                  style={{
                     backgroundColor: '#fa8c16',
                     borderColor: '#fa8c16',
                     color: 'white',
                     borderRadius: '10px',
                     width: '32%',
                     marginLeft: '2%',
                  }}
               >
                  {props.buttonName}
               </Button>
            </Col>
         </Row>
      </>
   )
})

export default EditableTable
