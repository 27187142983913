import React from 'react'
import {
   MenuUnfoldOutlined,
   MenuFoldOutlined,
   FilterOutlined,
   WarningOutlined,
} from '@ant-design/icons'
import Layout from 'antd/lib/layout'
import Menu from 'antd/lib/menu'
import { Card, Avatar } from 'antd'
import { useHistory } from 'react-router-dom'
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import './LayoutStyle.scss'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Topic } from '../../assets/topic.svg'
import { ReactComponent as Department } from '../../assets/department.svg'
import { ReactComponent as Status } from '../../assets/status.svg'
import { ReactComponent as UserSettings } from '../../assets/userSettings.svg'
import { deleteCookies } from '../../utils/AppHelpers'
import UserAvatar from '../../assets/avatar.png'
import { useStore } from '../../context'
import { observer } from 'mobx-react'

const { Header } = Layout
const { SubMenu } = Menu
const { Meta } = Card

interface IHeaderBar {
   collapsed: boolean
   handleOnCollapse: React.MouseEventHandler<HTMLButtonElement>
}

export const HeaderBar = observer((props: IHeaderBar) => {
   const history = useHistory()
   const { AuthStore, commonStore } = useStore()
   const { MainLayoutStore } = useStore()

   const getCollapseIcon = () => {
      if (props.collapsed) {
         return (
            <MenuUnfoldOutlined
               onClick={props.handleOnCollapse}
               className="trigger"
               data-testid="unfold-menu-icon"
            />
         )
      }
      return (
         <MenuFoldOutlined
            onClick={props.handleOnCollapse}
            className="trigger"
            data-testid="fold-menu-icon"
         />
      )
   }

   const onLogoutClick = () => {
      deleteCookies('jwt_token', '/', 'localhost')
      localStorage.setItem('userId', '')
      localStorage.setItem('jwt_token', '')
      localStorage.setItem('isAdmin', '')
      AuthStore.isAuthanticated = false
      commonStore.logOut()
      history.push({
         pathname: '/login',
         state: { logout: true },
      })
   }

   const onAvatarClick = () => {
      history.push('/RenewPassword')
      MainLayoutStore.setLocationPath('RenewPassword')
   }

   const menuItemOnClick = (event: any) => {      
      MainLayoutStore.setLocationPath(event.key)
   }

   return (
      <Header className="header" data-testid="header">
         <div className="header-collapse-icon">
            {window.innerWidth > 992 && getCollapseIcon()}
         </div>
         <Meta
            className="user-infos"
            avatar={
               <>
                  {/* <Dropdown overlay={menu} placement="bottomLeft"> */}
                  <div style={{ cursor: 'pointer' }} onClick={onAvatarClick}>
                     {/* <Badge
                        size="small"
                        count={6}
                        style={{ backgroundColor: 'rgb(250, 140, 22)' }}
                     > */}
                     <Avatar src={UserAvatar}></Avatar>
                     {/* </Badge> */}
                  </div>
                  {/* </Dr opdown> */}
               </>
            }
            title={commonStore.userFullname}
         ></Meta>

         <Menu className="menu" mode="horizontal" data-testid="settings-menu">
            {MainLayoutStore.commonStore.isAdmin && (
               <Menu.SubMenu
                  key={'filterSub'}
                  title={'Filters'}
                  icon={<FilterOutlined />}
               >
                  <Menu.Item
                     key="filter-1"
                     onClick={menuItemOnClick}
                     icon={<FilterOutlined />}
                  >
                     <NavLink to="/MyFilters" className="default-menu-text">
                        My filters
                     </NavLink>
                  </Menu.Item>
                  {MainLayoutStore.commonStore.isSupAdmin && (
                     <Menu.Item
                        key="filter-2"
                        onClick={menuItemOnClick}
                        icon={<FilterOutlined />}
                     >
                        <NavLink to="/Filters" className="default-menu-text">
                           All filters
                        </NavLink>
                     </Menu.Item>
                  )}
               </Menu.SubMenu>
            )}
            <SubMenu
               key="SubMenu"
               icon={<SettingOutlined />}
               title="Settings"
               className="default-menu-text"
            >
               {MainLayoutStore.commonStore.isAdmin && (
                  <>
                     <Menu.Item
                        key="2"
                        icon={<WarningOutlined />}
                        onClick={menuItemOnClick}
                     >
                        <NavLink to="/RiskLevels" className="default-menu-text">
                           Risk Levels
                        </NavLink>
                     </Menu.Item>
                     <Menu.Item
                        key="3"
                        icon={<Topic />}
                        onClick={menuItemOnClick}
                     >
                        <NavLink to="/Topics" className="default-menu-text">
                           Topics
                        </NavLink>
                     </Menu.Item>
                     <Menu.Item
                        key="4"
                        icon={<Status />}
                        onClick={menuItemOnClick}
                     >
                        <NavLink
                           to="/AssessmentStatus"
                           className="default-menu-text"
                        >
                           Assessment Status
                        </NavLink>
                     </Menu.Item>
                     <Menu.Item
                        key="5"
                        icon={<Department />}
                        onClick={menuItemOnClick}
                     >
                        <NavLink
                           to="/Departments"
                           className="default-menu-text"
                        >
                           Departments
                        </NavLink>
                     </Menu.Item>
                     <Menu.Item
                        key="6"
                        icon={<Department />}
                        onClick={menuItemOnClick}
                     >
                        <NavLink
                           to="/BusinessEntity"
                           className="default-menu-text"
                        >
                           Business Entities
                        </NavLink>
                     </Menu.Item>
                     <Menu.Item
                        key="7"
                        icon={<Department />}
                        onClick={menuItemOnClick}
                     >
                        <NavLink to="/Groups" className="default-menu-text">
                           Groups
                        </NavLink>
                     </Menu.Item>
                  </>
               )}
               <Menu.Item
                  key="8"
                  icon={<UserSettings />}
                  onClick={menuItemOnClick}
               >
                  <NavLink to="/Preferences" className="default-menu-text">
                     Preferences
                  </NavLink>
               </Menu.Item>
            </SubMenu>

            <Menu.Item
               key="LogoutSubMenu"
               icon={<LogoutOutlined />}
               onClick={onLogoutClick}
            >
               <span className="default-menu-text">Logout</span>
            </Menu.Item>
         </Menu>
      </Header>
   )
})
export default HeaderBar
