import {Helpers} from '../../api/Helpers'

export class FilterApi {
    public static getFilters = () => {
        return Helpers.get('/filter/filters')
    }

    public static getOtherFilters = () => Helpers.get('/filter/other')

    public static getPaginatedFilters = (page: number, pageSize: number) => Helpers.get(
        '/filter',
        '',
        null,
        {
            page,
            pageSize
        });

    public static getFilterById = (id: number) => {
        return Helpers.get('/filter/filters/' + id)
    }

    public static deleteFilter = (id: number) => {
        return Helpers.delete('/filter', id)
    }

    public static changeFilterStatus = (id: number, isActive: boolean) => {
        return Helpers.update('/filter/ChangeFilterStatus', id, isActive)
    }

    public static getCreateFilterInitData = () => {
        return Helpers.get('/filter/InitCreateFilter/2')
    }

    public static createFilter = (filterObject: any) => {
        return Helpers.post('/filter/CreateFilter', filterObject)
    }

    public static updateFilter = (filter: any) => {
        return Helpers.update('/filter/updateFilter', filter.id, filter)
    }

    public static getActiveFilterCount = () => {
        return Helpers.get('/filter/ActiveFilterCount')
    }

    public static getFilterPreferences = () => {
        return Helpers.get('/filter/FilterPreferences')
    }
    public static getPreferences = () => {
        return Helpers.get(
            '/settings/GetPreferences/' + localStorage.getItem('userId')
        )
    }
}
