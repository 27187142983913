import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Row, Col, Spin, Alert } from 'antd'
import { Typography } from 'antd'

import EditableTable from './components/EditableTable'

// import Item from 'antd/lib/list/Item' // Check it again

const { Title } = Typography
interface IRisk {
   endpoint: string
}

const Risks = observer((props: IRisk) => {
   const { SetttingsStore } = useStore()
   const pageTitle = 'Risk Levels'

   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)
      SetttingsStore.endpoint = props.endpoint
      SetttingsStore.getPageData() // Risk
      return () => {
         SetttingsStore.clearStore()
      }
   }, [])

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <Spin spinning={SetttingsStore.isLoading}>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            {/*  {SetttingsStore.isError && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={SetttingsStore.errorMessage}
                        type={SetttingsStore.errorType}
                        showIcon
                     />
                  </Col>
               </Row>
            )} */}

            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
               <Col style={{ textAlign: 'right' }} span={8}>
                  <Title
                     level={5}
                     style={{ marginLeft: '18%', color: '#8c8c8c' }}
                  >
                     <strong>Company Name:</strong> Reg-Room
                  </Title>
               </Col>
            </Row>
            <Row style={{ marginTop: '5%' }}>
               <Col span={24}>
                  <EditableTable
                     firstColumnTitle="Description"
                     secondColumnTitle="Rank"
                     deleteMessage="Your risk will be deleted permanently, would you like to continue?"
                     buttonName="Add New Risk"
                     isDraggable={false}
                  />
               </Col>
            </Row>
            <br />
            <br />
         </Spin>
      )
})
export default Risks
