import React from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import { Table, Tag } from 'antd'
import { RemoveAllTagFromString } from '../../../utils/Helpers'

const reportTableColumns = [
   {
      title: 'Date',
      dataIndex: 'createdDate',
      width: '12%',
   },
   {
      title: 'Actioned by',
      dataIndex: 'userFullName',
      width: '20%',
   },
   {
      title: 'Comment',
      dataIndex: 'content',
      render: (e: any, record: any) =>
         RemoveAllTagFromString(record.content).replaceAll('&nbsp;', ''),
   },
   {
      title: 'Risk Level',
      dataIndex: 'riskLevel',
      width: '10%',
   },
   {
      title: 'Topic',
      dataIndex: 'topic',
      width: '10%',
      render: (text: any, record: any) => (
         <>
            {record.topic.map((e: any) => (
               <Tag color="#5cdbd3"> {e.description} </Tag>
            ))}
         </>
      ),
   },
   {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
   },
   {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: '12%',
   },
]

interface IHistoryContent {}
export const HistoryContent = observer((props: IHistoryContent) => {
   const { ImpactAssessmentsStore } = useStore()

   return (
      <>
         <Table
            size="small"
            columns={reportTableColumns}
            dataSource={ImpactAssessmentsStore.historyThreads}
         ></Table>
      </>
   )
})
