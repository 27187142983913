import { Helpers } from '../../api/Helpers'
import { IUser } from '../../types/Types'

export class CompanyUsersApi {
   public static getCompanyUsers = (isActive = true) => {
      return Helpers.get('/User/CompanyUsers', '', null, { isActive })
   }
   public static updateUser = (record: any, isUpdate: boolean) => {
      return Helpers.update('/User', record.id, record)
   }
   public static getDepartments = () => {
      return Helpers.get('/settings/departments')
   }
   public static delete = (key: string) => {
      return Helpers.delete('/User', key)
   }
   public static getUserDepartments = () => {
      return Helpers.get('/UserDepartments')
   }
   public static getUserPermissions = () => {
      return Helpers.get('/UserDepartments')
   }
   public static updateUserStatus = (id: any, isActive: boolean) => {
      return Helpers.update('/User/UpdateUserStatus', id, isActive)
   }
   public static deleteUser = (id: any) => {
      return Helpers.delete('/User', id)
   }
   public static updatePw = (endpoint: string, data: any) => {
      return Helpers.update(endpoint, '', data)
   }
   public static getRoles = () => {
      return Helpers.get('/common/roles')
   }
   public static getUser = (id: number) => {
      return Helpers.get('/user/' + id)
   }
   public static createUser = (record: IUser) => {
      return Helpers.post('/user/NewUser', record)
   }
   public static sendPasswordToUser = (id: any) => {
      return Helpers.post('/user/sendPassword/toUser', { id: id })
   }

   public static sendUserPasswordToLoggedUser = (id: any) => {
      return Helpers.post('/user/sendPassword/toLoggedUser', {
         id: id,
      })
   }
   public static unlockUser = (id: any) => {
      return Helpers.update('/User/unlockUser', id)
   }
}
