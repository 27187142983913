import axios from 'axios'
import { isEmpty, compareVersions } from '../utils/Helpers'

const API = axios.create({
   baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
})

API.interceptors.response.use(function (response) {
   const serverVersion = response.headers['x-version']
   const currentVersion = process.env.REACT_APP_VERSION as string

   if (!isEmpty(serverVersion) && !isEmpty(currentVersion)) {
      let compareResult = compareVersions(currentVersion, serverVersion)
      if (compareResult === -1) {
         //window.localStorage.setItem('version-update-needed', 'true')
         window.location.reload()
      }
   }
   return response
})

export default API
