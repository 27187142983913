import React from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import { Row, Table, Modal, Button } from 'antd'
import { SaveOutlined, ReadOutlined } from '@ant-design/icons'
import plus from '../../../assets/plus.svg'
import moment from 'moment'

export interface IMiniAssessmentPageModal {
   onAddNewAssessment: any
}

const MiniAssessmentPageModal = observer((props: IMiniAssessmentPageModal) => {
   const { DailyRulesStore } = useStore()

   const onConfirm = () => {
      DailyRulesStore.setMiniAssessmentModalVisible(false)
   }

   const footerButtons = [
      <Button
         type="primary"
         style={{
            backgroundColor: 'rgb(250, 140, 22)',
            borderColor: 'rgb(250, 140, 22)',
            borderRadius: '10px',
            color: 'white',
         }}
         onClick={onConfirm}
         icon={<SaveOutlined />}
      >
         Close
      </Button>,
   ]

   const handleAddAssessment = (record: any) => {
      props.onAddNewAssessment({
         mainRuleId: record.dailyGlobalRuleId,
         mainRuleAuditCoreId: record.mainRuleAuditId,
      })
   }

   const columns = [
      {
         title: 'ID',
         dataIndex: 'dailyGlobalRuleId',
         width: '5%',
      },
      {
         title: 'Internal No',
         dataIndex: 'internalNumber',
         align: 'center' as const,
      },
      {
         title: 'Title',
         dataIndex: 'record.mainRule.shortTitle',
         render: (id: any, record: any) => record.mainRule.shortTitle,
         align: 'left' as const,
      },
      {
         title: 'Admin Name',
         dataIndex: 'allocatedByFullName',
         sorter: (a: any, b: any) =>
            a.allocatedByFullName.localeCompare(b.allocatedByFullName),
      },
      {
         title: 'Allocated On',
         dataIndex: 'allocationDate',
         sorter: (a: any, b: any) =>
            moment(a.allocationDate).diff(moment(b.allocationDate)),
      },
      {
         title: 'Allocated To',
         dataIndex: 'allocatedToUserNames',
         sorter: (a: any, b: any) =>
            a.allocatedToUserNames.localeCompare(b.allocatedToUserNames),
      },
      {
         title: 'Response Due by',
         dataIndex: 'dateResponseDue',
         sorter: (a: any, b: any) =>
            moment(a.dateResponseDue).diff(moment(b.dateResponseDue)),
      },
      {
         title: 'Changed On',
         sorter: (a: any, b: any) =>
            moment(a.followUpDate).diff(moment(b.followUpDate)),
         defaultSortOrder: 'descend' as const,
         dataIndex: 'followUpDate',
      },
      {
         title: 'Risk Level',
         dataIndex: 'riskLevel.description',
         sorter: (a: any, b: any) =>
            a.riskLevel.description?.localeCompare(b.riskLevel.description),
         render: (text: any, record: any) => (
            <>{record.riskLevel.description}</>
         ),
      },
      {
         title: 'Status',
         dataIndex: 'status',
         sorter: (a: any, b: any) =>
            a.status.value?.localeCompare(b.status.value),
         render: (text: any, record: any) => <>{record.status.value}</>,
      },
      {
         title: 'Topic',
         dataIndex: 'topic',

         render: (text: any, record: any) => <>{record.topic.description}</>,
      },
      {
         width: '7%',
         render: (text: any, record: any) => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
               <img
                  src={plus}
                  style={{ fontSize: '2ch', cursor: 'pointer' }}
                  onClick={() => handleAddAssessment(record)}
                  alt="Send an Assignment"
                  title='Assessment Form'
               />
               <a
                  style={{
                     color: 'rgb(250, 140, 22)',
                     borderColor: 'white',
                     cursor: 'pointer',
                  }}
                  href={
                     '/AssessmentForm/' +
                     record.assessmentId +
                     '/' +
                     record.mainRuleAuditId
                  }
                  target="_blank"
                  rel="noreferrer"
               >
                  <ReadOutlined style={{ fontSize: '3ch' }} />
               </a>
            </div>
         ),
      },
   ]

   return (
      <Modal
         title="Impact Assessments"
         open={DailyRulesStore.miniAssessmentModalVisible}
         okText="Close"
         onCancel={(e) => DailyRulesStore.setMiniAssessmentModalVisible(false)}
         footer={footerButtons}
         okButtonProps={{
            style: {
               backgroundColor: '#ff4d4f',
               borderColor: 'rgb(250, 140, 22)',
               borderRadius: '10px',
               color: 'white',
            },
            // icon: <SaveOutlined />,
         }}
         destroyOnClose={true}
         maskClosable={false}
         width={1200}
      >
         <Row>{/* <Text> Please give a comment before delete </Text> */}</Row>
         <Row style={{ marginTop: '2%' }}>
            <Table
               rowKey={(record) => record.assessmentId}
               columns={columns}
               dataSource={DailyRulesStore.assessmentByRuleList}
               size="small"
               pagination={{
                  position: ['bottomCenter'],
                  pageSize: 10,
               }}
            />
         </Row>
      </Modal>
   )
})

export default MiniAssessmentPageModal
