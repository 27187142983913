import { makeAutoObservable } from 'mobx'
import { FilterApi } from './FilterApi'
import FilterDto from './dtos/FilterDto'
import FilterObjectDto from './dtos/FilterObjectDto'
import { CommonStore } from '../../context/CommonStore'
import { IsUserSuperAdmin } from '../../utils/Helpers'
import { toast } from 'react-hot-toast'

export class FilterStore {
   isLoading: boolean = false
   isStatusChanging: boolean = false
   filter: FilterDto
   filterList: FilterObjectDto
   countries: any[] = []
   regulators: any[] = []
   allRegulators: any = []
   entityTypes: any[] = []
   functions: any[] = []
   products: any[] = []
   regions: any[] = []
   ruleTypes: any[] = []
   filterLevels = [
      { id: 1, name: 'Group' },
      { id: 2, name: 'Business Entity' },
      { id: 3, name: 'Department' },
      { id: 4, name: 'User' },
   ]
   redirectToResult: boolean = false
   selectedCountries: any[] = []
   selectedRegulators: any[] = []
   selectedFunctions: any[] = []
   selectedEntityTypes: any[] = []
   selectedProducts: any[] = []
   selectedRuleTypes: any[] = []
   selectedRegions: any[] = []
   isSavedSuccessfully: boolean = false
   showValidationMessage: boolean = false

   allowedActiveFilter: number = 0
   allowedDisableFilter: number = 0

   isValid: boolean = true
   validationMessage: string =
      'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.'
   filterNameValidationMessage: string = 'Filter name cannot be empty!'
   criteriaValidationMessage: string = 'Choose at least 1 criteria!'
   generalScreenValidationMessage: string =
      'Fill filter name and select at least 1 filter!'
   pageMessage: string =
      'You have reached the maximum number of active filters you are allowed to set up. Please contact your Manager or disable/delete/update some of your filters.'

   commonStore = new CommonStore()

   constructor(commonStore: CommonStore) {
      makeAutoObservable(this)
      this.filter = new FilterDto()
      this.filterList = new FilterObjectDto()
      this.commonStore = commonStore
   }

   loadPageData() {
      this.isLoading = true
      FilterApi.getCreateFilterInitData()
         .then((result) => {
            this.countries = result.countries
            let regulatorsTemp = result.regulators
            this.regulators = regulatorsTemp
            this.allRegulators = regulatorsTemp
            this.entityTypes = result.entityTypes
            this.functions = result.functions
            this.products = result.products
            this.regions = result.regions
            this.ruleTypes = result.ruleTypes
            // this.globalFilter = result.globalFilter
            if (!IsUserSuperAdmin() && !this.isEditMode)
               this.initializeGlobalFilter(result.globalFilter)
            this.isLoading = false
         })
         .catch((_) => {
            this.isLoading = false
         })
   }

   isEditMode: boolean = false
   setIsEditMode = (val: boolean) => {
      this.isEditMode = val
   }

   isRegionDisabled: boolean = false
   isCountryDisabled: boolean = false
   isRegulatorsDisabled: boolean = false
   isEntityTypesDisabled: boolean = false
   isRuleTypesDisabled: boolean = false
   isFunctionsDisabled: boolean = false
   isProductssDisabled: boolean = false

   initializeGlobalFilter(globalFilter: any) {
      if (globalFilter === null || globalFilter === undefined) return

      if (globalFilter.regions.length > 0) {
         this.filter.selectedRegionCodes = globalFilter.regions
         this.isRegionDisabled = true
      }

      if (globalFilter.countries.length > 0) {
         this.filter.selectedCountryCodes = globalFilter.countries
         this.isCountryDisabled = true
      }

      if (globalFilter.regulators.length > 0) {
         this.filter.selectedRegulatorCodes = globalFilter.regulators
         this.isRegulatorsDisabled = true
      }

      if (globalFilter.entityTypes.length > 0) {
         this.filter.selectedEntityTypeCodes = globalFilter.entityTypes
         this.isEntityTypesDisabled = true
      }

      if (globalFilter.ruleTypes.length > 0) {
         this.filter.selectedRuleTypeCodes = globalFilter.ruleTypes
         this.isRuleTypesDisabled = true
      }

      if (globalFilter.functions.length > 0) {
         this.filter.selectedFunctionCodes = globalFilter.functions
         this.isFunctionsDisabled = true
      }

      if (globalFilter.products.length > 0) {
         this.filter.selectedProductCodes = globalFilter.products
         this.isProductssDisabled = true
      }
   }

   createFilter(data: object | null = null) {
      if (!this.screenValidator(data)) return
      this.isLoading = true
      toast.promise(FilterApi.createFilter(data ?? this.newFilterToJson()), {
         loading: 'Creating filter...',
         success: (result) => {
            this.isLoading = false
            if (result.errorCode === 0) {
               this.redirectToResult = true
            } else {
               return result.additionalMessage
            }
            return 'Filter created successfully!'
         },
         error: () => {
            this.isLoading = false
            return 'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.'
         },
      })
   }

   getFilters() {
      this.isLoading = true

      FilterApi.getFilters()
         .then((result) => {
            this.filterList.filters = result.filters
         })
         .catch((err) => {
            console.error(err)
         })
         .finally(() => (this.isLoading = false))
   }

   getPaginatedFilters(page: number = 1, pageSize: number) {
      this.isLoading = true

      FilterApi.getPaginatedFilters(page, pageSize)
         .then((result) => {
            this.filterList.filters = result.results
            this.filterList.pageSize = result.pageSize
            this.filterList.pageNumber = result.pageNumber
            this.filterList.totalRecords = result.totalRecords
            this.filterList.totalPages = result.totalPages
         })
         .catch((err) => {
            console.error(err)
         })
         .finally(() => (this.isLoading = false))
   }

   async getOtherFilters() {
      this.isLoading = true

      try {
         const result = await FilterApi.getOtherFilters()
         this.filterList.filters = result.filters
      } catch (err) {
         console.error(err)
      } finally {
         this.isLoading = false
      }
   }

   getFilterById(id: number) {
      FilterApi.getFilterById(id)
         .then((result) => {
            this.filterList.filters = result.filters
            if (
               Array.isArray(this.filterList.filters) &&
               this.filterList.filters.length
            ) {
               const selectedFilter = this.filterList.filters[0]
               const tempFilter = new FilterDto()

               this.selectedCountries = selectedFilter.countries.map(
                  (item: any) => {
                     return { key: item.countryId }
                  }
               )
               this.selectedRegulators = selectedFilter.regulators.map(
                  (item: any) => {
                     return { key: item.regulatorId }
                  }
               )
               this.selectedEntityTypes = selectedFilter.entityTypes.map(
                  (item: any) => {
                     return { key: item.entityTypeId }
                  }
               )
               this.selectedRuleTypes = selectedFilter.ruleTypes.map(
                  (item: any) => {
                     return { key: item.id }
                  }
               )
               this.selectedFunctions = selectedFilter.functions.map(
                  (item: any) => {
                     return { key: item.key }
                  }
               )
               this.selectedProducts = selectedFilter.products.map(
                  (item: any) => {
                     return { key: item.key }
                  }
               )
               this.selectedRegions = selectedFilter.regions.map(
                  (item: any) => {
                     return item.regionId
                  }
               )

               tempFilter.filterId = selectedFilter.id
               tempFilter.filterName = selectedFilter.filterName
               tempFilter.freeTextFilter = selectedFilter.freeText
               tempFilter.filterLevel = selectedFilter.filterLevel.id
               tempFilter.searchByReference = selectedFilter.referenceText

               // TODO : Consider selectedFilter.countries && instead using ? for optimized performance.
               tempFilter.selectedCountryCodes = selectedFilter.countries?.map(
                  (x: any) => x.countryId
               )
               tempFilter.selectedRegulatorCodes = selectedFilter.regulators?.map(
                  (x: any) => x.regulatorId
               )
               tempFilter.selectedEntityTypeCodes = selectedFilter.entityTypes?.map(
                  (x: any) => x.code
               )
               tempFilter.selectedRuleTypeCodes = selectedFilter.ruleTypes?.map(
                  (x: any) => x.name
               )
               tempFilter.selectedFunctionCodes = selectedFilter.functions?.map(
                  (x: any) => x.value
               )
               tempFilter.selectedProductCodes = selectedFilter.products?.map(
                  (x: any) => x.value
               )
               tempFilter.selectedRegionCodes = selectedFilter.regions?.map(
                  (x: any) => x.code
               )

               this.setFilter(tempFilter)
            }
         })
         .catch((err) => {
            console.error(err)
            // this.isLoading = false;
         })
   }
   showMaxActiveFilterError: boolean = false
   createFilterButtonDisabled: boolean = false
   currentActiveFilterCount: number = 0
   getActiveFilterCount() {
      this.showMaxActiveFilterError = false
      FilterApi.getActiveFilterCount()
         .then((result) => {
            if (result === undefined || result === null) {
               return
            }

            this.currentActiveFilterCount = result.activeFilterCount
         })
         .catch((_) => {})
   }

   showPageMessage() {
      if (this.currentActiveFilterCount > this.allowedActiveFilter - 1) {
         this.createFilterButtonDisabled = true
         return (this.showMaxActiveFilterError = true)
      } else {
         this.createFilterButtonDisabled = false
         return (this.showMaxActiveFilterError = false)
      }
   }

   getPreferences() {
      FilterApi.getPreferences()
         .then((result) => {
            this.allowedActiveFilter =
               result.filterCountPreference.activeFilterCount
         })
         .catch((_) => {})
   }

   clearStore() {
      this.redirectToResult = false
   }

   updateFilter(data: object | null = null) {
      const isPageValid = this.screenValidator(data)

      if (!isPageValid) return

      this.isLoading = true
      FilterApi.updateFilter(data ?? this.updateFilterToJson())
         .then((_) => {
            this.isLoading = false
            this.redirectToResult = true
         })
         .catch((_) => {
            this.isLoading = false
         })
   }

   allowedRemainFilterCount() {
      return (
         this.filterList.totalAllowedFilterCount -
         this.filterList.filters.filter((e) => e.isActive === true).length
      )
   }

   disabledFilterCount() {
      return this.filterList.filters.filter((e) => e.isActive === false).length
   }

   activeFilterCount() {
      return this.filterList.filters.filter((e) => e.isActive === true).length
   }

   disableFilterCount() {
      return this.filterList.filters.filter((e) => e.isActive === false).length
   }

   checkIsSwitchValid(params: boolean) {
      return params === true
         ? this.activeFilterCount() < this.allowedActiveFilter
         : this.disableFilterCount() < this.allowedDisableFilter
   }

   changeFilterStatus(id: number, params: any) {
      let isActive = false
      this.isStatusChanging = true

      //if (this.isValid)
      const filters = [...this.filterList.filters]
      const index = filters.findIndex((value) => value.id === id)
      const item = { ...this.filterList.filters[index] }
      item.isActive = !item.isActive
      filters[index] = item
      this.filterList.filters = filters
      isActive = item.isActive

      FilterApi.changeFilterStatus(id, isActive)
         .then((result) => {
            this.isStatusChanging = false
         })
         .catch((err) => {
            this.isStatusChanging = false
         })
   }

   getFilterPreferences() {
      FilterApi.getFilterPreferences()
         .then((result) => {
            this.allowedActiveFilter =
               result.filterCountPreference.activeFilterCount
            this.allowedDisableFilter =
               result.filterCountPreference.disabledFilterCount
         })
         .catch((err) => {
            this.isStatusChanging = false
         })
   }

   wildcardValidator = () => {
      if (
         (this.filter.searchByReference.length < 4 &&
            this.filter.searchByReference.includes('*')) ||
         (this.filter.freeTextFilter.length < 4 &&
            this.filter.freeTextFilter.includes('*'))
      ) {
         this.validationMessage =
            'Wildcard is not allowed in a term with less than 3 characters.'
         return true
      } else if (this.filter.freeTextFilter.charAt(0) === '*') {
         this.validationMessage =
            '* are not permitted at the beginning of a search term or query'
         return true
      } else return false
   }

   clearSelectedRegulatories() {
      this.filter.selectedRegulatorCodes = []
   }

   filterRegulatory() {
      if (
         this.selectedCountries !== null &&
         this.selectedCountries.length > 0
      ) {
         let countryIds = this.selectedCountries.map((e: any) => {
            return parseInt(e.key)
         })
         this.regulators = this.allRegulators
            ?.filter((e: any) => countryIds.includes(e.countryId))
            .sort((a: any, b: any) => a.code.localeCompare(b.code))
      } else {
         this.regulators = this.allRegulators
      }
   }

   screenValidator = (data: object | null = null) => {
      if (this.wildcardValidator()) return (this.isValid = false)

      if (!this.filter.filterName) {
         this.validationMessage = this.filterNameValidationMessage
         return (this.isValid = false)
      }
      if (
         this.filter.filterName !== '' &&
         this.filter.searchByReference !== '' &&
         this.filter.filterLevel !== ''
      ) {
         return (this.isValid = true)
      } else if (
         data === null &&
         !this.filter.selectedCountryCodes.length &&
         !this.filter.selectedEntityTypeCodes.length &&
         !this.filter.selectedRegulatorCodes.length &&
         !this.filter.selectedRuleTypeCodes.length &&
         !this.filter.selectedFunctionCodes.length &&
         !this.filter.selectedProductCodes.length &&
         !this.filter.selectedRegionCodes.length &&
         this.filter.freeTextFilter === '' &&
         this.filter.filterName === ''
      ) {
         this.validationMessage = this.generalScreenValidationMessage
         return (this.isValid = false)
      } else if (data) {
         const removed = ['id', 'referenceText', 'filterLevel', 'filterName']
         const entries = Object.entries(data).filter(
            ([key, _]) => !removed.includes(key)
         )
         const values = entries
            .map((value) => value[1])
            .filter((value) => !removed.includes(value))
         const isEmpty = values.some((value) => value.length > 0)
         if (!isEmpty) {
            this.validationMessage = this.generalScreenValidationMessage
            this.isValid = false
            return (this.isValid = false)
         }
         this.isValid = true
         return (this.isValid = true)
      } else return (this.isValid = true)
   }

   filterNameOnChange = (value: any) => {
      this.filter.filterName = value
   }

   setIsPageValid = (isValid: boolean) => {
      this.isValid = isValid
   }

   setFilter = (filter: FilterDto) => {
      this.filter = filter
   }

   onFreeTextOnChange = (e: any) => {
      this.filter.freeTextFilter = e.target.value
   }
   onSearchByReference = (e: any) => {
      this.filter.searchByReference = e.target.value
   }

   onValidationMessageClose = () => {
      this.isValid = true
   }

   setFilterLevel = (e: any) => {
      this.filter.filterLevel = e
   }

   setSelectedCountries = (e: any, event: any) => {
      this.filter.selectedCountryCodes = e
      this.selectedCountries = event
   }
   setSelectedRegulators = (regulatorCodes: any, event: any) => {
      this.filter.selectedRegulatorCodes = regulatorCodes
      this.selectedRegulators = event
   }
   setSelectedEntityTypes = (e: any, event: any) => {
      this.filter.selectedEntityTypeCodes = e
      this.selectedEntityTypes = event
   }
   setSelectedFunctions = (e: any, event: any) => {
      this.filter.selectedFunctionCodes = e
      this.selectedFunctions = event
   }
   setSelectedProducts = (e: any, event: any) => {
      this.filter.selectedProductCodes = e
      this.selectedProducts = event
   }
   setSelectedRegions = (e: any, event: any) => {
      this.filter.selectedRegionCodes = e
      this.selectedRegions = event
   }
   setSelectedRuleTypes = (e: any, event: any) => {
      this.filter.selectedRuleTypeCodes = e
      this.selectedRuleTypes = event
   }

   setAllowedActiveFilter = (val: number) => {
      this.allowedActiveFilter = val
   }

   setAllowedDisableFilter = (val: number) => {
      this.allowedDisableFilter = val
   }

   deleteFilter = (filterId: number) => {
      const newData = [...this.filterList?.filters]
      const index = newData.findIndex((e) => e.id === filterId)
      newData.splice(index, 1)

      // TODO : Improve error handling.
      FilterApi.deleteFilter(filterId)
         .then((result) => {
            this.updatePageData(newData)
         })
         .catch((err) => {})
   }

   updatePageData = (newData: any[]) => {
      this.filterList.filters = newData
   }

   clearCreateFilter() {
      this.selectedCountries = []
      this.selectedRegulators = []
      this.selectedFunctions = []
      this.selectedEntityTypes = []
      this.selectedProducts = []
      this.selectedRuleTypes = []
      this.selectedRegions = []
      this.isRegionDisabled = false
      this.isRegionDisabled = false
      this.isCountryDisabled = false
      this.isRegulatorsDisabled = false
      this.isEntityTypesDisabled = false
      this.isRuleTypesDisabled = false
      this.isFunctionsDisabled = false
      this.isProductssDisabled = false
      this.isEditMode = false
   }

   newFilterToJson(): any {
      return {
         filterName: this.filter.filterName,
         filterLevel: parseInt(this.filter.filterLevel), // {"id":1, "name":"Global"},
         countries: this.selectedCountries.map((item: any) =>
            parseInt(item.key)
         ),
         regulators: this.selectedRegulators.map((item: any) =>
            parseInt(item.key)
         ),
         entityTypes: this.selectedEntityTypes.map((item: any) =>
            parseInt(item.key)
         ),
         ruleTypes: this.selectedRuleTypes.map((item: any) =>
            parseInt(item.key)
         ),
         functions: this.selectedFunctions.map((item: any) =>
            parseInt(item.key)
         ),
         products: this.selectedProducts.map((item: any) => parseInt(item.key)),
         regions: this.selectedRegions.map((item: any) => parseInt(item.key)),
         freeText: this.filter.freeTextFilter,
         referenceText: this.filter.searchByReference,
         reports: [],
         typeOfRuleDate: 1,
      }
   }

   // This is for update.
   updateFilterToJson(): any {
      return {
         id: this.filter.filterId,
         filterName: this.filter.filterName,
         filterLevel: this.filter.filterLevel,
         countries: this.selectedCountries.map((item: any) =>
            parseInt(item.key)
         ),
         regulators: this.selectedRegulators.map((item: any) =>
            parseInt(item.key)
         ),
         entityTypes: this.selectedEntityTypes.map((item: any) =>
            parseInt(item.key)
         ),
         ruleTypes: this.selectedRuleTypes.map((item: any) =>
            parseInt(item.key)
         ),
         functions: this.selectedFunctions.map((item: any) =>
            parseInt(item.key)
         ),
         products: this.selectedProducts.map((item: any) => parseInt(item.key)),
         regions: this.selectedRegions.map((item: any) => parseInt(item.key)),
         freeText: this.filter.freeTextFilter,
         referenceText: this.filter.searchByReference,
         reports: [],
      }
   }

   showErrorMessage(
      message: string = 'Connection error occurred. Please get in touch with administrator.'
   ) {
      toast.error(message)
   }
}
