import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { useStore } from './context/index'

const NotFound = () => {
   const { AuthStore } = useStore()
   AuthStore.isAuthenticated()
   const history = useHistory()

   return (
      // <Status code={404}>
      <div style={{ backgroundColor: 'rgb(249 242 231)', height: '100ch', padding:'20px' }}>
         <h3
            style={{
               display: 'flex',
               justifyContent: 'center',
               fontSize: '10ch'
            }}
         >
            
         </h3>
         <div
            style={{
               display: 'flex',
               justifyContent: 'center',
               fontSize: '2.5ch',
               textAlign:'center'
            }}
         >
            <span>You may have reached here from a link or a wrong URL.
               Please click on the button below to log in. <br></br>
               If you are already logged in, use the menu on the left to go to your desired page</span>
         </div>
         <div
            style={{
               display: 'flex',
               justifyContent: 'center',
               marginTop: '15px',
            }}
         >
            {AuthStore.isAuthanticated ? (
               <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                     backgroundColor: 'rgb(250, 140, 22)',
                     borderColor: 'rgb(250, 140, 22)',
                     borderRadius: '10px',
                     color: 'white',
                     marginTop:'2em'
                  }}
                  onClick={(e) => history.push('/impactassessments')}
               >
                  Return to impact assessment
               </Button>
            ) : (
               <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                     backgroundColor: 'rgb(250, 140, 22)',
                     borderColor: 'rgb(250, 140, 22)',
                     borderRadius: '10px',
                     color: 'white',
                  }}
                  onClick={(e) => history.push('/login')}
               >
                  Return to login page
               </Button>
            )}
         </div>
      </div>
      // </Status>
   )
}

export default NotFound
