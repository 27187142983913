import React, { useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import {
   Typography,
   Row,
   Col,
   Select,
   Modal,
   Tag,
   Alert,
   Button,
   Table,
} from 'antd'

import { CompactPicker } from 'react-color'
import { CloseOutlined } from '@ant-design/icons'
import { PlusCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
const { Option } = Select

export interface IAssessmentColor {}

const AssessmentColor = observer((props: IAssessmentColor) => {
   const { SetttingsStore } = useStore()

   const tableColumns = [
      {
         title: 'Status',
         dataIndex: 'highlightStatus',
         width: '40%',
      },
      {
         title: 'Color',
         dataIndex: 'highlightColor',
         width: '20%',
         render: (_: any, record: any) => {
            return (
               <Tag color={record.highlightColor}>{record.highlightColor}</Tag>
            )
         },
      },
      {
         title: 'Due By',
         dataIndex: 'highlightDay',
         width: '22%',
      },
      {
         dataIndex: 'operation',
         width: '25%',
         render: (_: any, record: any) => {
            return (
               <Button
                  type="link"
                  icon={<CloseOutlined />}
                  style={{
                     marginTop: '-3%',
                     color: '#ff4d4f',
                     borderRadius: '10px',
                  }}
                  onClick={(e: any) =>
                     SetttingsStore.onHighlightDelete(record.id)
                  }
               >
                  Delete
               </Button>
            )
         },
      },
   ]

   const assessmentStatusOptions = SetttingsStore.assessmentStatuses?.map(
      (status: any) => {
         return (
            <Option value={status.key} label={status.value} key={status.key}>
               {status.value}
            </Option>
         )
      }
   )

   const dueByOptions = [1, 2, 3, 4, 5, 6, 7].map((e: any) => {
      return (
         <Option value={e} label={e + '-day'} key={e}>
            {e + '-day'}
         </Option>
      )
   })

   const openAssessmentColorModal = (params: any) => {
      SetttingsStore.setIsAssessmentHighlightModalVisible(true)
   }

   const [color, setColor] = useState({
      primaryColor: '#f50',
      errorColor: '#ff4d4f',
      warningColor: '#faad14',
      successColor: '#52c41a',
      infoColor: '#1890ff',
   })

   const onColorChange = (nextColor: any) => {
      SetttingsStore.setHighlightColor(nextColor)
      const mergedNextColor = {
         ...color,
         ...nextColor,
      }
      setColor(mergedNextColor)
   }

   return (
      <>
         <br />
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col>
               <Button
                  onClick={openAssessmentColorModal}
                  icon={<PlusCircleOutlined />}
                  type="link"
                  style={{
                     borderRadius: '10px',
                     color: 'rgb(250, 140, 22)',
                     marginLeft: '5%',
                  }}
               >
                  <span style={{ textDecoration: 'underline' }}> Add </span>
               </Button>
            </Col>
         </Row>
         <Table
            size="small"
            columns={tableColumns}
            dataSource={SetttingsStore.highlightPreferences}
            style={{ width: '70%', marginLeft: '4%  ' }}
         ></Table>

         <Modal
            title="Add new preference for Assessment highlight"
            style={{ backgroundColor: 'grey' }}
            open={SetttingsStore.isAssessmentHighlightModalVisible}
            onCancel={(e: any) => {
               SetttingsStore.setIsAssessmentHighlightModalVisible(false)
            }}
            onOk={(e: any) => SetttingsStore.onSaveHigligthPreference()}
            //  footer={footerButtons}
            afterClose={() => SetttingsStore.clearHighlightObject()}
            destroyOnClose={true}
            width="40%"
         >
            {SetttingsStore.isError && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={SetttingsStore.errorMessage}
                        type="error"
                        showIcon
                     />
                  </Col>
               </Row>
            )}
            <Row style={{ marginTop: '2%' }}>
               <Col span={5}></Col>
               <Col span={10} style={{ display: 'grid', marginLeft: '7%' }}>
                  <Text> Status:</Text>
                  <Select
                     placeholder="Select Status"
                     size="small"
                     value={SetttingsStore.selectedHighlightStatus}
                     onChange={SetttingsStore.onHighlightStatusChange}
                     optionLabelProp="label"
                  >
                     {assessmentStatusOptions}
                  </Select>{' '}
               </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
               <Col span={5}></Col>
               <Col span={10} style={{ display: 'grid', marginLeft: '7%' }}>
                  <Text> Due by:</Text>
                  <Select
                     placeholder="Highlight before"
                     size="small"
                     value={SetttingsStore.selectedHighlightDay}
                     onChange={SetttingsStore.onSelectedHighlightDayChange}
                     optionLabelProp="label"
                  >
                     {dueByOptions}
                  </Select>{' '}
               </Col>
            </Row>
            <Row style={{ marginTop: '2%', marginBottom: '4%' }}>
               <Col span={5}></Col>
               <Col span={10} style={{ display: 'grid', marginLeft: '7%' }}>
                  <Text> Color:</Text>
                  <CompactPicker
                     // presetColors={[
                     //    '#f50',
                     //    'rgb(250, 140, 22)',
                     //    '#1890ff',
                     //    '#25b864',
                     // ]}
                     color={color.primaryColor}
                     onChange={({ hex }) => {
                        onColorChange({
                           primaryColor: hex,
                        })
                     }}
                  />
               </Col>
            </Row>
         </Modal>
      </>
   )
})

export default AssessmentColor
