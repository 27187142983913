import { Helpers } from './Helpers'

export class CommonApi {
   public static init = (userId: number) => {
      return Helpers.get('/Common/userReferenceData/' + userId)
   }

   public static logOut = () => {
      return Helpers.get('/auth/logout')
   }

   public static getOrganisationGraph = () => {
      return Helpers.get('/User/GetOrganisationalGraph')
   }

   public static checkIsAuthanticated(): Promise<any> {
      return Helpers.get('/common/checkIsAuthanticated')
   }
}
