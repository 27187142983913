import { PropsWithChildren, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '../context'
import { intersection } from '../helpers'

type ICan = {
   roles: string[]
}

const Can = ({ roles, children }: PropsWithChildren<ICan>): any => {
   const { commonStore } = useStore()

   const isAuthorized = useMemo<boolean>(
      () =>
         intersection(
            [...roles],
            commonStore.userRoles.map((r) => (r as any).roleName)
         ).length > 0,
      [roles, commonStore.userRoles]
   )

   return (roles.length === 0 || isAuthorized) && children
}

export default observer(Can)
