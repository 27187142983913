import { Helpers } from '../../api/Helpers'
import { objectToFormData } from '../../helpers'
import { DailyRuleList } from '../../mocks/DailyRules'

export class DailyRulesApi {
   public static getDailyRuleList(
      dateType: string,
      startDate: string,
      endDate: string,
      isNonAssessed: string,
      selectedFilterIds: number[]
   ): Promise<any> {
      const response = Helpers.get(
         '/DailyRules/filter?dateType=' +
            dateType +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&isNonAssessed=' +
            isNonAssessed,
         '',
         selectedFilterIds
      )
      return response
   }

   public static getRuleDetails(ruleId: number, auditId: string): Promise<any> {
      const response = Helpers.get('/DailyRules/' + ruleId + '/' + auditId)
      return response
   }

   public static getNonAssessedRuleList(
      startDate: string,
      endDate: string
   ): Promise<any> {
      const response = Helpers.get(
         '/DailyRules/NonAssessedRules?startDate=' +
            startDate +
            '&endDate=' +
            endDate
      )
      return response
   }

   public static getAssessments(ruleId: number) {
      const response = Helpers.get('/ImpactAssessment/GetAssessments/' + ruleId)
      return response
   }

   public static getUsersByDepartment(): Promise<any> {
      const response = Helpers.get('/User/CompanyUsersByDepartment')
      return response
   }

   public static getInitData(): Promise<any> {
      const response = Helpers.get('/ImpactAssessment/InitData')
      return response
   }

   public static sendAssessment(data: any): Promise<any> {
      const formData = objectToFormData(data)
      const response = Helpers.post('/ImpactAssessment/SendAssessment', formData)
      return response
   }

   public static removeRules(data: any): Promise<any> {
      const response = Helpers.post('/DailyRules/RemoveDailyRules', data)
      return response
   }

   public static getActiveFilterNames(): Promise<any> {
      const response = Helpers.get('/Filter/FilterNames')
      return response
   }

   public static getPreferences(endpoint: string): Promise<any> {
      return Helpers.get(endpoint)
   }

   public static getFilters(): Promise<any> {
      return Helpers.get('/filter/filters')
   }

   public static downloadNonAssessedRules(ruleIds: any): Promise<any> {
      const response = Helpers.post(
         '/dailyrules/downloadnonassessedrules',
         ruleIds
      )
      return response
   }
}
