import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Alert, Row, Spin, Col } from 'antd'
import { Typography } from 'antd'

import DndTable from './components/DndTable'
// import Item from 'antd/lib/list/Item' // Check it again

const { Title } = Typography

interface ITopics {
   endpoint: string
}

const Topics = observer((props: ITopics) => {
   const { SetttingsStore } = useStore()
   const pageTitle = 'Topics' // Dont DRY. Extract component and use everywhere.

   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)
      SetttingsStore.endpoint = props.endpoint
      SetttingsStore.getPageData() // Topic
   }, [])

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <Spin spinning={SetttingsStore.isLoading}>
            {SetttingsStore.isError && (
               <Alert message={SetttingsStore.errorMessage} type="error" />
            )}
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
               <Col style={{ textAlign: 'right' }} span={8}>
                  <Title
                     level={5}
                     style={{ marginLeft: '18%', color: '#8c8c8c' }}
                  >
                     <strong>Company Name:</strong> Reg-Room
                  </Title>
               </Col>
            </Row>
            <DndTable />
            <br />
            <br />
         </Spin>
      )
})
export default Topics
