import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Row, Col, Spin, Alert } from 'antd'
import { Typography } from 'antd'
import OrganisationalChart from '../../components/OrganisationalChart'

import EditableTable from './components/EditableTable'

const { Title } = Typography

interface IGroups {
   endpoint: string
}

const Groups = observer((props: IGroups) => {
   const { SetttingsStore } = useStore()
   const pageTitle = 'Groups'
   //    const validationMessage = `Your Risk Level will be deleted permanently, would you like to continue?`

   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)
      SetttingsStore.endpoint = props.endpoint
      SetttingsStore.getPageData()
      return () => {
         SetttingsStore.clearStore()
      }
   }, [])

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <Spin spinning={SetttingsStore.isLoading}>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            {/* {SetttingsStore.isError && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={SetttingsStore.errorMessage}
                        type={SetttingsStore.errorType}
                        showIcon
                     />
                  </Col>
               </Row>
            )} */}
            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
            </Row>
            <Row style={{ marginTop: '5%' }}>
               <Col span={3}> </Col>
               <Col span={12} style={{ marginLeft: '2%' }}>
                  <OrganisationalChart initialDepth={2} />
               </Col>
               <Col span={5} style={{ marginLeft: '-0.5%' }}></Col>
               <Col span={3}> </Col>
            </Row>
            <EditableTable
               firstColumnTitle="Group Name"
               secondColumnTitle=""
               deleteMessage="Group will be deleted permanently, would you like to continue?"
               buttonName="Add New Group"
               isDraggable={false}
            />
            <br />
            <br />
         </Spin>
      )
})
export default Groups
