import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Button, Row, Col, Typography } from 'antd'
import {
   CheckCircleOutlined,
   RightCircleOutlined,
   PlusCircleOutlined,
   LockOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Divider } from 'antd'
import { useStore } from '../../context/index'
const { Text, Paragraph } = Typography

export interface IUserUpdateResultPage {
   isNewUser: string
}

export const UserUpdateResultPage = observer((props: IUserUpdateResultPage) => {
   const history = useHistory()
   const { CompanyUsersStore } = useStore()
   const params = useParams<IUserUpdateResultPage>()

   return (
      <div>
         <Row style={{ justifyContent: 'center' }}>
            <CheckCircleOutlined
               style={{
                  fontSize: '50px',
                  color: 'green',
                  margin: '3%',
                  justifyContent: 'center',
               }}
            />
         </Row>
         {params.isNewUser === 'true' && (
            <>
               <Row style={{ justifyContent: 'center' }}>
                  <Text>The user has been created successfully!</Text>
               </Row>
               <Row style={{ justifyContent: 'center', marginTop: '2%' }}>
                  <Col span={2}>
                     {' '}
                     <>
                        <Text>Password:</Text>{' '}
                     </>
                  </Col>
                  <Col>
                     <Paragraph copyable strong mark>
                        {CompanyUsersStore.userPassword}
                     </Paragraph>
                  </Col>
               </Row>
            </>
         )}
         {params.isNewUser === 'false' && (
            <Row style={{ justifyContent: 'center' }}>
               <Text>The User has been updated successfully!</Text>
            </Row>
         )}
         <Divider
            dashed
            className="dashed"
            style={{ justifyContent: 'center', marginTop: '25%' }}
         />
         <Row style={{ justifyContent: 'center', marginTop: '3%' }}>
            <Button
               type="link"
               icon={<PlusCircleOutlined />}
               onClick={() => history.push('/Departments')}
            >
               Create new department
            </Button>
            <Button
               type="link"
               icon={<RightCircleOutlined />}
               onClick={() => history.push('/CompanyUsers')}
               style={{ marginLeft: '3%' }}
            >
               Go to Company Users
            </Button>
            {/* TEST IT */}
            {/* <Button
               type="link"
               onClick={() => CompanyUsersStore.sendPasswordToLoggedUser()}
               style={{ marginLeft: '3%' }}
               icon={<LockOutlined />}
            >
               Send new password to 'me'{' '}
            </Button> */}
         </Row>
      </div>
   )
})
