import { Helpers } from '../../api/Helpers'

export class NewsletterApi {
   public static getNewsletterList(
      startDate: string,
      endDate: string
   ): Promise<any> {
      const response = Helpers.get(
         '/Newsletter/MyNewsletters?startDate=' +
            startDate +
            '&endDate=' +
            endDate
      )
      return response
   }

   public static getNewsletterDetails(newsletterId: number): Promise<any> {
      const response = Helpers.get('/Newsletter/GetNewsletter/' + newsletterId)
      return response
   }
   public static removeNewsletter(data: number): Promise<any> {
      const response = Helpers.post('/Newsletter/RemoveNewsletter', data)
      return response
   }

   public static shareNewsletter(data: number): Promise<any> {
      const response = Helpers.post('/Newsletter/ShareNewsletter', data)
      return response
   }

  }
