import { useEffect, useState } from 'react'
import {
   PieChart,
   Pie,
   ResponsiveContainer,
   Sector,
   Legend,
   Cell,
} from 'recharts'
import { Row, Col, Select, Card, Skeleton, Alert } from 'antd'
import { Color } from '../../../../../utils/Color'
import { useStore } from '../../../../../context'
import { RangeValue } from '../CustomRangePicker'
import moment from 'moment'
import { observer } from 'mobx-react'

const selectOptions = [
   {
      value: 'RuleType',
      label: 'Rule Type',
   },
   {
      value: 'RiskLevel',
      label: 'Risk Levels',
   },
   {
      value: 'Topic',
      label: 'Topics',
   },
   {
      value: 'AssessmentStatus',
      label: 'Assessment Status',
   },
]

const chartInfos = {
   RuleType: {
      chartLabel: 'My Allocated Assessments by Rule Type',
      chartName: 'My Allocated Assessments by Rule Type',
      chartDescription:
         'This graph describes the breakdown of the assessments you have allocated during the selected period, grouped by Rule Type',
   },
   RiskLevel: {
      chartLabel: 'My Allocated Assessments by Risk Level',
      chartName: 'My Allocated Assessments by Risk Level',
      chartDescription:
         'This graph describes the breakdown of the assessments you have allocated during the selected period, grouped by Risk Level',
   },
   AssessmentStatus: {
      chartLabel: 'My Allocated Assessments by Status',
      chartName: 'My Allocated Assessments by Status',
      chartDescription:
         'This graph describes the breakdown of the assessments you have allocated during the selected period, grouped by Status',
   },
   Topic: {
      chartLabel: 'My Allocated Assessments by Topics',
      chartName: 'My Allocated Assessments by Topics',
      chartDescription:
         'This graph describes the breakdown of the assessments you have allocated during the selected period, grouped by Topics',
   },
} as any

const renderActiveShape = (props: any) => {
   const RADIAN = Math.PI / 180
   const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
   } = props
   const sin = Math.sin(-RADIAN * midAngle)
   const cos = Math.cos(-RADIAN * midAngle)
   const sx = cx + (outerRadius + 10) * cos
   const sy = cy + (outerRadius + 10) * sin
   const mx = cx + (outerRadius + 30) * cos
   const my = cy + (outerRadius + 30) * sin
   const ex = mx + (cos >= 0 ? 1 : -1) * 22
   const ey = my
   const textAnchor = cos >= 0 ? 'start' : 'end'

   return (
      <g>
         <text
            x={cx}
            y={cy}
            dy={8}
            textAnchor="middle"
            fill={fill}
            fontSize="1.5em"
         >
            {value}
         </text>
         <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
         />
         <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
         />
         <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
         />
         <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
         <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
         >{`${payload.name}`}</text>
         <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
         >
            {`(Rate ${(percent * 100).toFixed(2)}%)`}
         </text>
      </g>
   )
}

interface IPieCharts {
   dates: RangeValue
   admin: string | null | undefined
}

function PieCharts({ dates, admin }: IPieCharts) {
   const { DashboardStore } = useStore()
   const startDate = moment().subtract(7, 'days')
   const endDate = moment()
   const Colors = Object.values(Color)

   const [breakdown, setBreakdown] = useState('RuleType')

   const [activeIndex, setActiveIndex] = useState(0)

   useEffect(() => {
      const sD = dates != null && dates.length > 1 ? dates[0] : startDate
      const eD = dates != null && dates.length > 1 ? dates[1] : endDate

      DashboardStore.getBreakdownStatistics(sD, eD, breakdown, admin)

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dates, breakdown, admin])

   const onPieEnter = (_: any, index: number) => {
      setActiveIndex(index)
   }

   const handleSelectChange = (value: string) => {
      setBreakdown(value)
   }

   return (
      <Row gutter={[12, 12]}>
         <Col lg={24} md={24}>
            {DashboardStore.isBreakdownStatisticsLoading ? (
               <Skeleton.Input active={true} size="large" block={true} />
            ) : (
               <Card
                  bordered={false}
                  bodyStyle={{
                     padding: '24px 36px 24px 0',
                  }}
               >
                  <div className="lineCharts">
                     <Row justify="space-between">
                        <Col md={12}>
                           <div className="title">
                              <span className="label">
                                 {chartInfos[breakdown].chartLabel}
                              </span>
                              <span className="description">
                                 {chartInfos[breakdown].chartDescription}
                              </span>
                           </div>
                        </Col>
                        <Col md={12}>
                           <Row gutter={8} justify="end">
                              <Col>
                                 <Select
                                    placeholder="select a type of breakdown"
                                    style={{ width: 300 }}
                                    value={breakdown}
                                    onChange={handleSelectChange}
                                    options={selectOptions}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                       (option?.label ?? '')
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                    }
                                    showSearch
                                 />
                              </Col>
                           </Row>
                        </Col>
                     </Row>

                     {DashboardStore.breakdownStatistics === null ||
                     DashboardStore.breakdownStatistics.length === 0 ? (
                        <Row
                           justify="center"
                           style={{ minHeight: '200px' }}
                           align="middle"
                        >
                           <Col md={18}>
                              <Alert
                                 message="No Data found"
                                 description="No data found for the selected criteria."
                                 type="info"
                                 showIcon
                              />
                           </Col>
                        </Row>
                     ) : (
                        <ResponsiveContainer minHeight={400} width="100%">
                           <PieChart width={600} height={600}>
                              <Pie
                                 activeIndex={activeIndex}
                                 activeShape={renderActiveShape}
                                 data={DashboardStore.breakdownStatistics}
                                 cx="50%"
                                 cy="50%"
                                 innerRadius={100}
                                 outerRadius={140}
                                 fill="#8884d8"
                                 dataKey="value"
                                 onMouseEnter={onPieEnter}
                              >
                                 {DashboardStore.breakdownStatistics.map(
                                    (_: any, index: number) => (
                                       <Cell
                                          key={`cell-${index}`}
                                          fill={Colors[index % Colors.length]}
                                       />
                                    )
                                 )}
                              </Pie>
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>
                     )}
                  </div>
               </Card>
            )}
         </Col>
      </Row>
   )
}

export default observer(PieCharts)
