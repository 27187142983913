import { ReactElement } from 'react'
import Router from '../../Router'

function ContentLayout(): ReactElement {
   return (
      <div style={{ minHeight: '80vh' }}>
         <div className="routing-content">
            <div className="site-card-border-less-wrapper">
               <Router />
            </div>
         </div>
      </div>
   )
}

export default ContentLayout
