import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import {
   Alert,
   Button,
   Card,
   Col,
   Input,
   message,
   Radio,
   Row,
   Select,
   Spin,
   Typography,
} from 'antd'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context'
import { ReactComponent as Back } from '../../assets/back.svg'
import { SaveOutlined } from '@ant-design/icons'
import FilterDto from './dtos/FilterDto'
import { Helmet } from 'react-helmet'
import { hasUserRight } from '../../utils/Helpers'
import { FilterApi } from './FilterApi'

const { Text, Title } = Typography

interface ICreateFilter {
   match: any
   location: any
}

const CreateFilter = observer((props: ICreateFilter) => {
   const { FilterStore } = useStore()
   const history = useHistory()
   const pageTitle = 'Add New Filter'

   const [selectedRegions, setSelectedRegions] = useState<number[]>([])
   const [selectedCountries, setSelectedCountries] = useState<number[]>([])
   const [selectedRegulators, setSelectedRegulators] = useState<number[]>([])
   const [selectedEntityType, setSelectedEntityType] = useState<number[]>([])
   const [selectedFunctions, setSelectedFunctions] = useState<number[]>([])
   const [selectedProducts, setSelectedProducts] = useState<number[]>([])
   const [selectedRuleTypes, setSelectedRuleTypes] = useState<number[]>([])
   const [defaultRegulators, setDefaultRegulators] = useState<any[]>([])

   const regionOptions = useMemo(() => {
      return FilterStore.regions.map((value) => {
         return {
            label: value.code,
            value: value.regionId,
         }
      })
   }, [FilterStore.regions])
   const countryOptions = useMemo(() => {
      return FilterStore.countries.map((value) => {
         return {
            label: value.code,
            value: value.countryId,
         }
      })
   }, [FilterStore.countries])
   const entityTypeOptions = useMemo(
      () =>
         FilterStore.entityTypes.map((value) => {
            return {
               label: value.code,
               value: value.entityTypeId,
            }
         }),
      [FilterStore.entityTypes]
   )
   const ruleTypesOptions = useMemo(
      () =>
         FilterStore.ruleTypes.map((value) => {
            return {
               label: value.name,
               value: value.id,
            }
         }),
      [FilterStore.ruleTypes]
   )
   const functionOptions = useMemo(
      () =>
         FilterStore.functions.map((value) => {
            return {
               label: value.value,
               value: value.key,
            }
         }),
      [FilterStore.functions]
   )
   const productOptions = useMemo(
      () =>
         FilterStore.products.map((value) => {
            return {
               label: value.value,
               value: value.key,
            }
         }),
      [FilterStore.products]
   )
   const regulatorOptions = useMemo(() => {
      const options = FilterStore.regulators
         .filter((value) => selectedCountries.includes(value.countryId))
         .map((value) => {
            return {
               label: value.code,
               value: value.regulatorId,
            }
         })
      const defautFilters = defaultRegulators.map((value) => ({
         label: value.code,
         value: value.regulatorId,
      }))
      return [...options, ...defautFilters]
   }, [FilterStore.regulators, selectedCountries, defaultRegulators])

   const filterLevelRadios = FilterStore.filterLevels.map((level) => {
      // if (localStorage.getItem('hasUserRole') === 'false')
      return (
         <Radio
            value={level.id}
            key={level.id}
            disabled={!hasUserRight(level.id)}
         >
            {level.name}
         </Radio>
      )
   })

   const handleRegionsChange = useCallback((e: any) => {
      setSelectedRegions(e)
   }, [])
   const handleCountriesChange = useCallback((e: any) => {
      setSelectedCountries(e)
      const regulators = FilterStore.regulators
         .filter((value) => e.includes(value.countryId))
         .map((value) => value.regulatorId)
      setSelectedRegulators((prevState) =>
         prevState.filter((r) => regulators.includes(r))
      )
   }, [])
   const handleRegulatorsChange = useCallback((e: any) => {
      setSelectedRegulators(e)
   }, [])
   const handleEntityTypesChange = useCallback((e: any) => {
      setSelectedEntityType(e)
   }, [])
   const handleFunctionsChange = useCallback((e: any) => {
      setSelectedFunctions(e)
   }, [])
   const handleProductsChange = useCallback((e: any) => {
      setSelectedProducts(e)
   }, [])
   const handleRuleTypesChange = useCallback((e: any) => {
      setSelectedRuleTypes(e)
   }, [])

   const updateFilterToJson = useMemo(
      () => ({
         id: FilterStore.filter.filterId,
         filterName: FilterStore.filter.filterName,
         filterLevel: FilterStore.filter.filterLevel,
         countries: selectedCountries.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         regulators: selectedRegulators.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         entityTypes: selectedEntityType.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         ruleTypes: selectedRuleTypes.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         functions: selectedFunctions.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         products: selectedProducts.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         regions: selectedRegions.map((item: any) =>
            item.key ? parseInt(item.key) : parseInt(item)
         ),
         freeText: FilterStore.filter.freeTextFilter,
         referenceText: FilterStore.filter.searchByReference,
         reports: [],
      }),
      [
         FilterStore.filter.filterId,
         FilterStore.filter.filterName,
         FilterStore.filter.filterLevel,
         selectedCountries,
         selectedRegulators,
         selectedEntityType,
         selectedRuleTypes,
         selectedFunctions,
         selectedProducts,
         selectedRegions,
         FilterStore.filter.freeTextFilter,
         FilterStore.filter.searchByReference,
      ]
   )

   const onCreateFilter = useCallback(() => {
      FilterStore.isEditMode
         ? FilterStore.updateFilter(updateFilterToJson)
         : FilterStore.createFilter(updateFilterToJson)

      if (!FilterStore.isValid && !FilterStore.isLoading) {
         message.warning(
            FilterStore.validationMessage,
            3,
            FilterStore.onValidationMessageClose
         )
      }
      // console.log(FilterStore.updateFilterToJson());
   }, [FilterStore, updateFilterToJson])
   if (FilterStore.redirectToResult)
      if (FilterStore.isValid && !FilterStore.isLoading) {
         history.push({
            pathname: '/FilterResult',
            state: { isEditMode: FilterStore.isEditMode },
         })
      } else {
         message.warning(
            FilterStore.validationMessage,
            3,
            FilterStore.onValidationMessageClose
         )
      }

   useEffect(() => {
      const location = props.location

      FilterStore.setIsEditMode(
         location.pathname === '/EditFilter/' && // TODO : developed for MVP. URL Check is not good idea, consider to improve robustness.
            (location.state.filterId !== undefined ||
               location.state.filterId !== null)
      )

      FilterStore.loadPageData()
      FilterStore.setIsPageValid(true)
      FilterStore.setFilter(new FilterDto())
      FilterStore.getPreferences()

      // If page will populated with data then,
      if (FilterStore.isEditMode) {
         FilterStore.getFilterById(location.state.filterId)
         FilterApi.getFilterById(location.state.filterId).then((value) => {
            const filters = value.filters
            if (Array.isArray(filters) && filters.length) {
               const selectedFilter = filters[0]
               setSelectedRegions(
                  selectedFilter.regions.map(
                     (value: any) => value.regionId as number
                  )
               )
               setSelectedCountries(
                  selectedFilter.countries.map(
                     (value: any) => value.countryId as number
                  )
               )
               setSelectedRegulators(
                  selectedFilter.regulators.map(
                     (value: any) => value.regulatorId as number
                  )
               )
               setDefaultRegulators(selectedFilter.regulators)
               setSelectedEntityType(
                  selectedFilter.entityTypes.map(
                     (value: any) => value.entityTypeId as number
                  )
               )
               setSelectedRuleTypes(
                  selectedFilter.ruleTypes.map(
                     (value: any) => value.id as number
                  )
               )
               setSelectedFunctions(
                  selectedFilter.functions.map(
                     (value: any) => value.key as number
                  )
               )
               setSelectedProducts(
                  selectedFilter.products.map(
                     (value: any) => value.key as number
                  )
               )
            }
         })
      } else {
         FilterStore.getActiveFilterCount()
         FilterStore.filter.filterLevel = 4
      }

      return () => {
         FilterStore.clearStore()
         FilterStore.clearCreateFilter()
      }
   }, [])

   const searchByDescription = (input: any, option: any) => {
      return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
   }

   return (
      <>
         <Helmet>
            <title>{pageTitle}</title>
         </Helmet>
         <Spin spinning={FilterStore.isLoading}>
            {FilterStore.showPageMessage() && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={FilterStore.pageMessage}
                        type="error"
                        showIcon
                     />
                  </Col>
               </Row>
            )}
            <Row>
               <Col style={{ textAlign: 'center' }} span={23}>
                  <Title level={3}>
                     {FilterStore.isEditMode
                        ? 'Update Filter'
                        : 'Create Filter'}
                  </Title>
               </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
               <Card
                  title={
                     <>
                        <Row>
                           <Text className="mandatoryAsterix">Filter Name</Text>
                           <Input
                              value={FilterStore.filter.filterName}
                              style={{
                                 marginLeft: '17.3%',
                                 width: '47%',
                                 borderRadius: '20px',
                              }}
                              placeholder="Filter Name"
                              onChange={(e) =>
                                 FilterStore.filterNameOnChange(e.target.value)
                              }
                           />
                        </Row>
                        <br />
                        <Row>
                           <Text className="mandatoryAsterix">
                              Filter Level
                           </Text>
                           <Radio.Group
                              style={{ marginLeft: '18%' }}
                              value={FilterStore.filter.filterLevel}
                              onChange={(e) =>
                                 FilterStore.setFilterLevel(e.target.value)
                              }
                           >
                              {filterLevelRadios}
                           </Radio.Group>
                        </Row>
                     </>
                  }
                  style={{ width: '80%', marginLeft: '10%', height: '80%' }}
               >
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Regions: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedRegions}
                           placeholder="Select Regions"
                           size="middle"
                           options={regionOptions}
                           onChange={handleRegionsChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Countries: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedCountries}
                           placeholder="Select country"
                           size="middle"
                           options={countryOptions}
                           onChange={handleCountriesChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Regulators: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedRegulators}
                           placeholder="Select Regulators"
                           size="middle"
                           options={regulatorOptions}
                           onChange={handleRegulatorsChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Entity Types: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedEntityType}
                           placeholder="Select Entity Types"
                           size="middle"
                           options={entityTypeOptions}
                           onChange={handleEntityTypesChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Rule Types: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedRuleTypes}
                           placeholder="Select Rule Types"
                           size="middle"
                           options={ruleTypesOptions}
                           onChange={handleRuleTypesChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>

                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Functions: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedFunctions}
                           placeholder="Select Functions"
                           size="middle"
                           options={functionOptions}
                           onChange={handleFunctionsChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Products: </Text>
                     </Col>
                     <Col span={15}>
                        <Select
                           mode="multiple"
                           style={{ marginLeft: '1%', width: '102%' }}
                           value={selectedProducts}
                           placeholder="Select Products"
                           size="middle"
                           options={productOptions}
                           onChange={handleProductsChange}
                           filterOption={searchByDescription}
                           showSearch
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Filter by text: </Text>
                     </Col>
                     <Col span={15}>
                        <Input
                           style={{ marginLeft: '1%', width: '102%' }}
                           placeholder="Free text"
                           value={FilterStore.filter.freeTextFilter}
                           onChange={(e) => FilterStore.onFreeTextOnChange(e)}
                        />
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Text> Search by Reference: </Text>
                     </Col>
                     <Col span={15}>
                        <Input
                           style={{ marginLeft: '1%', width: '102%' }}
                           placeholder="Free text"
                           value={FilterStore.filter.searchByReference}
                           onChange={(e) => FilterStore.onSearchByReference(e)}
                        />
                     </Col>
                  </Row>
               </Card>
            </Row>
            <Row style={{ marginTop: '3%' }}>
               <Col
                  style={{
                     display: 'flex',
                     justifyContent: 'flex-end',
                  }}
                  span={3}
               >
                  <Back />
               </Col>
               <Col span={15}>
                  <Button
                     type="link"
                     htmlType="submit"
                     disabled={false}
                     onClick={() => history.push('/MyFilters')}
                  >
                     Back to My Filters{' '}
                  </Button>{' '}
               </Col>
               <Col span={5}>
                  <Button
                     htmlType="submit"
                     disabled={FilterStore.createFilterButtonDisabled}
                     onClick={onCreateFilter}
                     style={{
                        backgroundColor: '#fa8c16',
                        borderColor: '#fa8c16',
                        color: 'white',
                        borderRadius: '10px',
                        width: '57%',
                        marginLeft: '4%',
                     }}
                     icon={<SaveOutlined />}
                  >
                     {FilterStore.isEditMode ? 'Update' : 'Save'}
                  </Button>{' '}
               </Col>
            </Row>
         </Spin>
      </>
   )
})
export default withRouter(CreateFilter)
