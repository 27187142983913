import { Helpers } from '../../api/Helpers'
import { Item } from '../../types/Types'

export class SettingsApi {
   public static getPageData = (endpoint: string) => {
      return Helpers.get(endpoint)
   }
   public static save = (record: Item, endpoint: string, isUpdate: boolean) => {
      record.key = parseInt(record.key)
      if (isUpdate) return Helpers.update(endpoint, record.key, record)
      else return Helpers.post(endpoint, record)
   }

   public static saveBulkData = (
      data: Item[],
      endpoint: string,
      isUpdate: boolean
   ) => {
      return Helpers.post(endpoint, data)
   }

   public static delete = (key: string, endpoint: string) => {
      return Helpers.delete(endpoint, key)
   }

   public static savePreferences = (data: any, endpoint: string) => {
      return Helpers.post(endpoint, data)
   }

   public static getPreferences = (endpoint: string) => {
      return Helpers.get(endpoint)
   }

   public static getGroups = () => {
      return Helpers.get('/settings/groups')
   }

   public static saveHiglightPreferences = (endpoint: string, data: any) => {
      return Helpers.post(endpoint, data)
   }

   public static getHiglightPreferences = (endpoint: string) => {
      return Helpers.get(endpoint)
   }
   public static onHighlightDelete = (endpoint: string, key: string) => {
      return Helpers.delete(endpoint, key)
   }

   public static getSelectedColumnKeys = (endpoint: string) => {
      return Helpers.get(endpoint)
   }

   public static saveExcelPreference = (endpoint: string, data: any) => {
      return Helpers.post(endpoint, data)
   }

   public static getUserExcelPreferences = (endpoint: string) => {
      return Helpers.get(endpoint)
   }

   public static deleteExcelPreference = (endpoint: string, key: string) => {
      return Helpers.delete(endpoint, key)
   }
}
