import { Helpers } from '../../api/Helpers'
import { CreatedFilters } from '../../mocks/CreatedFilter'
import { Item } from '../../types/Types'

export class DepartmentsApi {
   public static getPageData = (endpoint: string) => {
      return Helpers.get(endpoint)
   }
   public static save = (record: Item, endpoint: string, isUpdate: boolean) => {
      if (isUpdate) return Helpers.update(endpoint, record.key, record)
      else return Helpers.post(endpoint, record)
   }

   public static delete = (key: string, endpoint: string) => {
      return Helpers.delete(endpoint, key)
   }
}
