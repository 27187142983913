import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from "../../context"
import { Helmet } from 'react-helmet'
import {
   Button,
   Card,
   Col,
   ConfigProvider,
   DatePicker,
   Divider,
   Input,
   Modal,
   Popover,
   Row,
   Select,
   Spin,
   Table,
   Tag,
   Typography,
} from 'antd'
import {
   CloseCircleFilled,
   CloseCircleOutlined,
   DownloadOutlined,
   DownOutlined,
   EditOutlined,
   FileExcelTwoTone,
   FileTextTwoTone,
   MailTwoTone,
   PlusCircleOutlined,
   SearchOutlined,
   SendOutlined,
   SwapOutlined,
} from '@ant-design/icons'
import _ from 'lodash'

import { ReactComponent as Clear } from '../../assets/clear.svg'
import { ReactComponent as Reset } from '../../assets/reset.svg'
import AssessmentModal from './components/AssessmentModal'
import RequestForSignOffModal from './components/RequestForSignOffModal'
import { ReportModal } from './components/ReportModal'
import { RemoveAllTagFromString } from '../../utils/Filters'
import moment from 'moment'
import { disabledDate } from '../../utils/Helpers'
import DeleteConfirmModal from './components/DeleteConfirmModal'
import BulkEmailConfirmModal from './components/BulkEmailConfirmModal'
import { ExcelPreferenceModal } from './components/ExcelPreferenceModal'
import NewsletterNameModal from './components/NewsletterNameModal'
import EditableCell from './components/Tables/EditableCell'
import EditableRow from './components/Tables/EditableRow'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import { ImpactAssessmentsApi } from './ImpactAssessmentsApi'

const { Text, Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select


interface ICompanyUsers {
   endpoint: string
}

const expandable = {
   expandedRowRender: (record: any) => {
      RemoveAllTagFromString(record.mainRule.introductionLine)
      return (
         <>
            <Row>
               <Col span={1}> </Col>
               <Col span={3}>
                  <strong>Description: </strong>
               </Col>
               <Col span={18}>
                  {RemoveAllTagFromString(record.mainRule.introductionLine)}
               </Col>
            </Row>
            <Row>
               <Col span={1}> </Col>
               <Col span={3}>
                  <strong>Comment: </strong>
               </Col>
               <Col>{record.comment === '' ? 'N/A' : record.comment}</Col>
            </Row>
            <Row>
               <Col span={1}> </Col>
               <Col span={3}>
                  <strong>Departments: </strong>
               </Col>
               <Col>
                  {_.isEmpty(record.departments)
                     ? 'N/A'
                     : record.departments.map((e: any) => (
                          <Tag color="geekblue">{e.name}</Tag>
                       ))}
               </Col>
            </Row>
         </>
      )
   },
}

function getDateInterval(days: number): { startDate: Date; endDate: Date } {
   const now = Date.now();
   const pastTime = now - (days * 24 * 60 * 60 * 1000);
   console.log({
      startDate: new Date(pastTime),
      endDate: new Date(now),
      now,
      pastTime,
      days
   }, "DATE");
   return {
      startDate: new Date(pastTime),
      endDate: new Date(now),
   };
}

const ImpactAssessments = observer((props: ICompanyUsers) => {
   const pageTitle = 'Impact Assessments'
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [maxPeriod, setMaxPeriod] = useState<number>(100)

   const showModal = () => {
      setIsModalVisible(true)
   }

   const handleOk = () => {
      setIsModalVisible(false)
      ImpactAssessmentsStore.downloadAssessmentsByPreferenceId()
   }

   const handleCancel = () => {
      setIsModalVisible(false)
      ImpactAssessmentsStore.clearExcelPreferenceId()
   }
   const [allocationDates, setAllocationDates] = useState([] as any)
   const [assessmentDates, setAssessmentDates] = useState([] as any)
   const [responseDueDates, setResponseDueDates] = useState([] as any)
   const [initializeValuesFromStore, setInitializeValuesFromStore] = useState(
      false
   )

   const [isAllocationDateError, setIsAllocationDateError] = useState(false)
   const [isAssessmentDateError, setIsAssessmentDateError] = useState(false)
   const [isResponseDueDateError, setIsResponseDueDateError] = useState(false)
   const [selectedAssessmentTitle, setSelectedAssessmentTitle] = useState('')
   const { ImpactAssessmentsStore } = useStore()

   // TODO: Change from any to a type!
   const handleSave = (row: any) => {
      let newData = []
      let filteredNewData = []

      // Update table source
      newData = [...ImpactAssessmentsStore.impactAssessments]
      filteredNewData = ImpactAssessmentsStore.impactAssessmentsFiltered
         ? [...ImpactAssessmentsStore.impactAssessmentsFiltered]
         : []

      const index = newData.findIndex(
         (item) => row.assessmentId === item.assessmentId
      )
      const item = newData[index]
      newData.splice(index, 1, {
         ...item,
         ...row,
      })

      ImpactAssessmentsStore.setImpactAssessments(newData)

      // Update table filtered source
      const filteredIndex = filteredNewData.findIndex(
         (item) => row.assessmentId === item.assessmentId
      )
      const filteredItem = filteredNewData[filteredIndex]
      filteredNewData.splice(filteredIndex, 1, {
         ...filteredItem,
         ...row,
      })
      ImpactAssessmentsStore.setImpactAssessmentsFiltered(filteredNewData)

      ImpactAssessmentsStore.setSelectedAssessmentId(row.assessmentId)
      // ImpactAssessmentsStore.updateRow(row)
   }

   const state = {
      expandable,
   }

   const columns = [
      {
         title: 'ID',
         dataIndex: 'dailyGlobalRuleId',
         sorter: (a: any, b: any) => a.dailyGlobalRuleId - b.dailyGlobalRuleId,
         render: (id: any, record: any) => (
            <Button
               type="link"
               onClick={() =>
                  onReportModalPopupClick(
                     record.assessmentId,
                     record.mainRuleAuditId,
                     record.mainRule.shortTitle
                  )
               }
            >
               {id}
            </Button>
         ),
         responsive: ['md'] as Breakpoint[],
      },
      {
         title: 'Internal No',
         dataIndex: 'internalNumber',
         editable: true,
         sorter: (a: any, b: any) => a.internalNo - b.internalNo,
         align: 'center' as const,
         responsive: ['md'] as Breakpoint[],
      },
      {
         title: 'Title',
         dataIndex: 'record.mainRule.shortTitle',
         sorter: (a: any, b: any) =>
            a.mainRule.shortTitle.localeCompare(b.mainRule.shortTitle),
         render: (id: any, record: any) => record.mainRule.shortTitle,
         align: 'left' as const,
         responsive: ['md'] as Breakpoint[],
      },
      {
         title: 'Rule Type',
         dataIndex: 'record.mainRule.ruleType',
         editable: true,
         sorter: (a: any, b: any) =>
            a.mainRule.ruleType.localeCompare(b.mainRule.ruleType),
         render: (id: any, record: any) => record.mainRule.ruleType,
         align: 'left' as const,
         responsive: ['md'] as Breakpoint[],
      },
      {
         title: 'Admin Name',
         dataIndex: 'allocatedByFullName',
         sorter: (a: any, b: any) =>
            a.allocatedByFullName.localeCompare(b.allocatedByFullName),
         responsive: ['md'] as Breakpoint[],
      },
      {
         title: 'Allocated On',
         dataIndex: 'allocationDate',
         sorter: (a: any, b: any) =>
            moment(
               a.allocationDate,
               ImpactAssessmentsStore.localeDateFormat
            ).diff(
               moment(b.allocationDate, ImpactAssessmentsStore.localeDateFormat)
            ),
         responsive: ['md'] as Breakpoint[],
      },
      {
         title: 'Allocated To',
         dataIndex: 'allocatedToUserNames',
         responsive: ['md'] as Breakpoint[],
         sorter: (a: any, b: any) =>
            a.allocatedToUserNames.localeCompare(b.allocatedToUserNames),
      },
      {
         title: 'Response Due by',
         dataIndex: 'dateResponseDue',
         responsive: ['md'] as Breakpoint[],
         sorter: (a: any, b: any) => {
            var compare = moment(
               a.dateResponseDue,
               ImpactAssessmentsStore.localeDateFormat
            ).diff(
               moment(
                  b.dateResponseDue,
                  ImpactAssessmentsStore.localeDateFormat
               )
            )
            return compare
         },
         render: (text: string, record: any) => {
            if (record.isDue)
               return <Tag color={record.colorPreference}>{text}</Tag>
            else return <> {text} </>
         },
      },
      {
         title: 'Changed On',
         responsive: ['md'] as Breakpoint[],
         sorter: (a: any, b: any) =>
            moment(
               a.followUpDate,
               ImpactAssessmentsStore.localeDateFormat
            ).diff(
               moment(b.followUpDate, ImpactAssessmentsStore.localeDateFormat)
            ),
         defaultSortOrder: 'descend' as const,
         dataIndex: 'followUpDate',
         render: (text: any, record: any) => <> {text.split(' ')[0]} </>,
      },
      {
         title: 'Risk Level',
         dataIndex: 'riskLevel',
         responsive: ['md'] as Breakpoint[],
         editable: true,
         sorter: (a: any, b: any) =>
            a.riskLevel.description?.localeCompare(b.riskLevel.description),
         render: (text: any, record: any) => (
            <>{record.riskLevel.description}</>
         ),
      },
      {
         title: 'Status',
         dataIndex: 'status',
         responsive: ['md'] as Breakpoint[],
         editable: true,
         sorter: (a: any, b: any) =>
            a.status.value?.localeCompare(b.status.value),
         render: (text: any, record: any) => <>{record.status.value}</>,
      },
      {
         title: 'Topic',
         dataIndex: 'topic',
         editable: true,
         responsive: ['md'] as Breakpoint[],
         render: (text: any, record: any) => (
            <>
               {record.topic.map((e: any) => (
                  <Tag color="#5cdbd3"> {e.description} </Tag>
               ))}
            </>
         ),
      },
      {
         title: 'Action',
         responsive: ['md'] as Breakpoint[],
         render: (text: any, record: any) => (
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  minWidth: '120px',
                  padding: '0 5px 0 5px',
               }}
            >
               <a
                  style={{
                     color: 'rgb(250, 140, 22)',
                     borderColor: 'white',
                     display: 'flex',
                  }}
                  // ghost
                  type="link"
                  href={
                     '/AssessmentForm/' +
                     record.assessmentId +
                     '/' +
                     record.mainRuleAuditId
                  } //"http://localhost:3001/AssessmentForm/1/116509-1"
                  target="_blank"
                  rel="noreferrer"
               >
                  <EditOutlined style={{ margin: '10%' }} />
                  Edit
               </a>{' '}
               {ImpactAssessmentsStore.hasLoggedUserAtleastAdmin && (
                  <Popover
                     content={
                        <>
                           <Row>
                              <Button
                                 type="link"
                                 icon={<SwapOutlined />}
                                 style={{ color: '#613400' }}
                                 onClick={(e) => onChangeAssessors(record)}
                              >
                                 Update Assessment allocation{' '}
                              </Button>{' '}
                           </Row>
                           <Row>
                              <Button
                                 type="link"
                                 icon={<PlusCircleOutlined />}
                                 style={{ color: '#613400' }}
                                 onClick={(e) => onAddNewAssessment(record)}
                              >
                                 Add new assessment allocation{' '}
                              </Button>{' '}
                           </Row>
                           {record.isUpdated && (
                              <Row>
                                 <Button
                                    type="link"
                                    icon={<SendOutlined />}
                                    style={{ color: '#613400' }}
                                    onClick={(e) =>
                                       onRequestForSignOffClick(record)
                                    }
                                 >
                                    Request for sign-off{' '}
                                 </Button>{' '}
                              </Row>
                           )}
                           {!record.isUpdated && (
                              <Row>
                                 <Button
                                    type="link"
                                    icon={<Clear />}
                                    style={{ color: '#ff4d4f' }}
                                    onClick={(e) =>
                                       onAssessmentDeleteButton(record)
                                    }
                                 >
                                    <span style={{ marginLeft: '9%' }}>
                                       {' '}
                                       Delete{' '}
                                    </span>
                                 </Button>{' '}
                              </Row>
                           )}
                        </>
                     }
                     trigger="hover"
                     placement="left"
                     id={record.assessmentId}
                     destroyTooltipOnHide={true}
                  >
                     <a
                        href="#!"
                        style={{
                           color: 'rgb(250, 140, 22)',
                           borderColor: 'white',
                           display: 'flex',
                        }}
                     >
                        <DownOutlined style={{ margin: '10%' }} />
                        More
                     </a>{' '}
                  </Popover>
               )}
            </div>
         ),
      },
   ]

   const clearSearchPanel = () => {
      setAllocationDates([])
      ImpactAssessmentsStore.setAllocationDates([])
      setAssessmentDates([])
      ImpactAssessmentsStore.setAssessmentDates([])
      setResponseDueDates([])
      ImpactAssessmentsStore.setResponseDueDates([])
      ImpactAssessmentsStore.setSelectedRiskLevels([])
      ImpactAssessmentsStore.setSelectedRuleTypes([])
      ImpactAssessmentsStore.setSelectedStatusTypes([])
      ImpactAssessmentsStore.setSelectedTopics([])
      ImpactAssessmentsStore.setSelectedAllocatorUserIds([])
      ImpactAssessmentsStore.setSelectedAssessors([])
      ImpactAssessmentsStore.setFilterInternalNumber('')
   }

   const onReportModalPopupClick = (
      assessmentId: string,
      mainRuleAuditId: string,
      sortTitle: string
   ) => {
      ImpactAssessmentsStore.setAuditId(mainRuleAuditId)
      ImpactAssessmentsStore.fetchSelectedReport(assessmentId)
      ImpactAssessmentsStore.getAssessmentDetails(assessmentId)

      Modal.info({
         icon: null,
         title: 'Assessment audit for: ' + sortTitle,
         width: '90%',
         okButtonProps: {
            style: {
               borderRadius: '10px',
            },
            icon: <CloseCircleOutlined />,
         },
         okText: 'Close',
         okType: 'default',
         closable: true,
         maskClosable: true,
         content: <ReportModal />,
         onOk() {},
      })
   }

   const onExcelPreferenceClick = () => {
      showModal()
   }

   const onChangeAssessors = (record: any) => {
      onManageClick(record)

      ImpactAssessmentsStore.setIsAssessmentModalVisible(true)
      setInitializeValuesFromStore(true)
      setSelectedAssessmentTitle('Update Assessment allocation')
   }

   const onAddNewAssessment = (record: any) => {
      onManageClick(record)

      ImpactAssessmentsStore.setIsAssessmentModalVisible(true)
      setInitializeValuesFromStore(false)
      setSelectedAssessmentTitle('Add new assessment allocation')
   }

   const onRequestForSignOffClick = (record: any) => {
      onManageClick(record)

      ImpactAssessmentsStore.setRequestForSignOffModalVisible(true)
      ImpactAssessmentsStore.getSupervisors(
         record.allocatedToUser.map((e: any) => e.id)
      )
   }

   const onAssessmentDeleteButton = (record: any) => {
      onManageClick(record)
      ImpactAssessmentsStore.setIsDeleteConfirmModalVisible(true)
   }

   const onBulkEmail = (params: any) => {
      ImpactAssessmentsStore.setIsBulkEmailModalVisible(true)
   }

   const onCreateNewsletter = (params: any) => {
      ImpactAssessmentsStore.setIsNewsletterNameModalVisible(true)
   }

   const onManageClick = (record: any) => {
      ImpactAssessmentsStore.setSelectedAssessmentId(record.assessmentId)
      ImpactAssessmentsStore.setSelectedAssessmentRuleId([
         record.dailyGlobalRuleId,
      ])
      ImpactAssessmentsStore.setSelectedAuditCoreIds([record.mainRuleAuditId])
   }

   const onOpenChange = (
      open: any,
      setDates: any,
      dates: any,
      setRangePickerError: any
   ) => {
      if (open) {
         setDates([])
      } else {
         if (
            dates[0] === undefined ||
            dates[0] === null ||
            dates[1] === undefined ||
            dates[1] === null
         ) {
            ImpactAssessmentsStore.setIsSearchParamsValid(false)
            setRangePickerError(true)
         } else {
            ImpactAssessmentsStore.setIsSearchParamsValid(true)
            setRangePickerError(false)
         }
      }
   }

   const assessmentListSearchboxOnChange = (target: any) => {
      let targetValue = target?.target?.value

      if (targetValue === '' || targetValue === undefined) {
         ImpactAssessmentsStore.isFiltered = false
      } else {
         const lowerCaseValue = targetValue.toLowerCase()
         ImpactAssessmentsStore.setImpactAssessmentsFiltered(
            ImpactAssessmentsStore.impactAssessments?.filter(
               (e: any) =>
                  e.mainRule.mainRuleId.toPrecision().match(targetValue) ||
                  e.mainRule.shortTitle.toLowerCase().match(lowerCaseValue) ||
                  e.allocatedByFullName?.toLowerCase().match(lowerCaseValue) ||
                  e.allocationDate.match(lowerCaseValue) ||
                  e.dateResponseDue.match(lowerCaseValue) ||
                  e.followUpDate.match(lowerCaseValue) ||
                  e.allocatedToUserNames?.toLowerCase().match(lowerCaseValue) ||
                  e.riskLevel?.description
                     ?.toLowerCase()
                     .match(lowerCaseValue) ||
                  e.status?.value?.toLowerCase().match(lowerCaseValue) ||
                  e.topic
                     .map((e: any) => e.description)
                     .concat()
                     .toLocaleString()
                     .toLowerCase()
                     .match(lowerCaseValue)
            )
         )
         ImpactAssessmentsStore.isFiltered = true
      }

      ImpactAssessmentsStore.setSearchValue(targetValue)
   }

   const [isExportButtonActive, setIsExportButtonActive] = useState(false)
   // TODO: Change any to DataType.
   const rowSelection = {
      // selectedRowKeys: ImpactAssessmentsStore.selectedRowKeys,

      getCheckboxProps: (record: any) => ({
         disabled: record.name === 'Disabled User', // Column configuration not to be checked
         name: record.name,
      }),
      onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
         const selectedAllKeys = selectedRows.map((e: any) => e?.assessmentId)

         ImpactAssessmentsStore.setSelectedRowKeys(selectedAllKeys)

         setIsExportButtonActive(selectedRows.length > 0)
      },
      onSelect: (
         record: any,
         selected: boolean,
         selectedRows: any,
         nativeEvent: any
      ) => {
         let selectedRowKeys: number[] = []

         if (selected) {
            // If checkbox is selected then add to Selected Keys
            selectedRowKeys = [
               ...ImpactAssessmentsStore.selectedRowKeys,
               record.assessmentId,
            ]
         } else {
            // If checkbox is unselected then remove it from Selected Keys
            const ruleIds = [...ImpactAssessmentsStore.selectedRowKeys]
            const auditCoreIds = [
               ...ImpactAssessmentsStore.selectedAuditCoreIds,
            ]

            const indexOfSelectedRuleId = ruleIds.indexOf(record.assessmentId)

            ruleIds.splice(indexOfSelectedRuleId, 1)
            auditCoreIds.splice(indexOfSelectedRuleId, 1)

            selectedRowKeys = ruleIds
         }

         setIsExportButtonActive(selected)
         ImpactAssessmentsStore.setSelectedRowKeys(selectedRowKeys)
      },
   }

   const components = {
      body: {
         row: EditableRow,
         cell: EditableCell,
      },
   }

   const modifiedColumns = columns.map((col) => {
      if (!col.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record: any) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleSave,
         }),
      }
   })

   const dateRangeValidatorStyle = (isError: boolean) => {
      return isError
         ? {
              borderColor: 'red',
              width: '88%',
           }
         : {
              width: '88%',
           }
   }

   const searchByDescription = (input: any, option: any) => {
      return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
   }

   useEffect(() => {
      ImpactAssessmentsStore.fillInitialStore()
      ImpactAssessmentsStore.getUserExcelPreferences()
      ImpactAssessmentsApi.getMaxPeriodDate().then((value) => {
         setMaxPeriod(
            !isNaN(parseInt(value?.value)) ? parseInt(value?.value) : 100
         );
         let defaultDate: {allocationStartDate: any, allocationEndDate: any} = {allocationStartDate: [], allocationEndDate: []}
         if (value){
            const maxVal = !isNaN(parseInt(value?.value)) ? parseInt(value?.value) : 90;
            console.log(maxVal, value, "TESTER");
            const {startDate, endDate} = getDateInterval(maxVal)
            setAllocationDates([moment(endDate, ImpactAssessmentsStore.localeDateFormat), moment(startDate, ImpactAssessmentsStore.localeDateFormat)])
            defaultDate = {allocationStartDate: moment(startDate, ImpactAssessmentsStore.localeDateFormat).format('MM/DD/YYYY'), allocationEndDate: moment(endDate, ImpactAssessmentsStore.localeDateFormat).format('MM/DD/YYYY')}
         }
         ImpactAssessmentsStore.getDefaultPreferences(defaultDate)
      })

      return () => {
         ImpactAssessmentsStore.clearIAState()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getRiskLevels = ImpactAssessmentsStore.riskLevels?.map((e: any) => {
      return (
         <Option value={e.key} label={e.description} key={e.key}>
            {e.description}
         </Option>
      )
   })

   const getRuleTypes = ImpactAssessmentsStore.ruleTypes?.map((val, index) => {
      return (
         <Option value={index + 1} label={val} key={index + 1}>
            {val}
         </Option>
      )
   })

   /* const getStatus = ImpactAssessmentsStore.status?.map((e: any) => {
      return (
         <Option value={e.key} label={e.value} key={e.key}>
            {e.value}
         </Option>
      )
   }) */

   const getStatus = ImpactAssessmentsStore.status?.map((e: any) => {
      return {
         value: e.key,
         label: e.value,
      }
   }) as any[]

   const getTopics = ImpactAssessmentsStore.topics?.map((e: any) => {
      return (
         <Option value={e.key} label={e.description} key={e.key}>
            {e.description}
         </Option>
      )
   })

   const getAllocatorsAndAdmins = ImpactAssessmentsStore.allocatorsAndAdmins?.map(
      (e: any) => {
         let fullName = e.name + ' ' + e.surname
         return (
            <Option value={e.id} label={fullName} key={e.id}>
               {fullName}
            </Option>
         )
      }
   )

   const getAssessors = ImpactAssessmentsStore.assessors?.map((e: any) => {
      let fullName = e.name + ' ' + e.surname
      return (
         <Option value={e.id} label={fullName} key={e.id}>
            {fullName}
         </Option>
      )
   })

   const getBusinessEntities = ImpactAssessmentsStore.businessEntities?.map(
      (e: any) => {
         return (
            <Option value={e.id} label={e.name} key={e.id}>
               {e.name}
            </Option>
         )
      }
   )

   const getGroups = ImpactAssessmentsStore.groups?.map((e: any) => {
      return (
         <Option value={e.key} label={e.description} key={e.key}>
            {e.description}
         </Option>
      )
   })

   const getDepartments = ImpactAssessmentsStore.departments?.map((e: any) => {
      return (
         <Option value={e.key} label={e.name} key={e.key}>
            {e.name}
         </Option>
      )
   })

   return (
      <Spin spinning={ImpactAssessmentsStore.isLoading}>
         <Helmet>
            <title>{pageTitle}</title>
         </Helmet>

         <Row>
            <Col style={{ textAlign: 'center' }} span={24}>
               <Title level={3} style={{}}>
                  {pageTitle}
               </Title>
            </Col>
         </Row>

         {/*Add new assessment allocation Model*/}
         <AssessmentModal
            initializeValuesFromStore={initializeValuesFromStore}
            title={selectedAssessmentTitle}
         />

         <RequestForSignOffModal />
         <DeleteConfirmModal />
         <BulkEmailConfirmModal />
         <NewsletterNameModal />
         <Card bordered={true} style={{ backgroundColor: '#fafafa' }}>
            <Row className="search-row">
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Allocation Date:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <RangePicker
                     value={allocationDates}
                     format={ImpactAssessmentsStore.localeDateFormat}
                     size="small"
                     disabledDate={(e) =>
                        disabledDate(e, allocationDates, maxPeriod)
                     }
                     onCalendarChange={(val) => {
                        setAllocationDates(val)
                        ImpactAssessmentsStore.setAllocationDates(val)
                     }}
                     onOpenChange={(e) =>
                        onOpenChange(
                           e,
                           setAllocationDates,
                           ImpactAssessmentsStore.allocationDates,
                           setIsAllocationDateError
                        )
                     }
                     style={dateRangeValidatorStyle(isAllocationDateError)}
                  />
                  {isAllocationDateError && (
                     <span style={{ marginLeft: '7%', color: 'red' }}>
                        {' '}
                        You must select a date range{' '}
                     </span>
                  )}
               </Col>
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Assessment Date:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <RangePicker
                     value={assessmentDates}
                     format={ImpactAssessmentsStore.localeDateFormat}
                     disabledDate={(e) =>
                        disabledDate(e, assessmentDates, maxPeriod)
                     }
                     size="small"
                     onCalendarChange={(val) => {
                        setAssessmentDates(val)
                        ImpactAssessmentsStore.setAssessmentDates(val)
                     }}
                     onOpenChange={(e) =>
                        onOpenChange(
                           e,
                           setAssessmentDates,
                           ImpactAssessmentsStore.assessmentDates,
                           setIsAssessmentDateError
                        )
                     }
                     style={dateRangeValidatorStyle(isAssessmentDateError)}
                  />
                  {isAssessmentDateError && (
                     <span style={{ marginLeft: '7%', color: 'red' }}>
                        {' '}
                        You must select a date range{' '}
                     </span>
                  )}
               </Col>
            </Row>
            <Row className="search-row">
               {/* <Col span={1} /> */}
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Response Due Date:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <RangePicker
                     value={responseDueDates}
                     format={ImpactAssessmentsStore.localeDateFormat}
                     disabledDate={(e) =>
                        disabledDate(e, responseDueDates, maxPeriod)
                     }
                     size="small"
                     onCalendarChange={(val) => {
                        setResponseDueDates(val)
                        ImpactAssessmentsStore.setResponseDueDates(val)
                     }}
                     onOpenChange={(e) =>
                        onOpenChange(
                           e,
                           setResponseDueDates,
                           ImpactAssessmentsStore.responseDueDates,
                           setIsResponseDueDateError
                        )
                     }
                     style={dateRangeValidatorStyle(isResponseDueDateError)}
                  />
                  {isResponseDueDateError && (
                     <span style={{ marginLeft: '7%', color: 'red' }}>
                        {' '}
                        You must select a date range{' '}
                     </span>
                  )}
               </Col>
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Risk Levels:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select Risk Levels"
                     size="small"
                     value={ImpactAssessmentsStore.selectedRiskLevels}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedRiskLevels(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getRiskLevels}
                  </Select>{' '}
               </Col>
            </Row>
            <Row className="search-row">
               {/* <Col span={1} /> */}
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Status:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select Status Types"
                     size="small"
                     value={ImpactAssessmentsStore.selectedStatusTypes}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedStatusTypes(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                     options={getStatus}
                  ></Select>
               </Col>
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Topics:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select Topics"
                     size="small"
                     value={ImpactAssessmentsStore.selectedTopics}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedTopics(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getTopics}
                  </Select>
               </Col>
            </Row>
            <Row className="search-row">
               {/* <Col span={1} /> */}
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Allocators/Admins:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select the Allocators/Admins"
                     size="small"
                     value={ImpactAssessmentsStore.selectedAllocatorUserIds}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedAllocatorUserIds(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getAllocatorsAndAdmins}
                  </Select>
               </Col>
               <Col span={4} style={{ textAlign: 'right' }}>
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Assessors:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select Assessors"
                     size="small"
                     value={ImpactAssessmentsStore.selectedAssessorUserIds}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedAssessors(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getAssessors}
                  </Select>
               </Col>
            </Row>
            <Row className="search-row">
               {/* <Col span={1} /> */}
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>B. Entity:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select business entity"
                     size="small"
                     value={ImpactAssessmentsStore.selectedBusinessEntities}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedBusinessEntities(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getBusinessEntities}
                  </Select>
               </Col>
               <Col span={4} style={{ textAlign: 'right' }}>
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Group:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select group"
                     size="small"
                     value={ImpactAssessmentsStore.selectedGroups}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedGroups(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getGroups}
                  </Select>
               </Col>
            </Row>
            <Row className="search-row">
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Departments:</>{' '}
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select department"
                     size="small"
                     value={ImpactAssessmentsStore.selectedDepartments}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedDepartments(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getDepartments}
                  </Select>
               </Col>
               <Col span={4} style={{ textAlign: 'right' }}>
                  <Text style={{ marginRight: '5%' }}>
                     {' '}
                     <>Internal Number:</>{' '}
                  </Text>
               </Col>
               <Col span={3}>
                  <Input
                     value={ImpactAssessmentsStore.filterInternalNumber}
                     size="small"
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setFilterInternalNumber(
                           e.target.value
                        )
                     }
                  />
               </Col>
               <Col span={2} />
            </Row>
            <Row>
               <Col span={4} className="left-col">
                  <Text style={{ marginRight: '5%' }}>
                     <>Rule Types:</>
                  </Text>
               </Col>
               <Col span={8}>
                  <Select
                     mode="multiple"
                     style={{ width: '88%' }}
                     placeholder="Select rule types"
                     size="small"
                     value={ImpactAssessmentsStore.selectedRuleTypes}
                     onChange={(e: any) =>
                        ImpactAssessmentsStore.setSelectedRuleTypes(e)
                     }
                     optionLabelProp="label"
                     filterOption={searchByDescription}
                  >
                     {getRuleTypes}
                  </Select>
               </Col>
            </Row>
            <Row>
               <Col span={14}></Col>
               <Col span={5} style={{ textAlign: 'right' }}>
                  <Button
                     // htmlType="submit"
                     onClick={clearSearchPanel}
                     icon={<Reset />}
                     className="clear-button"
                  >
                     <span style={{ marginLeft: 8 }}>Clear</span>
                  </Button>
               </Col>
               <Col span={5}>
                  <Button
                     // htmlType="submit"
                     onClick={(e) => ImpactAssessmentsStore.filterAssessments()}
                     icon={<SearchOutlined />}
                     style={{
                        backgroundColor: 'rgb(250, 140, 22)',
                        borderColor: 'rgb(250, 140, 22)',
                        borderRadius: '10px',
                        color: 'white',
                     }}
                  >
                     Search{' '}
                  </Button>
               </Col>
            </Row>
         </Card>
         <Row
            style={{
               marginTop: '3%',
               marginBottom: '-2%',
            }}
         >
            <Col span={24} style={{ textAlign: 'center' }}>
               <Text>
                  <strong>Row Count :</strong> {ImpactAssessmentsStore.rowCount}{' '}
               </Text>
            </Col>
         </Row>
         <Divider
            style={{ backgroundColor: 'rgb(250, 140, 22)', opacity: '0.3' }}
         />
         <Modal
            open={isModalVisible}
            title="Excel download preference"
            width="40%"
            okText="Download Excel"
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{
               style: {
                  backgroundColor: 'rgb(250, 140, 22) ',
                  borderColor: 'rgb(250, 140, 22)',
                  borderRadius: '10px',
               },
               disabled: ImpactAssessmentsStore.disableDownloadExcelPreference,
               icon: <DownloadOutlined />,
            }}
            cancelText="Cancel"
            closable={true}
            maskClosable={true}
         >
            <ExcelPreferenceModal />
         </Modal>
         <Row className="table-search-row">
            <Col span={17}>
               <Button
                  type="dashed"
                  htmlType="submit"
                  onClick={() => onExcelPreferenceClick()}
                  icon={<FileExcelTwoTone />}
                  style={{
                     borderRadius: '10px',
                     color: 'rgb(250, 140, 22)',
                     marginLeft: '1%',
                     marginBottom: '1%',
                  }}
                  disabled={!isExportButtonActive}
               >
                  Export to Excel{' '}
               </Button>
               <Button
                  type="dashed"
                  htmlType="submit"
                  onClick={onBulkEmail}
                  icon={<MailTwoTone />}
                  style={{
                     borderRadius: '10px',
                     color: 'rgb(250, 140, 22)',
                     marginLeft: '1%',
                     marginBottom: '1%',
                  }}
                  disabled={!isExportButtonActive}
               >
                  Email assessors{' '}
               </Button>
               <Button
                  type="dashed"
                  htmlType="submit"
                  onClick={onCreateNewsletter}
                  icon={<FileTextTwoTone />}
                  style={{
                     borderRadius: '10px',
                     color: 'rgb(250, 140, 22)',
                     marginLeft: '1%',
                     marginBottom: '1%',
                  }}
                  disabled={!isExportButtonActive}
               >
                  Create newsletter{' '}
               </Button>
            </Col>
            <Col span={7} style={{ display: 'flex', height: 33 }}>
               <Input
                  placeholder="Search table"
                  value={ImpactAssessmentsStore.searchValue}
                  onChange={(e: any) => assessmentListSearchboxOnChange(e)}
                  suffix={
                     <>
                        <CloseCircleFilled
                           className="close-circle"
                           onClick={(e) =>
                              ImpactAssessmentsStore.clearSearchBox()
                           }
                        />{' '}
                     </>
                  }
               />
               <div className="search-icon-div">
                  <SearchOutlined className="search-icon" />
               </div>
            </Col>
         </Row>
         <ConfigProvider>
            <Table
               components={components}
               rowClassName={() => 'editable-row'}
               rowKey={(record) => record.assessmentId}
               rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
               }}
               {...state}
               columns={modifiedColumns}
               dataSource={
                  ImpactAssessmentsStore.isFiltered
                     ? ImpactAssessmentsStore.impactAssessmentsFiltered
                     : ImpactAssessmentsStore.impactAssessments
               }
               size="small"
               pagination={{
                  position: ['bottomCenter'],
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: [
                     '10',
                     '20',
                     '30',
                     '50',
                     '100',
                     '500',
                     '1000',
                  ],
                  showTotal: (total: any, range: any) =>
                     `${range[0]}-${range[1]} of ${total} items`,
               }}
            />
         </ConfigProvider>
         <Row
            style={{
               marginTop: '3%',
               marginBottom: '-2%',
            }}
         >
            <Col span={24} style={{ textAlign: 'center' }}>
               <Text>
                  <strong>Row Count :</strong> {ImpactAssessmentsStore.rowCount}{' '}
               </Text>
            </Col>
         </Row>
         <Divider />
      </Spin>
   )
})
export default ImpactAssessments
