import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import {
   Typography,
   Row,
   Col,
   DatePicker,
   Divider,
   Select,
   Modal,
   Table,
   Spin,
   Alert,
   Input,
   Button,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { ReactComponent as Clear } from '../../../assets/clear.svg'
import moment from 'moment'
import { getNextWeekDate } from '../../../utils/Helpers'

const { TextArea } = Input
const { Text } = Typography
const { Option } = Select

export interface IRequestForSignOffModal {}

const RequestForSignOffModal = observer((props: IRequestForSignOffModal) => {
   const { ImpactAssessmentsStore } = useStore()
   const onCancel = () => {
      ImpactAssessmentsStore.setRequestForSignOffModalVisible(false)
   }

   const onSendRequestSignOff = () => {
      ImpactAssessmentsStore.sendRequestSignOff()
      ImpactAssessmentsStore.setRequestForSignOffModalVisible(false)
   }
   const footerButtons = ImpactAssessmentsStore.isTreeViewExist
      ? [
           <Button
              key="back"
              style={{ borderRadius: '10px' }}
              icon={<Clear />}
              onClick={onCancel}
           >
              Cancel
           </Button>,
           <Button
              key="submit"
              type="primary"
              onClick={onSendRequestSignOff}
              style={{
                 backgroundColor: 'rgb(250, 140, 22)',
                 borderColor: 'rgb(250, 140, 22)',
                 borderRadius: '10px',
                 color: 'white',
              }}
              icon={<SaveOutlined />}
           >
              Send
           </Button>,
        ]
      : [
           <Button
              key="back"
              style={{ borderRadius: '10px' }}
              icon={<Clear />}
              onClick={onCancel}
           >
              Cancel
           </Button>,
        ]

   const columns = [
      //   {
      //      title: 'ID',
      //      dataIndex: 'id',
      //      width: '5%',
      //   },
      {
         title: 'Name',
         dataIndex: 'name',
         align: 'left' as const,
      },
      {
         title: 'Surname',
         dataIndex: 'surname',
         align: 'left' as const,
      },
      {
         title: 'Email',
         dataIndex: 'email',
         align: 'left' as const,
      },
   ]

   const [alertVisible, setAlertVisible] = useState(false)
   const rowSelection = {
      selectedRowKeys: ImpactAssessmentsStore.selectedSupervisorRowKeys,

      getCheckboxProps: (record: any) => ({
         disabled: record.name === 'Disabled User', // Column configuration not to be checked
         name: record.name,
      }),
      onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
         let selectedAllUserIds = selectedRows.map((e: any) => e?.id)
         ImpactAssessmentsStore.setSelectedSupervisorRowKeys(selectedAllUserIds)

         setAlertVisible(selectedRows.length === 0)
      },
      onSelect: (
         record: any,
         selected: boolean,
         selectedRows: any,
         nativeEvent: any
      ) => {
         let selectedAllUserIds = selectedRows.map((e: any) => e.id)
         ImpactAssessmentsStore.setSelectedSupervisorRowKeys(selectedAllUserIds)

         setAlertVisible(selectedRows.length === 0)
      },
   }

   return (
      <Spin spinning={ImpactAssessmentsStore.isSignOffLoading}>
         <Modal
            title="Request for sign-off"
            // style={{ backgroundColor: 'grey' }}
            open={ImpactAssessmentsStore.requestForSignOffModalVisible}
            onCancel={onCancel}
            footer={footerButtons}
            destroyOnClose={true}
            width="55%"
         >
            <Row style={{ marginBottom: '2%' }}>
               <Col span={24}>
                  {alertVisible && (
                     <Alert
                        message="Select at least 1 supervisor!"
                        type="warning"
                     />
                  )}
                  {ImpactAssessmentsStore.hasNoSupervisorErrorVisible && (
                     <Alert
                        message="Allocated user is not assigned to any Department!"
                        type="error"
                     />
                  )}
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Table
                     rowKey={(record: any) => record.id}
                     rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                     }}
                     columns={columns}
                     dataSource={ImpactAssessmentsStore.supervisors}
                     size="small"
                     pagination={{
                        position: ['bottomCenter'],
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30'],
                        showTotal: (total: any, range: any) =>
                           `${range[0]}-${range[1]} of ${total} items`,
                     }}
                  />
               </Col>
            </Row>
         </Modal>
      </Spin>
   )
})

export default RequestForSignOffModal
