import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".

export interface IDeletedRules {
   ruleNumber: number
}

const DeletedRules = observer((props: IDeletedRules) => {
   return <>No Data</>
})
export default DeletedRules
