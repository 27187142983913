import { Helpers } from '../../api/Helpers'
import { Roles } from '../../utils/Consts'
import { displayError } from '../../utils/Helpers'
import API from '../../api/Api'

export class DashboardApi {
   public static getEvents(
      eventType: string,
      startDate: string,
      endDate: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = ''
      }
      const response = Helpers.get(
         `/User/GetEvents?eventType=${eventType}&startDate=${startDate}&endDate=${endDate}&admin=${admin}`
      )

      return response
   }

   public static getAllUserStatistics(
      startDate: string,
      endDate: string
   ): Promise<any> {
      const response = Helpers.get(
         '/User/GetUserStatistics?startDate=' +
            startDate +
            '&endDate=' +
            endDate
      )

      return response
   }

   public static getEventsStatistics(
      startDate: string,
      endDate: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = ''
      }
      const response = Helpers.get(
         `/User/GetEventsStatistics?startDate=${startDate}&endDate=${endDate}&admin=${admin}`
      )
      return response
   }

   public static getDailyRulesData(
      startDate: string,
      endDate: string,
      changeType: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = ''
      }
      const response = Helpers.get(
         `/User/GetDailyRulesStatistics?startDate=${startDate}&endDate=${endDate}&changeType=${changeType}&admin=${admin}`
      )
      return response
   }

   public static getBreakdownStatistics(
      startDate: string,
      endDate: string,
      breakdown: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = ''
      }
      const response = Helpers.get(
         `/User/GetBreakdownStatistics?startDate=${startDate}&endDate=${endDate}&breakdown=${breakdown}&admin=${admin}`
      )
      return response
   }

   public static getDueAllocationStatistics(
      startDate: string,
      endDate: string,
      option: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = ''
      }
      const response = Helpers.get(
         `/User/GetDueAllocationStatistics?startDate=${startDate}&endDate=${endDate}&state=${option}&admin=${admin}`
      )
      return response
   }

   public static getPreferences(endpoint: string): Promise<any> {
      return Helpers.get(endpoint)
   }

   public static exportEventsToExcel(
      eventType: string,
      startDate: string,
      endDate: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = null
      }

      return new Promise((resolve, reject) => {
         API.post(
            `User/ExportEventsToExcel`,
            { startDate, endDate, admin, eventType },
            {
               headers: {
                  'Access-Control-Allow-Origin': Helpers.getBaseApiUrl(),
                  'Content-Type': 'application/json',
               },
               responseType: 'blob',
               withCredentials: true,
            }
         )
            .then((res) => {
               resolve({
                  blob: res.data,
                  fileName: res.headers['x-suggested-filename'],
               })
            })
            .catch((err) => {
               console.error(err, 'error')
               displayError(err)
               reject(err)
            })
      })
   }

   public static exportToExcel(
      startDate: string,
      endDate: string,
      admin: string | null | undefined
   ): Promise<any> {
      if (admin == null || admin === undefined) {
         admin = null
      }

      return new Promise((resolve, reject) => {
         API.post(
            `User/ExportDashboardToExcel`,
            { startDate, endDate, admin },
            {
               headers: {
                  'Access-Control-Allow-Origin': Helpers.getBaseApiUrl(),
                  'Content-Type': 'application/json',
               },
               responseType: 'blob',
               withCredentials: true,
            }
         )
            .then((res) => {
               resolve({
                  blob: res.data,
                  fileName: res.headers['x-suggested-filename'],
               })
            })
            .catch((err) => {
               console.error(err, 'error')
               displayError(err)
               reject(err)
            })
      })
   }

   public static getAdminUsers(): Promise<any> {
      const response = Helpers.get(
         `/User/GetUsersByRoles?roles=${Roles.Admin}&roles=${Roles.SuperAdmin}`
      )
      return response
   }
}
