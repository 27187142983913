import { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { Button, Col, Dropdown, MenuProps, Row, Select, Space } from 'antd'
import './Dashboard.scss'
import { useStore } from '../../context/index'
import {
   Widgets,
   DailyRules,
   PieCharts,
   Allocations,
} from './components/charts'
import { DownOutlined, ReloadOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
import CustomRangePicker, {
   RangeValue,
} from './components/charts/CustomRangePicker'
import moment from 'moment'
import Can from '../../components/Can'
import { Roles } from '../../utils/Consts'

interface IDashboard {
   match: any
   location: any
}

const transformOptions = (admins: any[]) => {
   const defaultOptions = [{ label: 'All users', value: 0 }]
   return defaultOptions.concat(
      admins.map((admin) => {
         return { label: `${admin.name} ${admin.surname}`, value: admin.id }
      })
   )
}

const Dashboard = observer((props: IDashboard) => {
   const { DashboardStore } = useStore()
   const startDate = moment().startOf('day').subtract(1, 'months')
   const endDate = moment().startOf('day')
   const [dates, setDates] = useState<RangeValue>([
      moment(startDate),
      moment(endDate),
   ])
   const [admin, setAdmin] = useState<string | undefined | null>(null)

   useEffect(() => {
      DashboardStore.getDefaultPreferences()
      DashboardStore.getAdminUsers()

      return () => {
         DashboardStore.clearState()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [admin])

   const onMenuClick: MenuProps['onClick'] = (e) => {
      console.log('click', e)
   }

   const items = [
      {
         key: '1',
         label: 'Export Dashboard',
         onClick: () => {
            DashboardStore.exportDashboardToExcel(dates, admin)
         },
      },
   ]

   const handleChangeRangePicker = (val: RangeValue) => {
      setDates(val)
   }

   const onChange = (value: string) => {
      setAdmin(value)
   }

   const onClear = () => {
      setAdmin(null)
   }

   const onRefresh = () => {
      console.log('refresh')
   }

   return (
      <>
         <Helmet>
            <title>Dashboard</title>
         </Helmet>
         <Row align="middle" justify="space-between" gutter={[8, 8]}>
            <Col className="dashboard-header" span={8}>
               <span> Dashboard </span>
            </Col>
            <Col span={6}>
               <Can roles={[Roles.SuperAdmin]}>
                  <Select
                     style={{ width: '100%' }}
                     showSearch
                     allowClear
                     placeholder="Select an admin"
                     optionFilterProp="children"
                     onChange={onChange}
                     onClear={onClear}
                     value={admin}
                     filterOption={(input, option) =>
                        (option?.label ?? '')
                           .toLowerCase()
                           .includes(input.toLowerCase())
                     }
                     options={transformOptions(DashboardStore.admins)}
                  />
               </Can>
            </Col>

            <Col span={6}>
               <CustomRangePicker
                  onChange={handleChangeRangePicker}
                  dateFormat={DashboardStore.dateFormat}
                  value={dates}
               />
            </Col>
            <Col span={4}>
               <Dropdown menu={{ items, onClick: onMenuClick }}>
                  <Button
                     block
                     loading={DashboardStore.isExportToExcelLoading}
                     style={{
                        backgroundColor: '#fa8c16',
                        color: 'white',
                        borderRadius: '5px',
                     }}
                  >
                     <Space>
                        Actions
                        <DownOutlined />
                     </Space>
                  </Button>
               </Dropdown>
            </Col>
            <Col>
               <Button onClick={onRefresh}>
                  <Space>
                     Refresh <ReloadOutlined />
                  </Space>
               </Button>
            </Col>
         </Row>
         <p>&nbsp;</p>
         <Widgets dates={dates} admin={admin} />

         <p>&nbsp;</p>
         <p>&nbsp;</p>
         <DailyRules dates={dates} admin={admin} />

         <p>&nbsp;</p>
         <p>&nbsp;</p>
         <PieCharts dates={dates} admin={admin} />

         <p>&nbsp;</p>
         <p>&nbsp;</p>
         <Allocations dates={dates} admin={admin} />
      </>
   )
})

export default Dashboard
