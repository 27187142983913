import { observer } from 'mobx-react-lite'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'

import ChangePassword from './features/login/ChangePassword'
import Login from './features/login/Login'
import { useStore } from './context'
import { useMemo } from 'react'
import { intersection } from './helpers'
import { Roles } from './utils/Consts'

const RouteWithAuth = observer((props: any) => {
   const { AuthStore } = useStore()
   AuthStore.initUser()
   AuthStore.isAuthenticated()

   if (AuthStore.isAuthanticated && props.path === '/login') {
      return <Redirect to="/Dashboard" />
   } else if (AuthStore.isAuthanticated) {
      return <Route {...props} />
   } else if (props.location.pathname === '/ChangePassword') {
      return <ChangePassword />
   } else {
      return <Login location={false} />
   }
})

export const PublicRoute = ({ component: Component, ...rest }: any) => {
   const { AuthStore } = useStore()

   return (
      <Route
         {...rest}
         render={(props) => {
            if (!AuthStore.isAuthanticated) {
               return <Component {...props} />
            }

            return (
               <Redirect
                  to={{
                     pathname: '/',
                     state: { from: props.location },
                  }}
               />
            )
         }}
      />
   )
}

export const PrivateRoute = ({
   component: Component,
   roles = [],
   isRestrict = true,
   redirectPath = "/forbidden",
   ...rest
}: any) => {
   const { commonStore } = useStore()
   const isAuthorized = useMemo<boolean>(
      () =>
         intersection(
            [...roles, Roles.Admin, Roles.SuperAdmin, Roles.Dev],
            commonStore.userRoles.map((r) => (r as any).roleName)
         ).length > 0,
      [roles, commonStore.userRoles]
   )

   return (
      <Route
         {...rest}
         render={(props) => {
            if (!commonStore.isAuthanticated) {
               return (
                  <Redirect
                     to={{
                        pathname: '/login',
                        state: { from: props.location },
                     }}
                  />
               )
            }
            if ((!roles || roles.length === 0) && !isRestrict)
               return <Component {...props} {...rest} />
            else if (isAuthorized) {
               return <Component {...props} {...rest} />
            } else {
               return <Redirect to={{ pathname: redirectPath }} />
            }
         }}
      />
   )
}

export default RouteWithAuth
