import React, { useRef, useState, useContext } from 'react'
import { Input, Select, Form, InputRef } from 'antd'
import { useStore } from '../../../../context/index'
import { EditableContext } from './EditableRow'

interface Item {
   key: string
   name: string
   age: string
   address: string
}

interface EditableCellProps {
   title: React.ReactNode
   editable: boolean
   children: React.ReactNode
   dataIndex: keyof Item
   record: Item
   handleSave: (record: Item) => void
}

const { Option } = Select

// TODO: Consider to create separate component for EditableCell.
const EditableCell: React.FC<EditableCellProps> = ({
   title,
   editable,
   children,
   dataIndex,
   record,
   handleSave,
   ...restProps
}) => {
   const [editing, setEditing] = useState(false)
   const inputRef = useRef<InputRef>(null)
   const form = useContext(EditableContext)!
   const { ImpactAssessmentsStore } = useStore()

   const getRiskLevels = ImpactAssessmentsStore.riskLevels?.map((e: any) => {
      return (
         <Option value={e.key} label={e.description} key={e.key}>
            {e.description}
         </Option>
      )
   })

   const getStatus = ImpactAssessmentsStore.status?.map((e: any) => {
      return (
         <Option value={e.key} label={e.value} key={e.key}>
            {e.value}
         </Option>
      )
   })

   const getTopics = ImpactAssessmentsStore.topics?.map((e: any) => {
      return (
         <Option value={e.key} label={e.description} key={e.key}>
            {e.description}
         </Option>
      )
   })

   const getRuleTypes = ImpactAssessmentsStore.ruleTypes?.map(
      (e: any, index) => {
         return (
            <Option value={index + 1} label={e} key={e}>
               {e}
            </Option>
         )
      }
   )

   const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
   }

   const updateAssessmentInternalNo = (row: any) => {
      handleSave(row)
      ImpactAssessmentsStore.updateAssessmentInternalNo(row.internalNumber)
   }
   const updateAssessmentRiskLevel = (row: any) => {
      handleSave(row)
      ImpactAssessmentsStore.updateAssessmentRiskLevel(row.riskLevel.key)
   }
   const updateAssessmentStatus = (row: any) => {
      handleSave(row)
      ImpactAssessmentsStore.updateAssessmentStatus(row.status.key)
   }
   const updateAssessmentTopics = (row: any) => {
      handleSave(row)

      ImpactAssessmentsStore.updateAssessmentTopics(row.topicIds)
   }

   const save = async () => {
      try {
         const values = await form.validateFields()
         toggleEdit()

         if (values['internalNumber'] !== undefined)
            updateAssessmentInternalNo({ ...record, ...values })
         else if (ImpactAssessmentsStore.selectedRiskLevel !== '') {
            let riskLevelObj = {
               riskLevel: ImpactAssessmentsStore.selectedRiskObj,
            }
            updateAssessmentRiskLevel({ ...record, ...riskLevelObj })
            ImpactAssessmentsStore.clearSelectedRiskLevel()
         } else if (ImpactAssessmentsStore.selectedStatus !== '') {
            let statusObj = {
               status: ImpactAssessmentsStore.selectedStatusObj,
            }
            updateAssessmentStatus({ ...record, ...statusObj })
            ImpactAssessmentsStore.clearSelectedStatus()
         } else if (ImpactAssessmentsStore.selectedTopicIds.length !== 0) {
            let topicIdsObj = {
               topicIds: ImpactAssessmentsStore.selectedTopicIds,
               topic: ImpactAssessmentsStore.selectedTopicObjs,
            }
            updateAssessmentTopics({ ...record, ...topicIdsObj })
            ImpactAssessmentsStore.clearSelectedTopicIds()
         }
      } catch (errInfo) {
         console.error('Save failed:', errInfo)
      }
   }

   let childNode = children

   if (editable) {
      if (editing) {
         if (String(dataIndex) === 'internalNumber')
            childNode = (
               <Form.Item
                  style={{ margin: 0 }}
                  name={dataIndex}
                  rules={[
                     {
                        required: false,
                        message: `${title} is required.`,
                     },
                  ]}
               >
                  <Input ref={inputRef} onPressEnter={save} onBlur={save} />
               </Form.Item>
            )
         else if (String(dataIndex) === 'riskLevel')
            childNode = (
               <>
                  <Form.Item
                     style={{ margin: 0 }}
                     name={dataIndex}
                     rules={[
                        {
                           required: false,
                           message: `${title} is required.`,
                        },
                     ]}
                  >
                     <Select
                        style={{ width: '88%' }}
                        placeholder="Select Risk Levels"
                        size="small"
                        onBlur={save}
                        // value={ImpactAssessmentsStore.selectedRiskLevel}
                        onChange={(e: any) => {
                           ImpactAssessmentsStore.setSelectedRiskLevel(e)
                        }}
                        optionLabelProp="label"
                     >
                        {getRiskLevels}
                     </Select>{' '}
                  </Form.Item>
               </>
            )
         else if (String(dataIndex) === 'status')
            childNode = (
               <>
                  <Form.Item
                     style={{ margin: 0 }}
                     name={dataIndex}
                     rules={[
                        {
                           required: false,
                           message: `${title} is required.`,
                        },
                     ]}
                  >
                     <Select
                        style={{ width: '88%' }}
                        placeholder="Select Status"
                        size="small"
                        onBlur={save}
                        // value={ImpactAssessmentsStore.selectedStatus}
                        onChange={(e: any) => {
                           ImpactAssessmentsStore.setSelectedStatus(e)
                        }}
                        optionLabelProp="label"
                     >
                        {getStatus}
                     </Select>{' '}
                  </Form.Item>
               </>
            )
         else if (String(dataIndex) === 'topic')
            childNode = (
               <>
                  <Form.Item
                     style={{ margin: 0 }}
                     name={dataIndex}
                     rules={[
                        {
                           required: false,
                           message: `${title} is required.`,
                        },
                     ]}
                  >
                     <Select
                        mode="multiple"
                        style={{ width: '11ch' }}
                        placeholder="Select Topics"
                        size="small"
                        onBlur={save}
                        // value={ImpactAssessmentsStore.selectedStatus}
                        onChange={(e: any) => {
                           ImpactAssessmentsStore.setSelectedTopicIds(e)
                        }}
                        optionLabelProp="label"
                     >
                        {getTopics}
                     </Select>{' '}
                  </Form.Item>
               </>
            )
         else if (String(dataIndex) === 'record.mainRule.ruleType') {
            childNode = (
               <>
                  <Form.Item
                     style={{ margin: 0 }}
                     name={dataIndex}
                     rules={[
                        {
                           required: false,
                           message: `${title} is required.`,
                        },
                     ]}
                  >
                     <Select
                        style={{ width: '88%' }}
                        placeholder="Select RuleType"
                        size="small"
                        onBlur={save}
                        // value={ImpactAssessmentsStore.selectedStatus}
                        onChange={(e: any) => {
                           ImpactAssessmentsStore.setSelectedRuleType(e)
                        }}
                        optionLabelProp="label"
                     >
                        {getRuleTypes}
                     </Select>{' '}
                  </Form.Item>
               </>
            )
         }
      } else
         childNode = (
            <div
               className="editable-cell-value-wrap"
               style={{ paddingRight: 24 }}
               onClick={toggleEdit}
            >
               {children}
            </div>
         )
   }

   return <td {...restProps}>{childNode}</td>
}

export default EditableCell
