import { Button, Form } from 'antd'
import { makeAutoObservable } from 'mobx'
import toast from 'react-hot-toast'
import { Item } from '../../types/Types'
import { alertMessages, warningStyle } from '../../utils/Helpers'
import { SettingsApi } from './SettingsApi'
type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus']

// import { RiskLevelApi } from './RiskLevelApi'

export class SetttingsStore {
   pageData: any[] = []
   endpoint: string = ''
   isLoading: boolean = false

   riskLevels: any[] = []
   topics: any[] = []
   assessmentStatuses: any[] = []
   groups: any[] = []

   selectedRisk: string = ''
   selectedTopic: string = ''
   selectedAssessmentStatus: string = ''

   isError: boolean = false
   errorMessage: string = ''
   errorType: any = 'error'

   isGroupExist: boolean = false
   isAssessmentHighlightModalVisible: boolean = false
   isExcelPreferenceModalVisible: boolean = false

   languages: any[] = [
      { key: 'us', code: 'en-US', description: 'United States - MM/DD/YYYY' },
      { key: 'gb', code: 'en-GB', description: 'United Kingdom - DD/MM/YYYY' },
   ]
   selectedLanguageCode: string = ''

   selectedActiveFilterCount: number | undefined
   selectedDisabledFilterCount: number | undefined
   selectedNotificationOnChange: boolean = false
   selectedNotificationOnAssignment: boolean = false
   selectedReminderDate: number = 0
   selectedResponseDueDate: string = ''
   selectedColumns: string = 'All columns by default'
   userExcelPreferences: any[] = []

   getPreferencesInitData() {
      this.isLoading = true
      this.isError = false
      SettingsApi.getPageData(this.endpoint + '/GetPreferencesInitData')
         .then((result: any) => {
            if (result.errorCode === 0) {
               this.riskLevels = result.riskLevels
               this.topics = result.topics
               this.assessmentStatuses = result.assessmentStatuses
            } else {
               this.showErrorMessage(
                  'The preferences could not initialize correctly. Get in touch with Administrator.'
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'The preferences could not initialize correctly. Get in touch with Administrator.'
            )
         })
      this.isLoading = false
   }

   getPageData() {
      this.isLoading = true
      this.isError = false

      SettingsApi.getPageData(this.endpoint)
         .then((result: any) => {
            this.pageData = result?.result
            this.isLoading = false
            // TODO : Error Handling
         })
         .catch((err) => {
            this.isLoading = false
            // TODO : Error Handling
            // this.isSuccess = false
            // this.errorDescription(err.description)
            // this.errorCode = err.code
         })
   }

   getGroups(history: any) {
      SettingsApi.getGroups()
         .then((result: any) => {
            this.groups = result?.result
            this.isGroupExist = result?.result.length !== 0

            if (!this.isGroupExist) {
               toast(
                  <span>
                     In order to create Business Entity, You must have Group
                     first. Click below
                     <Button
                        type="default"
                        style={{
                           borderRadius: '10px',
                           marginTop: '1rem',
                        }}
                        onClick={() => history.push('/Groups')}
                     >
                        Create Group
                     </Button>
                  </span>,
                  { ...warningStyle, duration: 5000 }
               )
            }

            this.isLoading = false
         })
         .catch((err) => {
            console.error(err)
            this.isLoading = false
         })
   }

   save(record: any, isUpdate: boolean) {
      // if (this.isItemValid(record))

      this.isError = false
      toast.promise(
         SettingsApi.save(record, this.endpoint, isUpdate),
         {
            loading: 'Saving ...',
            success: () => {
               this.getPageData()
               return 'Successfully saved'
            },
            error:
               'An error occurred when saving the changes to your settings.',
         },
         {}
      )
   }

   saveSortedPageData() {
      // if (this.isItemValid(record))
      toast.promise(
         SettingsApi.saveBulkData(this.pageData, this.endpoint + '/sort', true),
         {
            loading: 'Saving settings ...',
            success: () => {
               return 'Successfully saved'
            },
            error:
               'An error occurred when saving the changes to your settings.',
         },
         {}
      )
   }

   delete(record: Item) {
      this.isError = false
      const toastId = toast.loading('Deleting...')
      return SettingsApi.delete(record.key, this.endpoint)
         .then((result: any) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.',
                  toastId
               )
            else if (result.errorCode === 0) {
               const newData = [...this.pageData]
               const index = newData.findIndex((e) => e.key === record.key)
               newData.splice(index, 1)
               this.updatePageData(newData)
               this.showSuccessMessage('successfully deleted', toastId)
            } else {
               this.showErrorMessage(
                  result.additionalMessage + ' Code: ' + result.errorCode,
                  toastId
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'Could not deleted, Get in touch with Administrator.' + err
            )
         })
   }

   showErrorMessage(message: string, id: string = 'error') {
      toast.error(message, { id })
   }

   showSuccessMessage(message: string, id: string = 'error') {
      toast.success(message, { id })
   }

   clearStore() {
      this.pageData = []
      this.endpoint = ''
   }

   updatePageData(pageData: Item[]) {
      this.pageData = pageData
   }

   swapItemIndexes(oldIndex: number, newIndex: number) {
      const firstItemIndex = this.pageData[oldIndex].index
      this.pageData[oldIndex].index = this.pageData[newIndex].index
      this.pageData[newIndex].index = firstItemIndex
   }

   isItemValid(item: Item) {
      return item.description !== '' && item.key !== '' && item.value !== ''
   }

   // Validator for input boxes, it works inputs onChange event,
   checkInput = (
      item: any,
      value: string,
      record: Item,
      firstColumnName: string,
      secondColumnName: string
   ) => {
      if (value === '' || value === null)
         return Promise.reject('Cannot be empty')

      const tempData = [...this.pageData]
      const currentItemIndex = tempData.findIndex((x) => x.key === record.key)
      tempData.splice(currentItemIndex, 1)

      if (item.field === 'description') {
         const index = tempData.findIndex((x) => x.description === value)
         if (index > -1)
            return Promise.reject('The ' + firstColumnName + ' already exist.')
         else return Promise.resolve()
      }

      if (item.field === 'value') {
         const index = tempData.findIndex((x) => x.value == value.toString())
         if (index > -1)
            return Promise.reject('The ' + secondColumnName + ' already exist.')
         else return Promise.resolve()
      }

      return Promise.resolve()
   }

   checkBusinessEntityInputs = (
      item: any,
      value: string,
      record: Item,
      secondColumnName: string
   ) => {
      if (value === '' || value === null)
         return Promise.reject('Cannot be empty')

      const tempData = [...this.pageData]
      const currentItemIndex = tempData.findIndex((x) => x.key === record.key)
      tempData.splice(currentItemIndex, 1)

      if (item.field === 'value') {
         const index = tempData.findIndex((x) => x.value == value.toString())
         if (index > -1)
            return Promise.reject('The ' + secondColumnName + ' already exist.')
         else return Promise.resolve()
      }

      return Promise.resolve()
   }

   setSelectedAssessmentStatus(e: any) {
      this.selectedAssessmentStatus = e
   }

   setSelectedRisk(e: any) {
      this.selectedRisk = e
   }

   setSelectedTopic(e: any) {
      this.selectedTopic = e
   }

   setIsLoading(val: boolean) {
      this.isLoading = val
   }

   savePreferences() {
      this.isLoading = true
      this.isError = false
      const toastId = toast.loading('Saving...')
      SettingsApi.savePreferences(
         this.savePreferencesJson(),
         this.endpoint + '/savepreferences'
      )
         .then((result) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.',
                  toastId
               )
            else if (result.errorCode === 0) {
               this.showSuccessMessage(
                  'Preferences saved successfully!',
                  toastId
               )
            } else {
               this.showErrorMessage(
                  result.additionalMessage + ' Code: ' + result.errorCode,
                  toastId
               )
            }

            setTimeout((e) => this.scrollToTop(), 500)
         })
         .catch((err) => {
            this.showErrorMessage(
               'Preferences could not saved. Code: ' + err,
               toastId
            )
         })

      this.isLoading = false
   }

   scrollToTop() {
      this.isLoading = false
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: 'smooth',
      })
   }

   setSelectedNotificationOnChange(checked: boolean) {
      this.selectedNotificationOnChange = checked
   }
   setSelectedNotificationOnAssignment(checked: boolean) {
      this.selectedNotificationOnAssignment = checked
   }
   setSelectedReminderDate(e: number) {
      this.selectedReminderDate = e
   }

   setSelectedResponseDueDate(val: string) {
      this.selectedResponseDueDate = val
   }

   getSelectedPreferences() {
      SettingsApi.getPreferences(
         this.endpoint + '/GetPreferences/' + localStorage.getItem('userId')
      )
         .then((result) => {
            if (result === null || result === undefined)
               this.showErrorMessage('Default preferences is undefined.')
            else if (result.errorCode === 0) {
               this.selectedRisk =
                  result.preferences.riskLevel === 0
                     ? ''
                     : result.preferences.riskLevel
               this.selectedAssessmentStatus =
                  result.preferences.assessmentStatus === 0
                     ? ''
                     : result.preferences.assessmentStatus

               this.selectedNotificationOnChange =
                  result.preferences.notificationOnChange
               this.selectedNotificationOnAssignment =
                  result.preferences.notificationOnAssignment
               this.selectedDefaultStatuses =
                  result.preferences.defaultAssessmentStatuses
               this.selectedReminderDate =
                  result.preferences.assessmentDueReminderPeriod
               this.selectedResponseDueDate =
                  result.preferences.responseDueDateNumberOfWeek
               this.selectedActiveFilterCount =
                  result.filterCountPreference.activeFilterCount
               this.selectedDisabledFilterCount =
                  result.filterCountPreference.disabledFilterCount
               this.selectedLanguageCode = result.preferences.languagePreference
            } else {
               this.showErrorMessage(
                  'Default preferences is undefined. Code: ' + result.errorCode
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'Default preferences is undefined. Code: ' + err
            )
         })
   }
   savePreferencesJson(): any {
      return {
         userId: parseInt(localStorage.getItem('userId') || '0'),
         riskLevel: this.selectedRisk,
         assessmentStatus:
            this.selectedAssessmentStatus === ''
               ? 0
               : this.selectedAssessmentStatus,
         notificationOnChange: this.selectedNotificationOnChange,
         notificationOnAssignment: this.selectedNotificationOnAssignment,
         activeFilterCount: this.selectedActiveFilterCount,
         disabledFilterCount: this.selectedDisabledFilterCount,
         responseDueDateNumberOfWeek: this.selectedResponseDueDate,
         assessmentDueReminderPeriod: this.selectedReminderDate,
         languagePreference: this.selectedLanguageCode,
         defaultAssessmentStatuses: this.selectedDefaultStatuses,
      }
   }

   setSelectedActiveFilterCount(e: any) {
      this.selectedActiveFilterCount = e
   }

   setSelectedDisabledFilterCount(e: any) {
      this.selectedDisabledFilterCount = e
   }

   setSelectedLanguageCode(e: string) {
      this.selectedLanguageCode = e
   }

   setIsAssessmentHighlightModalVisible(val: boolean) {
      this.isAssessmentHighlightModalVisible = val
   }
   setIsExcelPreferenceModalVisible(val: boolean) {
      this.isExcelPreferenceModalVisible = val
   }
   selectedDefaultStatuses: any[] = [] // TODO: Create an enum for assessment status.
   setSelectedDefaultStatuses(val: any) {
      this.selectedDefaultStatuses = val
   }

   selectedHighlightStatus: any
   onHighlightStatusChange = (val: any) => {
      this.selectedHighlightStatus = val
   }
   selectedHighlightDay: any
   onSelectedHighlightDayChange = (val: any) => {
      this.selectedHighlightDay = val
   }
   highlightColor: any = { primaryColor: '#f50' }
   setHighlightColor(val: any) {
      this.highlightColor = val
   }
   newHighlightPreferenceJson(): any {
      return {
         highlightStatus: this.selectedHighlightStatus,
         highlightDay: this.selectedHighlightDay,
         highlightColor: this.highlightColor.primaryColor,
      }
   }

   clearHighlightObject() {
      this.selectedHighlightStatus = ''
      this.selectedHighlightDay = ''
      this.highlightColor = ''
      this.isExcelPreferenceModalVisible = false
   }

   onSaveHigligthPreference() {
      const toastId = toast.loading('Saving Highlight Preferences...')
      SettingsApi.saveHiglightPreferences(
         this.endpoint +
            '/SaveHiglightPreferences/' +
            localStorage.getItem('userId'),
         this.newHighlightPreferenceJson()
      )
         .then((result) => {
            if (result === null || result === undefined) {
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.',
                  toastId
               )
               this.isAssessmentHighlightModalVisible = false
            } else if (result.errorCode === 0) {
               this.getHiglightPreferences()
               this.isAssessmentHighlightModalVisible = false
               this.showSuccessMessage(
                  'Hightlight Preference saved successfully',
                  toastId
               )
            } else {
               this.showErrorMessage(
                  result.additionalMessage + ' Code:' + result.errorCode,
                  toastId
               )

               // this.isAssessmentHighlightModalVisible = false
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err,
               toastId
            )
         })
   }

   targetKeys: any[] = []
   setTargetKeys(val: any) {
      this.targetKeys = val
   }
   getSelectedExcelColumnKeys() {
      SettingsApi.getSelectedColumnKeys('/settings/GetSelectedColumnKeys/')
         .then((result) => {
            if (result === null || result === undefined) {
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.'
               )
               this.isAssessmentHighlightModalVisible = false
            } else {
               this.targetKeys = result
               return result
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err
            )
         })
   }

   getUserExcelPreferences() {
      SettingsApi.getUserExcelPreferences(
         '/settings/GetSelectedColumnKeysMultiple/'
      )
         .then((result) => {
            if (result === null || result === undefined) {
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.'
               )
               this.isAssessmentHighlightModalVisible = false
            } else {
               // this.targetKeys = result

               this.userExcelPreferences = result
               return result
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err
            )
         })
   }

   preferenceName: string = ''
   preferenceNameError: string = ''
   preferenceNamevalidateStatus: ValidateStatus
   preferenceNameValid: boolean = true
   preferenceColumnSelectedValid: boolean = true
   preferenceFormValid = (): boolean => {
      if (this.preferenceName.trim().length === 0) {
         this.preferenceNamevalidateStatus = 'error'
         this.preferenceNameError = 'Name is required'
         this.preferenceNameValid = false
      } else {
         this.preferenceNameError = ''
         this.preferenceNamevalidateStatus = ''
         this.preferenceNameValid = true
      }

      if (this.targetKeys.length <= 0) {
         this.preferenceColumnSelectedValid = false
      } else {
         this.preferenceColumnSelectedValid = true
      }

      return this.preferenceNameValid && this.preferenceColumnSelectedValid
   }

   setPreferenceName = (val: string) => {
      this.preferenceName = val
      if (
         this.userExcelPreferences.findIndex(
            (x) => x.excelPreferenceName.trim() === val
         ) === -1
      ) {
         this.preferenceNameError = ''
         this.preferenceNamevalidateStatus = ''
         this.preferenceNameValid = true
      } else {
         this.preferenceNamevalidateStatus = 'error'
         this.preferenceNameError =
            'An excel preference with this name already exist. Please choose another'
         this.preferenceNameValid = false
      }
   }
   onAddExcelPreference() {
      const toastId = toast.loading('Saving Excel Preference ...')
      SettingsApi.saveExcelPreference(
         '/settings/CreateExcelExportPreference/',
         {
            selectedColumKeys: this.targetKeys,
            PreferenceName: this.preferenceName.trim(),
         }
      )
         .then((result) => {
            this.isExcelPreferenceModalVisible = false // Improve error handling.
            this.getUserExcelPreferences()
            if (!result) this.showErrorMessage(alertMessages.error500, toastId)
            else toast.success('Preference Saved Successfully', { id: toastId })
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err,
               toastId
            )
         })
   }

   deleteExcelPreference(id: any) {
      const toastId = toast.loading('Deleting Excel Preference ...')
      SettingsApi.deleteExcelPreference(
         '/settings/DeleteExcelExportPreference',
         id
      )
         .then((result) => {
            this.isExcelPreferenceModalVisible = false // TODO: Improve error handling.
            this.getUserExcelPreferences()
            if (!result) this.showErrorMessage(alertMessages.error500, toastId)
            else
               toast.success('Preference Deleted Successfully', { id: toastId })
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err,
               toastId
            )
         })
   }

   nextTargetKeys: any[] = []
   selectedExcelPreferenceId: number | undefined
   selectedExcelPreference = {
      selectedColumKeys: [],
      excelPreferenceName: '',
      excelPreferenceId: 0,
   }

   clearExcelPreferenceForm = () => {
      this.selectedExcelPreference = {
         selectedColumKeys: [],
         excelPreferenceName: '',
         excelPreferenceId: 0,
      }
      this.preferenceName = ''
      this.preferenceNameError = ''
      this.preferenceNamevalidateStatus = ''
   }

   setSelectedExcelPreference = (val: any) => {
      this.selectedExcelPreference = val
      this.preferenceName = val.excelPreferenceName
      this.targetKeys = val.selectedColumKeys

      //  .userExcelPreferences
   }
   setSelectedExcelPreferenceId = (val: any) => {
      this.selectedExcelPreferenceId = val
   }
   setNextTargetKeys = (val: any) => {
      this.nextTargetKeys = val
   }

   updateExcelPreference() {
      const toastId = toast.loading('Updating Excel Preference ...')
      SettingsApi.saveExcelPreference(
         '/settings/UpdateExcelExportPreference/',
         {
            selectedColumnKeys: this.selectedExcelPreference.selectedColumKeys,
            selectedExcelPreferenceId: this.selectedExcelPreferenceId,
         }
      )
         .then((result) => {
            this.isExcelPreferenceModalVisible = false // TODO: Improve error handling.
            this.getUserExcelPreferences()
            if (result === null || result === undefined)
               this.showErrorMessage(alertMessages.error500, toastId)
            else
               toast.success('Preference Updated Successfully', { id: toastId })
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err,
               toastId
            )
         })
   }

   highlightPreferences: any[] = []
   getHiglightPreferences() {
      SettingsApi.getHiglightPreferences(
         this.endpoint + '/GetHiglightPreferences/'
      )
         .then((result) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.'
               )
            else if (result.errorCode === 0) {
               this.highlightPreferences = result.higlightPreferenceDtos
            } else {
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                     result.errorCode
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err
            )
         })
   }

   onHighlightDelete(id: any) {
      const toastId = toast.loading('Deleting Highlight preference ...')
      SettingsApi.onHighlightDelete(
         this.endpoint + '/DeleteHiglightPreference',
         id
      )
         .then((result: any) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.',
                  toastId
               )
            else if (result.errorCode === 0) {
               this.getHiglightPreferences()
               toast.success('Highlight deleted successfully', { id: toastId })
            } else {
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                     result.errorCode,
                  toastId
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err,
               toastId
            )
         })
   }

   setIsError = (val: any) => {
      this.isError = val
   }

   setErrorMessage = (val: any) => {
      this.errorMessage = val
   }

   showSelectedColorConfig = (params: any) => {}

   constructor() {
      makeAutoObservable(this)
   }
}
