import React, { useEffect } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Row, Button, Col, Spin, Divider, Select, Switch } from 'antd'
import { Typography } from 'antd'
import ReactCountryFlag from 'react-country-flag'
import AssessmentColor from './components/AssessmentColor'
import ExcelPreferences from './components/ExcelPreferences'

const { Text, Title } = Typography

const { Option } = Select

interface IPreferences {
   endpoint: string
}

//TODO: Clear the excel preference form #####
//TODO: Add the ability to close the modal with the X #####
//TODO: Validate the excel preference form(name) #####
//TODO: when the form is open clear the alert message #####
const Preferences = observer((props: IPreferences) => {
   const { SetttingsStore } = useStore()
   const pageTitle = 'Preferences'

   const onSaveButton = () => {
      SetttingsStore.savePreferences()
   }

   useEffect(() => {
      SetttingsStore.endpoint = props.endpoint
      SetttingsStore.getPreferencesInitData()
      SetttingsStore.getSelectedPreferences()
      SetttingsStore.getHiglightPreferences()
   }, [])

   const languageOptions = SetttingsStore.languages.map((language: any) => {
      return (
         <Option
            value={language.code}
            label={language.description}
            key={language.code}
         >
            {/* <div style={{ display: 'flex' }}> */}
            <ReactCountryFlag
               countryCode={language.key}
               svg
               style={{
                  width: '1em',
                  height: '1em',
                  marginBottom: '2%',
                  marginRight: '2%',
               }}
            />
            {language.description}
            {/* </div> */}
         </Option>
      )
   })

   const riskLevelOptions = SetttingsStore.riskLevels?.map((riskLevel: any) => {
      return (
         <Option
            value={riskLevel.key}
            label={riskLevel.description}
            key={riskLevel.key}
         >
            {riskLevel.description}
         </Option>
      )
   })

   const assessmentStatusOptions = SetttingsStore.assessmentStatuses?.map(
      (status: any) => {
         return (
            <Option value={status.key} label={status.value} key={status.key}>
               {status.value}
            </Option>
         )
      }
   )

   const reminderDates = [
      { key: 24, value: '1-Day' },
      { key: 48, value: '2-Day' },
      { key: 72, value: '3-Days' },
      { key: 168, value: '1-Week' },
   ]

   const responseDueDates = [
      { key: 1, value: '1-Week' },
      { key: 2, value: '2-Week' },
      { key: 3, value: '3-Week' },
      { key: 4, value: '4-Week' },
      { key: 5, value: '5-Week' },
   ]

   const responseDueDateOptions = responseDueDates.map((e) => (
      <Option key={e.key} label={e.value} value={e.key}>
         {e.value}
      </Option>
   ))

   const reminderOptions = reminderDates.map((e) => (
      <Option key={e.key} label={e.value} value={e.key}>
         {e.value}
      </Option>
   ))

   const activeFilterCountOptions = [10, 15, 20, 25].map((e) => (
      <Option key={e} label={e} value={e}>
         {e}
      </Option>
   ))

   const disabledFilterCountOptions = [10, 15, 20, 25].map((e) => (
      <Option key={e} label={e} value={e}>
         {e}
      </Option>
   ))

   return (
      <Spin spinning={SetttingsStore.isLoading}>
         <Helmet>
            <title>{pageTitle}</title>
         </Helmet>
         <Row>
            <Col span={8}></Col>
            <Col style={{ textAlign: 'center' }} span={8}>
               <Title level={3} style={{}}>
                  {pageTitle}
               </Title>
            </Col>
         </Row>    
         <Divider orientation="left" plain>
            <strong> General </strong>
         </Divider>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Response Due Date:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  style={{ width: '47%', marginTop: '-1%' }}
                  placeholder="Response Due Date..."
                  size="small"
                  value={SetttingsStore.selectedResponseDueDate}
                  onChange={(e: any, event: any) =>
                     SetttingsStore.setSelectedResponseDueDate(e)
                  }
               >
                  {responseDueDateOptions}
               </Select>
            </Col>
         </Row>
         <br />
         <Divider orientation="left" plain>
            <strong> My Default Filters</strong>
         </Divider>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Risk Level:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  style={{ width: '47%', marginTop: '-1%' }}
                  placeholder="Risk Levels..."
                  size="small"
                  value={SetttingsStore.selectedRisk}
                  onChange={(e: any, event: any) =>
                     SetttingsStore.setSelectedRisk(e)
                  }
               >
                  {riskLevelOptions}
               </Select>
            </Col>
         </Row>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Assessment Status:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  style={{ width: '47%', marginTop: '-1%' }}
                  placeholder="Assessment Status..."
                  size="small"
                  value={SetttingsStore.selectedAssessmentStatus}
                  onChange={(e: any) =>
                     SetttingsStore.setSelectedAssessmentStatus(e)
                  }
               >
                  {assessmentStatusOptions}
               </Select>
            </Col>
         </Row>
         <br />
         <Divider orientation="left" plain>
            <strong> Allowed Filter Count </strong>
         </Divider>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Active Filter Count:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  style={{ width: '47%', marginTop: '-1%' }}
                  placeholder="Active Filter Count..."
                  size="small"
                  value={SetttingsStore.selectedActiveFilterCount}
                  onChange={(e: number) =>
                     SetttingsStore.setSelectedActiveFilterCount(e)
                  }
               >
                  {activeFilterCountOptions}
               </Select>
            </Col>
         </Row>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Disabled Filter Count:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  style={{ width: '47%', marginTop: '-1%' }}
                  placeholder="Disabled Filter Count..."
                  size="small"
                  value={SetttingsStore.selectedDisabledFilterCount}
                  onChange={(e: number) =>
                     SetttingsStore.setSelectedDisabledFilterCount(e)
                  }
               >
                  {disabledFilterCountOptions}
               </Select>
            </Col>
         </Row>
         <br />
         <Divider orientation="left" plain>
            <strong> Email Notifications </strong>
         </Divider>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={8}>
               <Text> Notify me before an assessment is due:</Text>
            </Col>
            <Col span={9}>
               <Select
                  style={{ width: '48%', marginTop: '-1%' }}
                  placeholder="Reminder date..."
                  size="small"
                  value={SetttingsStore.selectedReminderDate}
                  onChange={(e: number) =>
                     SetttingsStore.setSelectedReminderDate(e)
                  }
               >
                  {reminderOptions}
               </Select>
            </Col>
         </Row>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={8}>
               <Text> Notify me on assessment status change:</Text>
            </Col>
            <Col span={9}>
               <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={SetttingsStore.selectedNotificationOnChange}
                  onChange={(checked: boolean) =>
                     SetttingsStore.setSelectedNotificationOnChange(checked)
                  }
               />
            </Col>
         </Row>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={8}>
               <Text> Notify me when an assessment is assigned to me:</Text>
            </Col>
            <Col span={9}>
               <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={SetttingsStore.selectedNotificationOnAssignment}
                  onChange={(checked: boolean) =>
                     SetttingsStore.setSelectedNotificationOnAssignment(checked)
                  }
               />
            </Col>
         </Row>
         <br />
         <Divider orientation="left" plain>
            <strong> Language, Date And Time </strong>
         </Divider>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Default Language:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  style={{ width: '47%', marginTop: '-1%' }}
                  placeholder="Select language..."
                  size="small"
                  value={SetttingsStore.selectedLanguageCode}
                  onChange={(e: any, event: any) =>
                     SetttingsStore.setSelectedLanguageCode(e)
                  }
               >
                  {languageOptions}
               </Select>
            </Col>
         </Row>
         <br />
         <Divider orientation="left" plain>
            <strong> Impact Assessment Page Settings </strong>
         </Divider>
         <Row style={{ marginTop: '2%' }}>
            <Col span={1}></Col>
            <Col span={5}>
               <Text> Default status:</Text>
            </Col>
            <Col span={12} style={{ marginLeft: '7%' }}>
               <Select
                  placeholder="Select Status"
                  size="small"
                  value={SetttingsStore.selectedDefaultStatuses}
                  onChange={(e: any) => {
                     SetttingsStore.setSelectedDefaultStatuses(e)
                  }}
                  optionLabelProp="label"
                  style={{ width: '47%', marginTop: '-1%' }}
                  mode="multiple"
               >
                  {assessmentStatusOptions}
               </Select>{' '}
            </Col>
         </Row>
         <br />
         <Divider orientation="left" plain>
            <strong> Assessment Due Highlight Colors </strong>
         </Divider>
         <AssessmentColor />
         <Divider orientation="left" plain>
            <strong> Excel column preference </strong>
         </Divider>
         <ExcelPreferences />

         <br />
         <br />
         <br />
         <Row>
            <Col span={10}></Col>
            <Col span={5}>
               <Button
                  type="primary"
                  style={{
                     borderRadius: '10px',
                     backgroundColor: 'rgb(250, 140, 22)',
                     borderColor: 'rgb(250, 140, 22)',
                     color: 'white',
                     marginLeft: '5%',
                  }}
                  onClick={onSaveButton}
               >
                  Save Preferences
               </Button>
            </Col>
         </Row>
         <br />
      </Spin>
   )
})
export default Preferences
