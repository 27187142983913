import { makeAutoObservable } from 'mobx'
import { NewsletterApi } from './NewsletterApi'
import { CommonStore } from '../../context/CommonStore'
import moment from 'moment'
import { Helpers } from '../../api/Helpers'

import { toast } from 'react-hot-toast'
import { warningStyle } from '../../utils/Helpers'

export class NewsletterStore {
   localeDateFormat: string = 'DD/MM/YYYY'
   name: string | undefined
   surname: string | undefined
   searchValue: string = ''
   isFiltered: boolean = false
   newsletterList: any[] = [] // TODO.
   startDate: moment.Moment = moment()
      .subtract(7, 'days')
      .locale(this.localeDateFormat) // Date
   endDate: moment.Moment = moment().endOf('day').locale(this.localeDateFormat) // Date
   isDateRangeValid: boolean = true
   selectedRowKeys: any[] = []
   newsletterListFiltered: any[] = [] // TODO.
   connectionErrorMessageVisible: boolean = false
   isLoading: boolean = false
   errorType: any = 'error'
   alertMessage: string = ''
   isAssessmentModalVisible: boolean = false
   fullVersion: boolean = true
   newsletterCount: number = 0
   newsletterOpenedId: number = 0
   miniAssessmentModalVisible: boolean = false
   buttonProps: any = {
      type: 'link',
   }
   newsletterContent: string = ''
   isDeleteConfirmModalVisible: boolean = false

   private root: CommonStore
   constructor(root: CommonStore) {
      makeAutoObservable(this)
      this.root = root
      this.localeDateFormat = 'DD/MM/YYYY'
   }

   setLocaleDateFormat = (value: string) => {
      this.localeDateFormat = value
   }
   setFullVersion(isFullVersion: boolean) {
      this.fullVersion = isFullVersion
   }

   setMiniAssessmentModalVisible(val: boolean) {
      this.miniAssessmentModalVisible = val
   }

   IsModalVisible: boolean = false
   setIsModalVisible(val: boolean) {
      this.IsModalVisible = val
   }

   removeNewsletterListFromTheList() {
      const newData = [...this.newsletterList]
      const filteredRuleList = newData.filter((newsletter: any) => {
         const index = this.selectedRowKeys.findIndex(
            (e) => e === newsletter.mainRuleId
         )
         if (index < 0) return newsletter
      })
      this.newsletterList = filteredRuleList
      this.newsletterCount = this.newsletterList.length
   }

   removeSelectedRowKeys(selectedKeys: any[]) {
      this.selectedRowKeys.filter((e) => e === selectedKeys)
   }

   setOpenNewsletterId(newsletterId: number) {
      this.newsletterOpenedId = newsletterId
   }

   setSearchValue(value: string) {
      this.searchValue = value
   }

   setNewsletterList(newsletter: any[]) {
      this.newsletterList = newsletter
   }

   setNewsletterListFiltered(newsletters: any[]) {
      this.newsletterListFiltered = newsletters
   }

   // @Computed
   clearSearchBox() {
      this.searchValue = ''
      this.isFiltered = false
   }
   setCalanderDate(startDate: moment.Moment, endDate: moment.Moment) {
      this.startDate = startDate.locale(this.localeDateFormat)
      this.endDate = endDate.locale(this.localeDateFormat)
   }
   getNewsletterDetails(newsletterId: number) {
      this.isLoading = true
      this.connectionErrorMessageVisible = false

      NewsletterApi.getNewsletterDetails(newsletterId)
         .then((result) => {
            if (result !== '' && result !== undefined) {
               this.newsletterContent = result
            } else {
               this.connectionErrorMessageVisible = true
               this.alertMessage = result.additionalMessage
            }
            this.isLoading = false
         })
         .catch((err) => {
            this.isLoading = false
         })
   }

   getNewsletter() {
      this.isLoading = true
      NewsletterApi.getNewsletterList(
         this.startDate.format('YYYY-MM-DD'),
         this.endDate.format('YYYY-MM-DD')
      )
         .then((result) => {
            if (result.length === 0) {
               this.showWarningMessage(
                  'There is no newsletter for the given period.'
               )
               this.newsletterList = []
            } else {
               this.newsletterCount = result.length
               this.newsletterList = result
            }
            this.isLoading = false
         })
         .catch((err) => {
            this.showErrorMessage(
               'This newsletter is tough to delete. Please refresh the page and try' +
                  ' again. If the problem persists, inform a reg-room administrator at  ' +
                  Helpers.infoEmail +
                  ' with the code UI-1001'
            )
            this.isLoading = false
         })
   }

   validateInputs() {
      if (this.startDate === null) this.isDateRangeValid = false
      return this.isDateRangeValid
   }

   setIsDateRangeValid = (isValid: boolean) => {
      this.isDateRangeValid = isValid
   }

   deleteNewsletter(newsletterId: number) {
      // TODO : Improve error handling.
      const toastId = toast.loading('Deleting...')
      NewsletterApi.removeNewsletter(newsletterId)
         .then((result) => {
            if (!(result.name === 'Error' || result === false)) {
               const newArray = [...this.newsletterList]
               const index = newArray.findIndex(
                  (e) => e.newsletterId === newsletterId
               )
               newArray.splice(index, 1)
               this.newsletterList = newArray
               this.showSuccessMessage(
                  'Newsletter deleted successfully',
                  toastId
               )
            } else {
               this.showErrorMessage(
                  'This newsletter is tough to delete. Please refresh the page and try' +
                     ' again. If the problem persists, inform a reg-room administrator at  ' +
                     Helpers.infoEmail +
                     ' with the code UI-1001',
                  toastId
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'This newsletter is tough to delete. Please refresh the page and try' +
                  ' again. If the problem persists, inform a reg-room administrator at  ' +
                  Helpers.infoEmail +
                  ' with the code UI-1001',
               toastId
            )
         })
   }

   shareNewsletter(newsletterId: number) {
      // TODO : Improve error handling.
      const toastId = toast.loading('Sharing...')
      NewsletterApi.shareNewsletter(newsletterId)
         .then((result) => {
            if (!(result.name === 'Error' || result === false)) {
               const newArray = [...this.newsletterList]
               const index = this.newsletterList.findIndex(
                  (e) => e.newsletterId === newsletterId
               )
               newArray[index].isPublic = result.isPublic
               newArray[index].token = result.token
               this.newsletterList = newArray
               this.showSuccessMessage(
                  'Public link created successfully',
                  toastId
               )
            } else {
               this.showErrorMessage(
                  'This newsletter is tough to share. Please refresh the page and try' +
                     ' again. If the problem persists, inform a reg-room administrator at ' +
                     Helpers.infoEmail +
                     ' with the code UI-1002',
                  toastId
               )
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'This newsletter is tough to delete. Please refresh the page and try' +
                  ' again. If the problem persists, inform a reg-room administrator at  ' +
                  Helpers.infoEmail +
                  ' with the code UI-1001',
               toastId
            )
         })
   }

   ClearState() {
      this.newsletterList = []
      this.selectedRowKeys = []
      this.newsletterCount = 0
      this.connectionErrorMessageVisible = false
      this.clearSearchBox()
      this.newsletterOpenedId = 0
   }

   showErrorMessage(message: string = '', id: string = 'error') {
      toast.error(message, { id })
   }

   showSuccessMessage(message: string, id: string = 'error') {
      toast.success(message, { id })
   }

   showWarningMessage(message: string) {
      toast(message, warningStyle)
   }
}
