export const deleteCookies = (name: string, path: string, domain: string) => {
   var rr = get_cookie(name)
   if (rr) {
      document.cookie =
         name +
         '=' +
         (path ? ';path=' + path : '') +
         (domain ? ';domain=' + domain : '') +
         ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
   }
}
export const get_cookie = (name: string) => {
   const aa = document.cookie.split(';').some((c) => {
      return c.trim().startsWith(name + '=')
   })
   return aa
}
