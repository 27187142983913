import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import {
   Table,
   ConfigProvider,
   Space,
   DatePicker,
   Affix,
   Alert,
   Spin,
} from 'antd'
import { RemoveAllTagFromString } from '../../utils/Filters'
import { Card, Divider, Menu } from 'antd'
import { Row, Col, Input, Button, Typography } from 'antd'
import {
   SearchOutlined,
   CloseCircleFilled,
   PlusCircleOutlined,
   DownloadOutlined,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import plus from '../../assets/plus.svg'
import { Helmet } from 'react-helmet'

import './DailyRulesScreen.scss'
import AssessmentModal from '../impactAssessments/components/AssessmentModal'

const { RangePicker } = DatePicker
const { Title, Text } = Typography

export interface INonAssessedRules {
   ruleNumber: number
}

const NonAssessedRules = observer((props: INonAssessedRules) => {
   const { DailyRulesStore, ImpactAssessmentsStore } = useStore()
   const [isAffixButtonActive, setIsAffixButtonActive] = useState(false)
   const [selectedAssessmentTitle, setSelectedAssessmentTitle] = useState('')
   const [currentRules, setCurrentRules] = useState<any[]>([])
   const [selectedRules, setSelectedRules] = useState<object[]>([])
   const [addedRecords, setAddedRecord] = useState<string[]>([])
   const [selectedAuditIdsForReset, setSelectedAuditIdsForReset] = useState<
      string[]
   >([])

   const pageTitle = 'Non-Assessed Rules'

   const onManageClick = useCallback(
      (record: any) => {
         ImpactAssessmentsStore.setSelectedAssessmentId(record.assessmentId)
         ImpactAssessmentsStore.setSelectedAssessmentRuleId([record.mainRuleId])
         ImpactAssessmentsStore.setSelectedAuditCoreIds([
            record.mainRuleAuditCoreId,
         ])
      },
      [ImpactAssessmentsStore]
   )

   const resetModal = useCallback(() => {
      ImpactAssessmentsStore.setStatusKey(
         ImpactAssessmentsStore.statusPreference
      )
      ImpactAssessmentsStore.setRiskKey(
         ImpactAssessmentsStore.riskLevelPreference
      )
      ImpactAssessmentsStore.setResDueDate(
         ImpactAssessmentsStore.preferencesResDueDate
      )
   }, [ImpactAssessmentsStore])

   const onAddNewAssessment = useCallback(
      (record: any) => {
         setCurrentRules([record])
         onManageClick(record)

         ImpactAssessmentsStore.setIsAssessmentModalVisible(true)
         setSelectedAssessmentTitle('Add new assessment allocation')
      },
      [onManageClick, ImpactAssessmentsStore]
   )

   const onAllocatedSelectedRules = useCallback(() => {
      resetModal()
      setCurrentRules([...selectedRules])

      ImpactAssessmentsStore.setIsAssessmentModalVisible(true)
      setSelectedAssessmentTitle('Add new assessment allocation')
   }, [resetModal, selectedRules, ImpactAssessmentsStore])

   const dataSource = useMemo(
      () =>
         DailyRulesStore.isFiltered
            ? DailyRulesStore.dailyRuleListFiltered
            : DailyRulesStore.dailyRuleList,
      [
         DailyRulesStore.isFiltered,
         DailyRulesStore.dailyRuleList,
         DailyRulesStore.dailyRuleListFiltered,
      ]
   )

   const refreshTable = useCallback(() => {
      if (currentRules)
         setAddedRecord((r) => [
            ...r,
            ...currentRules.map((item: any) => item.mainRuleId),
         ])
      setCurrentRules([])

      ImpactAssessmentsStore.setSelectedAssessmentRuleId(
         selectedRules.map((e: any) => e?.mainRuleId)
      )
      ImpactAssessmentsStore.setSelectedAuditCoreIds(selectedAuditIdsForReset)
      DailyRulesStore.setSelectedAuditCoreIds(selectedAuditIdsForReset)
   }, [currentRules])

   const dataSourceClean = useMemo(
      () => dataSource.filter((i) => !addedRecords.includes(i.mainRuleId)),
      [dataSource, addedRecords]
   )

   const columns = [
      {
         title: 'Rule ID',
         dataIndex: 'mainRuleId',
         key: 'mainRuleId',
         render: (text: string) => (
            <a
               href={'https://reg-track.com/rules/search/Details/' + text}
               target="_blank"
               rel="noreferrer"
               style={{ cursor: 'pointer' }}
            >
               {text}
            </a>
         ), // TODO : add href attribute to all anchors.
         width: '10%',
      },
      {
         title: 'Title',
         dataIndex: 'shortTitle',
         key: 'shortTitle',
         width: '25%',
         render: (text: string) => <span>{text}</span>,
      },
      {
         title: 'Introduction Line',
         dataIndex: 'introductionLine',
         key: 'introductionLine',
         width: '25%',
         render: (text: string) => {
            // TODO: Use react-html-parser lib here.
            return <p dangerouslySetInnerHTML={{ __html: text }}></p>
         },
      },
      {
         title: 'Type',
         dataIndex: 'ruleType',
         key: 'ruleType',
         width: '8%',
      },
      {
         title: 'Removed By',
         dataIndex: 'userName',
         key: 'userName',
         width: '14%',
      },
      {
         title: 'Comment',
         dataIndex: 'comment',
         key: 'comment',
         width: '10%',
      },

      {
         title: 'Removal Date',
         dataIndex: 'deletedDate',
         key: 'deletedDate',
         width: '14%',
      },
      {
         title: 'Action',
         key: 'action',
         width: '8%',
         render: (record: any) => (
            <Space size="middle">
               <Tooltip placement="top" title="Allocate this rule">
                  <img
                     src={plus}
                     onClick={() => onAddNewAssessment(record)}
                     alt="Send an Assignment"
                  />
               </Tooltip>
            </Space>
         ),
      },
   ]

   // TODO: Change any to DataType.
   const rowSelection = {
      selectedRowKeys: DailyRulesStore.selectedRowKeys,

      getCheckboxProps: (record: any) => ({
         disabled: record.name === 'Disabled User', // Column configuration not to be checked
         name: record.name,
      }),
      onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
         const selectedAllKeys = selectedRows.map((e: any) => e?.mainRuleId)
         const selectedAuditCoreIds = selectedRows.map(
            (e: any) => e.mainRuleAuditCoreId
         )

         DailyRulesStore.setSelectedRowKeys(selectedAllKeys)
         DailyRulesStore.setSelectedAuditCoreIds(selectedAuditCoreIds)

         setIsAffixButtonActive(selectedRows.length > 0)
         DailyRulesStore.setMultipleSelected(selectedRows.length > 0)
      },
      onSelect: (
         record: any,
         selected: boolean,
         selectedRows: any,
         nativeEvent: any
      ) => {
         let selectedRowKeys: number[] = []
         let selectedAuditIds: string[] = []
         setSelectedRules([...selectedRows.map((r: any) => ({ ...r }))])

         if (selected) {
            // If checkbox is selected then add to Selected Keys
            selectedRowKeys = [
               ...DailyRulesStore.selectedRowKeys,
               record.mainRuleId,
            ]
            selectedAuditIds = [
               ...DailyRulesStore.selectedAuditCoreIds,
               record.mainRuleAuditCoreId,
            ]
         } else {
            // If checkbox is unselected then remove it from Selected Keys
            const ruleIds = [...DailyRulesStore.selectedRowKeys]
            const auditCoreIds = [...DailyRulesStore.selectedAuditCoreIds]

            const indexOfSelectedRuleId = ruleIds.indexOf(record.mainRuleId)

            ruleIds.splice(indexOfSelectedRuleId, 1)
            auditCoreIds.splice(indexOfSelectedRuleId, 1)

            selectedRowKeys = ruleIds
            selectedAuditIds = auditCoreIds
         }

         setIsAffixButtonActive(selected)
         DailyRulesStore.setSelectedRowKeys(selectedRowKeys) // non
         DailyRulesStore.setMainRuleIds(selectedRowKeys)
         DailyRulesStore.setSelectedAuditCoreIds(selectedAuditIds) // oui

         DailyRulesStore.setMultipleSelected(selectedRowKeys.length > 0)

         ImpactAssessmentsStore.setSelectedAssessmentRuleId(selectedRowKeys) // oui
         ImpactAssessmentsStore.setSelectedAssessmentId('')
         ImpactAssessmentsStore.setSelectedAuditCoreIds(selectedAuditIds) // oui

         setSelectedAuditIdsForReset(selectedAuditIds)
      },
   }

   const searchDailyRules = () => {
      DailyRulesStore.setSelectedRowKeys([])
      DailyRulesStore.getNonAssessedRules()
   }

   const [isAdmin, setIsAdmin] = useState(false)

   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)

      DailyRulesStore.setIsNonAssessed('true')
      DailyRulesStore.getDefaultPreferences()
      DailyRulesStore.getNonAssessedRules()

      return DailyRulesStore.ClearState()
   }, [])

   useEffect(() => {
      ImpactAssessmentsStore.fillInitialStore()
      ImpactAssessmentsStore.getDefaultPreferences()
      ImpactAssessmentsStore.getUserExcelPreferences()

      return () => {
         ImpactAssessmentsStore.clearIAState()
      }
   }, [])

   // TODO Move to store. dailyRuleList data will be in the store as well...
   const ruleListSearchboxOnChange = (target: any) => {
      const targetValue = target?.target?.value
      if (targetValue === '' || targetValue === undefined) {
         DailyRulesStore.isFiltered = false
      } else {
         DailyRulesStore.setDailyRuleListFiltered(
            DailyRulesStore.dailyRuleList?.filter(
               (e: any) =>
                  e.mainRuleId.toPrecision().match(targetValue) ||
                  e.shortTitle.match(targetValue) ||
                  e.introductionLine.match(targetValue) ||
                  RemoveAllTagFromString(e.introductionLine)
                     .toLowerCase()
                     .match(targetValue)
            )
         )
         DailyRulesStore.isFiltered = true
      }

      DailyRulesStore.setSearchValue(targetValue)
   }

   const onCalenderChange = (e: any, dateRange: any) => {
      if (e !== null && e[0] !== null && e[1] !== null) {
         DailyRulesStore.setCalanderDate(e[0], e[1])
         DailyRulesStore.setIsDateRangeValid(true)
      }
   }

   const onAffixChange = () => {
      if (DailyRulesStore.buttonProps.type === 'link') {
         DailyRulesStore.setAffixButtonsType('primary')
      } else {
         DailyRulesStore.setAffixButtonsType('link')
      }
   }

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            <Row>
               <Col style={{ textAlign: 'center' }} span={24}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
            </Row>

            <AssessmentModal
               initializeValuesFromStore={false}
               title={selectedAssessmentTitle}
               action={refreshTable}
               selected={currentRules}
            />

            <Spin spinning={DailyRulesStore.isLoading}>
               {DailyRulesStore.connectionErrorMessageVisible && (
                  <Row style={{ marginBottom: '1%' }}>
                     <Col span={24}>
                        <Alert
                           message={DailyRulesStore.alertMessage}
                           type={DailyRulesStore.errorType}
                           showIcon
                        />
                     </Col>
                  </Row>
               )}
               <Card bordered={true} style={{ backgroundColor: '#fafafa' }}>
                  <Row>
                     <Col span={3}>{/* <Text> Removal Date </Text> */}</Col>
                     <Col
                        span={2}
                        style={{ display: 'flex', justifyContent: 'center' }}
                     >
                        <Text style={{ marginTop: '4%' }}>
                           {' '}
                           <strong>Date: </strong>{' '}
                        </Text>
                     </Col>
                     <Col span={8} style={{ textAlign: 'left' }}>
                        <RangePicker
                           style={{ width: '100%' }}
                           format={DailyRulesStore.localeDateFormat}
                           onCalendarChange={onCalenderChange}
                           defaultValue={[
                              DailyRulesStore.startDate,
                              DailyRulesStore.endDate,
                           ]}
                           // value={[
                           //    moment(DailyRulesStore.startDate, dateFormat),
                           //    moment(DailyRulesStore.endDate, dateFormat),
                           // ]}
                           className={
                              !DailyRulesStore.isDateRangeValid
                                 ? 'dateRange-validator'
                                 : ''
                           }
                        />
                        {!DailyRulesStore.isDateRangeValid && (
                           <span style={{ color: 'red', fontSize: '13px' }}>
                              Date Range is required!{' '}
                           </span>
                        )}
                     </Col>
                  </Row>
                  <Row style={{ marginTop: '2%' }}>
                     <Col span={5} />
                     <Col span={8} style={{ textAlign: 'right' }}>
                        <Button
                           htmlType="submit"
                           onClick={searchDailyRules}
                           icon={<SearchOutlined />}
                           style={{
                              backgroundColor: 'rgb(250, 140, 22)',
                              borderColor: 'rgb(250, 140, 22)',
                              borderRadius: '10px',
                              color: 'white',
                           }}
                        >
                           Search{' '}
                        </Button>
                     </Col>
                  </Row>
               </Card>
               <Space direction="vertical" className="space">
                  <Row style={{ marginLeft: '4%' }}>
                     <Col span="4"></Col>

                     <Col span="14"></Col>
                  </Row>
                  <Divider
                     type="horizontal"
                     style={{
                        marginTop: '4%',
                        marginBottom: '3%',
                        backgroundColor: 'rgb(250, 140, 22)',
                        opacity: '0.3',
                     }}
                  />
                  <Row className="table-search-row">
                     <Col span={4}>
                        <Affix offsetTop={10} onChange={onAffixChange}>
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'flex-start',
                              }}
                           >
                              <Button
                                 {...DailyRulesStore.buttonProps}
                                 disabled={!DailyRulesStore.multipleSelected}
                                 onClick={onAllocatedSelectedRules}
                                 icon={<PlusCircleOutlined />}
                                 className={
                                    isAffixButtonActive
                                       ? 'affix-button-active'
                                       : 'affix-button-disable'
                                 }
                              >
                                 Allocate selected rules{' '}
                              </Button>
                           </div>
                        </Affix>
                     </Col>
                     <Col span={4}>
                        <Affix
                           offsetTop={10}
                           onChange={onAffixChange}
                           style={{ marginLeft: '10%' }}
                        >
                           <Button
                              {...DailyRulesStore.buttonProps}
                              icon={<DownloadOutlined />}
                              className={
                                 isAffixButtonActive
                                    ? 'affix-button-active'
                                    : 'affix-button-disable'
                              }
                              disabled={!DailyRulesStore.multipleSelected}
                              onClick={() =>
                                 DailyRulesStore.downloadNonAssessedRules()
                              }
                           >
                              Export{' '}
                           </Button>
                        </Affix>
                     </Col>
                     <Col
                        span={16}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                     >
                        <Input
                           placeholder="Search table"
                           style={{ width: '25%' }}
                           value={DailyRulesStore.searchValue}
                           onChange={(e: any) => ruleListSearchboxOnChange(e)}
                           suffix={
                              <>
                                 <CloseCircleFilled
                                    className="close-circle"
                                    onClick={() =>
                                       DailyRulesStore.clearSearchBox()
                                    }
                                 />{' '}
                              </>
                           }
                        />
                        <div className="search-icon-div">
                           <SearchOutlined className="search-icon" />
                        </div>
                     </Col>
                  </Row>
                  <ConfigProvider>
                     <Table
                        rowKey={(record) => record.mainRuleId}
                        rowSelection={{
                           type: 'checkbox',
                           ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={dataSourceClean}
                        size="middle"
                        pagination={{
                           position: ['bottomCenter'],
                           defaultPageSize: 10,
                           showSizeChanger: true,
                           pageSizeOptions: [
                              '10',
                              '20',
                              '30',
                              '50',
                              '100',
                              '500',
                              '1000',
                           ],
                        }}
                     />
                  </ConfigProvider>
               </Space>
            </Spin>
         </>
      )
})
export default NonAssessedRules
