import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import {
   Row,
   Col,
   Button,
   Table,
   Tag,
   Typography,
   Input,
   Popover,
   Spin,
   Select,
} from 'antd'
import {
   PlusCircleOutlined,
   EditOutlined,
   CloseCircleFilled,
   SearchOutlined,
   DownOutlined,
   UserDeleteOutlined,
   LockOutlined,
   FileExcelOutlined,
} from '@ant-design/icons'
import { ReactComponent as Disable } from '../../assets/disable.svg'
import { ReactComponent as Enable } from '../../assets/user-right.svg'
import OrganisationalChart from '../../components/OrganisationalChart'

import './CompanyUsers.scss'
import { Helpers } from '../../api/Helpers'

const { Title } = Typography
interface ICompanyUsers {
   endpoint: string
}

const CompanyUsers = observer((props: ICompanyUsers) => {
   const { CompanyUsersStore } = useStore()
   const pageTitle = 'Company Users'
   const history = useHistory()

   const [isActive, setIsActive] = useState(true)

   useEffect(() => {
      CompanyUsersStore.getCompanyUsers(isActive)

      return () => {
         CompanyUsersStore.clearStore()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isActive])

   const sendNewPasswordToUser = (id: any) => {
      CompanyUsersStore.sendPasswordToUser(id)
   }

   const unlockUser = (id: any) => {
      CompanyUsersStore.unlockUser(id)
   }

   const columns = [
      {
         title: 'Name',
         dataIndex: 'name',
         hidden: false,
         key: 'name',
         // render: (text: string) => <a>{text}</a>,
      },
      {
         title: 'Surname',
         dataIndex: 'surname',
         hidden: false,
         key: 'surname',
      },
      {
         title: 'Email',
         dataIndex: 'email',
         hidden: false,
         key: 'email',
      },
      // {
      //    title: 'Licence Type',
      //    dataIndex: 'licenceType',
      //    hidden: false,
      //    key: 'licenceType',
      // },
      {
         title: 'Start Date',
         dataIndex: 'startDate',
         hidden: false,
         key: 'startDate',
      },
      // {
      //    title: 'End Date',
      //    dataIndex: 'endDate',
      //    hidden: false,
      //    key: 'endDate',
      // },
      {
         title: 'Departments',
         key: 'departments',
         dataIndex: 'departments',
         hidden: false,
         render: (departments: any[]) => (
            <>
               {departments.map((tag) => {
                  return (
                     <Tag color="geekblue" key={tag.key}>
                        {tag.name.toUpperCase()}
                     </Tag>
                  )
               })}
            </>
         ),
      },
      {
         title: 'Permissions',
         key: 'permissions',
         hidden: false,
         dataIndex: 'roles',
         render: (tags: any[]) => (
            <>
               {tags.map((tag) => {
                  return (
                     <Tag color="blue" key={tag.roleName}>
                        {tag.roleName.toUpperCase()}
                     </Tag>
                  )
               })}
            </>
         ),
      },
      {
         title: 'Operation',
         key: 'operation',
         hidden: !CompanyUsersStore.isLoggedUserSuperAdminOrAdmin,
         render: (event: any) => {
            return (
               <span
                  style={{
                     display: 'flex',
                     alignItems: 'flex-start',
                  }}
               >
                  <>
                     {' '}
                     <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => onEditClick(event)}
                        style={{ color: 'rgb(250, 140, 22)' }}
                     >
                        Edit{' '}
                     </Button>
                     <Popover
                        content={
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'flex-start',
                                 flexDirection: 'column',
                              }}
                           >
                              <Button
                                 type="link"
                                 ghost
                                 htmlType="submit"
                                 // disabled={!multipleSelected}
                                 onClick={() => sendNewPasswordToUser(event.id)}
                                 icon={<LockOutlined />}
                                 style={{ color: '#613400' }}
                              >
                                 Send new password to 'user'{' '}
                              </Button>
                              <Button
                                 type="link"
                                 ghost
                                 htmlType="submit"
                                 // disabled={!multipleSelected}
                                 onClick={() =>
                                    CompanyUsersStore.sendPasswordToLoggedUser(
                                       event.id
                                    )
                                 }
                                 style={{ color: '#613400' }}
                                 icon={<LockOutlined />}
                              >
                                 Send new password to 'me'{' '}
                              </Button>
                              {event.status && (
                                 <Button
                                    type="link"
                                    ghost
                                    htmlType="submit"
                                    onClick={(e: any) => {
                                       CompanyUsersStore.disableUser(event.id)
                                    }}
                                    // icon={<Disable />}
                                    style={{ color: '#613400' }}
                                 >
                                    <Disable />
                                    <span style={{ marginLeft: '8%' }}>
                                       Disable User{' '}
                                    </span>
                                 </Button>
                              )}
                              {!event.status && (
                                 <Button
                                    type="link"
                                    ghost
                                    htmlType="submit"
                                    onClick={(e: any) => {
                                       CompanyUsersStore.enableUser(event.id)
                                    }}
                                    // icon={<Disable />}
                                    style={{ color: '#613400' }}
                                 >
                                    <Enable />
                                    <span style={{ marginLeft: '8%' }}>
                                       Enable User{' '}
                                    </span>
                                 </Button>
                              )}
                              {CompanyUsersStore.isLoggedUserSuperAdminOrAdmin &&
                                 !event.status && (
                                    <Col span={4} style={{ textAlign: 'left' }}>
                                       <Button
                                          type="link"
                                          onClick={(e: any) =>
                                             unlockUser(event.id)
                                          }
                                          icon={<PlusCircleOutlined />}
                                          style={{
                                             color: '#fa8c16',
                                             borderRadius: '10px',
                                          }}
                                       >
                                          Unlock User
                                       </Button>
                                    </Col>
                                 )}
                              <Button
                                 type="link"
                                 ghost
                                 htmlType="submit"
                                 // disabled={!multipleSelected}
                                 onClick={() =>
                                    CompanyUsersStore.deleteUser(event.id)
                                 }
                                 icon={<UserDeleteOutlined />}
                                 style={{ color: '#ff4d4f' }}
                              >
                                 Delete User{' '}
                              </Button>
                           </div>
                        }
                        trigger="click"
                        placement="bottom"
                     >
                        <Button
                           style={{
                              // borderColor: 'rgb(250, 140, 22)',
                              borderRadius: '10px',
                              color: 'rgb(250, 140, 22)',
                           }}
                           type="link"
                        >
                           More
                           <DownOutlined />
                        </Button>
                     </Popover>
                  </>
               </span>
            )
         },
      },
   ].filter((item) => !item.hidden)

   const onEditClick = (event: any) => {
      history.push({
         pathname: '/User/' + event.id,
         state: { id: event.id },
      })
   }

   const exportPath = useMemo(
      () => `${Helpers.getBaseApiUrl()}/api/User/ExportAsExcel`,
      []
   )

   const handleStatus = (value: string) => {
      console.log(`selected ${value}`)
      setIsActive(value === 'active')
   }

   return (
      <>
         <Helmet>
            <title>{pageTitle}</title>
         </Helmet>
         <Spin spinning={CompanyUsersStore.isLoading}>
            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
            </Row>
            <Row style={{ marginTop: '3%', marginBottom: '0.7%' }}>
               <Col span={3} style={{ textAlign: 'left' }}>
                  <OrganisationalChart initialDepth={3} />
               </Col>
               <Col span={3} style={{ textAlign: 'left' }}>
                  <Button
                     type="link"
                     onClick={() => history.push('/Departments')}
                     icon={<PlusCircleOutlined />}
                     style={{
                        color: '#fa8c16',
                        borderRadius: '10px',
                     }}
                  >
                     Add New Department
                  </Button>
               </Col>
               {CompanyUsersStore.isLoggedUserSuperAdminOrAdmin && (
                  <Col span={3} style={{ textAlign: 'left' }}>
                     <Button
                        type="link"
                        onClick={() => history.push('/NewUser')}
                        icon={<PlusCircleOutlined />}
                        style={{
                           color: '#fa8c16',
                           borderRadius: '10px',
                        }}
                     >
                        Add New User
                     </Button>
                  </Col>
               )}
               <Col span={3} style={{ textAlign: 'left' }}>
                  <Button
                     type="link"
                     icon={<FileExcelOutlined />}
                     style={{
                        color: '#fa8c16',
                        borderRadius: '10px',
                     }}
                     href={exportPath}
                     download
                  >
                     Export to excel
                  </Button>
               </Col>
               <Col span={4}>
                  <Select
                     style={{ width: '90%' }}
                     defaultValue="active"
                     onChange={handleStatus}
                     options={[
                        { value: 'active', label: 'Active Users' },
                        { value: 'disabled', label: 'Disabled users' },
                     ]}
                  />
               </Col>
               <Col span={8} className="table-search-row">
                  <Input
                     placeholder="Search table"
                     style={{ width: '100%' }}
                     value={CompanyUsersStore.searchValue}
                     onChange={(e: any) =>
                        CompanyUsersStore.companyUserSearchboxOnChange(e)
                     }
                     suffix={
                        <>
                           <CloseCircleFilled className="close-circle" />
                        </>
                     }
                  />
                  <div className="search-icon-div">
                     <SearchOutlined className="search-icon" />
                  </div>
               </Col>
            </Row>
            <Row>
               {/* <Card bordered={false} className="card"> */}
               <Table
                  size="middle"
                  columns={columns}
                  dataSource={
                     CompanyUsersStore.isFiltered
                        ? CompanyUsersStore.pagedCompanyUsers
                        : CompanyUsersStore.companyUsers
                  }
                  pagination={{
                     defaultPageSize: 10,
                     showSizeChanger: true,
                     pageSizeOptions: ['10', '20', '30'],
                     showTotal: (total: any, range: any) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  style={{ width: '100%' }}
               />
               {/* </Card> */}
            </Row>
         </Spin>
         <br />
         <br />
      </>
   )
})
export default CompanyUsers
