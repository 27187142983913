export const GetSemicolonFormat = (array: any[], extractBy: string) => {
   const allExtractedBy = array?.map((currentObj: any) => {
      return currentObj[extractBy]
   })

   let result = ''
   allExtractedBy?.map((currentObj: any) => {
      result = currentObj + '; ' + result
      return result
   })

   return result === '' ? '--' : result.slice(0, result.length - 2)
}

export const RemoveAllTagFromString = (input: string) => {
   return input.replace(/(<([^>]+)>)/gi, '')
}
