import { useEffect, useState } from 'react'
import './lineCharts.scss'
import { Row, Col, Card, Select } from 'antd'
import { RangeValue } from '../CustomRangePicker'
import { observer } from 'mobx-react'
import { useStore } from '../../../../../context'
import moment from 'moment'
import AreaCharts from './AreaCharts'
import { parseDates } from '../../../../../utils/Helpers'

interface IDailyRules {
   dates: RangeValue
   admin: string | null | undefined
}

const selectOptions = [
   {
      value: 'Insert',
      label: 'New Events',
   },
   {
      value: 'Update',
      label: 'Updated Events',
   },
]

const chartInfos = {
   Insert: {
      chartLabel: 'New Events',
      chartName: 'New Events',
      chartDescription:
         'This chart shows the evolution over time of the new rules or events from Reg-Track',
   },
   Update: {
      chartLabel: 'Updated Events',
      chartName: 'Updated Events',
      chartDescription:
         'This chart shows the evolution over time of the rules or events that have been updated from Reg-Track',
   },
} as any

function DailyRules({ dates, admin }: IDailyRules) {
   const { DashboardStore } = useStore()

   const startDate = moment().subtract(7, 'days')
   const endDate = moment()

   const [changeType, setChangeType] = useState('Insert')

   useEffect(() => {
      const sD = dates != null && dates.length > 1 ? dates[0] : startDate
      const eD = dates != null && dates.length > 1 ? dates[1] : endDate

      DashboardStore.getDailyRulesData(sD, eD, changeType, admin)
      return () => {}

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dates, changeType, admin])

   const handleSelectChange = (value: string) => {
      setChangeType(value)
   }

   return (
      <Row gutter={[12, 12]}>
         <Col lg={24} md={24}>
            <Card bordered={false}>
               <div className="lineCharts">
                  <Row justify="space-between">
                     <Col md={12}>
                        <div className="title">
                           <span className="label">
                              {chartInfos[changeType].chartLabel}
                           </span>
                           <span className="description">
                              {chartInfos[changeType].chartDescription}
                           </span>
                        </div>
                     </Col>
                     <Col md={12}>
                        <Row gutter={8} justify="end">
                           <Col>
                              <Select
                                 placeholder="select a type of breakdown"
                                 style={{ width: 300 }}
                                 value={changeType}
                                 onChange={handleSelectChange}
                                 options={selectOptions}
                                 filterOption={(input, option) =>
                                    (option?.label ?? '')
                                       .toLowerCase()
                                       .includes(input.toLowerCase())
                                 }
                                 optionFilterProp="children"
                                 showSearch
                              />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
                  <AreaCharts
                     isLoading={DashboardStore.isDailyRulesStatisticsLoading}
                     items={parseDates(DashboardStore.dailyRulesStatistics)}
                     dates={dates}
                     title={chartInfos[changeType].chartName}
                  />
               </div>
            </Card>
         </Col>
      </Row>
   )
}

export default observer(DailyRules)
