import { Card, Col, Row, Typography } from 'antd'
import { observer } from 'mobx-react'
import { formatNumber } from '../../../../../utils/Helpers'

import './Widget.scss'

interface IWidget {
   id?: string
   title: string
   value: number
   openModal?: any
}

const { Title } = Typography

const Widget = observer((props: IWidget) => {
   const openModal = () => {
      props.openModal(props.id, props.title)
   }

   return (
      <Card hoverable onClick={openModal}>
         <div className="number">
            <Row align="middle">
               <Col xs={18}>
                  <span>{props.title}</span>
                  <Title level={3}>{formatNumber(props.value)}</Title>
               </Col>
               <Col xs={6}>
                  <div className="icon-box">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
                        />
                     </svg>
                  </div>
               </Col>
            </Row>
         </div>
      </Card>
   )
})

export default Widget
