import React from 'react'
import 'chartjs-adapter-moment'
import {
   Chart as ChartJS,
   TimeScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Filler,
   Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Card, Skeleton } from 'antd'
import { RangeValue } from '../CustomRangePicker'

ChartJS.register(
   TimeScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Filler,
   Legend
)

const options = {
   responsive: true,
   scales: {
      x: {
         type: 'time',
         time: {
            unit: 'day',
            displayFormats: {
               day: 'YYYY-MM-DD',
            },       
            tooltipFormat: 'MMMM, DD YYYY'   
         },
      },
      y: {
         beginAtZero: true,
      },
   },
   plugins: {
      legend: {
         position: 'top' as const,
      },
      title: {
         display: false,
         text: 'Chart.js Line Chart',
      },
   },
} as any

export interface AreaChartItem {
   x: string
   y: number
}
interface IAreaCharts {
   items: AreaChartItem[]
   title: string
   dates: RangeValue
   isLoading: boolean
}
export default function AreaCharts({
   items,
   title,
   isLoading,
   dates,
}: IAreaCharts) {
   const data = {
      datasets: [
         {
            fill: true,
            label: title,
            data: items,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
         },
      ],
   }

   return isLoading ? (
      <Card style={{ width: '100%', padding: '0px 24px 0px 24px' }}>
         <Skeleton.Input active={true} size="large" block={true} />
      </Card>
   ) : (
      <Card>
         <div>
            <Line options={options} data={data} />
         </div>
      </Card>
   )
}
