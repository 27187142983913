import { makeAutoObservable } from 'mobx'
import { ImpactAssessmentsApi } from './ImpactAssessmentsApi'
import { addClassToAllDrawerLi, isEmpty } from '../../utils/Helpers'
import moment from 'moment'
import { getNextWeekDate } from '../../utils/Helpers'
import { b64toBlob } from '../../utils/Helpers'
import { Form } from 'antd'
import { toast } from 'react-hot-toast'
import { RcFile } from 'antd/es/upload'
import { AssessmentAttachedFileTypes } from '../../types'
import { objectToFormData } from '../../helpers'

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus']

export interface Iaudit {
   id: number
   description: string
   date: string
   commentedBy: string
   riskLevel: string
   dueDate: string
   topic: string
   threads: Iaudit[]
}

export class ImpactAssessmentsStore {
   riskLevels: any[] = []
   ruleTypes: string[] = []
   status: any[] = []
   topics: any[] = []
   allocatorsAndAdmins: any[] = []
   assessors: any[] = []
   businessEntities: any[] = []
   groups: any[] = []
   departments: any[] = []
   impactAssessments: any[] = []
   isFiltered: boolean = false
   impactAssessmentsFiltered: any // TODO.
   searchValue: string = ''
   receiveEmails: boolean = false
   statusKey: string | null = null
   topicKeys: number[] = []
   riskKey: string | null = null
   resDueDate: string = ''
   reportAudit: any[] = []
   auditData: any = {}
   taxonomies: any = {}
   reportAssignedTo: string = ''
   reportRiskLevel: string = ''
   reportStatus: string = ''
   reportDueOn: string = ''
   reportInternalNo: string = ''
   reportTopic: string = ''
   selectedAssessmentId: string = ''
   selectedAssessmentIndex: number = -1
   selectedAssessment: any = {}
   isAssessmentModalVisible: boolean = false
   selectedAssesorKeys: any[] = []
   auditId: string = ''
   selectedRisk: string = ''
   selectedAssessmentStatus: string = ''
   selectedRiskLevel: string = ''
   selectedStatus: string = ''
   selectedRuleType: string = ''
   localeDateFormat: string = 'DD/MM/YYYY'
   today: any = moment().format('MM/DD/YYYY')
   preferencesResDueDate: moment.Moment = moment().locale(this.localeDateFormat)
   riskLevelValidator: boolean = false
   statusValidator: boolean = false

   usersByDepartment: any[] = []
   allocationDates: any[] = []
   assessmentDates: any[] = []
   responseDueDates: any[] = []
   selectedTopics: any[] = []
   selectedRiskLevels: any[] = []
   selectedAssessorUserIds: any[] = []
   selectedAssessorUserIdsForTree: any[] = []
   selectedAllocatorUserIds: any[] = []
   selectedStatusTypes: any[] = [] // TODO: Create an enum for assessment status.
   selectedGroups: any[] = []
   selectedBusinessEntities: any[] = []
   selectedDepartments: any[] = []
   selectedRuleTypes: string[] = []

   internalNumber: string = ''
   selectedAssessmentRuleId: any[] = []
   isLoading: boolean = false
   historyThreads: any[] = []
   content: string = ''
   selectedAssessmentRule: any = {}
   firstNLiFromAssessmentSummary: string = ''
   isTreeViewExist: boolean = false
   isAdmin: boolean = false
   isCommentOnly: boolean = false
   rowCount: number = 0
   hasLoggedUserAtleastAdmin: boolean = false
   isDeleteConfirmModalVisible: boolean = false

   isError: boolean = false
   errorMessage: string = ''
   errorType: any = 'error'

   editor: any = {}
   assessmentDetail: any = {}

   riskLevelPreference: string | null = null
   statusPreference: string | null = null
   hasUserOnlyAssessorRole: boolean = false

   selectedRowKeys: any[] = []
   commentForRemovedAssessment: string = ''

   appBaseUrl: string = ''

   attachedFiles: File[] | null = null
   filesToDisplay: AssessmentAttachedFileTypes | null = null

   setAttachedFiles = (files: File[]) => (this.attachedFiles = files)

   setSelectedRowKeys(selectedKeys: any[]) {
      this.selectedRowKeys = selectedKeys
   }

   fillInitialStore() {
      ImpactAssessmentsApi.getInitData()
         .then((result) => {
            this.riskLevels = result.riskLevels
            this.ruleTypes = result.ruleTypes
            this.status = result.assessmentStatus
            this.topics = result.topics
            this.allocatorsAndAdmins = result.users
            this.assessors = result.users
            this.businessEntities = result.businessEntities
            this.groups = result.groups
            this.departments = result.departments
            this.hasLoggedUserAtleastAdmin = result.hasUserAtLeastAdminRole
            this.hasUserOnlyAssessorRole = result.hasUserOnlyAssessorRole
            this.appBaseUrl = result.appBaseUrl // No need for now.
         })
         .catch((_) => {})
   }

   setSelectedAssessmentRuleId(ruleId: any[]) {
      this.selectedAssessmentRuleId = ruleId
   }
   selectedRuleTypeObj: string = ''
   setSelectedRuleType(val: string) {
      let index = this.ruleTypes.indexOf(val)
      this.selectedRuleTypeObj = index > -1 ? this.ruleTypes[index] : ''
      this.selectedRuleType = val
   }
   clearSelectedRuleType = () => {
      this.selectedRuleType = ''
   }

   setSelectedRuleTypes(val: string[]) {
      this.selectedRuleTypes = val
   }

   excelPreferenceId: any
   setExcelPreferenceId(val: any) {
      this.excelPreferenceId = val
      this.disableDownloadExcelPreference = false
   }
   clearExcelPreferenceId() {
      this.excelPreferenceId = ''
   }

   selectedRiskObj: any = {}
   setSelectedRiskLevel(val: string) {
      let index = this.riskLevels.findIndex((e) => e.key === val)
      this.selectedRiskObj = index > -1 ? this.riskLevels[index] : {}
      this.selectedRiskLevel = val
   }
   clearSelectedRiskLevel = () => {
      this.selectedRiskLevel = ''
   }

   selectedStatusObj: any = {}
   setSelectedStatus = (val: string) => {
      let index = this.status.findIndex((e) => e.key === val)
      this.selectedStatusObj = index > -1 ? this.status[index] : {}
      this.selectedStatus = val
   }
   clearSelectedStatus = () => {
      this.selectedStatus = ''
   }

   selectedTopicIds: any[] = []
   selectedTopicObjs: any[] = []
   setSelectedTopicIds = (val: any) => {
      this.selectedTopicIds = val

      this.selectedTopicObjs = this.topics.filter((e) => val.includes(e.key))
   }
   clearSelectedTopicIds = () => {
      this.selectedTopicIds = []
   }

   updateRow = (row: any) => {
      ImpactAssessmentsApi.updateRow(this.selectedAssessmentId, row)
         .then((result) => {
            // this.isModalLoading = false
            // this.errorCode = result.errorCode
            if (result.errorCode > 0) {
               // this.hasModalError = true
               // this.modalErrorMessage += result.errorCode
               return
            }
            this.setSelectedAssessmentId('')
         })
         .catch((err) => {
            // this.isModalLoading = false
            // this.hasModalError = true
         })
   }

   updateAssessmentInternalNo = (internalNo: any) => {
      ImpactAssessmentsApi.updateAssessmentInternalNo(
         this.selectedAssessmentId,
         internalNo
      )
         .then((result) => {
            // this.isModalLoading = false
            // this.errorCode = result.errorCode
            if (result.errorCode > 0) {
               // this.hasModalError = true
               // this.modalErrorMessage += result.errorCode
               return
            }
            this.setSelectedAssessmentId('')
         })
         .catch((err) => {
            // this.isModalLoading = false
            // this.hasModalError = true
         })
   }

   updateAssessmentRiskLevel = (riskLevel: any) => {
      ImpactAssessmentsApi.updateAssessmentRiskLevel(
         this.selectedAssessmentId,
         riskLevel
      )
         .then((result) => {
            // this.isModalLoading = false
            // this.errorCode = result.errorCode
            if (result.errorCode > 0) {
               // this.hasModalError = true
               // this.modalErrorMessage += result.errorCode
               return
            }
            this.setSelectedAssessmentId('')
         })
         .catch((err) => {
            // this.isModalLoading = false
            // this.hasModalError = true
         })
   }

   updateAssessmentStatus = (status: any) => {
      ImpactAssessmentsApi.updateAssessmentStatus(
         this.selectedAssessmentId,
         status
      )
         .then((result) => {
            // this.isModalLoading = false
            // this.errorCode = result.errorCode
            if (result.errorCode > 0) {
               // this.hasModalError = true
               // this.modalErrorMessage += result.errorCode
               return
            }
            this.setSelectedAssessmentId('')
         })
         .catch((err) => {
            // this.isModalLoading = false
            // this.hasModalError = true
         })
   }

   updateAssessmentTopics = (topicIds: any) => {
      ImpactAssessmentsApi.updateAssessmentTopics(
         this.selectedAssessmentId,
         topicIds
      )
         .then((result) => {
            // this.isModalLoading = false
            // this.errorCode = result.errorCode
            if (result.errorCode > 0) {
               // this.hasModalError = true
               // this.modalErrorMessage += result.errorCode
               return
            }
            this.setSelectedAssessmentId('')
         })
         .catch((err) => {
            // this.isModalLoading = false
            // this.hasModalError = true
         })
   }

   preferencesRiskKey: string | null = null
   preferencesStatusKey: string | null = null
   getDefaultPreferences(dates: {allocationStartDate: any, allocationEndDate: any} = {allocationStartDate: [], allocationEndDate: []}) {
      ImpactAssessmentsApi.getPreferences(
         '/settings/GetPreferences/' + localStorage.getItem('userId')
      )
         .then((result) => {
            this.riskLevelPreference =
               result.preferences.riskLevel === 0
                  ? ''
                  : result.preferences.riskLevel
            this.statusPreference =
               result.preferences.assessmentStatus === 0
                  ? ''
                  : result.preferences.assessmentStatus

            this.preferencesRiskKey = this.riskLevelPreference
            this.preferencesStatusKey = this.statusPreference

            // TODO : defaultAssessmentStatuses should be stored as null on Savepreferences
            this.selectedStatusTypes =
               result.preferences.defaultAssessmentStatuses?.length === 0
                  ? []
                  : result.preferences.defaultAssessmentStatuses

            this.assessmentDetail.riskLevelId = this.riskLevelPreference
            this.assessmentDetail.statusId = this.statusPreference

            this.preferencesResDueDate = moment(
               getNextWeekDate(result.preferences.responseDueDateNumberOfWeek)
            ).locale(this.localeDateFormat)

            this.localeDateFormat =
               result.preferences.languagePreference === 'en-US'
                  ? 'MM/DD/YYYY'
                  : 'DD/MM/YYYY'

            this.getAssessments(dates)
         })
         .catch((err) => {})
   }

   getRuleDetails(ruleId: number) {
      this.isLoading = true
      ImpactAssessmentsApi.getRuleDetails(ruleId)
         .then((result) => {
            this.selectedAssessmentRule = result.mainRule
            this.selectedAssessmentRule.summary = addClassToAllDrawerLi(
               result.mainRule.summary
            )
            // this.getFirstNLiFromAssessmentSummary(3)
            // this.isLoading = false
         })
         .catch((err) => {
            // this.isLoading = false
         })
   }

   getAssessments(dates: {allocationStartDate: any, allocationEndDate: any} = {allocationStartDate: [], allocationEndDate: []}) {
      this.isLoading = true
      this.isError = false

      const defaultParams = this.assessmentfilterParams();
      ImpactAssessmentsApi.filterAssessments(defaultParams.allocationStartDate.length && defaultParams.allocationEndDate.length ? defaultParams : {...defaultParams, ...dates})
         .then((result) => {
            this.isLoading = false
            this.rowCount = 0

            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.'
               )
            else if (result.errorCode === 0) {
               this.impactAssessments = result.assessments
               this.rowCount = result.assessments.length
            } else {
               this.showErrorMessage(
                  'Get in touch with Administrator: ' +
                     result.additionalMessage +
                     ' Code: ' +
                     result.errorCode
               )
            }
         })
         .catch((err) => {
            this.isLoading = false
            this.showErrorMessage(err.additionalMessage)
         })
   }

   showErrorMessage(message: string, id: string = 'error') {
      toast.error(message, { id })
   }
   setSelectedAssessmentId(id: string) {
      this.selectedAssessmentId = id
      this.selectedAssessment = this.impactAssessments?.find(
         (x) => x.assessmentId === id
      )
   }

   setIsAssessmentModalVisible(isVisible: boolean) {
      this.isAssessmentModalVisible = isVisible
   }

   setAssessorsKey(keys: any) {
      this.selectedAssesorKeys = keys
   }

   initializeAssessorTree(
      selectedUserIds: any,
      selectedDepartmentIds: number[]
   ) {
      this.assignedDepartmentIds = selectedDepartmentIds
      this.findSelectedAssessorKeysByIds(selectedUserIds, selectedDepartmentIds)
   }

   hasModalError: boolean = false
   errorCode: number = 0
   isModalLoading: boolean = false
   modalErrorMessage: string =
      'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'

   sendAssessment(action?: () => void, selected?: any[]) {
      this.isModalLoading = true
      this.hasModalError = false

      toast.promise(
         ImpactAssessmentsApi.sendAssessment(this.newAssessmentJson(selected)),
         {
            loading: 'Sending...',
            success: (result: any) => {
               this.isModalLoading = false
               if (result.erroCode > 0) {
                  this.showErrorMessage(result.additionalMessage)
               }
               this.getAssessments()

               this.setIsAssessmentModalVisible(false)
               this.setSelectedAssessmentId('')

               if (action) action()

               return 'Assessment sent successfully.'
            },
            error: () => {
               this.isModalLoading = false
               return 'An error occurred, get in touch with an Administrator.'
            },
         },
         {
            style: {
               minWidth: '250px',
            },
            position: 'bottom-center',
            success: {
               duration: 5000,
            },
         }
      )
   }

   sendEmailComment: string = ''
   isBulkEmailModalVisible: boolean = false
   emailCommentValidator: boolean = false
   setIsBulkEmailModalVisible(val: boolean) {
      this.isBulkEmailModalVisible = val
   }

   onSendEmailComment(val: string) {
      this.sendEmailComment = val
   }
   setEmailCommentValidator(val: boolean) {
      this.emailCommentValidator = val
   }

   sendBulkEmail = () => {
      toast.promise(
         ImpactAssessmentsApi.sendBulkEmail(
            this.selectedRowKeys,
            this.sendEmailComment
         ),
         {
            loading: 'Sending...',
            success: () => {
               this.isModalLoading = false
               return 'Email sent successfully.'
            },
            error: () => {
               return 'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
            },
         },
         {
            style: {
               minWidth: '250px',
            },
            position: 'bottom-center',
            success: {
               duration: 5000,
            },
         }
      )
   }

   newsletterName: string = ''
   newsletterNameError: string = ''
   newsletterNameValidateStatus: ValidateStatus = ''
   isNewsletterNameModalVisible: boolean = false
   newsletterNameValidator: boolean = false
   setIsNewsletterNameModalVisible(val: boolean) {
      this.isNewsletterNameModalVisible = val
   }

   onLoadingValidateNewsLetter() {
      this.newsletterNameValidateStatus = 'validating'
      this.newsletterNameError =
         "Please wait we're validating the newsletter's name"
   }

   onCreateNewsletter(val: string) {
      this.newsletterName = val
      //TODO: Validate name here
      if (isEmpty(val)) {
         this.newsletterNameValidateStatus = 'error'
         this.newsletterNameError = "The newsletter's name is required"
         return
      }

      ImpactAssessmentsApi.validateNewsletterName(this.newsletterName)
         .then((result: any) => {
            if (result && result.errorCode === 0) {
               this.newsletterNameValidateStatus = 'success'
               this.newsletterNameError = "The newsletter's name is valid"
            } else {
               this.newsletterNameValidateStatus = 'error'
               this.newsletterNameError = result.additionalMessage
            }
         })
         .catch((err) => {
            this.newsletterNameValidateStatus = 'error'
            this.newsletterNameError =
               "An error occurred when validating newsletter's name"
         })
   }
   setNewsletterNameValidator(val: boolean) {
      this.newsletterNameValidator = val
      if (val) {
         this.newsletterNameError = "The newsletter's name is required"
         this.newsletterNameValidateStatus = 'error'
         return
      } else {
         this.newsletterNameError = ''
         this.newsletterNameValidateStatus = ''
      }
   }

   createNewsletter = () => {
      var toastId = toast.loading('Creating newsletter...')
      ImpactAssessmentsApi.createNewsletter(
         this.selectedRowKeys,
         this.newsletterName
      )
         .then((result: any) => {
            this.isModalLoading = false

            if (result.errorCode > 0) {
               toast.error(
                  'There was an error creating your newsletter. Check the details and try again.',
                  { id: toastId }
               )
               return
            } else
               toast.success('Your newsletter has been created successfully.', {
                  id: toastId,
                  duration: 5000,
                  style: { minWidth: '250px' },
               })
         })
         .catch((err) => {
            this.isModalLoading = false
            toast.error(
               'There was an error creating your newsletter. Check the details and try again.',
               { id: toastId }
            )
         })
   }

   updateInternalNumber() {
      ImpactAssessmentsApi.updateInternalNumber(
         this.selectedAssessmentId,
         this.internalNumber
      )
         .then((result) => {
            // this.isModalLoading = false
            // this.errorCode = result.errorCode
            if (result.errorCode > 0) {
               // this.hasModalError = true
               // this.modalErrorMessage += result.errorCode
               return
            }
            this.setSelectedAssessmentId('')
         })
         .catch((err) => {
            // this.isModalLoading = false
            // this.hasModalError = true
         })
   }

   updateAssessment(
      filesToAdd: RcFile[],
      filesToRemove: number[],
      action?: () => void
   ) {
      this.isModalLoading = true
      this.hasModalError = false
      const data = {
         ...this.updatedAssessmentJson(),
         filesToAdd,
         filesToRemove,
      }
      ImpactAssessmentsApi.updateAssessment(data)
         .then((result) => {
            this.isModalLoading = false
            this.errorCode = result.errorCode
            // this.clearSearchBox()
            if (result.errorCode > 0) {
               /* this.hasModalError = true
               this.modalErrorMessage += result.errorCode */
               this.showErrorMessage(result.additionalMessage)
               return
            }

            this.getAssessments()
            this.setIsAssessmentModalVisible(false)
            this.setSelectedAssessmentId('')
            if (action) action()
         })
         .catch((err) => {
            this.isModalLoading = false
            /* this.hasModalError = true */
            toast.error(
               'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.',
               { id: 'error' }
            )
         })
   }

   updatedAssessmentJson(): any {
      return {
         assessmentId: this.selectedAssessmentId,
         InternalNumber: this.internalNumber,
         allocatedToUser: this.findSelectedAssessorIdsByKeys(),
         topicIds: this.topicKeys,
         assessmentStatusId: this.statusKey,
         riskLevelId: this.riskKey,
         dateResponseDue: (this.resDueDate as any).toDate().toISOString(),
         comment: this.comment,
         assignedDepartmentIds: this.assignedDepartmentIds.filter(
            (v, i, a) => a.indexOf(v) === i
         ),
      }
   }

   comment: string = ''
   setComment(val: string) {
      this.comment = val
   }

   setInternalNumber(val: any) {
      this.internalNumber = val
   }

   filterInternalNumber: string = ''
   setFilterInternalNumber(val: string) {
      this.filterInternalNumber = val
   }

   clearSelectedAssesorKeysAndIds() {
      this.selectedAssessorUserIdsForTree = []
      this.selectedAssesorKeys = []
   }
   
   clearAssessmentModalState() {
      this.setAssessorsKey([])
      this.setTopicKeys([])
      this.setRiskKey(this.preferencesRiskKey)
      this.setStatusKey(this.preferencesStatusKey)
      this.assignedDepartmentIds = []
      this.setComment('')
      this.setResDueDate(this.preferencesResDueDate)
      this.clearSelectedAssesorKeysAndIds()
      this.selectedAssessmentRuleId = []
      this.selectedAuditCoreIds = []
   }

   findSelectedAssessorKeysByIds(
      selectedUserIds: any,
      selectedDepartmentId: number[]
   ) {
      this.selectedAssesorKeys = []
      this.usersByDepartment.forEach((element: any) => {
         element.children?.forEach((element: any) => {
            if (
               selectedUserIds.includes(element.userId) &&
               selectedDepartmentId.includes(element.departmentId)
            )
               this.selectedAssesorKeys.push(element.key)
         })
      })
   }

   findSelectedAssessorIdsByKeys() {
      this.selectedAssessorUserIdsForTree = []
      this.usersByDepartment?.forEach((element: any) => {
         element.children?.forEach((element: any) => {
            if (this.selectedAssesorKeys.includes(element.key))
               this.selectedAssessorUserIdsForTree.push(element.userId)
         })
      })
      // let removedDuplicates = [...new Set(this.selectedAssessorUserIds)];
      let removedDuplicates = Array.from(
         new Set(this.selectedAssessorUserIdsForTree)
      )
      var userDto = removedDuplicates.map((e: any) => {
         return { id: e }
      })
      return userDto
   }

   getTopicIndex(description: string) {
      let newArr = [...this.topics]
      let index = newArr.findIndex((e) => e.description === description)
      return index
   }

   deleteAssessment() {
      ImpactAssessmentsApi.deleteAssessment(this.removeAssessmentsJson())
         .then((result: any) => {
            const newArray = [...this.impactAssessments]

            const index = newArray.findIndex(
               (e) => e.assessmentId === this.selectedAssessmentId
            )
            newArray.splice(index, 1)
            this.impactAssessments = newArray
         })
         .catch((err) => {})
   }

   removeAssessmentsJson() {
      return {
         assessmentId: this.selectedAssessmentId,
         comment: this.commentForRemovedAssessment,
      }
   }

   setCommentForRemovedAssessment = (value: string) => {
      this.commentForRemovedAssessment = value
   }

   setIsDeleteConfirmModalVisible = (params: any) => {
      this.isDeleteConfirmModalVisible = params
   }

   supervisorUserIds: number[] = []
   supervisors: any[] = []
   selectedSupervisorRowKeys: number[] = []
   hasNoSupervisorErrorVisible: boolean = false

   setSelectedSupervisorRowKeys(userIds: any) {
      this.selectedSupervisorRowKeys = userIds
   }

   isSignOffLoading: boolean = false
   getSupervisors(userIds: number[]) {
      this.isSignOffLoading = true
      ImpactAssessmentsApi.getSupervisors(userIds)
         .then((result: any) => {
            this.isSignOffLoading = false
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.'
               )
            else if (result.errorCode === 0) {
               this.supervisors = result.users
               this.selectedSupervisorRowKeys = result.users.map(
                  (e: any) => e.id
               )
               this.hasNoSupervisorErrorVisible = result.users.length === 0
            } else {
               this.showErrorMessage(
                  'Get in touch with Administrator: ' +
                     result.additionalMessage +
                     ' Code: ' +
                     result.errorCode
               )
            }
         })
         .catch((err) => {
            this.isSignOffLoading = false
         })
   }

   sendRequestSignOff = () => {
      //TODO : Integrate email api.
      // ImpactAssessmentsApi.sendRequestSignOffEmail(
      //    this.selectedSupervisorRowKeys
      // )
      //    .then((result: any) => {
      //
      //    })
      //    .catch((err) => {
      //       this.isLoading = false
      //    })
   }

   setSearchValue(value: string) {
      this.searchValue = value
   }

   setImpactAssessmentsFiltered(filteredAssessments: any) {
      this.impactAssessmentsFiltered = filteredAssessments
   }

   setImpactAssessments(assessments: any) {
      this.impactAssessments = assessments
   }

   clearSearchBox() {
      this.searchValue = ''
      this.isFiltered = false
   }

   onReceiveEmails(checked: any) {
      this.receiveEmails = checked
   }
   setStatusKey(key: string | null) {
      this.statusKey = key
   }
   setRiskKey(key: string | null) {
      this.riskKey = key
   }
   setTopicKeys(key: number[]) {
      this.topicKeys = key
   }
   setResDueDate(date: any) {
      this.resDueDate = date
   }

   setSelectedAssessmenDetailAssessmentId(assessmentId: number) {
      this.assessmentDetail.assessmentId = assessmentId
   }

   setSelectedAssessmentDetailId(assessmentDetailId: number) {
      this.assessmentDetail.assessmentDetailId = assessmentDetailId
   }

   onEditorChange(content: string) {
      this.assessmentDetail.content = content
         .replace('<p>\n\n</p>', '')
         .replace('<p></p>', '')
         .replaceAll('<p>&nbsp;</p>', '')      
   }
   setAssessmentDetailContent(content: string) {
      this.assessmentDetail.content = content
   }
   setAssessmentDetailDueDate(date: any) {
      this.assessmentDetail.dueDate = date?.format('YYYY-MM-DD')
   }

   requestForSignOffModalVisible: boolean = false
   setRequestForSignOffModalVisible = (val: boolean) => {
      this.requestForSignOffModalVisible = val
   }

   setAssessmentDetailRiskLevelId(riskLevelId: any) {
      this.assessmentDetail.riskLevelId = riskLevelId
   }

   setRiskLevelValidator(val: boolean) {
      this.riskLevelValidator = val
   }

   setStatusValidator(val: boolean) {
      this.statusValidator = val
   }

   setAssessmentDetailTopicId(topicId: any) {
      this.assessmentDetail.topicId = topicId
   }

   setAssessmentDetailStatusId(status: any) {
      this.assessmentDetail.statusId = status
   }

   setAssessmentAttachedFiles(files: any) {
      this.assessmentDetail.attachedFiles = files
   }

   setIsError(val: boolean) {
      this.isError = val
   }

   setIsSuccessfullyAdded(val: boolean) {
      this.isSuccessfullyAdded = val
   }

   setIsCommentOnly(val: boolean) {
      this.isCommentOnly = val
      this.assessmentDetail.isCommentOnly = val
      this.assessmentDetail.dueDate = val
         ? '1900-01-01'
         : this.assessmentDetail.dueDate
   }

   assignedDepartmentIds: number[] = []
   addToAssignedDepartmentIds(id: number) {
      this.assignedDepartmentIds.push(id)
   }
   removeAssignedDepartmentId(id: number) {
      this.assignedDepartmentIds = this.assignedDepartmentIds.filter(
         (e) => e !== id
      )
   }

   isSuccessfullyAdded: boolean = false
   AddNewAssessmentDetail(
      attachedFiles: any[] = [],
      then: () => void = () => null
   ) {
      this.isLoading = true
      this.isSuccessfullyAdded = false

      const formData = objectToFormData({ ...this.assessmentDetail })
      if (attachedFiles.length)
         for (const file of attachedFiles) {
            formData.append('attachedFiles', file)
         }

      toast
         .promise(
            ImpactAssessmentsApi.addNewAssessmentDetail(formData),
            {
               loading: 'Adding New Assessment Details',
               success: (result) => {
                  if (result === null || result === undefined) {
                     this.isLoading = false
                     return 'No result, Get in touch with Administrator.'
                  } else if (result.errorCode === 0) {
                     this.getAssessmentDetails(
                        this.assessmentDetail.assessmentId
                     )
                     this.clearAssessmentObj()
                     this.isSuccessfullyAdded = true
                     return 'Your impact assesment details has been added successfully!'
                  } else {
                     this.isLoading = false
                     return (
                        'Get in touch with Administrator: ' +
                        result.additionalMessage +
                        ' Code: ' +
                        result.errorCode
                     )
                  }
               },
               error: () => {
                  return 'An error occurred when adding new assessment details'
               },
            },
            {}
         )
         .finally(() => then())
   }

   updateAssessmentDetail(
      attachedFiles: any[] = [],
      then: () => void = () => null
   ) {
      this.isSuccessfullyAdded = false
      this.isLoading = true
      const formData = objectToFormData({ ...this.assessmentDetail })
      if (attachedFiles.length)
         for (const file of attachedFiles) {
            formData.append('attachedFiles', file)
         }
      ImpactAssessmentsApi.updateAssessmentDetail(formData)
         .then((result: any) => {
            if (result === null || result === undefined) {
               this.isLoading = false
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.'
               )
            } else if (result.errorCode === 0) {
               this.getAssessmentDetails(this.assessmentDetail.assessmentId)
               this.clearAssessmentObj()
               this.isSuccessfullyAdded = true
            } else {
               this.isLoading = false
               this.showErrorMessage(
                  'Get in touch with Administrator: ' +
                     result.additionalMessage +
                     ' Code: ' +
                     result.errorCode
               )
            }
         })
         .catch((err) => {})
         .finally(() => then())
   }

   setHistoryThreads(history: Iaudit[]) {
      this.historyThreads = history
   }

   fetchSelectedReport(id: string) {
      const index = this.impactAssessments.findIndex(
         (e) => e.assessmentId === id
      )
      if (index < 0) return

      const selectedAssessment = this.impactAssessments[index]

      this.reportAssignedTo =
         selectedAssessment.allocatedToUserNames === ''
            ? 'N/A'
            : selectedAssessment.allocatedToUserNames
      this.reportRiskLevel =
         selectedAssessment.riskLevel == null
            ? 'N/A'
            : selectedAssessment.riskLevel.description
      this.reportStatus =
         selectedAssessment.status == null
            ? 'N/A'
            : selectedAssessment.status.value
      this.reportDueOn = selectedAssessment.dateResponseDue
      this.reportInternalNo =
         selectedAssessment.internalNumber == null
            ? 'N/A'
            : selectedAssessment.internalNumber
      this.reportTopic =
         selectedAssessment.topic == null
            ? 'N/A'
            : selectedAssessment.topic.description
   }

   getAssessmentDetails(id: string) {
      this.isLoading = true
      this.reportAudit = []
      ImpactAssessmentsApi.getAssessmentDetails(id, this.auditId)
         .then((result: any) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.'
               )
            else if (result.errorCode === 0) {
               this.reportAudit = result.assessmentDetails
               this.auditData = result.auditData
               this.auditData.summary = addClassToAllDrawerLi(
                  result.auditData?.summary,
                  true
               )
               this.taxonomies = result.taxonomies
               this.filesToDisplay = result.assessmentAttachedFile
            } else {
               this.showErrorMessage(
                  'Get in touch with Administrator: ' +
                     result.additionalMessage +
                     ' Code: ' +
                     result.errorCode
               )
            }

            this.isLoading = false
         })
         .catch((err) => {
            this.isLoading = false
         })
   }

   setAuditId(auditId: string) {
      this.auditId = auditId
   }

   selectedAuditCoreIds: string[] = []
   setSelectedAuditCoreIds(AuditIds: string[]) {
      this.selectedAuditCoreIds = AuditIds
   }

   newAssessmentJson(selected?: any[]): any {
      return {
         ruleIds: [], // Create a relation between mainruleid and assessmentDetail with mainruleid.
         mainRuleAuditCoreIds: [],
         status: this.statusKey,
         riskLevel: this.riskKey,
         topic: this.topicKeys?.length === 0 ? null : this.topicKeys,
         responseDueDate: (this.resDueDate as any).toDate().toISOString(),
         comment: this.comment,
         receiveEmails: this.receiveEmails,
         assignedUserIds: this.findSelectedAssessorIdsByKeys(),
         assignedDepartmentIds: this.assignedDepartmentIds.filter(
            (v, i, a) => a.indexOf(v) === i
         ),
         attachedFiles: this.attachedFiles,
         selected: selected?.map((x) => ({
            mainRuleId: x.mainRuleId,
            mainRuleAuditCoreId: x.mainRuleAuditCoreId ?? null,
         })),
      }
   }

   clearAssessmentObj() {
      this.assessmentDetail.content = ''
      this.assessmentDetail.assessmentDetailId = 0
      this.assessmentDetail.dueDate = this.preferencesResDueDate // '1900-01-01'
      this.assessmentDetail.riskLevelId = this.riskLevelPreference
      this.assessmentDetail.statusId = this.statusPreference
      this.assessmentDetail.topicId = null
      this.assessmentDetail.isCommentOnly = false
   }

   clearStateObj() {
      this.riskLevels = []
      this.topics = []
      this.status = []
   }

   setSelectedAssessmentIndex(index: number) {
      this.selectedAssessmentIndex = index
   }

   getUsersByDepartment() {
      ImpactAssessmentsApi.getUsersByDepartment()
         .then((result: any) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.'
               )
            else if (result.errorCode === 0) {
               this.usersByDepartment = result.usersByDepartment
               this.isTreeViewExist = result.isTreeViewExist
            } else {
               this.showErrorMessage(
                  'Get in touch with Administrator: ' +
                     result.additionalMessage +
                     ' Code: ' +
                     result.errorCode
               )
            }
         })
         .catch((err) => {})
   }

   isSearchParamsValid: boolean = true

   setIsSearchParamsValid(val: boolean) {
      this.isSearchParamsValid = val
   }
   filterAssessments() {
      if (this.isSearchParamsValid) {
         this.isLoading = true
         this.rowCount = 0
         ImpactAssessmentsApi.filterAssessments(this.assessmentfilterParams())
            .then((result) => {
               this.isLoading = false
               //this.clearSearchBox()
               if (result === null || result === undefined)
                  this.showErrorMessage(
                     'No result, Get in touch with Administrator.'
                  )
               else if (result.errorCode === 0) {
                  this.isError = false
                  this.impactAssessments = result.assessments
                  this.rowCount = result.assessments.length
               } else {
                  this.showErrorMessage(
                     'Get in touch with Administrator: ' +
                        result.additionalMessage +
                        ' Code: ' +
                        result.errorCode
                  )
               }
            })
            .catch((err) => {
               this.isLoading = false
            })
      }
   }

   downloadAssessments() {
      this.selectedRowKeys.length > 0 &&
         ImpactAssessmentsApi.downloadAssessments(this.selectedRowKeys)
            .then((response: any) => {
               if (response === null || response === undefined)
                  this.showErrorMessage(
                     'No result, Get in touch with Administrator.'
                  )
               else if (response.errorCode === 0) {
                  const blob = b64toBlob(
                     response.content,
                     'application/octet-stream'
                  )
                  const blobUrl = URL.createObjectURL(blob)
                  var a = window.document.createElement('a')

                  a.href = blobUrl
                  a.download = response.fileName

                  // Append anchor to body.
                  document.body.appendChild(a)
                  a.click()

                  // Remove anchor from body
                  document.body.removeChild(a)

                  return response
               } else {
                  this.showErrorMessage(
                     'Get in touch with Administrator: ' +
                        response.additionalMessage +
                        ' Code: ' +
                        response.errorCode
                  )
               }
            })
            .catch((err) => {})
   }

   downloadAssessmentsByPreferenceId() {
      const toastId = toast.loading('Downloading...')
      ImpactAssessmentsApi.downloadAssessmentsByPreferenceId(
         this.selectedRowKeys,
         this.excelPreferenceId
      )
         .then((response: any) => {
            if (response === null || response === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.',
                  toastId
               )
            else if (response.errorCode === 0) {
               const blob = b64toBlob(
                  response.content,
                  'application/octet-stream'
               )
               const blobUrl = URL.createObjectURL(blob)
               var a = window.document.createElement('a')

               a.href = blobUrl
               a.download = response.fileName

               // Append anchor to body.
               document.body.appendChild(a)
               a.click()

               // Remove anchor from body
               document.body.removeChild(a)

               toast.success('Downloaded successfully!', { id: toastId })
               return response
            } else {
               this.showErrorMessage(
                  'Get in touch with Administrator: ' +
                     response.additionalMessage +
                     ' Code: ' +
                     response.errorCode,
                  toastId
               )
            }
         })
         .catch((err) => {
            console.error(err, '__err__')
            this.showErrorMessage(err.message, toastId)
         })
   }

   setAllocationDates(val: any) {
      this.allocationDates = val
   }
   setAssessmentDates(val: any) {
      this.assessmentDates = val
   }
   setResponseDueDates(val: any) {
      this.responseDueDates = val
   }
   setSelectedRiskLevels(val: any) {
      this.selectedRiskLevels = val
   }
   setSelectedTopics(val: any) {
      this.selectedTopics = val
   }
   setSelectedAssessors(val: any) {
      this.selectedAssessorUserIds = val
   }
   setSelectedAllocatorUserIds(val: any) {
      this.selectedAllocatorUserIds = val
   }
   setInternalNo(val: any) {
      this.internalNumber = val
   }
   setSelectedStatusTypes(val: any) {
      this.selectedStatusTypes = val
   }
   setSelectedGroups(val: any) {
      this.selectedGroups = val
   }

   setSelectedDepartments(val: any) {
      this.selectedDepartments = val
   }

   setSelectedBusinessEntities(val: any) {
      this.selectedBusinessEntities = val
   }

   assessmentfilterParams() {
      return {
         allocationStartDate:
            this.allocationDates !== null && this.allocationDates.length > 0
               ? this.allocationDates[0]?.format('MM/DD/YYYY') //_d.toLocaleDateString()
               : [],
         allocationEndDate:
            this.allocationDates !== null && this.allocationDates.length > 1
               ? this.allocationDates[1]?.format('MM/DD/YYYY') //_d.toLocaleDateString()
               : [],
         updateStartDate:
            this.assessmentDates !== null && this.assessmentDates.length > 0
               ? this.assessmentDates[0]?.format('MM/DD/YYYY') //_d.toLocaleDateString()
               : [],
         updateEndDate:
            this.assessmentDates !== null && this.assessmentDates.length > 1
               ? this.assessmentDates[1]?.format('MM/DD/YYYY') //._d.toLocaleDateString()
               : [],
         responseDueStartDate:
            this.responseDueDates !== null && this.responseDueDates.length > 0
               ? this.responseDueDates[0]?.format('MM/DD/YYYY') //._d.toLocaleDateString()
               : [],
         responseDueEndDate:
            this.responseDueDates !== null && this.responseDueDates.length > 1
               ? this.responseDueDates[1]?.format('MM/DD/YYYY') //._d.toLocaleDateString()
               : [],
         riskLevels: this.selectedRiskLevels,
         topics: this.selectedTopics,
         assessorUserIds: this.selectedAssessorUserIds,
         allocatorUserIds: this.selectedAllocatorUserIds,
         statusTypes: this.selectedStatusTypes,
         internalNumber: this.filterInternalNumber,
         groups: this.selectedGroups,
         businessEntities: this.selectedBusinessEntities,
         departments: this.selectedDepartments,
         ruleTypes: this.selectedRuleTypes,
      }
   }

   userExcelPreferences: any[] = []
   disableDownloadExcelPreference: boolean = true
   excelPreferenceClickDisabled = () => {
      if (
         this.userExcelPreferences.length > 0 &&
         !isEmpty(this.excelPreferenceId)
      ) {
         this.disableDownloadExcelPreference = false
      } else {
         this.disableDownloadExcelPreference = true
      }
   }
   getUserExcelPreferences() {
      ImpactAssessmentsApi.getUserExcelPreferences(
         '/settings/GetSelectedColumnKeysMultiple/'
      )
         .then((result) => {
            if (result === null || result === undefined) {
               this.showErrorMessage(
                  'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.'
               )
            } else {
               // this.targetKeys = result

               this.userExcelPreferences = result
               return result
            }
         })
         .catch((err) => {
            this.showErrorMessage(
               'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. Code: ' +
                  err
            )
         })
   }

   clearIAState() {
      this.selectedStatusTypes = []
      this.allocationDates = []
      this.assessmentDates = []
      this.responseDueDates = []
   }

   constructor() {
      makeAutoObservable(this)
   }
}
