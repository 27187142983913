import { makeAutoObservable } from 'mobx'
import { Item, IUser } from '../../types/Types'
import { CompanyUsersApi } from './CompanyUsersApi'
import { toast } from 'react-hot-toast'
import { alertMessages } from '../../utils/Helpers'

// import { RiskLevelApi } from './RiskLevelApi'

export class CompanyUsersStore {
   roles: any[] = []
   departments: any[] = []
   permissions: any[] = []
   companyUsers: any[] = []
   pagedCompanyUsers: any[] = []
   selectedUser: IUser = {
      id: 0,
      email: '',
      name: '',
      surname: '',
      departments: [],
      roles: [],
   }
   selectedUserDepartments: any[] = []
   selectedUserRoles: any[] = []
   selectedDepartmentValues: any[] = []
   selectedUserPermissions: any[] = []
   isAdmin: boolean = false
   searchValue: string = ''
   isFiltered: boolean = false
   isLoading: boolean = false
   redirectToResult: boolean = false
   resulErrorMessageVisible: boolean = false
   isLoggedUserSuperAdminOrAdmin: boolean = false
   alertMessage: string = ''

   updatePwMessage: string = ''
   isError: boolean = false
   errorMessage: string = ''
   errorType: any = 'error'
   currentPw: string = ''
   newPw: string = ''
   orgChart: any
   companyUserSearchboxOnChange(search: any) {
      const targetValue = search?.target?.value

      if (targetValue === '' || targetValue === undefined) {
         this.isFiltered = false
      } else {
         const lowerCaseInput = targetValue.toLowerCase()
         this.setFilteredCompanyUsers(
            this.companyUsers?.filter(
               (e: any) =>
                  e.name.toLowerCase().match(lowerCaseInput) ||
                  e.surname.toLowerCase().match(lowerCaseInput) ||
                  e.email.toLowerCase().match(lowerCaseInput) ||
                  e.licenceType.toLowerCase().match(lowerCaseInput) ||
                  e.startDate.toLowerCase().match(lowerCaseInput) ||
                  e.endDate.toLowerCase().match(lowerCaseInput) ||
                  e.departments.findIndex((e: any) =>
                     e.name.toLowerCase().match(lowerCaseInput)
                  ) >= 0 ||
                  e.roles.findIndex((e: any) =>
                     e.roleName.toLowerCase().match(lowerCaseInput)
                  ) >= 0

               // e.surname.match(targetValue) ||
            )
         )
         this.isFiltered = true
      }

      this.searchValue = targetValue
   }

   isEmailEmpty: boolean = false
   isNameEmpty: boolean = false
   isSurameEmpty: boolean = false
   isSelectedRolesEmpty: boolean = false

   setIsEmailEmpty(val: boolean) {
      this.isEmailEmpty = val
   }
   setIsNameEmpty(val: boolean) {
      this.isNameEmpty = val
   }
   setIsSurnameEmpty(val: boolean) {
      this.isSurameEmpty = val
   }
   setIsSelectedRolesEmpty(val: boolean) {
      this.isSelectedRolesEmpty = val
   }

   setFilteredCompanyUsers(filtered: any) {
      this.pagedCompanyUsers = filtered
   }

   getCompanyUsers(isActive = true) {
      this.isLoading = true
      CompanyUsersApi.getCompanyUsers(isActive)
         .then((result: any) => {
            this.isLoading = false
            if (result.errorCode === 0) {
               this.companyUsers = result.users
               this.isLoggedUserSuperAdminOrAdmin =
                  result.isLoggedUserSuperAdminOrAdmin
            } else {
               this.showErrorMessage(result.additionalMessage)
            }
         })
         .catch((err) => {
            this.resulErrorMessageVisible = true
            this.showErrorMessage()
            this.isLoading = false
         })
   }

   Initialize(id: number) {
      this.isSelectedRolesEmpty = false
      this.getUser(id)
   }

   getUser(id: number) {
      this.isLoading = true

      CompanyUsersApi.getUser(id)
         .then((result: any) => {
            this.isLoading = false

            if (result.errorCode === 0) {
               this.selectedUser = {
                  ...result.user,
                  departments: result.user.departments.map((d: any) => {
                     return { value: d.key, label: d.name }
                  }),
               }
               this.selectedUserRoles = result.user.roles
               this.selectedUserDepartments = result.user.departments
               this.getDepartments()
               this.getRoles()
               this.getUserDepartments()
               this.isUserSuperAdmin()
            } else {
               this.showErrorMessage(result.additionalMessage)
            }
         })
         .catch((err) => {
            this.isLoading = false

            this.showErrorMessage()
         })
   }

   getRoles() {
      CompanyUsersApi.getRoles()
         .then((result: any) => {
            if (result.errorCode === 0) {
               this.roles = result.roles.filter(
                  (e: any) => e.roleName !== 'SuperAdmin'
               )
            } else {
               this.showErrorMessage(result.additionalMessage)
            }
         })
         .catch((err) => {
            this.showErrorMessage()
         })
   }

   getUserDepartments() {
      this.selectedDepartmentValues = this.selectedUserDepartments?.map(
         (e: any) => {
            return e.name
         }
      )
   }

   onUserRoleChange(checkbox: any, item: any) {
      let userRoles = [...this.selectedUser?.roles]
      if (checkbox.target.checked) {
         const selectedRole = this.roles.find(
            (e) => e.roleName === checkbox.target.value
         )
         this.selectedUser.roles = [...userRoles, selectedRole]
      } else {
         const removedSelectedRoles = userRoles.filter(
            (e) => e.roleName !== checkbox.target.value
         )
         this.selectedUser.roles = removedSelectedRoles
      }
   }

   hasUserRole(id: any) {
      if (
         this.selectedUserRoles === null ||
         this.selectedUserRoles === undefined ||
         this.selectedUserRoles.length === 0
      )
         return false
      return this.selectedUserRoles.findIndex((e: any) => e.id === id) !== -1
   }

   setSelectedUser(id: any) {
      this.selectedUser = this.companyUsers.find((x) => x.id === id)
   }

   getDepartments() {
      CompanyUsersApi.getDepartments()
         .then((result: any) => {
            this.departments = result.departments.sort((a: any, b: any) =>
               a.name.localeCompare(b.name)
            )
         })
         .catch((err) => {})
   }

   isUserSuperAdmin() {
      const adminRole = this.selectedUserRoles?.find((x: any) => {
         return x.roleName === 'SuperAdmin'
      })

      adminRole !== undefined ? (this.isAdmin = true) : (this.isAdmin = false)
   }

   getUsersCount() {
      return this.companyUsers?.length
   }

   onDepartmentChange(selectedValues: any, selectedArray: any) {
      console.log({ selectedValues, selectedArray })
      this.selectedDepartmentValues = selectedValues
      this.selectedUser.departments = selectedArray
   }

   onUserAdminRoleSwitch(isAdmin: any) {
      this.isAdmin = isAdmin
      if (isAdmin) this.selectedUser.roles = [{ id: 1, roleName: 'SuperAdmin' }]
      else this.selectedUser.roles = []
   }

   onNameChange(e: any) {
      this.selectedUser.name = e.target.value
      this.isNameEmpty = e.target.value === ''
   }

   onEmailChange = (e: any) => {
      this.selectedUser.email = e.target.value
      this.isEmailEmpty = e.target.value === ''
   }

   onSurnameChange(e: any) {
      this.selectedUser.surname = e.target.value
      this.isSurameEmpty = e.target.value === ''
   }

   onPagination(pageNumber: number, pageSize: number = 0) {
      if (pageNumber === 0)
         this.pagedCompanyUsers = this.companyUsers.slice(0, pageSize)
      else
         this.pagedCompanyUsers = this.companyUsers.slice(
            (pageNumber - 1) * pageSize,
            pageNumber * pageSize
         )
   }

   userPassword: string = ''
   createUser = () => {
      this.isLoading = true
      const toastId = toast.loading('Creating User ...')
      CompanyUsersApi.createUser(this.userObj())
         .then((result) => {
            this.isLoading = false
            if (result.errorCode === 0) {
               this.redirectToResult = true
               this.resulErrorMessageVisible = false
               this.alertMessage = ''
               this.userPassword = result.additionalMessage
               toast.success(
                  `Successfully created user. His password is ${result.additionalMessage}`,
                  { id: toastId, duration: 5000 }
               )
            } else {
               this.resulErrorMessageVisible = true
               this.alertMessage = result.additionalMessage
               toast.error(result.additionalMessage, { id: 'error' })
            }
         })
         .catch((err) => {
            this.isLoading = false
            this.redirectToResult = true
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   updateUser(isUpdate: boolean) {
      this.isLoading = true
      const toastId = toast.loading('Creating User ...')
      CompanyUsersApi.updateUser(this.userObj(), isUpdate)
         .then((result) => {
            this.isLoading = false
            if (result.errorCode === 0) {
               toast.success(`Successfully updated user.`, {
                  id: toastId,
                  duration: 5000,
               })
            } else {
               toast.dismiss(toastId)
               this.showErrorMessage(result.additionalMessage)
            }
         })
         .catch((err) => {
            this.isLoading = false
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   disableUser(id: any) {
      toast.promise(
         CompanyUsersApi.updateUserStatus(id, false),
         {
            loading: 'Updating user...',
            success: () => {
               // TODO: Improve efficiency. Try to change user status button on the UI side instead of the call all user list after update one row only..
               this.getCompanyUsers()
               this.isLoading = false
               return 'User successfully disable'
            },
            error: () => {
               this.isLoading = false
               return 'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
            },
         },
         { id: 'error' }
      )
   }

   enableUser(id: any) {
      toast.promise(
         CompanyUsersApi.updateUserStatus(id, true),
         {
            loading: 'Updating user...',
            success: () => {
               // TODO: Improve efficiency. Try to change user status button on the UI side instead of the call all user list after update one row only..
               this.getCompanyUsers()
               this.isLoading = false
               return 'User successfully enable'
            },
            error: () => {
               this.isLoading = false
               return 'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
            },
         },
         { id: 'error' }
      )
   }

   deleteUser(id: any) {
      const toastId = toast.loading('Deleting User ...')
      CompanyUsersApi.deleteUser(id)
         .then((result: any) => {
            this.isLoading = false
            if (result.errorCode === 0) {
               toast.success(`Successfully deleted user.`, {
                  id: toastId,
                  duration: 5000,
               })
            } else {
               toast.dismiss(toastId)
               this.showErrorMessage(result.additionalMessage)
            }
         })
         .catch((err) => {
            this.isLoading = false
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   onValidationMessageClose = () => {
      this.showEmailNotificationMessage = false
   }

   showEmailNotificationMessage: boolean = false
   sendPasswordToUser = (id: any) => {
      this.isLoading = true
      const toastId = toast.loading('Sending password...')
      CompanyUsersApi.sendPasswordToUser(id)
         .then((result: any) => {
            this.isLoading = false
            if (result.errorCode === 0) {
               toast.success(`Password has been sent to your email`, {
                  id: toastId,
                  duration: 5000,
               })
            } else {
               toast.dismiss(toastId)
               this.showErrorMessage(result.additionalMessage)
            }
         })
         .catch((err) => {
            this.isLoading = false
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   sendPasswordToLoggedUser = (id: any) => {
      this.isLoading = true
      const toastId = toast.loading('Sending password...')
      CompanyUsersApi.sendUserPasswordToLoggedUser(id)
         .then((result) => {
            this.isLoading = false
            if (result.errorCode > 0) {
               toast.dismiss(toastId)
               this.showErrorMessage(result.additionalMessage)
            } else {
               toast.success('Password has been sent to your email.', {
                  id: toastId,
                  duration: 5000,
               })
            }
         })
         .catch((err) => {
            this.isLoading = false
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   delete(record: Item) {
      if (this.isItemValid(record)) {
         const toastId = toast.loading('Deleting user...')
         return CompanyUsersApi.delete(record.key)
            .then((result) => {
               toast.success('Successfully delete user.', { id: toastId })
            })
            .catch((err) => {
               toast.error(alertMessages.error500, { id: toastId })
            })
      } else return false
   }

   clearStore() {
      this.companyUsers = []
      this.permissions = []
      this.departments = []
      this.resulErrorMessageVisible = false
      this.isAdmin = false
   }

   clearUserStore() {
      this.redirectToResult = false
      this.isEmailEmpty = false
      this.isNameEmpty = false
      this.isSurameEmpty = false
      this.roles = []
      this.departments = []
      this.selectedUserRoles = []
      this.selectedDepartmentValues = []
      this.isAdmin = false
      this.selectedUser = {
         id: 0,
         email: '',
         name: '',
         surname: '',
         departments: [],
         roles: [],
      }
      this.selectedUser.roles = []
      this.selectedUserDepartments = []
   }

   isItemValid(item: any) {
      return item.description !== '' && item.key !== '' && item.value !== ''
   }

   userObj() {
      return {
         id: this.selectedUser.id,
         email: this.selectedUser.email,
         name: this.selectedUser.name,
         surname: this.selectedUser.surname,
         roles: this.selectedUser.roles.map((e: any) => e.id),
         departments: this.selectedUser.departments.map((e: any) =>
            parseInt(e.value)
         ),
      }
   }

   onCurrentPwChange(e: any) {
      this.currentPw = e.target.value
   }

   onNewPwChange(e: any) {
      this.newPw = e.target.value
   }
   showMessage: boolean = false

   setShowMessage(val: boolean) {
      this.showMessage = val
   }

   updatePw() {
      this.isLoading = true
      const toastId = toast.loading('Updating password...')
      CompanyUsersApi.updatePw('/User/updatePw', this.updatePwJson())
         .then((result) => {
            if (result.errorCode === 0) {
               toast.success('Successfully updated.', { id: toastId })
            } else {
               toast.dismiss(toastId)
               this.showErrorMessage(result.additionalMessage)
            }

            this.isError = result.errorCode > 0
            this.isLoading = false
         })
         .catch((err) => {
            this.isLoading = false
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   updatePwJson(): any {
      return {
         currentPassword: this.currentPw,
         newPassword: this.newPw,
      }
   }

   unlockUser(id: any) {
      this.isLoading = true
      const toastId = toast.loading('Unlocking user...')
      CompanyUsersApi.unlockUser(id)
         .then((result) => {
            if (result.errorCode === 0) {
               toast.success('Successfully unlocked.', { id: toastId })
            } else {
               toast.dismiss(toastId)
               this.showErrorMessage(result.additionalMessage)
            }

            this.isError = result.errorCode > 0
            this.isLoading = false
         })
         .catch((err) => {
            this.isLoading = false
            toast.dismiss(toastId)
            this.showErrorMessage()
         })
   }

   showErrorMessage(
      message: string = 'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
   ) {
      toast.error(message, { id: 'error' })
   }

   constructor() {
      makeAutoObservable(this)
   }
}
