import { observer } from 'mobx-react' // Or "mobx-react".
import { Result, Button, Row, Typography, message } from 'antd'
import {
   CheckCircleOutlined,
   RightCircleOutlined,
   FilterOutlined,
   PlusCircleOutlined,
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import './Filter.scss'
import { FilterStore } from './FilterStore'
import { Divider } from 'antd'
import { useStore } from '../../context/index'
import { FilterApi } from './FilterApi'

const { Text } = Typography

interface IFilterResultScreen {
   match: any
   location: any
}
export const FilterResultScreen = observer((props: IFilterResultScreen) => {
   const history = useHistory()
   const { FilterStore } = useStore()

   const createAnotherFilter = () => {
      // TODO: add activeFilterCount validator. This can be implemented on Create New Filter as well.
      // if (FilterStore.activeFilterCount() >= 10)
      //    message.error(
      //       'You cannot create more than 10 active filter. Go to My Filters and delete or disable one of your filters.'
      //    )
      // else
      history.push('/AddNewFilter')
   }
   return (
      <>
         <Row style={{ justifyContent: 'center' }}>
            <CheckCircleOutlined
               style={{
                  fontSize: '50px',
                  color: 'green',
                  margin: '3%',
                  justifyContent: 'center',
               }}
            />
         </Row>
         <Row style={{ justifyContent: 'center' }}>
            <Text className="successMessage">
               Your filter '<strong>{FilterStore.filter.filterName}</strong>'
               has been{' '}
               {props.location.state.isEditMode ? 'updated' : 'created'}{' '}
               successfully!
            </Text>
         </Row>
         <div style={{ height: '30vh' }} />
         <Divider dashed className="dashed" />
         <Row style={{ justifyContent: 'center', marginTop: '3%' }}>
            <Button
               type="link"
               icon={<PlusCircleOutlined />}
               onClick={createAnotherFilter}
            >
               Create another filter
            </Button>
            <Button
               type="link"
               icon={<RightCircleOutlined />}
               onClick={() => history.push('/MyFilters')}
               style={{ marginLeft: '3%' }}
            >
               Go My Filters
            </Button>
            <Button
               type="link"
               icon={<RightCircleOutlined />}
               onClick={() => history.push('/dailyrules')}
               style={{
                  marginLeft: '3%',
               }}
            >
               Go Daily Rules
            </Button>
         </Row>
      </>
   )
})
