import { Toaster } from 'react-hot-toast'

import 'ckeditor5/ckeditor5.css';
import './App.css'
import 'antd/dist/antd.css'
import MainLayout from './components/layout/MainLayout'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Login from './features/login/Login'
import ChangePassword from './features/login/ChangePassword'
import { observer } from 'mobx-react'
import { useStore } from './context'
import { Loader } from './utils'

const App = observer(() => {
   const { commonStore } = useStore()
   return (
      <>
         {commonStore.isAuthLoading ? (
            <Loader />
         ) : (
            <>
               <BrowserRouter basename="/">
                  <Switch>
                     <Route
                        path={'/'}
                        render={() => <Redirect to="/Dashboard" />}
                        exact={true}
                     />
                     <Route path={'/login'} component={Login} exact={true} />
                     <Route
                        path="/ChangePassword"
                        component={ChangePassword}
                        exact={true}
                     />
                     <Route path="/" component={MainLayout} />
                  </Switch>
               </BrowserRouter>
               <Toaster position="top-right" />
            </>
         )}
      </>
   )
})

export default App
