import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useHistory } from 'react-router-dom'
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Row, Col, Spin, Alert } from 'antd'
import { Typography } from 'antd'
import OrganisationalChart from '../../components/OrganisationalChart'
import EditableTable from './components/EditableTable'
import { CloseCircleFilled } from '@ant-design/icons'
import { Input } from 'antd'

const { Title } = Typography

interface IDepartments {
   endpoint: string
}

const Departments = observer((props: IDepartments) => {
   const { DepartmentsStore } = useStore()
   const history = useHistory()

   const pageTitle = 'Departments'

   const [isAdmin, setIsAdmin] = useState(false)

   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)
      DepartmentsStore.endpoint = props.endpoint
      DepartmentsStore.getPageData(history)
      return () => {
         DepartmentsStore.clearStore()
      }
   }, [])

   const departmentSearchboxOnChange = (target: any) => {
      let targetValue = target?.target?.value
      if (targetValue === '' || targetValue === undefined) {
         DepartmentsStore.isFiltered = false
      } else {
         DepartmentsStore.setDailyRuleListFiltered(
            DepartmentsStore.pageData?.filter(
               (e: any) =>
                  e.name.toLowerCase().match(targetValue.toLowerCase()) ||
                  e.businessEntity.name
                     .toLowerCase()
                     .match(targetValue.toLowerCase()) ||
                  e.businessEntity?.group?.description
                     .toLowerCase()
                     .match(targetValue.toLowerCase())
            )
         )
         DepartmentsStore.isFiltered = true
      }

      DepartmentsStore.setSearchValue(targetValue)
   }

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <Spin spinning={DepartmentsStore.isLoading}>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>

            {/*  {DepartmentsStore.isError && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={DepartmentsStore.errorMessage}
                        type={DepartmentsStore.errorType}
                        showIcon
                     />
                  </Col>
               </Row>
            )} */}
            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3}>{pageTitle}</Title>
               </Col>
            </Row>

            {/* {!DepartmentsStore.isBusinessEntityExist && (
               <>
                  <Row style={{ marginBottom: '1%' }}>
                     <Col span={24}>
                        <Alert
                           message="In order to create Department, You must have Business Entity first. Click below;"
                           type="info"
                           showIcon
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col style={{ textAlign: 'center' }} span={24}>
                        <Button
                           type="link"
                           onClick={() => history.push('/businessEntity')}
                           icon={<UsergroupAddOutlined />}
                           style={{
                              backgroundColor: '#fa8c16',
                              color: 'white',
                              borderRadius: '10px',
                           }}
                           size="large"
                        >
                           Create Business Entity
                        </Button>
                     </Col>
                  </Row>
               </>
            )} */}
            <Row style={{ marginTop: '5%' }}>
               <Col span={3}> </Col>
               <Col span={12} style={{ marginLeft: '2%' }}>
                  <OrganisationalChart initialDepth={4} />
               </Col>
               <Col span={5} style={{ marginLeft: '-0.5%' }}>
                  <Input
                     placeholder="Search table"
                     // style={{ width: '60%' }}
                     value={DepartmentsStore.searchValue}
                     onChange={(e: any) => departmentSearchboxOnChange(e)}
                     suffix={
                        <>
                           <CloseCircleFilled
                              className="close-circle"
                              // onClick={() => DailyRulesStore.clearSearchBox()} //TODO : USE STORE
                           />{' '}
                        </>
                     }
                  />
               </Col>
               <Col span={3}> </Col>
            </Row>
            {DepartmentsStore.isBusinessEntityExist && (
               <EditableTable
                  firstColumnTitle="Department"
                  secondColumnTitle="Supervisor Name"
                  deleteMessage="Department will be deleted permanently, would you like to continue?"
                  buttonName="Add New Department"
                  isDraggable={false}
               />
            )}
            <br />
            <br />
         </Spin>
      )
})
export default Departments
