import { Helpers } from '../../api/Helpers'
import { objectToFormData, toFlat } from '../../helpers'

export class ImpactAssessmentsApi {
   public static getInitData = () => {
      return Helpers.get('/ImpactAssessment/InitData')
   }

   public static getAssessments = () => {
      return Helpers.get('/ImpactAssessment/GetAssessments')
   }

   public static delete = (key: string) => {
      // return Helpers.delete('/', key)
   }

   public static filterAssessments(f: any): Promise<any> {
      return Helpers.get(
         '/ImpactAssessment/filter?allocationStartDate=' +
            f.allocationStartDate +
            '&allocationEndDate=' +
            f.allocationEndDate +
            '&updateStartDate=' +
            f.updateStartDate +
            '&updateEndDate=' +
            f.updateEndDate +
            '&ResponseDueStartDate=' +
            f.responseDueStartDate +
            '&ResponseDueEndDate=' +
            f.responseDueEndDate +
            '&internalNumber=' +
            f.internalNumber +
            '&statusTypes=[' +
            f.statusTypes +
            ']&allocatorUserIds=[' +
            f.allocatorUserIds +
            ']&topics=[' +
            f.topics +
            ']&riskLevels=[' +
            f.riskLevels +
            ']&assessorUserIds=[' +
            f.assessorUserIds +
            ']&departments=[' +
            f.departments +
            ']&businessEntities=[' +
            f.businessEntities +
            ']&groups=[' +
            f.groups +
            ']&ruleType=[' +
            f.ruleTypes +
            ']'
      )
   }

   public static getUsersByDepartment(): Promise<any> {
      const response = Helpers.get('/User/CompanyUsersByDepartment')
      return response
   }

   public static updateAssessment(assessment: any): Promise<any> {
      const filesToAdd = assessment.filesToAdd
      delete assessment.filesToAdd
      const formData = objectToFormData(assessment)
      if (filesToAdd?.length)
         for (const file of filesToAdd) formData.append('attachedFiles', file)

      const response = Helpers.update(
         '/ImpactAssessment',
         assessment.assessmentId,
         formData
      )
      return response
   }

   public static updateInternalNumber(
      assessmentId: string,
      internalNumber: string
   ): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/updateInternalNumber',
         assessmentId,
         internalNumber
      )
      return response
   }

   public static sendAssessment = (data: any): Promise<any> => {
      const files = data.attachedFiles
      delete data.attachedFiles
      const formData = objectToFormData(data)
      for (const file of files) {
         formData.append('attachedFiles', file)
      }

      return Helpers.post('/ImpactAssessment/SendAssessment', formData)
   }

   public static deleteAssessment(request: any): Promise<any> {
      const response = Helpers.post(
         '/ImpactAssessment/DeleteAssessment',
         request
      )
      return response
   }

   public static getRuleDetails(ruleId: number): Promise<any> {
      const response = Helpers.get('/DailyRules/' + ruleId)
      return response
   }

   public static addNewAssessmentDetail(assessmentDetail: any): Promise<any> {
      const response = Helpers.post(
         '/ImpactAssessment/AddAssessmentDetail/',
         assessmentDetail
      )
      return response
   }

   public static getAssessmentDetails(
      assessmentId: string,
      auditId: string
   ): Promise<any> {
      const response = Helpers.get(
         '/ImpactAssessment/GetAssessmentDetails/' +
            assessmentId +
            '/' +
            auditId
      )
      return response
   }

   public static updateAssessmentDetail(
      assessmentDetailAudit: any
   ): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/updateAssessmentDetail',
         0,
         assessmentDetailAudit
      )
      return response
   }

   public static getPreferences(endpoint: string): Promise<any> {
      return Helpers.get(endpoint)
   }

   public static getSupervisors(userIds: number[] = []): Promise<any> {
      let userIdsParams = ''
      userIds.forEach((element) => {
         userIdsParams += 'userIds=' + element + '&'
      })

      return Helpers.get('/common/GetUsersSupervisor?' + userIdsParams)
   }

   public static updateRow(assessmentId: string, row: any): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/UpdateAssessmentCells',
         assessmentId,
         row
      )
      return response
   }

   // -----
   public static updateAssessmentInternalNo(
      assessmentId: any,
      internalNo: any
   ): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/UpdateAssessmentInternalNumber',
         assessmentId,
         internalNo
      )
      return response
   }
   public static updateAssessmentRiskLevel(
      assessmentId: any,
      riskLevel: any
   ): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/updateAssessmentRiskLevel',
         assessmentId,
         riskLevel
      )
      return response
   }
   public static updateAssessmentStatus(
      assessmentId: any,
      status: any
   ): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/updateAssessmentStatus',
         assessmentId,
         status
      )
      return response
   }
   public static updateAssessmentTopics(
      assessmentId: any,
      topicIds: any
   ): Promise<any> {
      const response = Helpers.update(
         '/ImpactAssessment/updateAssessmentTopics',
         assessmentId,
         topicIds
      )
      return response
   }
   //---

   public static downloadAssessments(assessmentIds: any[]): Promise<any> {
      const response = Helpers.post(
         '/ImpactAssessment/ExportAssessmentToExcel',
         assessmentIds
      )
      return response
   }

   public static downloadAssessmentsByPreferenceId(
      assessmentIds: any[],
      excelPreferenceId: any
   ): Promise<any> {
      const response = Helpers.post(
         '/ImpactAssessment/ExportIAToExcelByPreference',
         {
            assessmentIds: assessmentIds,
            excelPreferenceId: excelPreferenceId,
         }
      )
      return response
   }

   public static sendBulkEmail(
      assessmentIds: any[],
      comment: string
   ): Promise<any> {
      const response = Helpers.post('/ImpactAssessment/SendBulkEmail', {
         assessmentIds: assessmentIds,
         comment: comment,
      })
      return response
   }

   public static createNewsletter(
      assessmentIds: any[],
      newsletterName: string
   ): Promise<any> {
      const response = Helpers.post(
         '/ImpactAssessment/CreateNewsletterFromAssessment',
         {
            assessmentIds: assessmentIds,
            newsLetterName: newsletterName,
         }
      )
      return response
   }

   public static validateNewsletterName(name: string): Promise<any> {
      const response = Helpers.get(
         `/ImpactAssessment/ValidateNewsletterName/${name}`
      )
      return response
   }

   public static getUserExcelPreferences = (endpoint: string) => {
      return Helpers.get(endpoint)
   }

   public static sendRequestSignOffEmail = (supervisorIds: number[]) => {}

   public static getMaxPeriodDate = () =>
      Helpers.get('/Common/GetSetting/APP:IMPACTASSESSMENT:MAXPERIOD')

   // public static endpoint = 'ImpactAssessment'
}
