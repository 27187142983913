import { Link } from 'react-router-dom'
import { observer } from 'mobx-react' // Or "mobx-react".
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Card, Row, Col } from 'antd'
import { ReactComponent as Logo } from '../../assets/reg-impact-transp.svg'
import { useStore } from '../../context/index'
import { ReactComponent as Back } from '../../assets/back.svg'

const layout = {
   labelCol: { span: 8 },
   wrapperCol: { span: 15 },
}
const tailLayout = {
   wrapperCol: { offset: 19, span: 11 },
}

const ChangePassword = observer(() => {
   const { LoginStore } = useStore()
   const history = useHistory()
   const [form] = Form.useForm()
   const [showSuccessMessage, setShowSuccessMessage] = useState(false)
   const onChangePassword = () => {
      LoginStore.changePassword()
      form.resetFields()
      setShowSuccessMessage(true)
   }

   const onFinishFailed = (errorInfo: any) => {
      console.error('Failed:', errorInfo)
   }

   useEffect(() => {
      LoginStore.setRenewToken(history.location.search.split('=')[1])
   }, [])

   return (
      <>
         {true && (
            <Card style={{ minHeight: '100vh', backgroundColor: '#ffd591' }}>
               <Row style={{ height: '100px', justifyContent: 'center' }}>
                  <Logo height="70%" style={{ marginLeft: '1.5%' }} />
               </Row>
               <Row style={{ height: '10ch' }} />
               <Row style={{ justifyContent: 'center' }}>
                  <Card style={{ width: '70ch' }}>
                     {showSuccessMessage ? (
                        <span
                           style={{
                              color: '#52c41a',
                              display: 'flex',
                              justifyContent: 'center',
                              marginBottom: '2%',
                           }}
                        >
                           Password changed successfully
                        </span>
                     ) : (
                        ''
                     )}
                     <Row>
                        <Col span={1}> </Col>
                        <Col span={23}>
                           <Form
                              {...layout}
                              form={form}
                              name="basic"
                              onFinish={onChangePassword}
                              onFinishFailed={onFinishFailed}
                           >
                              <Form.Item
                                 label="New Password:"
                                 name="password"
                                 rules={[
                                    {
                                       required: true,
                                       message:
                                          'Please input your new password!',
                                    },
                                 ]}
                                 labelAlign="left"
                              >
                                 <Input.Password
                                    size="small"
                                    onChange={(e) =>
                                       LoginStore.onNewPwChange(e)
                                    }
                                 />
                              </Form.Item>
                              <Form.Item
                                 label="Confirm Password:"
                                 name="passwordConfirm"
                                 rules={[
                                    {
                                       required: true,
                                       message:
                                          'Please confirm your new password!',
                                    },
                                    ({ getFieldValue }) => ({
                                       validator(_, value) {
                                          if (
                                             !value ||
                                             getFieldValue('password') === value
                                          ) {
                                             return Promise.resolve()
                                          }
                                          return Promise.reject(
                                             new Error(
                                                'The two passwords that you entered do not match!'
                                             )
                                          )
                                       },
                                    }),
                                 ]}
                                 labelAlign="left"
                              >
                                 <Input.Password
                                    size="small"
                                    // onChange={(e) => SetttingsStore.onNewPwChange(e)}
                                 />
                              </Form.Item>

                              <Form.Item>
                                 <Row>
                                    <Col span={6}>
                                       <div
                                          style={{
                                             display: 'flex',
                                             marginTop: '1%',
                                          }}
                                       >
                                          <Back />
                                          <a
                                             onClick={(e) =>
                                                history.push('/login')
                                             }
                                             style={{
                                                marginTop: '1%',
                                                marginLeft: '1%',
                                             }}
                                          >
                                             Login
                                          </a>
                                       </div>
                                    </Col>
                                    <Col
                                       span={18}
                                       style={{
                                          display: 'flex',
                                          justifyContent: 'end',
                                       }}
                                    >
                                       <Button
                                          type="primary"
                                          htmlType="submit"
                                          className="login-form-button"
                                          style={{
                                             borderRadius: '10px',
                                             backgroundColor:
                                                'rgb(250, 140, 22)',
                                             borderColor: 'rgb(250, 140, 22)',
                                             color: 'white',
                                             // marginLeft: '-2%',
                                          }}
                                       >
                                          Confirm
                                       </Button>
                                    </Col>
                                 </Row>
                              </Form.Item>
                           </Form>
                        </Col>
                     </Row>
                  </Card>
               </Row>
            </Card>
         )}
      </>
   )
})

export default ChangePassword
