import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { Helmet } from 'react-helmet'
import {
   Alert,
   Button,
   Card,
   Col,
   Divider,
   Pagination,
   Popconfirm,
   Row,
   Spin,
   Switch,
   Tag,
   Typography,
} from 'antd'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import {
   DeleteOutlined,
   EditOutlined,
   PlusCircleOutlined,
   UserOutlined,
} from '@ant-design/icons'
import { ReactComponent as GreenBall } from '../../assets/greenball.svg'
import { ReactComponent as GreyBall } from '../../assets/greyball.svg'
import { GetSemicolonFormat } from '../../utils/Filters'
import { useStore } from '../../context'
import { hasUserRight, warningStyle } from '../../utils/Helpers'
import { toast } from 'react-hot-toast'

interface IMyFilters {
   isAll: boolean
}

interface IFilterList {
   group: object
   isAll: boolean
   onSwitchChange: (params: any, filter: any) => void
   onDeleteConfirm: (params: number) => void
}

const { Text, Title } = Typography

const FilterListCpt = ({
   group,
   isAll,
   onSwitchChange,
   onDeleteConfirm,
}: IFilterList & RouteComponentProps) => {
   const history = useHistory()
   return (
      <>
         {Object.entries(group).map(([username, value], index: number) => (
            <>
               {isAll && (
                  <Row
                     style={{ justifyContent: 'center', marginTop: '1%' }}
                     key={index + 's'}
                  >
                     <div
                        style={{
                           width: '78%',
                           marginLeft: 40,
                           borderRadius: '20px',
                           display: 'flex',
                        }}
                     >
                        <span
                           style={{
                              borderRadius: 5,
                              padding: 5,
                              display: 'flex',
                              gap: 10,
                              alignItems: 'center',
                           }}
                        >
                           <UserOutlined />
                           <Tag color="blue">{username} </Tag>
                        </span>
                     </div>
                  </Row>
               )}
               {value.map((filter: any, index: number) => {
                  return (
                     // <Spin spinning={FilterStore.isStatusChanging} >
                     <Row
                        style={{ justifyContent: 'center', marginTop: '1%' }}
                        key={`row1-${index}`}
                     >
                        <Card
                           style={{
                              width: '78%',
                              backgroundColor: '#fafafa',
                              borderRadius: '20px',
                           }}
                        >
                           <Row>
                              {!isAll && (
                                 <Col span={3}>
                                    {hasUserRight(filter.filterLevel.id) && (
                                       <Switch
                                          checkedChildren="Active"
                                          unCheckedChildren="Disabled"
                                          checked={filter.isActive}
                                          onChange={(event) =>
                                             onSwitchChange(event, filter)
                                          }
                                       />
                                    )}
                                 </Col>
                              )}
                              <Col
                                 span={7}
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                 }}
                              >
                                 <Text>
                                    {' '}
                                    <strong>Filter Name:</strong>{' '}
                                    {filter.filterName}
                                 </Text>
                              </Col>
                              <Col
                                 span={7}
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                 }}
                              >
                                 <span style={{ alignContent: 'center' }}>
                                    <strong>Filter Level:</strong>{' '}
                                    {filter.filterLevel?.name}
                                 </span>
                              </Col>
                              {!isAll && (
                                 <Col
                                    span={7}
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'flex-end',
                                    }}
                                 >
                                    {hasUserRight(filter.filterLevel.id) && (
                                       <Button
                                          type="link"
                                          icon={<EditOutlined />}
                                          onClick={() =>
                                             history.push({
                                                pathname: '/EditFilter/',
                                                state: { filterId: filter.id }, // TODO : Return Filter ID from response.
                                             })
                                          }
                                          style={{
                                             marginTop: '-3%',
                                             color: '#52c41a',
                                          }}
                                       >
                                          Edit{' '}
                                       </Button>
                                    )}
                                    <Popconfirm
                                       title="Your filter will be deleted would you like to continue?"
                                       okText="Yes"
                                       cancelText="No"
                                       onConfirm={(_) =>
                                          onDeleteConfirm(filter.id)
                                       }
                                    >
                                       {hasUserRight(filter.filterLevel.id) && (
                                          <Button
                                             type="link"
                                             icon={<DeleteOutlined />}
                                             style={{
                                                marginLeft: '5%',
                                                marginTop: '-3%',
                                                color: '#ff4d4f',
                                             }}
                                          >
                                             Delete
                                          </Button>
                                       )}
                                    </Popconfirm>
                                 </Col>
                              )}
                           </Row>
                           <Divider />
                           <Row style={{ border: 2 }}>
                              <Col span={3}>
                                 <Text>
                                    <strong>Countries:</strong>
                                 </Text>
                              </Col>
                              <Col span={9}>
                                 <Text>
                                    {GetSemicolonFormat(
                                       filter.countries,
                                       'code'
                                    )}
                                 </Text>
                              </Col>

                              <Col style={{ marginLeft: '3%' }} span={4}>
                                 <Text>
                                    <strong>Regulators:</strong>
                                 </Text>
                              </Col>
                              <Col span={7}>
                                 <Text>
                                    {GetSemicolonFormat(
                                       filter.regulators,
                                       'code'
                                    )}
                                 </Text>
                              </Col>
                           </Row>
                           <Row style={{ border: 2 }}>
                              <Col span={3}>
                                 <Text>
                                    <strong>Entity Types:</strong>
                                 </Text>
                              </Col>
                              <Col span={9}>
                                 <Text>
                                    {GetSemicolonFormat(
                                       filter.entityTypes,
                                       'code'
                                    )}
                                 </Text>
                              </Col>

                              <Col style={{ marginLeft: '3%' }} span={4}>
                                 <Text>
                                    <strong>Rule Types:</strong>
                                 </Text>
                              </Col>
                              <Col span={7}>
                                 <Text>
                                    {GetSemicolonFormat(
                                       filter.ruleTypes,
                                       'name'
                                    )}
                                 </Text>
                              </Col>
                           </Row>
                           <Row style={{ border: 2 }}>
                              <Col span={3}>
                                 <Text>
                                    <strong>Functions:</strong>
                                 </Text>
                              </Col>
                              <Col span={9}>
                                 <Text>
                                    {GetSemicolonFormat(
                                       filter.functions,
                                       'value'
                                    )}
                                 </Text>
                              </Col>

                              <Col style={{ marginLeft: '3%' }} span={4}>
                                 <Text>
                                    <strong>Products:</strong>
                                 </Text>
                              </Col>
                              <Col span={7}>
                                 <Text>
                                    {GetSemicolonFormat(
                                       filter.products,
                                       'value'
                                    )}
                                 </Text>
                              </Col>
                           </Row>
                           <Row style={{ border: 2 }}>
                              <Col span={3}>
                                 <Text>
                                    <strong>Regions:</strong>
                                 </Text>
                              </Col>
                              <Col span={9}>
                                 <Text>
                                    {GetSemicolonFormat(filter.regions, 'code')}
                                 </Text>
                              </Col>

                              <Col style={{ marginLeft: '3%' }} span={4}>
                                 <Text>
                                    <strong>Filter by text:</strong>
                                 </Text>
                              </Col>
                              <Col span={7}>
                                 <Text>
                                    {filter.freeText === null
                                       ? '--'
                                       : filter.freeText}
                                 </Text>
                              </Col>
                           </Row>
                           <Row style={{ border: 2 }}>
                              <Col span={3}>
                                 <Text>
                                    <strong>Reference :</strong>
                                 </Text>
                              </Col>
                              <Col span={9}>
                                 <Text>
                                    {filter.referenceText === null
                                       ? '--'
                                       : filter.referenceText}
                                 </Text>
                              </Col>
                           </Row>
                        </Card>
                     </Row>
                     // </Spin>
                  )
               })}
               <div
                  style={{
                     marginTop: 42,
                  }}
               ></div>
            </>
         ))}
      </>
   )
}

const FilterList = withRouter(FilterListCpt)

const MyFilters = observer(({ isAll }: IMyFilters & RouteComponentProps) => {
   const pageTitle = !isAll ? 'My Filters' : 'Filters'
   const history = useHistory()
   const { FilterStore } = useStore()

   const [isAdmin, setIsAdmin] = useState(false)
   const [isSupAdmin, setIsSupAdmin] = useState(false)

   const [page, setPage] = useState(1)
   const [pageSize, setPageSize] = useState(10)

   const data = useMemo(() => FilterStore.filterList, [FilterStore])

   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
      } else setIsAdmin(true)
      if (localStorage.getItem('isSupAdmin') === 'false') {
         setIsSupAdmin(false)
      } else setIsSupAdmin(true)

      if (!isAll) FilterStore.getFilters()
      else FilterStore.getPaginatedFilters(page, pageSize)

      FilterStore.getFilterPreferences()

      showAllowedFilterMessage()
      // setWarningMessage(
      //    `Maximum ` + '' + ` active and ` + '' + ` disabled filter allowed.`
      // )
      return () => {}
   }, [page, pageSize])

   useEffect(() => {
      showAllowedFilterMessage()
   }, [FilterStore.filterList.filters])

   const filterCountValidationMessage = () => {
      toast.error('Exceeded allowed active/disable filter count.', {
         id: 'error',
      })
   }
   const onSwitchChange = (params: any, filter: any) => {
      if (FilterStore.checkIsSwitchValid(params)) {
         FilterStore.changeFilterStatus(filter.id, params)
      } else filterCountValidationMessage()

      showAllowedFilterMessage()
   }

   const showAllowedFilterMessage = () => {
      if (FilterStore.activeFilterCount() === FilterStore.allowedActiveFilter) {
         toast('You have reached allowed active filter count', {
            ...warningStyle,
            id: 'warning',
         })
      }
      if (
         FilterStore.disableFilterCount() === FilterStore.allowedDisableFilter
      ) {
         toast('You have reached the number of disabled filters allowed', {
            ...warningStyle,
            id: 'warning',
         })
      }
   }

   const onCreateAnotherFilter = () => {
      if (FilterStore.activeFilterCount() >= FilterStore.allowedActiveFilter)
         toast.error(
            'You cannot create more than ' +
               FilterStore.allowedActiveFilter +
               ' active filter. You must delete or disable one of your active filter.',
            { id: 'error' }
            // FilterStore.onValidationMessageClose
         )
      else history.push('/AddNewFilter')
   }

   const onDeleteConfirm = (id: any) => {
      FilterStore.deleteFilter(id)
      showAllowedFilterMessage()
   }

   const onPageChange = useCallback((page) => {
      setPage(page)
   }, [])

   const onSizeChange = useCallback((current, size) => {
      setPageSize(size)
   }, [])
   const filterByUser = useMemo(
      () =>
         FilterStore.filterList?.filters?.reduce((acc, current) => {
            const username = current?.user?.name
            if (!acc[username]) acc[username] = [{ ...current }]
            else acc[username].push({ ...current })

            return { ...acc }
         }, {}),
      [FilterStore.filterList.filters]
   )

   const filters = FilterStore.filterList?.filters?.map(
      (filter: any, index: number) => {
         return (
            // <Spin spinning={FilterStore.isStatusChanging} >
            <Row
               style={{ justifyContent: 'center', marginTop: '1%' }}
               key={index}
            >
               <Card
                  style={{
                     width: '78%',
                     backgroundColor: '#fafafa',
                     borderRadius: '20px',
                  }}
               >
                  <Row>
                     {!isAll && (
                        <Col span={3}>
                           {hasUserRight(filter.filterLevel.id) && (
                              <Switch
                                 checkedChildren="Active"
                                 unCheckedChildren="Disabled"
                                 checked={filter.isActive}
                                 onChange={(event) =>
                                    onSwitchChange(event, filter)
                                 }
                              />
                           )}
                        </Col>
                     )}
                     <Col
                        span={7}
                        style={{
                           display: 'flex',
                           justifyContent: 'flex-start',
                        }}
                     >
                        <Text>
                           {' '}
                           <strong>Filter Name:</strong> {filter.filterName}
                        </Text>
                     </Col>
                     <Col
                        span={7}
                        style={{ display: 'flex', justifyContent: 'center' }}
                     >
                        <span style={{ alignContent: 'center' }}>
                           <strong>Filter Level:</strong>{' '}
                           {filter.filterLevel?.name}
                        </span>
                     </Col>
                     {!isAll && (
                        <Col
                           span={7}
                           style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                           }}
                        >
                           {hasUserRight(filter.filterLevel.id) && (
                              <Button
                                 type="link"
                                 icon={<EditOutlined />}
                                 onClick={() =>
                                    history.push({
                                       pathname: '/EditFilter/',
                                       state: { filterId: filter.id }, // TODO : Return Filter ID from response.
                                    })
                                 }
                                 style={{ marginTop: '-3%', color: '#52c41a' }}
                              >
                                 Edit{' '}
                              </Button>
                           )}
                           <Popconfirm
                              title="Your filter will be deleted would you like to continue?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={(_) => onDeleteConfirm(filter.id)}
                           >
                              {hasUserRight(filter.filterLevel.id) && (
                                 <Button
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    style={{
                                       marginLeft: '5%',
                                       marginTop: '-3%',
                                       color: '#ff4d4f',
                                    }}
                                 >
                                    Delete
                                 </Button>
                              )}
                           </Popconfirm>
                        </Col>
                     )}
                  </Row>
                  <Divider />
                  <Row style={{ border: 2 }}>
                     <Col span={3}>
                        <Text>
                           <strong>Countries:</strong>
                        </Text>
                     </Col>
                     <Col span={9}>
                        <Text>
                           {GetSemicolonFormat(filter.countries, 'code')}
                        </Text>
                     </Col>

                     <Col style={{ marginLeft: '3%' }} span={4}>
                        <Text>
                           <strong>Regulators:</strong>
                        </Text>
                     </Col>
                     <Col span={7}>
                        <Text>
                           {GetSemicolonFormat(filter.regulators, 'code')}
                        </Text>
                     </Col>
                  </Row>
                  <Row style={{ border: 2 }}>
                     <Col span={3}>
                        <Text>
                           <strong>Entity Types:</strong>
                        </Text>
                     </Col>
                     <Col span={9}>
                        <Text>
                           {GetSemicolonFormat(filter.entityTypes, 'code')}
                        </Text>
                     </Col>

                     <Col style={{ marginLeft: '3%' }} span={4}>
                        <Text>
                           <strong>Rule Types:</strong>
                        </Text>
                     </Col>
                     <Col span={7}>
                        <Text>
                           {GetSemicolonFormat(filter.ruleTypes, 'name')}
                        </Text>
                     </Col>
                  </Row>
                  <Row style={{ border: 2 }}>
                     <Col span={3}>
                        <Text>
                           <strong>Functions:</strong>
                        </Text>
                     </Col>
                     <Col span={9}>
                        <Text>
                           {GetSemicolonFormat(filter.functions, 'value')}
                        </Text>
                     </Col>

                     <Col style={{ marginLeft: '3%' }} span={4}>
                        <Text>
                           <strong>Products:</strong>
                        </Text>
                     </Col>
                     <Col span={7}>
                        <Text>
                           {GetSemicolonFormat(filter.products, 'value')}
                        </Text>
                     </Col>
                  </Row>
                  <Row style={{ border: 2 }}>
                     <Col span={3}>
                        <Text>
                           <strong>Regions:</strong>
                        </Text>
                     </Col>
                     <Col span={9}>
                        <Text>
                           {GetSemicolonFormat(filter.regions, 'code')}
                        </Text>
                     </Col>

                     <Col style={{ marginLeft: '3%' }} span={4}>
                        <Text>
                           <strong>Filter by text:</strong>
                        </Text>
                     </Col>
                     <Col span={7}>
                        <Text>
                           {filter.freeText === null ? '--' : filter.freeText}
                        </Text>
                     </Col>
                  </Row>
                  <Row style={{ border: 2 }}>
                     <Col span={3}>
                        <Text>
                           <strong>Reference :</strong>
                        </Text>
                     </Col>
                     <Col span={9}>
                        <Text>
                           {filter.referenceText === null
                              ? '--'
                              : filter.referenceText}
                        </Text>
                     </Col>
                  </Row>
               </Card>
            </Row>
            // </Spin>
         )
      }
   )

   if (!isAdmin || (!isSupAdmin && isAll))
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            <Spin spinning={FilterStore.isLoading}>
               <Row>
                  <Col style={{ textAlign: 'center' }} span={19}>
                     <Title level={3}>{pageTitle}</Title>
                  </Col>
                  {!isAll && (
                     <Col span={4}>
                        <Button
                           type="link"
                           onClick={onCreateAnotherFilter}
                           icon={<PlusCircleOutlined />}
                           style={{
                              backgroundColor: '#fa8c16',
                              color: 'white',
                              borderRadius: '10px',
                           }}
                        >
                           Create Another Filter
                        </Button>
                     </Col>
                  )}
               </Row>

               {!isAll && (
                  <Row
                     style={{
                        marginTop: '2%',
                        marginBottom: '2%',
                        marginLeft: '35%',
                        width: '55%',
                     }}
                  >
                     <Col span={1}>
                        <GreenBall />
                     </Col>
                     <Col span={7}>
                        {' '}
                        <Text>
                           Active : {FilterStore.activeFilterCount()} /{' '}
                           {FilterStore.allowedActiveFilter}
                        </Text>
                     </Col>
                     <Col span={1}>
                        <GreyBall />
                     </Col>
                     <Col span={7}>
                        {/* <YellowBall />  */}
                        <Text>
                           Disabled : {FilterStore.disabledFilterCount()} /
                           {FilterStore.allowedDisableFilter}
                        </Text>
                     </Col>
                     {/* <Col span={1}>
               <PurpleBall />
            </Col>
            <Col span={7}>
               <Text>Remain : {FilterStore.allowedRemainFilterCount()}</Text>
            </Col> */}
                  </Row>
               )}
               <FilterList
                  group={filterByUser}
                  isAll={isAll}
                  onSwitchChange={onSwitchChange}
                  onDeleteConfirm={onDeleteConfirm}
               />
               {/* <Row>
            <Col span="10"></Col>
            <Col span="12">
               <Button
                  type="link"
                  onClick={onCreateAnotherFilter}
                  icon={<PlusCircleOutlined />}
                  style={{
                     marginTop: '5%',
                     backgroundColor: '#fa8c16',
                     color: 'white',
                     borderRadius: '10px',
                  }}
               >
                  Create Another Filter
               </Button>
            </Col>
         </Row> */}
               {isAll && (
                  <Row justify="end">
                     <Pagination
                        current={data.pageNumber}
                        defaultPageSize={pageSize}
                        total={data?.totalRecords}
                        onChange={onPageChange}
                        onShowSizeChange={onSizeChange}
                        showSizeChanger
                        showTotal={(total) => `Total ${total} items`}
                     />
                  </Row>
               )}
            </Spin>
         </>
      )
})

export default withRouter(MyFilters)
