import React from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import { Row, Modal, Input, Form } from 'antd'
import { ReactComponent as Clear } from '../../../assets/clear.svg'
import { FileAddOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'

export interface INewsletterNameModal {}

const NewsletterNameModal = observer((props: INewsletterNameModal) => {
   const { ImpactAssessmentsStore } = useStore()

   const onConfirm = (params: any) => {
      if (ImpactAssessmentsStore.newsletterNameValidateStatus !== 'success') {
         ImpactAssessmentsStore.setNewsletterNameValidator(true)
      } else {
         ImpactAssessmentsStore.createNewsletter()
         ImpactAssessmentsStore.setIsNewsletterNameModalVisible(false)
         ImpactAssessmentsStore.setNewsletterNameValidator(false)
      }
   }

   const onCancel = (params: any) => {
      ImpactAssessmentsStore.setIsNewsletterNameModalVisible(false)
      ImpactAssessmentsStore.setNewsletterNameValidator(false)
   }

   const debouncedChange = debounce((name: string) => {
      ImpactAssessmentsStore.onCreateNewsletter(name)
   }, 2000)

   const onNameChange = (params: any) => {
      ImpactAssessmentsStore.onLoadingValidateNewsLetter()
      debouncedChange(params.target.value)
   }

   const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
   }

   return (
      <Modal
         title="Enter a name for your newsletter"
         open={ImpactAssessmentsStore.isNewsletterNameModalVisible}
         onOk={onConfirm}
         onCancel={onCancel}
         okText="Create Newsletter"
         cancelButtonProps={{
            style: {
               borderRadius: '10px',
            },
            icon: <Clear />,
         }}
         okButtonProps={{
            style: {
               backgroundColor: '#fa8c16',
               borderColor: 'rgb(250, 140, 22)',
               borderRadius: '10px',
               color: 'white',
            },
            icon: <FileAddOutlined />,
            disabled:
               ImpactAssessmentsStore.newsletterNameValidateStatus !==
               'success',
         }}
         destroyOnClose={true}
         width="40%"
      >
         <Row>{/* <Text> Please give a comment before delete </Text> */}</Row>
         <Row style={{ marginTop: '2%' }}>
            <Form style={{ width: '100%' }} layout="vertical">
               <Form.Item
                  {...formItemLayout}
                  style={{ marginBottom: '0' }}
                  label="Newsletter's name"
                  help={ImpactAssessmentsStore.newsletterNameError}
                  validateStatus={
                     ImpactAssessmentsStore.newsletterNameValidateStatus
                  }
                  rules={[
                     {
                        required: true,
                        message: "The newsletter's name is required",
                     },
                  ]}
               >
                  <Input
                     placeholder="Newsletter's name..."
                     onChange={onNameChange}
                  />
               </Form.Item>
            </Form>

            {/*  {ImpactAssessmentsStore.newsletterNameValidator && (
               <span style={{ color: 'red', fontSize: '13px' }}>
                  The newsletter's name is required{' '}
               </span>
            )} */}
         </Row>
      </Modal>
   )
})

export default NewsletterNameModal
