import { useEffect, useState } from 'react'
import './lineCharts.scss'
import { Row, Col, Card, Skeleton, Select } from 'antd'
import { RangeValue } from '../CustomRangePicker'
import { observer } from 'mobx-react'
import { useStore } from '../../../../../context'
import moment from 'moment'
import Widget from '../Widgets/Widget'
import AreaCharts from './AreaCharts'
import { parseDates } from '../../../../../utils/Helpers'

interface IDueAllocation {
   dates: RangeValue
   admin: string | null | undefined
}

const selectOptions = [
   {
      value: 'OverdueAllocation',
      label: 'Overdue Assessments',
      chartName: 'Overdue Assessments',
   },
   {
      value: 'DueAllocation',
      label: 'Assessments due over the selected period',
      chartName: 'Assessments over the selected period',
   },
   {
      value: 'UpcomingAllocation',
      label: 'Next month Due Assessments',
      chartName: 'Next month Due Assessments',
   },
]

const chartInfos = {
   OverdueAllocation: {
      chartLabel: 'Overdue Assessments',
      chartName: 'Overdue Assessments',
      chartDescription:
         'This chart shows the assessments that are overdue: The due date is before today regardless the selected period',
   },
   DueAllocation: {
      chartLabel: 'Due Assessments',
      chartName: 'Due Assessments',
      chartDescription:
         'This chart shows the assessments by "Due Date" you have allocated during the selected period',
   },
   UpcomingAllocation: {
      chartLabel: 'Upcoming due assessments',
      chartName: 'Upcoming due assessments',
      chartDescription:
         'This chart shows the assessments that are due in the next 30 days (From today)',
   },
} as any

function Allocations({ dates, admin }: IDueAllocation) {
   const { DashboardStore } = useStore()

   const startDate = moment().subtract(1, 'months')
   const endDate = moment()
   const [option, setOptions] = useState('DueAllocation')

   useEffect(() => {
      const sD = dates != null && dates.length > 1 ? dates[0] : startDate
      const eD = dates != null && dates.length > 1 ? dates[1] : endDate

      DashboardStore.getDueAllocationStatistics(sD, eD, option, admin)
      return () => {}

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dates, option, admin])

   const handleSelectChange = (value: string) => {
      setOptions(value)
   }

   return (
      <Row gutter={[12, 12]}>
         <Col lg={18} md={18} sm={24}>
            <Card bordered={false}>
               <div className="lineCharts">
                  <Row justify="space-between">
                     <Col md={12}>
                        <div className="title">
                           <span className="label">
                              {chartInfos[option].chartLabel}
                           </span>
                           <span className="description">
                              {chartInfos[option].chartDescription}
                           </span>
                        </div>
                     </Col>
                     <Col md={12}>
                        <Row gutter={8} justify="end">
                           <Col>
                              <Select
                                 placeholder="select a state of allocation"
                                 style={{ width: 300 }}
                                 value={option}
                                 onChange={handleSelectChange}
                                 options={selectOptions}
                                 filterOption={(input, option) =>
                                    (option?.label ?? '')
                                       .toLowerCase()
                                       .includes(input.toLowerCase())
                                 }
                                 optionFilterProp="children"
                                 showSearch
                              />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
                  <AreaCharts
                     isLoading={DashboardStore.isDueAllocationStatistics}
                     dates={dates}
                     title={chartInfos[option].chartName}
                     items={parseDates(
                        DashboardStore.dueAllocationStatistics.data
                     )}
                  />
               </div>
            </Card>
         </Col>
         <Col sm={24} lg={6} md={6}>
            {DashboardStore.isDueAllocationStatistics ? (
               <Skeleton.Input active={true} size="large" block={true} />
            ) : (
               <Widget
                  title={chartInfos[option].chartName}
                  value={DashboardStore.dueAllocationStatistics.count}
               />
            )}
         </Col>
      </Row>
   )
}

export default observer(Allocations)
