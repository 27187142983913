import { observer } from 'mobx-react' // Or "mobx-react".
import React, { useState } from 'react'
import { useStore } from '../../../context'
import {
   Button,
   Col,
   Form,
   Input,
   InputNumber,
   Popconfirm,
   Row,
   Space,
   Table,
} from 'antd'
import { EditableCellProps, Item } from '../../../types/Types'
import {
   CloseCircleOutlined,
   DeleteOutlined,
   EditOutlined,
   PlusCircleOutlined,
   SaveOutlined,
} from '@ant-design/icons'

export interface EditableTableProps {
   deleteMessage: string
   firstColumnTitle: string
   secondColumnTitle: string
   buttonName: string
   isDraggable: boolean
}

const EditableTable = observer((props: EditableTableProps) => {
   const [form] = Form.useForm()
   const { SetttingsStore } = useStore()
   const [editingKey, setEditingKey] = useState(0)
   const isEditing = (record: Item) => record.key === editingKey
   const isPredefined = (record: Item) => record.isPredefined

   const isNewRow = (record: Item) => record.key > '0' && record.key < '1'
   const [disable, setDisable] = useState(false)
   const [updateExistingRow, setUpdateExistingRow] = useState(false)

   const editRow = (record: Partial<Item> & { key: React.Key }) => {
      form.setFieldsValue({
         companyName: '',
         description: '',
         value: '',
         ...record,
      })
      setEditingKey(record.key)
      setUpdateExistingRow(true)
   }

   // TODO : Call Save or Update Api
   const saveRow = async (key: React.Key, isNewRow: any) => {
      try {
         const row = (await form.validateFields()) as Item
         row.value = row.value?.toString() // Find another way

         const newData = [...SetttingsStore.pageData]
         const index = newData.findIndex((item) => item.key === key)
         if (index > -1) {
            // Update or New Item Save
            const item = newData[index]
            newData.splice(index, 1, {
               ...item,
               ...row,
            })

            SetttingsStore.save(
               {
                  ...item,
                  ...row,
               },
               updateExistingRow
            )

            SetttingsStore.updatePageData(newData)
         }

         setEditingKey(0)
         setDisable(false)
      } catch (errInfo) {
         console.error('Validate Failed:', errInfo)
      }
   }

   const cancelRow = async (record: Item) => {
      if (record.description === '' && record.value === '') {
         const newData = [...SetttingsStore.pageData]
         newData.pop()
         SetttingsStore.updatePageData(newData)
      }
      setEditingKey(0)
      setDisable(false)
   }

   const deleteRow = (record: Item) => {
      SetttingsStore.delete(record)
   }

   const addNewRow = () => {
      const newKey = Math.random()
      const newRow = {
         key: newKey,
         companyName: '',
         description: '',
         value: '',
      }

      const newData = [...SetttingsStore.pageData, newRow]
      SetttingsStore.updatePageData(newData)
      setEditingKey(newKey)
      setDisable(true)
      setUpdateExistingRow(false)
      form.setFieldsValue({
         ...newRow,
      })
   }

   // If Cells is editing then show input boxes,
   const EditableCell: React.FC<EditableCellProps> = ({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
   }) => {
      const inputNode = inputType === 'number' ? <InputNumber /> : <Input />

      return (
         <td {...restProps}>
            {editing ? (
               <Form.Item
                  name={dataIndex}
                  style={{ margin: 0 }}
                  rules={[
                     ({ getFieldValue }) => ({
                        validator(_, value) {
                           return SetttingsStore.checkInput(
                              _,
                              value,
                              record,
                              props.firstColumnTitle,
                              props.secondColumnTitle
                           )
                        },
                     }),
                  ]}
               >
                  {inputNode}
               </Form.Item>
            ) : (
               children
            )}
         </td>
      )
   }

   let columns = [
      {
         title: props.firstColumnTitle,
         dataIndex: 'description',
         // width: '70%',
         editable: true,
         //  sorter: (first: Item, second: Item) =>
         //     first.description.length - second.description.length,
      },
      {
         title: props.secondColumnTitle,
         dataIndex: 'value',
         // width: '100%',
         align: 'center',
         editable: true,
         //  sorter: (first: Item, second: Item) =>
         //     parseInt(first.value) - parseInt(second.value),
      },
      {
         title: 'Operation',
         dataIndex: 'operation',
         align: 'center',
         width: '5%',
         render: (_: any, record: Item) => {
            const editable = isEditing(record)
            const _isPredefined = isPredefined(record)
            return _isPredefined ? (
               <> Predefined </>
            ) : editable ? (
               <Space>
                  <Button
                     type="link"
                     icon={<SaveOutlined />}
                     onClick={() => saveRow(record.key, isNewRow(record))}
                     style={{
                        marginTop: '-3%',
                        // backgroundColor: '#ffa940',
                        color: '#fa8c16',
                        borderRadius: '10px',
                        width: '95px',
                     }}
                  >
                     Save{' '}
                  </Button>
                  <Button
                     type="link"
                     icon={<CloseCircleOutlined />}
                     onClick={(e) => cancelRow(record)}
                     style={{
                        marginLeft: '5%',
                        marginTop: '-3%',
                        // backgroundColor: '#8c8c8c',
                        color: '#8c8c8c',
                        borderRadius: '10px',
                     }}
                  >
                     Cancel
                  </Button>
               </Space>
            ) : (
               <Space>
                  <Button
                     type="link"
                     icon={<EditOutlined />}
                     onClick={() => editRow(record)}
                     style={{
                        marginTop: '-3%',
                        // backgroundColor: '#73d13d',
                        color: '#52c41a',
                        borderRadius: '10px',
                        width: '95px',
                     }}
                  >
                     Edit{' '}
                  </Button>
                  <Popconfirm
                     title={props.deleteMessage}
                     okText="Yes"
                     cancelText="No"
                     okButtonProps={{
                        type: 'default',
                        style: { color: '#ff4d4f', backgroundColor: 'white' },
                     }}
                     cancelButtonProps={{
                        type: 'primary',
                     }}
                     onConfirm={(event) => deleteRow(record)}
                  >
                     <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{
                           marginLeft: '5%',
                           marginTop: '-3%',
                           // backgroundColor: '#ff4d4f',
                           color: '#ff4d4f',
                           borderRadius: '10px',
                        }}
                     >
                        Delete
                     </Button>
                  </Popconfirm>
               </Space>
            )
         },
      },
   ]

   function isNumeric(str: string) {
      // if (typeof str != 'string') return false // we only process strings!
      return !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
   }

   // !!!Think again on the onCell,
   const mergedColumns = columns
      .filter((e) => e.title !== '')
      .map((col: any) => {
         if (!col.editable) {
            return col
         }
         return {
            ...col,
            onCell: (record: any) => ({
               record,
               inputType:
                  isNumeric(record.value) && col.dataIndex === 'value'
                     ? 'number'
                     : col.dataIndex === 'group'
                     ? 'dropdown'
                     : 'text',
               // : 'text',
               dataIndex: col.dataIndex,
               title: col.title,
               editing: isEditing(record),
            }),
         }
      })

   return (
      <>
         <Row>
            <Form form={form} component={false}>
               <Table
                  style={{ width: '70%', marginLeft: '15%' }}
                  components={{
                     body: {
                        cell: EditableCell,
                     },
                  }}
                  bordered
                  dataSource={SetttingsStore.pageData}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  // pagination={{
                  //    onChange: cancel,
                  // }}
               />
            </Form>
         </Row>
         <br />
         <Row>
            <Col span={9}></Col>

            <Col span={15}>
               <Button
                  // type="primary"
                  onClick={addNewRow}
                  disabled={disable}
                  icon={<PlusCircleOutlined />}
                  style={{
                     backgroundColor: '#fa8c16',
                     borderColor: '#fa8c16',
                     color: 'white',
                     borderRadius: '10px',
                     width: '32%',
                     marginLeft: '2%',
                  }}
                  // className={buttonClass}
               >
                  {props.buttonName}
               </Button>
            </Col>
         </Row>
      </>
   )
})

export default EditableTable
