import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import { Table } from 'antd'

const tableColumns = [
   {
      title: 'Name',
      dataIndex: 'excelPreferenceName',
      width: '100%',
      align: 'left',
   },
] as any

interface IExcelPreferenceModal {}
export const ExcelPreferenceModal = observer((props: IExcelPreferenceModal) => {
   const { ImpactAssessmentsStore } = useStore()
   const rowSelection = {
      onSelect: (
         record: any,
         selected: boolean,
         selectedRows: any,
         nativeEvent: any
      ) => {
         ImpactAssessmentsStore.setExcelPreferenceId(record.excelPreferenceId)
      },
   }

   return (
      <>
         <br />
         <Table
            size="small"
            columns={tableColumns}
            rowKey={(record) => record.excelPreferenceId}
            rowSelection={{
               type: 'radio',
               ...rowSelection,
            }}
            dataSource={ImpactAssessmentsStore.userExcelPreferences}
         ></Table>
      </>
   )
})
