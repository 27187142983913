import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Spin, Row, Col, Alert } from 'antd'
import { Typography } from 'antd'
import EditableTable from './components/EditableTable'
const { Title } = Typography
interface IAssessmentStatus {
   endpoint: string
}

const AssessmentStatus = observer((props: IAssessmentStatus) => {
   const { SetttingsStore } = useStore()
   const pageTitle = 'Assessment Status'

   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)
      SetttingsStore.endpoint = props.endpoint
      SetttingsStore.getPageData()
      return () => {}
   }, [])

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <Spin spinning={SetttingsStore.isLoading}>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            {SetttingsStore.isError && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={SetttingsStore.errorMessage}
                        type={SetttingsStore.errorType}
                        showIcon
                     />
                  </Col>
               </Row>
            )}{' '}
            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3}>{pageTitle}</Title>
               </Col>
               <Col style={{ textAlign: 'right' }} span={8}>
                  <Title
                     level={5}
                     style={{ marginLeft: '18%', color: '#8c8c8c' }}
                  >
                     <strong>Company Name:</strong> Reg-Room
                  </Title>
               </Col>
            </Row>
            <Row style={{ marginTop: '5%' }}>
               <Col span={24}>
                  <EditableTable
                     firstColumnTitle="Description"
                     secondColumnTitle="Status"
                     deleteMessage="Your assessment status will be deleted permanently, would you like to continue?"
                     buttonName="Add New Status"
                     isDraggable={false}
                  />
               </Col>
            </Row>
            <br />
            <br />
         </Spin>
      )
})
export default AssessmentStatus
