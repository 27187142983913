import {AssessmentAttachedFileType} from "../../../types";
import "./style.scss";
import attachIcon from "./../../../assets/attach_icon.svg"
import {Button} from "antd";
import {urlToServer} from "../../../helpers";

type Props = {
    item: AssessmentAttachedFileType
}
export const FileItem = ({item}: Props) => {
  return <div className="file-item">
      <img src={attachIcon} className="file-item-img" alt=""/>
      <div className="file-item-name">
          {item.fileName}
      </div>
      <Button
          type="link"
          className="file-item-btn"
          href={urlToServer(item.filePath)}
          download
      >
          Download
      </Button>
  </div>
}