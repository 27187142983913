import { useEffect } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Row, Button, Form, Col, Spin, Divider, Input } from 'antd'

export interface IRenewPassword {}

const layout = {
   labelCol: { span: 5 },
   wrapperCol: { span: 8 },
}
const tailLayout = {
   wrapperCol: { offset: 10, span: 11 },
}

const RenewPassword = observer((props: IRenewPassword) => {
   const { CompanyUsersStore } = useStore()

   const [form] = Form.useForm()

   const onUpdatePw = () => {
      CompanyUsersStore.updatePw()
      form.resetFields()
   }

   const onFinishFailed = (errorInfo: any) => {
      console.error('Failed:', errorInfo)
   }

   useEffect(() => {
      CompanyUsersStore.setShowMessage(false)
   }, [])

   return (
      <Spin spinning={CompanyUsersStore.isLoading}>
         {/* {CompanyUsersStore.showMessage && (
            <Alert
               message={CompanyUsersStore.updatePwMessage}
               type={CompanyUsersStore.errorType}
               showIcon
            />
         )} */}
         <Divider orientation="left" plain>
            <strong>Change Password </strong>
         </Divider>
         <Row>
            <Col span={1}> </Col>
            <Col span={23}>
               <Form
                  {...layout}
                  form={form}
                  name="basic"
                  onFinish={onUpdatePw}
                  onFinishFailed={onFinishFailed}
               >
                  <Form.Item
                     label="Current Password:"
                     name="currentPassword"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your current password!',
                        },
                     ]}
                     labelAlign="left"
                  >
                     <Input.Password
                        size="small"
                        onChange={(e) => CompanyUsersStore.onCurrentPwChange(e)}
                     />
                  </Form.Item>
                  <Form.Item
                     label="New Password:"
                     name="password"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your new password!',
                        },
                     ]}
                     labelAlign="left"
                  >
                     <Input.Password
                        size="small"
                        onChange={(e) => CompanyUsersStore.onNewPwChange(e)}
                     />
                  </Form.Item>
                  <Form.Item
                     label="Confirm Password:"
                     name="passwordConfirm"
                     rules={[
                        {
                           required: true,
                           message: 'Please confirm your new password!',
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              if (
                                 !value ||
                                 getFieldValue('password') === value
                              ) {
                                 return Promise.resolve()
                              }
                              return Promise.reject(
                                 new Error(
                                    'The two passwords that you entered do not match!'
                                 )
                              )
                           },
                        }),
                     ]}
                     labelAlign="left"
                  >
                     <Input.Password
                        size="small"
                        // onChange={(e) => SetttingsStore.onNewPwChange(e)}
                     />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                     <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                           borderRadius: '10px',
                           backgroundColor: 'rgb(250, 140, 22)',
                           borderColor: 'rgb(250, 140, 22)',
                           color: 'white',
                           // marginLeft: '-2%',
                        }}
                     >
                        Save Password
                     </Button>
                  </Form.Item>
               </Form>
            </Col>
         </Row>
      </Spin>
   )
})
export default RenewPassword
