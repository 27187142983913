import { makeAutoObservable } from 'mobx'

export default class FilterObjectDto {
   filters: any[] = []
   totalAllowedFilterCount: number = 0

   pageNumber: number = 0;
   pageSize: number = 0;
   totalRecords: number = 0;
   totalPages: number = 0;

   constructor() {
      makeAutoObservable(this)
   }
}
