import React from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import { Row, Col, Table, Typography, Tag, Divider, Alert, Card } from 'antd'
import { RemoveAllTagFromString } from '../../../utils/Helpers'

const { Text } = Typography
const reportTableColumns = [
   {
      title: 'Date',
      dataIndex: 'createdDate',
      width: '11%',
   },
   {
      title: 'Actioned by',
      dataIndex: 'userFullName',
      width: '12%',
   },
   {
      title: 'Comment',
      dataIndex: 'content',
      render: (e: any, record: any) =>
         RemoveAllTagFromString(record.content).replaceAll('&nbsp;', ''),
   },
   {
      title: 'Risk Level',
      dataIndex: 'riskLevel',
      width: '10%',
   },
   {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
   },
   {
      title: 'Topic',
      dataIndex: 'topic',
      width: '14%',
      render: (text: any, record: any) => (
         <>
            {record.topic.map((e: any) => (
               <Tag color="#5cdbd3"> {e.description} </Tag>
            ))}
         </>
      ),
   },
   {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: '11%',
   },
]
interface IReportModal {}
export const ReportModal = observer((props: IReportModal) => {
   const { ImpactAssessmentsStore } = useStore()

   return (
      <>
         {ImpactAssessmentsStore.isError && (
            <Row style={{ marginBottom: '1%' }}>
               <Col span={24}>
                  <Alert
                     message={ImpactAssessmentsStore.errorMessage}
                     type={ImpactAssessmentsStore.errorType}
                     showIcon
                  />
               </Col>
            </Row>
         )}
         <br />
         <Card style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
            <Row>
               <Col span={3}>
                  <Text>
                     <strong>Assigned to:</strong>
                  </Text>
               </Col>
               <Col span={6}>
                  <Text>{ImpactAssessmentsStore.reportAssignedTo}</Text>
               </Col>
               <Col span={3}>
                  <Text>
                     <strong>Risk Level:</strong>
                  </Text>
               </Col>
               <Col span={5}>
                  <Text>{ImpactAssessmentsStore.reportRiskLevel}</Text>
               </Col>
               <Col span={2}>
                  <Text>
                     <strong>Status:</strong>
                  </Text>
               </Col>
               <Col span={5}>
                  <Text>{ImpactAssessmentsStore.reportStatus}</Text>
               </Col>
            </Row>
            <Row>
               <Col span={3}>
                  <Text>
                     <strong>Due On:</strong>
                  </Text>
               </Col>
               <Col span={6}>
                  <Text>{ImpactAssessmentsStore.reportDueOn}</Text>
               </Col>
               <Col span={3}>
                  <Text>
                     <strong>Internal No:</strong>
                  </Text>
               </Col>
               <Col span={5}>
                  <Text>{ImpactAssessmentsStore.reportInternalNo}</Text>
               </Col>
               <Col span={2}>
                  <Text>
                     <strong>Topic:</strong>
                  </Text>
               </Col>
               <Col span={5}>
                  <Text>{ImpactAssessmentsStore.reportTopic}</Text>
               </Col>
            </Row>
         </Card>
         <Divider
            style={{
               backgroundColor: 'rgb(250, 140, 22)',
               opacity: '0.3',
            }}
         />
         <Table
            size="small"
            columns={reportTableColumns}
            dataSource={ImpactAssessmentsStore.reportAudit}
         ></Table>
      </>
   )
})
