import { Helpers } from '../../api/Helpers'
import { DailyRuleList } from '../../mocks/DailyRules'

export class LoginApi {
   public static login(loginData: any): Promise<any> {
      const response = Helpers.post('/auth/login', loginData)
      return response
   }

   public static SendNewPassword(loginData: any): Promise<any> {
      const response = Helpers.post('/auth/SendNewPassword', loginData)
      return response
   }

   public static SendPasswordResetUrl(loginData: any): Promise<any> {
      const response = Helpers.post('/auth/SendNewPasswordUrlTo', loginData)
      return response
   }

   public static ChangePassword(loginData: any): Promise<any> {
      const response = Helpers.post('/auth/ChangePassword', loginData)
      return response
   }
}
