import React, { useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { ReactComponent as Logo } from '../../assets/reg-impact-transp.svg'
import { useStore } from '../../context/index'
import { Form, Input, Button, Card, Row, Spin, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { ReactComponent as Back } from '../../assets/back.svg'

const { Title } = Typography

interface ISendNewPassword {
   setIsForgotPassword: any
}

const SendNewPassword = observer((props: ISendNewPassword) => {
   const { LoginStore } = useStore()
   const [
      showSendRenewURLSuccessfulMessage,
      setShowSendRenewURLSuccessfulMessage,
   ] = useState(false)

   const onSendNewPassword = (params: any) => {
      LoginStore.setRenewCredentials(params.email)
      // LoginStore.SendNewPassword()
      LoginStore.SendPasswordResetUrl()
      setShowSendRenewURLSuccessfulMessage(true)
   }

   return (
      <Card style={{ minHeight: '100vh', backgroundColor: '#ffd591' }}>
         <Row style={{ height: '100px' }}>
            <Logo height="70%" style={{ marginLeft: '1.5%' }} />
         </Row>
         <Row style={{ height: '10ch' }} />
         <Row style={{ justifyContent: 'center' }}>
            <Card style={{ width: '70ch' }}>
               {LoginStore.showLoginErrorMessage ? (
                  <span
                     style={{
                        color: 'red',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '2%',
                     }}
                  >
                     {LoginStore.errorMessage}
                  </span>
               ) : (
                  ''
               )}
               {showSendRenewURLSuccessfulMessage && (
                  <Title level={3} style={{ textAlign: 'center' }}>
                     {' '}
                     An email has been sent to you with the instructions to
                     renew your password.
                  </Title>
               )}
               {!showSendRenewURLSuccessfulMessage && (
                  <Form
                     name="normal_login"
                     className="login-form"
                     initialValues={{ remember: true }}
                     onFinish={onSendNewPassword}
                  >
                     <Form.Item
                        name="email"
                        rules={[
                           {
                              required: true,
                              message: 'Please input your e-mail!',
                           },
                        ]}
                     >
                        <Input
                           prefix={
                              <UserOutlined className="site-form-item-icon" />
                           }
                           placeholder="Email"
                        />
                     </Form.Item>
                     <Form.Item>
                        <Button
                           type="primary"
                           htmlType="submit"
                           className="login-form-button"
                           style={{
                              backgroundColor: 'rgb(250, 140, 22)',
                              borderColor: 'rgb(250, 140, 22)',
                              borderRadius: '10px',
                              color: 'white',
                              width: '100%',
                           }}
                        >
                           Send new password
                        </Button>
                        <div style={{ display: 'flex', marginTop: '1%' }}>
                           <Back />
                           <a
                              onClick={(e) => props.setIsForgotPassword(false)}
                              style={{ marginTop: '1%', marginLeft: '1%' }}
                           >
                              Login
                           </a>
                        </div>
                     </Form.Item>
                  </Form>
               )}
            </Card>
         </Row>
      </Card>
   )
})

export default SendNewPassword
