import { makeAutoObservable } from 'mobx'
import { CommonStore } from '../context/CommonStore'
import { CommonApi } from '../api/CommonApi'

export class AuthStore {
   isLoading: boolean = false
   isValid: boolean = false
   isUserAuthorized: boolean = false
   token: string = 'TESTEST'
   userFullname: string = ''
   userId: number = 0
   isAuthanticated: boolean = true
   hasAuthanticationBeenChecked: boolean = false
   userReferenceData: any = {}
   userRoles = []
   activeAssessmentCount: number = 0
   isAdmin: boolean = false
   private commonStore = new CommonStore()
   public userToken: string | null = null

   constructor(commonStore: CommonStore) {
      makeAutoObservable(this)
      this.commonStore = commonStore
   }

   public initUser() {}

   private getOAuthUser() {}

   setToken(token: string) {
      this.token = token
      // localStorage.setItem('access_token', '')
   }

   setUserId(userId: number) {
      this.userId = userId
   }

   public signIn() {}
   public logOut() {}
   public isAuthenticated() {
      // Fix this function to handle authentication on many tabs [######]      
      if (localStorage.getItem('jwt_token') === '') {
         this.isAuthanticated = false         
      } else {
         this.isAuthanticated = true
      }

      if (!this.hasAuthanticationBeenChecked) {
         CommonApi.checkIsAuthanticated()
            .then((result: any) => {
               if (result.isExpired) this.silentRenew()
               this.isAuthanticated =
                  result === '' ? true : result.isAuthenticated
            })
            .catch((err) => {
               this.isAuthanticated = false
            })
      }else{
         return;
      }

      if (this.commonStore.isAuthanticated) {
         this.isAuthanticated = true
         return true
      }

      
      this.hasAuthanticationBeenChecked = true
   }

   public silentRenew() {
      // CommonApi.silentRenew()
      //    .then((result: any) => {
      //       this.isAuthanticated = result === '' ? true : result.isAuthenticated
      //    })
      //    .catch((err) => {
      //       this.isAuthanticated = false
      //    })
   }

   public getToken() {
      return localStorage.getItem('jwt_token')
   }
}
