import {makeAutoObservable} from 'mobx'
import {CommonApi} from '../api/CommonApi'
import {deleteCookies} from "../utils/AppHelpers";

export class CommonStore {
    isLoading: boolean = false
    isValid: boolean = false
    isUserAuthorized: boolean = false
    token: string = 'TESTEST'
    userFullname: string = ''
    userId: number = 0
    isAuthanticated: boolean = true
    isAuthLoading: boolean = false;
    userReferenceData: any = {}
    userRoles = []
    activeAssessmentCount: number = 0
    isAdmin: boolean = true
    isSupAdmin: boolean = true
    isDev: boolean = true
    isLogout: boolean = false

    constructor() {
        makeAutoObservable(this);
        this.init(parseInt(localStorage.getItem('userId') || '0'));
    }

    reset() {
        this.isLoading = false
        this.isValid = false
        this.isUserAuthorized = false
        this.token = 'TESTEST'
        this.userFullname = ''
        this.userId = 0
        this.isAuthanticated = false
        this.userReferenceData = {}
        this.userRoles = [];
        this.activeAssessmentCount = 0
        this.isAdmin = true
        this.isSupAdmin = true
        this.isDev = true
    }

    setToken(token: string) {
        this.token = token
    }

    setUserId(userId: number) {
        this.userId = userId
    }

    public init(userId: number) {
        this.isValid = false
        this.isAuthLoading = true;
        CommonApi.init(userId)
            .then((result) => {
                this.isValid = true

                this.userReferenceData.user = result.user
                this.userRoles = result.user.roles
                let roleNames = result.user.roles.map((e: any) => e.roleName)
                this.userFullname = result.user.name + ' ' + result.user.surname
                this.activeAssessmentCount = result.activeAssessmentCount
                localStorage.setItem('userRoles', roleNames.join())
                this.isAdmin = localStorage.getItem('isAdmin') === 'true'
                this.isSupAdmin = localStorage.getItem('isSupAdmin') === 'true'
                this.isDev = localStorage.getItem('isDev') === 'true'

                this.isAuthanticated = true;
            })
            .catch((err) => {
                this.isValid = false
                this.logout();
            })
            .finally(() => {
                setTimeout(() => {
                    this.isAuthLoading = false
                }, 1000)
            });
    }

    public logOut() {
        this.isAuthanticated = false;

        CommonApi.logOut()
            .then((result) => {
                this.isLogout = true;
                this.logout();
            })
            .catch((err) => {
                this.isValid = false
                this.isLogout = false
                this.isAuthanticated = true;
            })
    }

    public getToken() {
        return localStorage.getItem('jwt_token')
    }

    public logout() {
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("userId");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("isSupAdmin");
        localStorage.removeItem("isSupAdmin");
        localStorage.removeItem("isDev");
        localStorage.removeItem("hasUserRole");
        deleteCookies('jwt_token', '/', 'localhost')
        this.reset();
    }
}
