import { makeAutoObservable } from 'mobx'
import { LoginApi } from './LoginApi'
import { CommonStore } from '../../context/CommonStore'
import { toast } from 'react-hot-toast'

export class LoginStore {
   email: string | undefined
   password: string | undefined
   isLoading: boolean = false
   isUserValid: boolean = false
   jwt: string = ''
   showLoginErrorMessage = false
   errorMessage: string = ''
   isAdmin: boolean = false
   isDev: boolean = false

   private commonStore = new CommonStore()

   constructor(commonStore: CommonStore) {
      makeAutoObservable(this)
      this.commonStore = commonStore
   }

   loginData(): any {
      return {
         email: this.email,
         password: this.password,
         token: this.renewToken,
      }
   }

   async login() {
      this.isLoading = true

      try {
         var result = await LoginApi.login(this.loginData())

         if (result.errorCode === 0) {
            this.commonStore.init(result.userId)

            this.jwt = result.jwt
            let isAdmin =
               result.userRoles.findIndex(
                  (e: any) =>
                     e.roleName === 'Admin' || e.roleName === 'SuperAdmin'
               ) >= 0
            let isSupAdmin =
               result.userRoles.findIndex(
                  (e: any) => e.roleName === 'SuperAdmin'
               ) >= 0
            let isDev =
               result.userRoles.findIndex((e: any) => e.roleName === 'Dev') >= 0
            this.isAdmin = isAdmin
            this.commonStore.isAdmin = isAdmin
            this.commonStore.isAuthanticated = true
            localStorage.setItem('jwt_token', result.jwt)
            localStorage.setItem('userId', result.userId)
            localStorage.setItem('isAdmin', isAdmin ? 'true' : 'false')
            localStorage.setItem('isSupAdmin', isSupAdmin ? 'true' : 'false')
            localStorage.setItem('isDev', isDev ? 'true' : 'false')

            let hasUserRole = result.userRoles.length !== 0 ? 'true' : 'false'
            this.isUserValid = true
            this.showLoginErrorMessage = false
            localStorage.setItem('hasUserRole', hasUserRole)
         } else {
            this.jwt = ''
            this.isUserValid = false
            /*   this.showLoginErrorMessage = true
            this.errorMessage = result.additionalMessage
            this.messageColor = 'red' */
            this.showErrorMessage(result.additionalMessage)
         }
      } catch (e) {
         console.error(e)

         this.jwt = ''
         // this.connectionErrorMessageVisible = true
         // this.alertMessage = 'There has been a connection error we were unable to recover from. Please refresh the page and try again. If the issue persists, inform us at info@reg-track.com. It might be because your session has expired. Use the \"Logout\" button at the upper right hand corner of your screen to log out and then, log back in again.'
         this.isLoading = false
         this.isUserValid = false
         /* this.showLoginErrorMessage = true // TODO : show proper error message.
         this.errorMessage =
            'There has been a connection error we were unable to recover from. Please refresh the page and try again. If the issue persists, inform us at info@reg-track.com. It might be because your session has expired. Use the \"Logout\" button at the upper right hand corner of your screen to log out and then, log back in again.' */
         this.showErrorMessage(result.additionalMessage)
      } finally {
         this.isLoading = false
      }
   }

   setLoginCredentials(email: string, password: string) {
      this.email = email
      this.password = password
   }

   setRenewCredentials(email: string) {
      this.email = email
   }
   setShowLoginErrorMessage = (val: boolean) => {
      this.showLoginErrorMessage = val
   }
   setIsPasswordRenewed = (val: boolean) => {
      this.isPasswordRenewed = val
   }
   onNewPwChange(e: any) {
      this.password = e.target.value
   }

   isPasswordRenewed: boolean = false
   messageColor: string = 'red'
   SendNewPassword() {
      LoginApi.SendNewPassword(this.loginData())
         .then((result) => {
            // this.isPasswordRenewed = true
            // this.showLoginErrorMessage = true
            this.errorMessage =
               'Your new password sent to the your e-mail address.'
            this.messageColor = '#52c41a'
         })
         .catch((err) => {
            this.jwt = ''
            // this.connectionErrorMessageVisible = true
            // this.alertMessage = 'There has been a connection error we were unable to recover from. Please refresh the page and try again. If the issue persists, inform us at info@reg-track.com.'
            this.isLoading = false
            this.isUserValid = false
            this.showLoginErrorMessage = true // TODO : show proper error message.
            this.errorMessage =
               'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
         })
   }

   showErrorMessage(
      message: string = 'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.',
      id: string = 'error'
   ) {
      toast.error(message, { id })
   }

   SendPasswordResetUrl() {
      LoginApi.SendPasswordResetUrl(this.loginData())
         .then((result) => {
            // this.isPasswordRenewed = true
            // this.showLoginErrorMessage = true
            this.errorMessage =
               'Please check your e-mail to change your password.'
            this.messageColor = '#52c41a'
         })
         .catch((err) => {
            this.jwt = ''
            // this.connectionErrorMessageVisible = true
            // this.alertMessage = 'There has been a connection error we were unable to recover from. Please refresh the page and try again. If the issue persists, inform us at info@reg-track.com.'
            this.isLoading = false
            this.isUserValid = false
            this.showLoginErrorMessage = true // TODO : show proper error message.
            this.errorMessage =
               'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
         })
   }

   renewToken: string = ''
   setRenewToken(val: string) {
      this.renewToken = val
   }

   changePassword() {
      LoginApi.ChangePassword(this.loginData())
         .then((result) => {
            this.isPasswordRenewed = true
            this.showLoginErrorMessage = true
            this.errorMessage =
               'Please check your e-mail to change your password.'
            this.messageColor = '#52c41a'
         })
         .catch((err) => {
            this.jwt = ''
            // this.connectionErrorMessageVisible = true
            // this.alertMessage = 'There has been a connection error we were unable to recover from. Please refresh the page and try again. If the issue persists, inform us at info@reg-track.com.'
            this.isLoading = false
            this.isUserValid = false
            this.showLoginErrorMessage = true // TODO : show proper error message.
            this.errorMessage =
               'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
         })
   }

   validateInputs() {}

   ClearState() {}
}
