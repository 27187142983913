import toast from 'react-hot-toast'
import { add, format, differenceInCalendarDays } from 'date-fns'
import { AreaChartItem } from '../features/dashboard/components/charts/AreaCharts/AreaCharts'

export const warningStyle = {
   style: {
      background: '#FFA900',
      padding: '16px',
      color: '#FFF',
   },
   iconTheme: {
      primary: '#FFA900',
      secondary: '#FFFAEE',
   },
   icon: '⚠️',
}

export const alertMessages = {
   error500:
      'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.',
   error400: 'Bad request !',
   error404: 'Page not found !',
   error511:
      'It looks like your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again.',
}

export const getErrorMessage = (error: any) => {
   if (isDev()) {
      console.log(error)
      console.log(error.data)
   }

   if (error && error.data && error.data.additionalMessage) {
      return error.data.additionalMessage
   }
   return alertMessages.error500
}

export const isDev = () => {
   return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

export const isEmpty = (value: any) =>
   value === undefined ||
   value === null ||
   (typeof value === 'object' && Object.keys(value).length === 0) ||
   (typeof value === 'string' && value.trim().length === 0)

export const displayError = (error: any) => {
   if (isDev()) {
      console.log(error)
      console.log(error.response)
   }
   if (error && error.response) {
      switch (error.response.status) {
         case 511:
            console.log('url', error.response.config.url)
            if (
               error.response.config.url.indexOf('checkIsAuthanticated') === -1
            ) {
               toast.error(alertMessages.error511, { id: 'error' })
            }
            break
         default:
            toast.error(alertMessages.error500, { id: 'error' })
            break
      }
      return
   }

   if (error && error.response && error.response.data) {
      const message = error.response.data.additionalMessage
      if (!isEmpty(message)) {
         if (typeof message === 'object') {
            toast.error(message, { id: 'error' })
         } else {
            toast.error(message, { id: 'error' })
         }
      } else if (typeof message === 'string') {
         toast.error(message, { id: 'error' })
      } else {
         toast.error(alertMessages.error500, { id: 'error' })
      }
   }

   toast.error(alertMessages.error500, { id: 'error' })
}

export const getNextWeekDate = (defaultResDue: number) => {
   const today = new Date()

   let nextWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + defaultResDue * 7,
      23,
      59,
      59
   )
   return nextWeek
}

// Move to Helper
export const disabledDate = (current: any, dates: any, maxDays = 180) => {
   if (!dates || dates.length === 0) {
      return false
   }

   const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDays
   const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDays
   return tooEarly || tooLate
}

export const getToday = () => {
   const today = new Date()
   return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
}

export const addClassToAllLi = (val: any, className: string) => {
   if (!val) {
      return '<span>No Data</span>'
   }
   let splittedVal = val.split('<li><strong>')
   splittedVal.forEach((element: any, index: number, itSelf: any) => {
      itSelf[index] = element.replaceAll(
         '<li>',
         "<li class='" + className + "'>"
      )
   })
   return splittedVal.join('<li><strong>')
}

export const RemoveAllTagFromString = (input: string) => {
   return input.replace(/(<([^>]+)>)/gi, '')
}

export const addClassToAllDrawerLi = (val: any, isAudit: boolean = false) => {
   if (!val || val.length < 9) {
      return '<span>No Data</span>'
   }
   const countCriteriaOnTheLastLine = isAudit ? 60 : 75
   let withoutUl = val.slice(4, val.length - 5)

   let splittedByHeaderVal = withoutUl.trim().split('<li')
   splittedByHeaderVal.forEach((element: any, index: number, itSelf: any) => {
      if (element)
         if (element.includes('<strong>')) {
            itSelf[index] = '<li class="subHeaderOnsummary"' + element
         } else {
            let tagClearedLi = RemoveAllTagFromString(element)

            if (tagClearedLi.length > 0 && tagClearedLi.length < 60)
               itSelf[index] = '<li class="textAlignLeft"' + element
            else if (tagClearedLi.length > 59 && tagClearedLi.length < 95)
               itSelf[index] = '<li' + element
            else if (
               tagClearedLi.length > 96 &&
               tagClearedLi.length % 96 < countCriteriaOnTheLastLine
            )
               itSelf[index] = '<li class="textAlignLeft"' + element
            else itSelf[index] = '<li' + element
         }
   })
   return '<ul>' + splittedByHeaderVal.join('') + '</ul>'
}

export const IsUserSuperAdmin = () => {
   let roles = localStorage.getItem('userRoles') ?? ''
   return roles.includes('SuperAdmin')
}

export const hasUserRight = (filterLevelId: number) => {
   let roles = localStorage.getItem('userRoles') ?? ''
   switch (filterLevelId) {
      case (filterLevelId = 1):
         let isAtLeastSuperAdmin = roles.includes('SuperAdmin')
         return isAtLeastSuperAdmin

      case (filterLevelId = 2):
         let isAtLeastAdmin =
            roles.includes('SuperAdmin') || roles.includes('Admin')
         return isAtLeastAdmin

      case (filterLevelId = 3):
         let isAtLeastSupervisor =
            roles.includes('SuperAdmin') ||
            roles.includes('Admin') ||
            roles.includes('Supervisor')
         return isAtLeastSupervisor

      case (filterLevelId = 4):
         return true
      default:
         return true
   }
}

export const removeItem = <T,>(arr: Array<T>, value: T): Array<T> => {
   const index = arr.indexOf(value)
   if (index > -1) {
      arr.splice(index, 1)
   }
   return arr
}

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
   const byteCharacters = atob(b64Data)
   const byteArrays = []

   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
         byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
   }

   const blob = new Blob(byteArrays, { type: contentType })
   return blob
}

export const formatNumber = (number: number, format = 'en-GB'): string => {
   return new Intl.NumberFormat(format).format(number)
}

/**
 * get the dates between `startDate` and `endSate` with equal granularity
 */
export const getTicks = (startDate?: Date, endDate?: Date, num?: number) => {
   if (endDate === null || typeof endDate === 'undefined') {
      endDate = new Date()
   }

   if (startDate === null || typeof startDate === 'undefined') {
      startDate = add(new Date(), { days: -7 })
   }

   if (num === null || typeof num === 'undefined') {
      num = 8
   }

   const diffDays = differenceInCalendarDays(endDate, startDate)
   if (diffDays + 1 < num) {
      num = diffDays + 1
   }
   let current = startDate,
      velocity = Math.round(diffDays / (num - 1))

   const ticks = [startDate.getTime()]

   for (let i = 1; i < num - 1; i++) {
      ticks.push(add(current, { days: i * velocity }).getTime())
   }

   ticks.push(endDate.getTime())

   return ticks
}

export const dateFormatter = (date: any, dateFormat: string = 'yyyy-MM-dd') => {
   if (typeof date === 'undefined' || date === null) return ''
   return format(new Date(date), dateFormat)
}

/**
 * Add data of the date in ticks,
 * if there is no data in that date in `data`.
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
export const fillTicksData = (_ticks: any[], data: any[]) => {
   const ticks = [..._ticks]
   const filled = []
   let currentTick = ticks.shift()
   let lastData = null

   for (const it of data) {
      if (ticks.length && it.date > currentTick && lastData) {
         filled.push({ ...lastData, ...{ date: currentTick } })
         currentTick = ticks.shift()
      } else if (ticks.length && it.date === currentTick) {
         currentTick = ticks.shift()
      }

      filled.push(it)
      lastData = it
   }

   return filled.sort((a, b) => {
      if (a.date < b.date) return -1
      if (a.date > b.date) return 1
      return 0
   })
}

/**
 * Convert a list of dates into list of ticks
 *
 * @param Array<number> _ticks
 * @param {*} data
 */
export const convertDatesToTicks = (elts: any[]) => {
   return elts.map((elt) => {
      return { ...elt, date: Date.parse(elt.date) }
   })
}

export const parseDates = (data: any[]): AreaChartItem[] => {
   if (!data || !Array.isArray(data)) return []
   return data.map((item) => {
      return { x: item.date, y: item.value }
   })
}

export function compareVersions(v1: string, v2: string) {
   // Split the versions by dots
   const parts1 = v1.split('.').map(Number)
   const parts2 = v2.split('.').map(Number)

   // Compare major, minor, then patch versions
   for (let i = 0; i < 3; i++) {
      if (parts1[i] > parts2[i]) return 1 // v1 is greater than v2
      if (parts1[i] < parts2[i]) return -1 // v1 is less than v2
   }

   // If the loop completes without returning, the versions are the same
   return 0
}
