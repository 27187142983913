import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from 'antd/lib/layout'
import SiderMenu from './SiderMenu'
import { HeaderBar } from './HeaderBar'
import { observer } from 'mobx-react' // Or "mobx-react".
import ContentLayout from './ContentLayout'

import { useVersionCheck } from '../../utils/hooks'

import './LayoutStyle.scss'
const { Footer } = Layout

const MainLayout = observer(() => {
   const [collapsed, setCollapsed] = useState(false)
   const history = useHistory()

   const handleOnCollapse = () => {
      setCollapsed(history.location.pathname === '/ImpactAssessments')
   }
   const currentYear = () => {
      return new Date().getFullYear()
   }

   useEffect(() => {
      handleOnCollapse()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   //useVersionCheck();

   return (
      <Layout style={{ minHeight: '100vh', minWidth: '200vh' }}>
         {/* // Re-size minWitdh acording to users screen-size */}
         <SiderMenu collapsed={collapsed} handleOnCollapse={handleOnCollapse} />
         <Layout className="site-layout">
            <HeaderBar
               collapsed={collapsed}
               handleOnCollapse={(e: any) => setCollapsed(!collapsed)}
            ></HeaderBar>
            <ContentLayout></ContentLayout>
            <Footer style={{ textAlign: 'center' }} data-testid="footer">
               Reg Impact ©{currentYear()}
            </Footer>
         </Layout>
      </Layout>
   )
})

export default MainLayout
