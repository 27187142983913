import { makeAutoObservable } from 'mobx'
import { CommonStore } from '../context/CommonStore'
import { CommonApi } from '../api/CommonApi'

export class MainLayoutStore {
   isAdmin: boolean = false
   locationPath: string = ''
   orgChart: any = { name: 'Company-Name', children: {} }
   isLoading: boolean = false
   public commonStore: CommonStore

   constructor(commonStore: CommonStore) {
      makeAutoObservable(this)
      this.commonStore = commonStore
      this.isAdmin = localStorage.getItem('isAdmin') === 'true'
   }

   getOrganisationGraph() {
      this.isLoading = true
      CommonApi.getOrganisationGraph()
         .then((result: any) => {
            this.orgChart = result.children
            this.isLoading = false
         })
         .catch((err) => {
            this.isLoading = false
         })
   }

   setLocationPath(val: string) {
      this.locationPath = val
   }
}
