import { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import {
   Row,
   Col,
   Modal,
   Input,
   Button,
   Form,
   Typography,
   Transfer,
} from 'antd'

import { SaveOutlined } from '@ant-design/icons'

const { Text } = Typography

export interface IExcelPreferences {}

const ExcelPreferences = observer((props: IExcelPreferences) => {
   const { SetttingsStore } = useStore()

   const defaultColumnList = [
      { key: 1, title: 'Rule Id' },
      { key: 2, title: 'Internal No' },
      { key: 3, title: 'Title' },
      { key: 4, title: 'Admin Name' },
      { key: 5, title: 'Allocated on' },
      { key: 6, title: 'Allocated To' },
      { key: 7, title: 'Response Due by' },
      { key: 8, title: 'Changed On' },
      { key: 9, title: 'Risk level' },
      { key: 10, title: 'Status' },
      { key: 11, title: 'Topic' },
      { key: 12, title: 'Comment' },
      { key: 13, title: 'Summary' },
      { key: 14, title: 'Reference ' },
      { key: 15, title: 'Rule Type ' },
      { key: 16, title: 'Rule Change Type' },
      { key: 17, title: 'Last Update Date' },
      { key: 18, title: 'Rule Date' },
      { key: 19, title: 'Effective Date' },
      { key: 20, title: 'Regions' },
      { key: 21, title: 'Countries' },
      { key: 22, title: 'Regulators' },
      { key: 23, title: 'Functions' },
      { key: 24, title: 'Products' },
      { key: 25, title: 'Entity Types' },
      { key: 26, title: 'Rule Primary Link' },
      { key: 27, title: 'Update Primary Link' },
      { key: 28, title: 'Historic Summary' },
      { key: 32, title: 'Reg. Last Update' },
   ] as any

   const [selectedNewKeys, setSelectedNewKeys] = useState([] as string[])

   const onSelectChange = (
      sourceSelectedKeys: string[],
      targetSelectedKeys: string[]
   ) => {
      setSelectedNewKeys([...sourceSelectedKeys, ...targetSelectedKeys])
   }
   const onChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
      SetttingsStore.setTargetKeys(nextTargetKeys)
      var ww = Object.assign({}, SetttingsStore.selectedExcelPreference, {
         excelPreferenceName: SetttingsStore.preferenceName,
         selectedColumKeys: nextTargetKeys,
      })
      SetttingsStore.setSelectedExcelPreference(ww)
   }

   useEffect(() => {
      SetttingsStore.getUserExcelPreferences()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onModalOk = () => {
      if (SetttingsStore.preferenceFormValid()) {
         showTitle
            ? SetttingsStore.onAddExcelPreference()
            : SetttingsStore.updateExcelPreference()

         SetttingsStore.setIsExcelPreferenceModalVisible(false)
         SetttingsStore.setIsError(false)
         SetttingsStore.clearExcelPreferenceForm()
      } else {
         SetttingsStore.setIsError(true)
         SetttingsStore.setErrorMessage('Please select at least 1 column.')
      }
   }

   const getExcelPreferenceNames = (keys: any) => {
      if (keys) {
         var selectedItems = defaultColumnList.filter((e: any) => {
            return keys.includes(e.key)
         })
         return selectedItems.map((e: any) => e.title).join(', ')
      }
      return ''
   }

   const onDeleteButton = (excelPreference: any) => {
      SetttingsStore.deleteExcelPreference(excelPreference.excelPreferenceId)
   }
   const onUpdateButton = (excelPreference: any) => {
      setShowTitle(false)
      SetttingsStore.setSelectedExcelPreference(excelPreference)
      SetttingsStore.setSelectedExcelPreferenceId(
         excelPreference.excelPreferenceId
      )
      SetttingsStore.setIsExcelPreferenceModalVisible(true)
   }

   const [showTitle, setShowTitle] = useState(false)
   const onAddExcelPreference = () => {
      setShowTitle(true)
      SetttingsStore.setIsExcelPreferenceModalVisible(true)
   }

   const footerButtons = [
      <Button
         key="back"
         style={{
            backgroundColor: 'rgb(250, 140, 22)',
            borderColor: 'rgb(250, 140, 22)',
            borderRadius: '10px',
            color: 'white',
         }}
         onClick={(e: any) => onModalOk()}
         /* disabled={!SetttingsStore.preferenceFormValid()} */
      >
         {showTitle ? 'Add Excel Preference' : 'Update Excel Preference'}
      </Button>,
   ]
   const preferences = SetttingsStore.userExcelPreferences?.map((e: any) => {
      return (
         <Row style={{ marginBottom: '1%' }}>
            <Col span={1}></Col>
            <Col span={7}>{e.excelPreferenceName}</Col>
            <Col span={6}>{getExcelPreferenceNames(e.selectedColumKeys)}</Col>

            <Col span={6}>
               <Button
                  type="link"
                  style={{
                     color: 'rgb(250, 140, 22)',
                  }}
                  onClick={(x: any) => onUpdateButton(e)}
                  icon={<SaveOutlined />}
               >
                  <span style={{ textDecoration: 'underline' }}> Update </span>
               </Button>
               {e.excelPreferenceName.toUpperCase().trim() !== 'Default Excel export preferences'.toUpperCase().trim() ? (
                  <Button
                     type="link"
                     style={{
                        color: 'rgb(250, 140, 22)',
                     }}
                     onClick={(x: any) => onDeleteButton(e)}
                     icon={<SaveOutlined />}
                  >
                     <span style={{ textDecoration: 'underline' }}>
                        {' '}
                        Delete{' '}
                     </span>
                  </Button>
               ) : null}
            </Col>
         </Row>
      )
   })

   return (
      <>
         <br />
         <Row>
            <Col span={1}></Col>
            <Col span={6}>
               <Button
                  type="link"
                  style={{
                     color: 'rgb(250, 140, 22)',
                  }}
                  onClick={onAddExcelPreference}
                  icon={<SaveOutlined />}
               >
                  <span style={{ textDecoration: 'underline' }}> Add </span>
               </Button>
            </Col>
         </Row>

         {preferences}

         <Modal
            title="Excel Column Preference"
            style={{ backgroundColor: 'grey' }}
            open={SetttingsStore.isExcelPreferenceModalVisible}
            footer={footerButtons}
            onCancel={() => SetttingsStore.clearHighlightObject()}
            destroyOnClose={true}
            width={724}
            maskClosable={true}
            closable={true}
         >
            {showTitle && (
               <Form preserve={false}>
                  <Form.Item
                     validateStatus={
                        SetttingsStore.preferenceNamevalidateStatus
                     }
                     help={SetttingsStore.preferenceNameError}
                  >
                     <Input
                        placeholder="Title..."
                        value={SetttingsStore.preferenceName}
                        onChange={(e) =>
                           SetttingsStore.setPreferenceName(e.target.value)
                        }
                     />
                  </Form.Item>
               </Form>
            )}
            <Transfer
               dataSource={defaultColumnList}
               showSearch
               titles={['Unselected Columns', 'Selected Columns']}
               targetKeys={
                  SetttingsStore.selectedExcelPreference.selectedColumKeys
               }
               selectedKeys={selectedNewKeys}
               operations={['Add', 'Remove']}
               listStyle={{
                  width: 250,
                  height: 300,
               }}
               onChange={onChange}
               onSelectChange={onSelectChange}
               render={(item: any) => item.title}
            />
            {!SetttingsStore.preferenceColumnSelectedValid ? (
               <Text type="danger">Please choose one preference</Text>
            ) : null}
         </Modal>
      </>
   )
})

export default ExcelPreferences
