import { makeAutoObservable } from 'mobx'

export default class FilterDto {
   selectedCountryCodes: string[] = []
   selectedRegulatorCodes: string[] = []
   selectedEntityTypeCodes: string[] = []
   selectedRuleTypeCodes: string[] = []
   selectedFunctionCodes: string[] = []
   selectedProductCodes: string[] = []
   selectedRegionCodes: string[] = []
   freeTextFilter: string = ''
   searchByReference: string = ''
   filterName: string = ''
   filterId: number = 0
   filterLevel: any = { Code: '4', Name: 'User' }

   constructor() {
      makeAutoObservable(this)
   }
}
