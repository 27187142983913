import { Spin, Upload, UploadProps } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/es/upload/interface'

import './style.scss'
import { Helpers } from '../../../api/Helpers'
import toast from 'react-hot-toast'

type Props = {
   onFileChange: (file: RcFile) => void
   onFileDelete: (file: UploadFile) => void
   fileList: UploadFile[]
}

const { Dragger } = Upload

export const DragAndDrop = ({
   onFileChange,
   onFileDelete,
   fileList,
}: Props) => {
   const [excludes, setExcludes] = useState<string[]>([])
   const [isLoading, setIsLoading] = useState(true)

   const ext = useMemo(() => excludes.map((e) => `.${e.trim()}`).join(','), [
      excludes,
   ])

   useEffect(() => {
      Helpers.get('/Common/GetSetting/APP:ATTACHEMENT:EXTENSIONS')
         .then((value) => {
            setExcludes(
               (value.value as string)
                  .replace(' ', '')
                  .split(',')
                  .map((e) => e.trim())
            )
         })
         .finally(() => setIsLoading(false))
   }, [])

   const handleBeforeFileUpload = useCallback(
      (file) => {
         const ext = file.name.split('.').pop() ?? ''
         if (excludes.includes(ext)) onFileChange(file)
         else {
            toast.error('This file is not supported !')
         }
         
         return false
      },
      [excludes, onFileChange]
   )

   const props = useMemo<UploadProps>(
      () => ({
         name: 'file',
         multiple: true,
         beforeUpload: handleBeforeFileUpload,
         onRemove: onFileDelete,
         fileList,
         showUploadList: {
            showDownloadIcon: true,
            downloadIcon: 'Download',
            showRemoveIcon: true,
         },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onFileChange, onFileDelete, fileList, handleBeforeFileUpload]
   )
   return (
      <Spin spinning={isLoading}>
         <Dragger {...props} accept={ext} className="flex-column-parent">
            <p className="ant-upload-drag-icon">
               <InboxOutlined />
            </p>
            <p className="ant-upload-text">
               Click or drag file to this area to upload
            </p>
            <p
               className="ant-upload-hint"
               style={{
                  maxWidth: 304,
               }}
            >
               Support for a single or bulk upload and {ext} file extensions.
            </p>
         </Dragger>
      </Spin>
   )
}
