import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import {
   Affix,
   Alert,
   Badge,
   Button,
   Card,
   Col,
   ConfigProvider,
   DatePicker,
   Divider,
   Input,
   Modal,
   Popover,
   Row,
   Select,
   Space,
   Spin,
   Switch,
   Table,
   Tag,
   Tooltip,
   Typography,
} from 'antd'
import { RemoveAllTagFromString } from '../../utils/Filters'
import {
   ClearOutlined,
   CloseCircleFilled,
   FileExcelOutlined,
   InfoCircleTwoTone,
   PlusCircleOutlined,
   SearchOutlined,
} from '@ant-design/icons'
import plus from '../../assets/plus.svg'
import deleteIcon from '../../assets/delete.svg'
import DeleteConfirmModal from './components/DeleteConfirmModal'
import MiniAssessmentPageModal from './components/MiniAssessmentPageModal'
import { Helmet } from 'react-helmet'
import { ReactComponent as GreenBall } from '../../assets/greenball.svg'
import './DailyRulesScreen.scss'

import enUS from 'antd/lib/locale/en_US'
import { Helpers } from '../../api/Helpers'
import AssessmentModal from '../impactAssessments/components/AssessmentModal'

const { RangePicker } = DatePicker
const { Title, Text, Paragraph } = Typography

export interface DailyRulesProps {}

export const DailyRulesScreen: React.FC<DailyRulesProps> = observer((props) => {
   const [locale, setLocale] = useState(enUS)
   const [currentRecord, setCurrentRecord] = useState<any[]>([])
   const [selectedRules, setSelectedRules] = useState<object[]>([])
   const [addedRecords, setAddedRecord] = useState<string[]>([])
   const [searchText, setSearchText] = useState<string>('')

   const { DailyRulesStore, ImpactAssessmentsStore } = useStore()
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [selectedAssessmentTitle, setSelectedAssessmentTitle] = useState(
      'Add new assessment allocation'
   )

   const pageTitle = 'Daily Rules'
   const [isRuleDetailsModalVisible, setIsRuleDetailsModalVisible] = useState(
      false
   )

   const updateSelected = useCallback(
      (selected: any[]) => {
         setSelectedRules((rules: any[]) => {
            const result = [...selected.map((r: any) => ({ ...r }))].filter(
               (x) => x?.mainRuleId && x?.mainRuleAuditCoreId
            )
            if (!searchText) return result
            const notNullSelected = result

            if (notNullSelected.length === 0)
               return [
                  ...rules.filter(
                     (e: any) =>
                        !e.mainRuleId.toPrecision().match(searchText) &&
                        !e.shortTitle.match(searchText) &&
                        !e.ruleType
                           .toLowerCase()
                           .match(searchText.toLowerCase()) &&
                        !RemoveAllTagFromString(e.introductionLine)
                           .toLowerCase()
                           .match(searchText.toLowerCase())
                  ),
               ]
            const allReadyContain =
               rules.filter(
                  (x) =>
                     notNullSelected.filter(
                        (y) =>
                           y.mainRuleId === x.mainRuleId &&
                           y.mainRuleAuditCoreId === x.mainRuleAuditCoreId
                     ).length > 0
               ).length > 0
            if (notNullSelected && allReadyContain) return [...rules]
            return [...rules, ...notNullSelected]
         })
      },
      [searchText]
   )

   const isSelected = useMemo(() => {
      return selectedRules.length > 0
   }, [selectedRules])

   const resetModal = useCallback(() => {
      ImpactAssessmentsStore.setStatusKey(
         ImpactAssessmentsStore.statusPreference
      )
      ImpactAssessmentsStore.setRiskKey(
         ImpactAssessmentsStore.riskLevelPreference
      )
      ImpactAssessmentsStore.setResDueDate(
         ImpactAssessmentsStore.preferencesResDueDate
      )
   }, [ImpactAssessmentsStore])

   const onAllocatedSelectedRules = useCallback(() => {
      setCurrentRecord([...selectedRules])
      resetModal()
      ImpactAssessmentsStore.setIsAssessmentModalVisible(true)
      setSelectedAssessmentTitle('Add new assessment allocation')
   }, [selectedRules, resetModal, ImpactAssessmentsStore])

   const onManageClick = useCallback(
      (record: any) => {
         ImpactAssessmentsStore.setSelectedAssessmentId(record.assessmentId)
         ImpactAssessmentsStore.setSelectedAssessmentRuleId([record.mainRuleId])
         ImpactAssessmentsStore.setSelectedAuditCoreIds([
            record.mainRuleAuditCoreId,
         ])
      },
      [ImpactAssessmentsStore]
   )

   const onAddNewAssessment = useCallback(
      (record: any) => {
         setCurrentRecord((current) => [record])
         onManageClick(record)

         ImpactAssessmentsStore.setIsAssessmentModalVisible(true)
         setSelectedAssessmentTitle('Add new assessment allocation')
      },
      [onManageClick, ImpactAssessmentsStore]
   )

   const exportPath = useMemo(() => {
      const ids = DailyRulesStore.selectedRowKeys
         .map((value) => `ids=${value}`)
         .join('&')
      return `${Helpers.getBaseApiUrl()}/api/DailyRules/ExportAsExcel?${ids}`
   }, [DailyRulesStore.selectedRowKeys])

   const onShortTitleClick = (params: any) => {
      DailyRulesStore.setSelectedRuleType(params.ruleType)
      DailyRulesStore.getRuleDetails(
         params.mainRuleId,
         params.mainRuleAuditCoreId
      )
      setIsRuleDetailsModalVisible(true)
   }

   const onMiniAssessmentPageModal = (ruleId: number) => {
      DailyRulesStore.getAssessments(ruleId)
   }

   const columns = [
      {
         title: 'Rule ID',
         dataIndex: 'mainRuleId',
         key: 'mainRuleId',
         render: (text: string) => (
            <a
               href={'https://reg-track.com/rules/search/Details/' + text}
               target="_blank"
               rel="noreferrer"
               style={{ cursor: 'pointer' }}
            >
               {text}
            </a>
         ),
      },
      {
         title: 'Title',
         dataIndex: 'shortTitle',
         key: 'shortTitle',
         width: 'auto',
         render: (text: string, record: any) => {
            return (
               <div style={{ display: 'flex' }}>
                  <div
                     className="ant-btn-link"
                     onClick={(e) => onShortTitleClick(record)}
                     style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        cursor: 'pointer',
                        float: 'left',
                     }}
                  >
                     {text}
                  </div>
                  {record.changeType > 1 && (
                     <Badge
                        status="processing"
                        className="versionBadge"
                     ></Badge>
                  )}
               </div>
            )
         },
      },
      {
         title: 'Introduction Line',
         dataIndex: 'introductionLine',
         key: 'introductionLine',
         render: (text: string, record: any) => {
            // TODO: Use react-html-parser lib here.
            return (
               <div style={{ display: 'flex' }}>
                  <span
                     dangerouslySetInnerHTML={{ __html: text }}
                     style={{ marginTop: '2%' }}
                  ></span>
                  <Button
                     type="link"
                     shape="circle"
                     onClick={(
                        e: any //onMiniAssessmentPageModal(147733)
                     ) => onMiniAssessmentPageModal(record.mainRuleId)}
                     style={{ marginLeft: '1%' }}
                  >
                     <Badge
                        count={record.assessmentCount ?? 1}
                        //count={1}
                        style={{ backgroundColor: '#52c41a' }}
                     ></Badge>
                  </Button>
               </div>
            )
         },
      },
      {
         title: 'Type',
         dataIndex: 'ruleType',
         key: 'ruleType',
      },
      {
         title: 'Action',
         key: 'action',
         render: (text: string, record: any) => (
            <Space size="middle">
               {/* <a> Allocate </a> */}
               <Tooltip placement="top" title="Allocate this rule">
                  <img
                     src={plus}
                     onClick={() => onAddNewAssessment(record)}
                     alt="Send an Assignment"
                  />
               </Tooltip>
               <Tooltip placement="top" title="Remove from daily rules">
                  <img
                     src={deleteIcon}
                     onClick={() => onRemoveRule(record.mainRuleId)}
                     alt="Remove from daily rules"
                  />
               </Tooltip>
            </Space>
         ),
      },
   ]

   const onRemoveRule = (mainRuleId: any) => {
      DailyRulesStore.setSelectedRowKeys([mainRuleId])
      DailyRulesStore.setDeleteConfirmModalVisible(true)
   }

   // TODO: Change any to DataType.
   const rowSelection = {
      selectedRowKeys: DailyRulesStore.selectedRowKeys,

      getCheckboxProps: (record: any) => ({
         disabled: record.name === 'Disabled User', // Column configuration not to be checked-
         name: record.name,
      }),
      onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
         updateSelected(selectedRows)

         const selectedAllKeys = selectedRows?.map((e: any) => e?.mainRuleId) ?? []
         const selectedAuditCoreIds = selectedRows.map(
            (e: any) => e?.mainRuleAuditCoreId
         )

         DailyRulesStore.setSelectedRowKeys(selectedAllKeys)
         DailyRulesStore.setSelectedAuditCoreIds(selectedAuditCoreIds)

         ImpactAssessmentsStore.setSelectedAssessmentRuleId(selectedAllKeys)
         ImpactAssessmentsStore.setSelectedAssessmentId('')
         ImpactAssessmentsStore.setSelectedAuditCoreIds(selectedAuditCoreIds)

         setIsAffixButtonActive(selectedRules.length > 0)
         DailyRulesStore.setMultipleSelected(selectedRows.length > 0)
      },
      onSelect: (
         record: any,
         selected: boolean,
         selectedRows: any,
         nativeEvent: any
      ) => {
         let selectedRowKeys: number[] = []
         let selectedAuditIds: string[] = []
         updateSelected(selectedRows)
         if (selected) {
            // If checkbox is selected then add to Selected Keys
            selectedRowKeys = [
               ...DailyRulesStore.selectedRowKeys,
               record.mainRuleId,
            ]

            selectedAuditIds = [
               ...DailyRulesStore.selectedAuditCoreIds,
               record.mainRuleAuditCoreId,
            ]
         } else {
            // If checkbox is unselected then remove it from Selected Keys
            const ruleIds = [...DailyRulesStore.selectedRowKeys]
            const auditCoreIds = [...DailyRulesStore.selectedAuditCoreIds]

            const indexOfSelectedRuleId = ruleIds.indexOf(record.mainRuleId)

            ruleIds.splice(indexOfSelectedRuleId, 1)
            auditCoreIds.splice(indexOfSelectedRuleId, 1)

            selectedRowKeys = ruleIds
            selectedAuditIds = auditCoreIds
         }

         DailyRulesStore.setSelectedRowKeys(selectedRowKeys)
         DailyRulesStore.setMainRuleIds(selectedRowKeys)
         DailyRulesStore.setSelectedAuditCoreIds(selectedAuditIds)

         DailyRulesStore.setMultipleSelected(selectedRowKeys.length > 0)

         ImpactAssessmentsStore.setSelectedAssessmentRuleId(selectedRowKeys)
         ImpactAssessmentsStore.setSelectedAssessmentId('')
         ImpactAssessmentsStore.setSelectedAuditCoreIds(selectedAuditIds)
      },
   }

   const searchDailyRules = () => {
      DailyRulesStore.setSelectedRowKeys([])
      DailyRulesStore.setMainRuleIds([])
      DailyRulesStore.setSelectedAuditCoreIds([])
      ImpactAssessmentsStore.setSelectedAssessmentRuleId([])
      ImpactAssessmentsStore.setSelectedAssessmentId('')
      ImpactAssessmentsStore.setSelectedAuditCoreIds([])
      DailyRulesStore.setMultipleSelected(false)

      if (DailyRulesStore.validateInputs()) {
         DailyRulesStore.getDailyRules()
      }
   }

   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)

      DailyRulesStore.setIsNonAssessed('false')
      DailyRulesStore.getActiveFilterNames()
      DailyRulesStore.getFilters()

      return DailyRulesStore.ClearState()
   }, [])

   useEffect(() => {
      ImpactAssessmentsStore.fillInitialStore()
      ImpactAssessmentsStore.getDefaultPreferences()
      ImpactAssessmentsStore.getUserExcelPreferences()

      return () => {
         ImpactAssessmentsStore.clearIAState()
      }
   }, [])

   // TODO Move to store. dailyRuleList data will be in the store as well...
   const ruleListSearchboxOnChange = (target: any) => {
      let targetValue = target?.target?.value
      setSearchText(targetValue)
      // targetValue = targetValue.toLowerCase()
      if (targetValue === '' || targetValue === undefined) {
         DailyRulesStore.isFiltered = false
      } else {
         DailyRulesStore.setDailyRuleListFiltered(
            DailyRulesStore.dailyRuleList?.filter(
               (e: any) =>
                  e.mainRuleId.toPrecision().match(targetValue) ||
                  e.shortTitle.match(targetValue) ||
                  e.ruleType.toLowerCase().match(targetValue.toLowerCase()) ||
                  RemoveAllTagFromString(e.introductionLine)
                     .toLowerCase()
                     .match(targetValue.toLowerCase())
            )
         )
         DailyRulesStore.isFiltered = true
      }

      DailyRulesStore.setSearchValue(targetValue)
   }

   const onCalenderChange = (e: any, dateRange: any) => {
      if (e !== null && e[0] !== null && e[1] !== null) {
         DailyRulesStore.setCalanderDate(e[0], e[1])
         DailyRulesStore.setIsDateRangeValid(true)
      }
   }

   const [isAffixButtonActive, setIsAffixButtonActive] = useState(false)

   const onAffixChange = () => {
      if (DailyRulesStore.buttonProps.type === 'link') {
         DailyRulesStore.setAffixButtonsType('primary')
      } else {
         DailyRulesStore.setAffixButtonsType('link')
      }
   }

   const onDateTypeSelect = (params: string) => {
      DailyRulesStore.setDateType(params)
   }

   const footerButtons = [
      <Button
         type="primary"
         onClick={() => setIsRuleDetailsModalVisible(false)}
         style={{
            backgroundColor: 'rgb(250, 140, 22)',
            borderColor: 'rgb(250, 140, 22)',
            borderRadius: '10px',
            color: 'white',
         }}
         // icon={<SaveOutlined />}
      >
         Ok
      </Button>,
   ]

   const onVersionSwitchChange = (event: any) => {
      DailyRulesStore.setFullVersion(event)
   }

   const onRuleDetailModal = () => {
      setIsRuleDetailsModalVisible(false)
      DailyRulesStore.setFullVersion(true)
   }

   const refreshTable = useCallback(() => {
      if (currentRecord)
         setAddedRecord((r) => [
            ...r,
            ...currentRecord.map((c) => c.mainRuleId),
         ])
      setCurrentRecord([])
      const selectedAllKeys = selectedRules.map((e: any) => e.mainRuleId)
      const selectedAuditCoreIds = selectedRules.map(
         (e: any) => e.mainRuleAuditCoreId
      )

      DailyRulesStore.setSelectedRowKeys(selectedAllKeys)
      DailyRulesStore.setSelectedAuditCoreIds(selectedAuditCoreIds)

      ImpactAssessmentsStore.setSelectedAssessmentRuleId(selectedAllKeys)
      ImpactAssessmentsStore.setSelectedAssessmentId('')
      ImpactAssessmentsStore.setSelectedAuditCoreIds(selectedAuditCoreIds)

      setIsAffixButtonActive(selectedRules.length > 0)
      DailyRulesStore.setMultipleSelected(selectedRules.length > 0)
   }, [currentRecord, selectedRules])

   const dataSource = useMemo(
      () =>
         DailyRulesStore.isFiltered
            ? DailyRulesStore.dailyRuleListFiltered
            : DailyRulesStore.dailyRuleList,
      [
         DailyRulesStore.isFiltered,
         DailyRulesStore.dailyRuleList,
         DailyRulesStore.dailyRuleListFiltered,
      ]
   )

   const dataSourceClean = useMemo(
      () => dataSource.filter((i) => !addedRecords.includes(i.mainRuleId)),
      [dataSource, addedRecords]
   )

   const getFilters = DailyRulesStore.filterList?.map((e: any) => {
      return {
         value: e.id,
         label: e.filterName,
      }
   })

   const content = (
      <Space direction="vertical">
         <Text>Feel free to change your filter and search daily rules.</Text>
         <Text>
            This will not affect to your default active filter settings.
         </Text>
      </Space>
   )

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level. (#####)
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            <Row>
               <Col style={{ textAlign: 'center' }} span={24}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
            </Row>
            <AssessmentModal
               initializeValuesFromStore={false}
               title={selectedAssessmentTitle}
               action={refreshTable}
               selected={currentRecord}
            />
            <MiniAssessmentPageModal onAddNewAssessment={onAddNewAssessment} />

            <Modal
               title={DailyRulesStore.selectedRuleDetails.shortTitle}
               open={isRuleDetailsModalVisible}
               onCancel={onRuleDetailModal}
               footer={footerButtons}
               destroyOnClose={true}
               width={670}
               bodyStyle={{ overflowY: 'scroll', height: '400px' }}
            >
               <Spin spinning={DailyRulesStore.isLoading}>
                  {!DailyRulesStore.connectionErrorMessageVisible && (
                     <>
                        <Row>
                           <Col span={20}>
                              <p
                                 style={{
                                    fontSize: 'medium',
                                    color: '#f57f01',
                                    textAlign: 'left',
                                    marginLeft: '1%',
                                 }}
                                 dangerouslySetInnerHTML={{
                                    __html: DailyRulesStore.fullVersion
                                       ? DailyRulesStore.selectedRuleDetails
                                            .introductionLine
                                       : DailyRulesStore
                                            .selectedRuleDetailsAuditData
                                            .introductionLine,
                                 }}
                              ></p>
                           </Col>
                           <Col span={4}>
                              <Switch
                                 checkedChildren="Full Version"
                                 unCheckedChildren="Last Update Version"
                                 checked={DailyRulesStore.fullVersion}
                                 onChange={(event) =>
                                    onVersionSwitchChange(event)
                                 }
                              />
                           </Col>
                        </Row>

                        <Divider
                           style={{
                              backgroundColor: 'rgb(250, 140, 22)',
                              opacity: '0.3',
                              marginBottom: '2%',
                              marginTop: '0%',
                           }}
                        />
                        <Paragraph>
                           {
                              <p
                                 // style={{ color: '#874d00' }}
                                 className="summaryRow"
                                 dangerouslySetInnerHTML={{
                                    __html: DailyRulesStore.fullVersion
                                       ? DailyRulesStore.selectedRuleDetails
                                            .summary
                                       : DailyRulesStore
                                            .selectedRuleDetailsAuditData
                                            .summary,
                                 }}
                              ></p>
                           }
                        </Paragraph>
                        {/* <Divider />{' '} */}
                        <Card style={{ backgroundColor: '#fffbe6' }}>
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong> Regulators</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {' '}
                                 {
                                    DailyRulesStore.selectedRuleTaxonomies
                                       .regulators
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Entity Type:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {' '}
                                 {
                                    DailyRulesStore.selectedRuleTaxonomies
                                       .entityTypes
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Functions:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {' '}
                                 {
                                    DailyRulesStore.selectedRuleTaxonomies
                                       .functions
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 <strong> Countries:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {' '}
                                 {
                                    DailyRulesStore.selectedRuleTaxonomies
                                       .countries
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Products:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleTaxonomies
                                       .products
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Regions:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleTaxonomies
                                       .regions
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Rule Type:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {DailyRulesStore.selectedRuleType}
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Reference:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {DailyRulesStore.selectedRuleDetails.reference}
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Rule Date:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {DailyRulesStore.selectedRuleDetails.ruleDate}
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Effective Date:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleDetails
                                       .effectiveDate
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Last Update Date:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleDetails
                                       .lastUpdateDate
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Reg. Last Update:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleDetails
                                       .reportIssueDate
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Rule Id:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleDetails
                                       .mainRuleId
                                 }
                              </Col>
                           </Row>{' '}
                           <Row>
                              <Col span={6}>
                                 {' '}
                                 <strong>Linked to:</strong>{' '}
                              </Col>
                              <Col span={18}>
                                 {
                                    DailyRulesStore.selectedRuleDetails
                                       .linkToOtherRules
                                 }
                              </Col>
                           </Row>{' '}
                        </Card>
                     </>
                  )}
               </Spin>
            </Modal>
            <DeleteConfirmModal
               isVisible={isModalVisible}
               setVisible={setIsModalVisible}
            />
            <Spin spinning={DailyRulesStore.isLoading}>
               <Card bordered={true} style={{ backgroundColor: '#fafafa' }}>
                  <Row style={{ marginBottom: '2%' }}>
                     <Col span={4}>
                        <Select
                           defaultValue="Last Update Date"
                           bordered={false}
                           showArrow={true}
                           style={{ width: '100%' }}
                           onSelect={onDateTypeSelect}
                           options={[
                              {
                                 label: 'Last Update Date',
                                 value: 'LastUpdateDate',
                              },
                              {
                                 label: 'Effective Date',
                                 value: 'EffectiveDate',
                              },
                              { label: 'Rule Date', value: 'RuleDate' },
                              {
                                 label: 'Reg. Last Update',
                                 value: 'ReportIssueDate',
                              },
                           ]}
                        />
                     </Col>
                     <Col span={1}></Col>
                     <Col span={8} style={{ textAlign: 'left' }}>
                        <ConfigProvider locale={locale}>
                           <RangePicker
                              style={{ width: '100%' }}
                              // locale={locale.DatePicker}
                              format={DailyRulesStore.localeDateFormat}
                              onCalendarChange={onCalenderChange}
                              defaultValue={[
                                 DailyRulesStore.startDate,
                                 DailyRulesStore.endDate,
                              ]}
                              className={
                                 !DailyRulesStore.isDateRangeValid
                                    ? 'dateRange-validator'
                                    : ''
                              }
                           />
                           {!DailyRulesStore.isDateRangeValid && (
                              <span style={{ color: 'red', fontSize: '13px' }}>
                                 Date Range is required!{' '}
                              </span>
                           )}
                        </ConfigProvider>
                     </Col>
                  </Row>
                  <Row>
                     <Col span={4}>
                        <Text style={{ marginLeft: '6%' }}> Filters</Text>
                     </Col>
                     <Col span={1}></Col>
                     <Col span={8} style={{ textAlign: 'left' }}>
                        <Select
                           style={{ width: '100%' }}
                           placeholder="Select Filter"
                           size="middle"
                           mode="multiple"
                           value={DailyRulesStore.selectedFilters}
                           onChange={(e: any) => {
                              DailyRulesStore.setSelectedFilters(e)
                           }}
                           optionLabelProp="label"
                           options={getFilters}
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              (option?.label ?? '')
                                 .toLowerCase()
                                 .includes(input.toLowerCase())
                           }
                           showSearch
                        />
                     </Col>
                     <Col style={{ marginLeft: '1%', marginTop: '0.5%' }}>
                        <Popover placement="right" content={content}>
                           <InfoCircleTwoTone twoToneColor="orange" />
                        </Popover>
                     </Col>
                  </Row>
                  <Row>
                     <Col
                        span={5}
                        style={{ fontStyle: 'italic', marginLeft: '1%' }}
                     >
                        Applied Filter Logic
                     </Col>
                     <Col span={15}>
                        {DailyRulesStore.calculateAppliedFilterLogic()}
                     </Col>
                  </Row>
                  <Row style={{ marginTop: '2%' }}>
                     <Col span={6} />
                     <Col span={7} style={{ textAlign: 'right' }}>
                        <Button
                           htmlType="submit"
                           onClick={searchDailyRules}
                           icon={<SearchOutlined />}
                           style={{
                              backgroundColor: 'rgb(250, 140, 22)',
                              borderColor: 'rgb(250, 140, 22)',
                              borderRadius: '10px',
                              color: 'white',
                           }}
                        >
                           Search{' '}
                        </Button>
                     </Col>
                  </Row>
               </Card>
               {/* <Card bordered={false} className="card"> */}
               <Space direction="vertical" className="space">
                  <Row
                     style={{
                        // marginLeft: '3%',
                        marginTop: '3%',
                        marginBottom: '-2%',
                     }}
                  >
                     <Col span={1}>
                        <GreenBall />
                     </Col>
                     <Col span={3}>
                        <Text>
                           {' '}
                           <strong> Active Filter Names: </strong>{' '}
                        </Text>
                     </Col>
                     <Col span={7}>
                        {DailyRulesStore.activeFilterNames
                           .split(',')
                           .map((e: any) => (
                              <Tag color="purple"> {e} </Tag>
                           ))}
                     </Col>
                     <Col>
                        <Text>
                           <strong>Rule Count :</strong>{' '}
                           {dataSourceClean.length}{' '}
                        </Text>
                     </Col>
                  </Row>
                  <Divider
                     type="horizontal"
                     style={{
                        // marginTop: '4%',
                        marginBottom: '3%',
                        backgroundColor: 'rgb(250, 140, 22)',
                        opacity: '0.3',
                     }}
                  />
                  <Row className="table-search-row">
                     <Col span={17}>
                        <Affix offsetTop={10} onChange={onAffixChange}>
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'flex-start',
                              }}
                           >
                              <Button
                                 {...DailyRulesStore.buttonProps}
                                 htmlType="submit"
                                 disabled={!isSelected}
                                 onClick={() =>
                                    DailyRulesStore.setDeleteConfirmModalVisible(
                                       true
                                    )
                                 }
                                 icon={<ClearOutlined />}
                                 className={
                                    isSelected
                                       ? 'affix-button-active'
                                       : 'affix-button-disable'
                                 }
                              >
                                 Remove selected rules{' '}
                              </Button>
                              <Button
                                 {...DailyRulesStore.buttonProps}
                                 htmlType="submit"
                                 disabled={!isSelected}
                                 onClick={onAllocatedSelectedRules}
                                 icon={<PlusCircleOutlined />}
                                 className={
                                    isSelected
                                       ? 'affix-button-active'
                                       : 'affix-button-disable'
                                 }
                              >
                                 Allocate selected rules{' '}
                              </Button>
                              <Button
                                 {...DailyRulesStore.buttonProps}
                                 disabled={!isSelected}
                                 href={exportPath}
                                 download
                                 icon={<FileExcelOutlined />}
                                 className={
                                    isSelected
                                       ? 'affix-button-active'
                                       : 'affix-button-disable'
                                 }
                              >
                                 Export to excel{' '}
                              </Button>
                           </div>
                        </Affix>
                     </Col>
                     <Col
                        span={7}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                     >
                        <Input
                           placeholder="Search table"
                           style={{ width: '80%' }}
                           value={DailyRulesStore.searchValue}
                           onChange={(e: any) => ruleListSearchboxOnChange(e)}
                           suffix={
                              <>
                                 <CloseCircleFilled
                                    className="close-circle"
                                    onClick={() =>
                                       DailyRulesStore.clearSearchBox()
                                    } //TODO : USE STORE
                                 />{' '}
                              </>
                           }
                        />
                        <div className="search-icon-div">
                           <SearchOutlined className="search-icon" />
                        </div>
                     </Col>
                  </Row>
                  <ConfigProvider>
                     <Table
                        rowKey={(record) => record.mainRuleId}
                        rowSelection={{
                           type: 'checkbox',
                           ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={dataSourceClean}
                        size="middle"
                        pagination={{
                           position: ['bottomCenter'],
                           defaultPageSize: 100,
                           showSizeChanger: true,
                           pageSizeOptions: [
                              '10',
                              '20',
                              '30',
                              '50',
                              '100',
                              '500',
                              '1000',
                           ],
                        }}
                     />
                  </ConfigProvider>
                  <Row style={{ display: 'flex', justifyContent: 'center' }}>
                     <Col>
                        <Text>
                           <strong>Rule Count :</strong>{' '}
                           {DailyRulesStore.ruleCount}{' '}
                        </Text>
                     </Col>
                  </Row>
               </Space>
            </Spin>
         </>
      )
})
