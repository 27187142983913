import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useStore } from '../../context/index'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
   Card,
   Select,
   Typography,
   Col,
   Row,
   Button,
   Input,
   Switch,
   Checkbox,
   Spin,
} from 'antd'
import { ReactComponent as Back } from '../../assets/back.svg'
import { SaveOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

const { Text, Title } = Typography

export interface IUser {
   match: any
   location: any
}

export interface ParamTypes {
   id: any
}

const User = observer((props: IUser) => {
   const { CompanyUsersStore } = useStore()
   const newUserTitle = 'New User'
   const updateUserTitle = 'Update User'
   const history = useHistory()
   let params = useParams<ParamTypes>()
   const [isNewUser, setIsNewUser] = useState(false)

   useEffect(() => {
      if (params.id === '' || params.id === undefined) {
         setIsNewUser(true)
         CompanyUsersStore.getDepartments()
         CompanyUsersStore.getRoles()
         CompanyUsersStore.clearUserStore()
      } else {
         CompanyUsersStore.Initialize(parseInt(params.id))
      }

      return () => {
         CompanyUsersStore.clearUserStore()
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const IsUserFormValid = () => {
      // TODO : Move this logic to the store
      if (
         CompanyUsersStore.selectedUser?.name === '' ||
         CompanyUsersStore.selectedUser?.surname === '' ||
         CompanyUsersStore.selectedUser?.roles.length === 0 ||
         (isNewUser && CompanyUsersStore.selectedUser?.email === '')
      ) {
         CompanyUsersStore.setIsNameEmpty(
            CompanyUsersStore.selectedUser?.name === ''
         )
         CompanyUsersStore.setIsSurnameEmpty(
            CompanyUsersStore.selectedUser?.surname === ''
         )
         CompanyUsersStore.setIsSelectedRolesEmpty(
            CompanyUsersStore.selectedUser?.roles.length === 0
         )
         CompanyUsersStore.setIsEmailEmpty(
            CompanyUsersStore.selectedUser?.email === ''
         )

         return false
      }
      return true
   }

   const onCreateUser = () => {
      if (IsUserFormValid()) CompanyUsersStore.createUser()
   }

   const onUpdateButton = () => {
      if (IsUserFormValid()) CompanyUsersStore.updateUser(true)
   }

   if (CompanyUsersStore.redirectToResult)
      if (!CompanyUsersStore.isLoading) {
         history.push({
            pathname: '/UserUpdateResultPage/' + isNewUser,
         })
      }

   const departmentOptions = CompanyUsersStore.departments?.map((e: any) => {
      return {
         label: e.name,
         value: e.key,
      }
   })

   const roleOptions = CompanyUsersStore.roles?.map((e: any, item: any) => {
      return (
         <Checkbox
            value={e.roleName}
            key={e.id}
            defaultChecked={CompanyUsersStore.hasUserRole(e.id)}
            onChange={(e: any) => CompanyUsersStore.onUserRoleChange(e, item)}
         >
            {' '}
            {e.roleName}
         </Checkbox>
      )
   })

   return (
      <>
         <Helmet>
            <title> {isNewUser ? newUserTitle : updateUserTitle} </title>
         </Helmet>
         <Spin spinning={CompanyUsersStore.isLoading}>
            <Row>
               <Col style={{ textAlign: 'center' }} span={23}>
                  <Title level={3}>User Details</Title>
               </Col>
            </Row>
            <Card style={{ width: '80%', marginLeft: '10%' }}>
               {isNewUser && (
                  <Row>
                     <Col span={1} />
                     <Col span={6}>
                        <Text>
                           {' '}
                           <strong>E-Mail: </strong>
                        </Text>
                     </Col>
                     <Col span={12}>
                        <Input
                           style={
                              CompanyUsersStore.isEmailEmpty
                                 ? { marginTop: '-1%', borderColor: 'red' }
                                 : { marginTop: '-1%' }
                           }
                           placeholder="E-Mail..."
                           value={CompanyUsersStore.selectedUser?.email}
                           onChange={(e) => CompanyUsersStore.onEmailChange(e)}
                        />
                        {CompanyUsersStore.isEmailEmpty && (
                           <span style={{ color: 'red' }}>
                              {' '}
                              Email cannot be empty!{' '}
                           </span>
                        )}
                     </Col>
                  </Row>
               )}
               <Row style={{ marginTop: '2%' }}>
                  <Col span={1} />
                  <Col span={6}>
                     <Text>
                        {' '}
                        <strong>First Name: </strong>
                     </Text>
                  </Col>
                  <Col span={12}>
                     <Input
                        style={
                           CompanyUsersStore.isNameEmpty
                              ? { marginTop: '-1%', borderColor: 'red' }
                              : { marginTop: '-1%' }
                        }
                        placeholder="Name..."
                        value={CompanyUsersStore.selectedUser?.name}
                        onChange={(e) => CompanyUsersStore.onNameChange(e)}
                     />
                     {CompanyUsersStore.isNameEmpty && (
                        <span style={{ color: 'red' }}>
                           {' '}
                           First Name cannot be empty!{' '}
                        </span>
                     )}
                  </Col>
               </Row>
               <Row style={{ marginTop: '2%' }}>
                  <Col span={1}></Col>
                  <Col span={6}>
                     <Text>
                        {' '}
                        <strong>Last Name: </strong>
                     </Text>
                  </Col>
                  <Col span={12}>
                     <Input
                        style={
                           CompanyUsersStore.isSurameEmpty
                              ? { marginTop: '-1%', borderColor: 'red' }
                              : { marginTop: '-1%' }
                        }
                        placeholder="Surname..."
                        value={CompanyUsersStore.selectedUser?.surname}
                        onChange={(e) => CompanyUsersStore.onSurnameChange(e)}
                     />
                     {CompanyUsersStore.isSurameEmpty && (
                        <span style={{ color: 'red' }}>
                           {' '}
                           Surname cannot be empty!{' '}
                        </span>
                     )}
                  </Col>
               </Row>
               <Row style={{ marginTop: '2%' }}>
                  <Col span={1}></Col>
                  <Col span={6}>
                     <Text>
                        {' '}
                        <strong>Departments: </strong>
                     </Text>
                  </Col>
                  <Col span={12}>
                     <Select
                        mode="multiple"
                        style={{ width: '79%', marginTop: '-1%' }}
                        placeholder="Departments..."
                        value={CompanyUsersStore.selectedDepartmentValues}
                        onChange={(values: any, selectedArray: any) =>
                           CompanyUsersStore.onDepartmentChange(
                              values,
                              selectedArray
                           )
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                           (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        }
                        showSearch
                        options={departmentOptions}
                     />
                  </Col>
               </Row>
               <Row style={{ marginTop: '2%' }}>
                  <Col span={1}></Col>
                  <Col span={6}>
                     <Text>
                        {' '}
                        <strong>Permissions: </strong>
                     </Text>
                  </Col>
                  <Col span={12}>
                     <Switch
                        checked={CompanyUsersStore.isAdmin}
                        checkedChildren="Super Admin"
                        unCheckedChildren="Limited"
                        onChange={(e: any) =>
                           CompanyUsersStore.onUserAdminRoleSwitch(e)
                        }
                     />
                  </Col>
               </Row>
               {!CompanyUsersStore.isAdmin && (
                  <>
                     <Row style={{ marginTop: '2%' }}>
                        <Col span={1}></Col>
                        <Col span={6}>
                           <Text>
                              {' '}
                              <strong>Access Permissions: </strong>
                           </Text>
                        </Col>
                        <Col span={16}>{roleOptions}</Col>
                     </Row>
                     <br />
                     {CompanyUsersStore.isSelectedRolesEmpty && (
                        <Row>
                           <Col span={7}></Col>
                           <Col span={17}>
                              {' '}
                              <span style={{ color: 'red' }}>
                                 {' '}
                                 At least 1-Role should be selected!{' '}
                              </span>
                           </Col>
                        </Row>
                     )}
                  </>
               )}
            </Card>
            <Row style={{ marginTop: '4%', width: '80%' }}>
               <Col
                  style={{
                     display: 'flex',
                     justifyContent: 'flex-end',
                  }}
                  span={4}
               >
                  <Back />
               </Col>
               <Col span={19}>
                  <Button
                     type="link"
                     htmlType="submit"
                     disabled={false}
                     onClick={() => history.push('/CompanyUsers')}
                  >
                     Back to Company Users{' '}
                  </Button>{' '}
               </Col>
               <Col span={1}>
                  <Button
                     htmlType="submit"
                     disabled={false}
                     onClick={isNewUser ? onCreateUser : onUpdateButton}
                     style={{
                        backgroundColor: '#fa8c16',
                        borderColor: '#fa8c16',
                        color: 'white',
                        borderRadius: '10px',
                     }}
                     icon={<SaveOutlined />}
                  >
                     {isNewUser ? 'Create User' : 'Update User'}
                  </Button>{' '}
               </Col>
            </Row>
         </Spin>
         <br />
      </>
   )
})

export default User
