/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useCallback } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import {
   Typography,
   Row,
   Col,
   Button,
   Table,
   Divider,
   Card,
   Drawer,
   DatePicker,
   Select,
   Checkbox,
   Modal,
   Form,
   Spin,
   Tag,
} from 'antd'
import './ImpactAssessments.scss'
import { HistoryOutlined, ReadOutlined } from '@ant-design/icons'
import { useStore } from '../../context/index'
import {
   EditOutlined,
   SaveOutlined,
   CloseCircleOutlined,
} from '@ant-design/icons'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import { HistoryContent } from './components/HistoryContent'
import { Helmet } from 'react-helmet'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
   ClassicEditor,
   AccessibilityHelp,
   Alignment,
   Autoformat,
   AutoLink,
   Autosave,
   Bold,
   Code,
   Essentials,
   GeneralHtmlSupport,
   Heading,
   Indent,
   IndentBlock,
   Italic,
   Link,
   List,
   Paragraph as Paragraph2,
   RemoveFormat,
   SelectAll,
   ShowBlocks,
   SourceEditing,
   SpecialCharacters,
   SpecialCharactersArrows,
   SpecialCharactersCurrency,
   SpecialCharactersEssentials,
   SpecialCharactersLatin,
   SpecialCharactersMathematical,
   SpecialCharactersText,
   Strikethrough,
   Table as Table2,
   TableCaption,
   TableCellProperties,
   TableColumnResize,
   TableProperties,
   TableToolbar,
   TextTransformation,
   Underline,
   Undo,
} from 'ckeditor5'
import { useParams } from 'react-router-dom'
import { RemoveAllTagFromString } from '../../utils/Helpers'
import { DragAndDrop } from '../utils'
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/es/upload/interface'
import { FileItem } from '../utils/FileItem/FileItem'

const { Text, Title, Paragraph } = Typography

export interface IAssessmentForm {
   location: any
}

export interface ParamTypes {
   assessmentId: string
   mainRuleAuditId: string
}

const AssessmentForm = observer((props: IAssessmentForm) => {
   const { ImpactAssessmentsStore } = useStore()
   const [isUpdate, setIsUpdate] = useState(false)
   const [editorContent, setEditorContent] = useState('')
   let params = useParams<ParamTypes>()
   const auditSectionRef = useRef<null | HTMLDivElement>(null)
   const [files, setFiles] = useState<RcFile[]>([])

   const [isLayoutReady, setIsLayoutReady] = useState(false)
   const [editor, setEditor] = useState<any>(null)

   useEffect(() => {
      setIsLayoutReady(true)

      return () => setIsLayoutReady(false)
   }, [])

   const handleDragFileChange = useCallback((data: RcFile) => {
      setFiles((fs) => {
         return [...fs, data]
      })
   }, [])

   const handleDragFileDeleted = useCallback((data: UploadFile) => {
      setFiles((fs) => {
         return [...fs.filter((f) => f.uid !== data.uid)]
      })
   }, [])

   const successfullyAddedMessage = () => {}

   const errorMessage = () => {
      toast.error(ImpactAssessmentsStore.errorMessage)
   }

   const drawerSummary = (
      <div
         // ref={anchorEl}
         className="summaryRow"
         dangerouslySetInnerHTML={{
            __html: ImpactAssessmentsStore.selectedAssessmentRule.summary,
         }}
      ></div>
   )

   useEffect(() => {
      ImpactAssessmentsStore.setSelectedAssessmenDetailAssessmentId(
         parseInt(params.assessmentId)
      )
      ImpactAssessmentsStore.setAuditId(params.mainRuleAuditId)
      ImpactAssessmentsStore.getRuleDetails(
         parseInt(params.mainRuleAuditId.split('-')[0])
      )
      ImpactAssessmentsStore.getAssessmentDetails(params.assessmentId)
      ImpactAssessmentsStore.fillInitialStore()
      ImpactAssessmentsStore.getDefaultPreferences() // TODO: Use API Cache for this type of API Calls.
      scrollToBottom()

      form.setFieldsValue({
         datePickerField: moment(
            ImpactAssessmentsStore.preferencesResDueDate,
            'YYYY-MM-DD'
         ),
      })
      ImpactAssessmentsStore.setAssessmentDetailDueDate(
         moment(ImpactAssessmentsStore.preferencesResDueDate, 'YYYY-MM-DD')
      )

      return () => {
         ImpactAssessmentsStore.clearAssessmentObj()
         ImpactAssessmentsStore.clearStateObj()
      }
   }, [])

   useEffect(() => {
      if (ImpactAssessmentsStore.isSuccessfullyAdded) {
         onSuccessfullyAddedAssessment()
         ImpactAssessmentsStore.setIsSuccessfullyAdded(false)
      }
   }, [ImpactAssessmentsStore.isSuccessfullyAdded])

   useEffect(() => {
      if (ImpactAssessmentsStore.isError) {
         errorMessage()
         ImpactAssessmentsStore.setIsError(false)
      }
   }, [ImpactAssessmentsStore.isError])

   const scrollToBottom = () => {
      auditSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
   }

   const [visible, setVisible] = useState(false)
   const onClose = () => {
      setVisible(false)
   }

   const onEditAssessment = (id: any) => {
      setIsUpdate(true)
      const index = ImpactAssessmentsStore.reportAudit.findIndex(
         (e) => e.assessmentDetailId === id
      )
      const _selectedAssessment = ImpactAssessmentsStore.reportAudit[index]
      
      setEditorContent(_selectedAssessment.content)
      if (editor != null) editor.setData(_selectedAssessment.content)

      ImpactAssessmentsStore.setAssessmentDetailDueDate(
         moment(_selectedAssessment.dueDate, 'YYYY-MM-DD')
      )
      form.setFieldsValue({
         datePickerField: moment(_selectedAssessment.dueDate, 'YYYY-MM-DD'),
      })

      const selectedRiskLevelObj = ImpactAssessmentsStore.riskLevels.find(
         (e) => e.description === _selectedAssessment.riskLevel
      )
      const selectedTopicsObj = _selectedAssessment.topic

      const selectedStatusObj = ImpactAssessmentsStore.status.find(
         (e) => e.value === _selectedAssessment.status
      )

      let splittedDeuDate = _selectedAssessment.dueDate.split('/')
      let formattedDate =
         splittedDeuDate[2] +
         '/' +
         splittedDeuDate[0] +
         '/' +
         splittedDeuDate[1]
      ImpactAssessmentsStore.setAssessmentDetailDueDate(
         moment(formattedDate, 'YYYY-MM-DD')
      )
      form.setFieldsValue({
         datePickerField: moment(formattedDate, 'YYYY-MM-DD'),
      })
      form.setFieldsValue({ riskLevelField: selectedRiskLevelObj?.description })
      form.setFieldsValue({
         topicField: selectedTopicsObj?.map((e: any) => e.key),
      })
      form.setFieldsValue({ statusField: selectedStatusObj?.value })

      ImpactAssessmentsStore.setAssessmentDetailRiskLevelId(
         selectedRiskLevelObj?.key
      )

      ImpactAssessmentsStore.setAssessmentDetailTopicId(
         selectedTopicsObj?.map((e: any) => e.key)
      )
      ImpactAssessmentsStore.setAssessmentDetailStatusId(selectedStatusObj?.key)
      ImpactAssessmentsStore.setSelectedAssessmentDetailId(
         _selectedAssessment.assessmentDetailId
      )
      ImpactAssessmentsStore.setIsCommentOnly(_selectedAssessment.isCommentOnly)
      ImpactAssessmentsStore.setAssessmentDetailContent(
         _selectedAssessment.content
      )
   }

   const onSuccessfullyAddedAssessment = () => {
      form.resetFields()
      clearEditorContent()
      setIsUpdate(false)
      ImpactAssessmentsStore.clearAssessmentObj()
      successfullyAddedMessage()
   }

   const onSaveAssessment = () => {
      // TODO: needs to change to 'Form' and it will validate automaticly.
      if (ImpactAssessmentsStore.assessmentDetail.riskLevelId === null) {
         ImpactAssessmentsStore.setRiskLevelValidator(true)
         return
      } else ImpactAssessmentsStore.setRiskLevelValidator(false)

      if (ImpactAssessmentsStore.assessmentDetail.statusId === null) {
         ImpactAssessmentsStore.setStatusValidator(true)
         return
      } else ImpactAssessmentsStore.setStatusValidator(false)

      ImpactAssessmentsStore.AddNewAssessmentDetail(files, () => setFiles([]))
   }

   const onUpdateAssessment = (params: any) =>
      ImpactAssessmentsStore.updateAssessmentDetail(files, () => setFiles([]))

   const onCancelButton = () => {
      form.resetFields()
      clearEditorContent()
      setIsUpdate(false)
      ImpactAssessmentsStore.clearAssessmentObj()
   }

   const clearEditorContent = () => {
      setEditorContent('')
   }

   const onHistoryClick = (id: any) => {
      const index = ImpactAssessmentsStore.reportAudit.findIndex(
         (e) => e.assessmentDetailId === id
      )

      if (index > -1) {
         const assessment = ImpactAssessmentsStore.reportAudit[index]
         ImpactAssessmentsStore.setHistoryThreads(
            assessment.assessmentDetailAudit
         )

         Modal.info({
            icon: null,
            title: 'Assessment Audit Trail',
            width: '65%',
            okButtonProps: {
               style: {
                  backgroundColor: 'rgb(250, 140, 22) ',
                  borderColor: 'rgb(250, 140, 22)',
                  borderRadius: '10px',
               },
               icon: <CloseCircleOutlined />,
            },
            okText: 'Close',
            content: <HistoryContent />,
            onOk() {},
         })
      }
   }

   const getRiskLevels = ImpactAssessmentsStore.riskLevels?.map((e: any) => {
      return {
         value: e.key,
         label: e.description,
      }
   })

   const getStatus = ImpactAssessmentsStore.status?.map((e: any) => {
      return {
         value: e.key,
         label: e.value,
      }
   })

   const getTopics = ImpactAssessmentsStore.topics?.map((e: any) => {
      return {
         value: e.key,
         label: e.description,
      }
   })

   const reportTableColumns = [
      {
         title: 'Date',
         dataIndex: 'createdDate',
         width: '8%',
      },
      {
         title: 'Actioned by',
         dataIndex: 'userFullName',
         width: '12%',
      },
      {
         title: 'Comment',
         dataIndex: 'content',
         render: (e: any, record: any) =>
            RemoveAllTagFromString(record.content).replaceAll('&nbsp;', ''),
         // width: '22%',
      },
      {
         title: 'Risk Level',
         dataIndex: 'riskLevel',
         width: '10%',
      },
      {
         title: 'Status',
         dataIndex: 'status',
         width: '10%',
      },
      {
         title: 'Topic',
         dataIndex: 'topic',
         width: '14%',
         render: (text: any, record: any) => (
            <>
               {record.topic.map((e: any) => (
                  <Tag color="#5cdbd3"> {e.description} </Tag>
               ))}
            </>
         ),
      },
      {
         title: 'Due Date',
         dataIndex: 'dueDate',
         width: '8%',
         render: (e: any, record: any) =>
            record.isCommentOnly ? (
               <span>N/A</span>
            ) : (
               <span> {record.dueDate} </span>
            ),
      },
      {
         title: 'Action',
         // dataIndex:''
         width: '12%',
         render: (e: any, record: any) =>
            RemoveAllTagFromString(record.content).slice(0, 8) ===
            '[System]' ? (
               <></>
            ) : (
               <>
                  <Button
                     type="link"
                     style={{ color: 'rgb(250, 140, 22)' }}
                     onClick={() => onEditAssessment(record.assessmentDetailId)}
                  >
                     <EditOutlined />
                     Edit{' '}
                  </Button>

                  {record.assessmentDetailAudit?.length > 0 && (
                     <Button
                        type="link"
                        style={{
                           color: 'rgb(250, 140, 22)',
                           marginLeft: '10%',
                        }}
                        onClick={() =>
                           onHistoryClick(record.assessmentDetailId)
                        }
                     >
                        {' '}
                        <HistoryOutlined />
                        {'         '}
                        History
                     </Button>
                  )}
               </>
            ),
      },
   ]
   const [form] = Form.useForm()

   const editorConfig = {
      toolbar: {
         items: [
            'undo',
            'redo',
            '|',
            'sourceEditing',
            'showBlocks',
            'selectAll',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'code',
            'removeFormat',
            '|',
            'specialCharacters',
            'link',
            'insertTable',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'indent',
            'outdent',
            '|',
            'accessibilityHelp',
         ],
         shouldNotGroupWhenFull: false,
      },
      plugins: [
         AccessibilityHelp,
         Alignment,
         Autoformat,
         AutoLink,
         Autosave,
         Bold,
         Code,
         Essentials,
         GeneralHtmlSupport,
         Heading,
         Indent,
         IndentBlock,
         Italic,
         Link,
         List,
         Paragraph2,
         RemoveFormat,
         SelectAll,
         ShowBlocks,
         SourceEditing,
         SpecialCharacters,
         SpecialCharactersArrows,
         SpecialCharactersCurrency,
         SpecialCharactersEssentials,
         SpecialCharactersLatin,
         SpecialCharactersMathematical,
         SpecialCharactersText,
         Strikethrough,
         Table2,
         TableCaption,
         TableCellProperties,
         TableColumnResize,
         TableProperties,
         TableToolbar,
         TextTransformation,
         Underline,
         Undo,
      ],
      heading: {
         options: [
            {
               model: 'paragraph',
               title: 'Paragraph',
               class: 'ck-heading_paragraph',
            },
            {
               model: 'heading1',
               view: 'h1',
               title: 'Heading 1',
               class: 'ck-heading_heading1',
            },
            {
               model: 'heading2',
               view: 'h2',
               title: 'Heading 2',
               class: 'ck-heading_heading2',
            },
            {
               model: 'heading3',
               view: 'h3',
               title: 'Heading 3',
               class: 'ck-heading_heading3',
            },
            {
               model: 'heading4',
               view: 'h4',
               title: 'Heading 4',
               class: 'ck-heading_heading4',
            },
            {
               model: 'heading5',
               view: 'h5',
               title: 'Heading 5',
               class: 'ck-heading_heading5',
            },
            {
               model: 'heading6',
               view: 'h6',
               title: 'Heading 6',
               class: 'ck-heading_heading6',
            },
         ],
      },
      htmlSupport: {
         allow: [
            {
               name: /^.*$/,
               styles: true,
               attributes: true,
               classes: true,
            },
         ],
      },
      initialData: editorContent,
      link: {
         addTargetToExternalLinks: true,
         defaultProtocol: 'https://',
         decorators: {
            toggleDownloadable: {
               mode: 'manual',
               label: 'Downloadable',
               attributes: {
                  download: 'file',
               },
            },
         },
      },
      placeholder: 'Type or paste your comment here!',
      table: {
         contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
         ],
      },
   } as any

   const useCKEditor = ImpactAssessmentsStore.isAdmin ? (
      <CKEditor
         editor={ClassicEditor}
         config={editorConfig}
         onChange={(_: any, editor: any) => {
            ImpactAssessmentsStore.onEditorChange(editor.getData())
         }}
         onReady={(editor) => {
            setEditor(editor)
         }}
      />
   ) : (
      <CKEditor
         editor={ClassicEditor}
         config={editorConfig}
         onChange={(_: any, editor: any) => {
            ImpactAssessmentsStore.onEditorChange(editor.getData())
         }}
         onReady={(editor) => {
            setEditor(editor)
         }}
      />
   )

   return (
      <Spin spinning={ImpactAssessmentsStore.isLoading}>
         <Helmet>
            <title>Assessment Form</title>
         </Helmet>
         <Card bordered={false}>
            <Card bordered={false} style={{ width: '668px' }}>
               <Title level={3} style={{ color: 'rgb(84, 0, 0)' }}>
                  {
                     <p
                        dangerouslySetInnerHTML={{
                           __html: ImpactAssessmentsStore.auditData?.shortTitle,
                        }}
                     ></p>
                  }
               </Title>
               <Title level={5} style={{ color: 'rgb(250, 140, 22)' }}>
                  {
                     <p
                        dangerouslySetInnerHTML={{
                           __html:
                              ImpactAssessmentsStore.auditData
                                 ?.introductionLine,
                        }}
                     ></p>
                  }
               </Title>

               <Paragraph>
                  {
                     <span
                        className="summaryRow"
                        dangerouslySetInnerHTML={{
                           __html: ImpactAssessmentsStore.auditData?.summary,
                        }}
                     ></span>
                  }{' '}
                  <div ref={auditSectionRef} />
                  &nbsp;&nbsp;&nbsp;
                  <ReadOutlined style={{ color: 'rgb(250, 140, 22)' }} />
                  <Button
                     type="link"
                     onClick={(e: any) => setVisible(true)}
                     style={{ marginLeft: '0.5%', color: 'rgb(250, 140, 22)' }}
                  >
                     Read More
                  </Button>
               </Paragraph>
            </Card>
            <Drawer
               title="RULE DETAIL"
               placement="right"
               closable={true}
               onClose={onClose}
               visible={visible}
               width="700px"
            >
               <Paragraph>{drawerSummary}</Paragraph>
            </Drawer>
            <Card bordered={false}>
               <Row style={{ border: 2 }}>
                  <Col span={3}>
                     <Text>
                        <strong>Regulators:</strong>
                     </Text>
                  </Col>
                  <Col span={9}>
                     <Text>
                        {ImpactAssessmentsStore.taxonomies?.regulators}
                     </Text>
                  </Col>

                  <Col style={{ marginLeft: '3%' }} span={4}>
                     <Text>
                        <strong>Entity Types :</strong>
                     </Text>
                  </Col>
                  <Col span={7}>
                     <Text>
                        {' '}
                        {ImpactAssessmentsStore.taxonomies?.entityTypes}{' '}
                     </Text>
                  </Col>
               </Row>
               <Row style={{ border: 2 }}>
                  <Col span={3}>
                     <Text>
                        <strong>Reference:</strong>
                     </Text>
                  </Col>
                  <Col span={9}>
                     <Text>{ImpactAssessmentsStore.auditData?.reference} </Text>
                  </Col>

                  <Col style={{ marginLeft: '3%' }} span={4}>
                     <Text>
                        <strong>Functions:</strong>
                     </Text>
                  </Col>
                  <Col span={7}>
                     <Text>
                        {ImpactAssessmentsStore.taxonomies?.functions}{' '}
                     </Text>
                  </Col>
               </Row>
               <Row style={{ border: 2 }}>
                  <Col span={3}>
                     <Text>
                        <strong>Countries:</strong>
                     </Text>
                  </Col>
                  <Col span={9}>
                     {ImpactAssessmentsStore.taxonomies?.countries}{' '}
                  </Col>

                  <Col style={{ marginLeft: '3%' }} span={4}>
                     <Text>
                        <strong>Products:</strong>
                     </Text>
                  </Col>
                  <Col span={7}>
                     {ImpactAssessmentsStore.taxonomies?.products}{' '}
                  </Col>
               </Row>
               <Row style={{ border: 2 }}>
                  <Col span={3}>
                     <Text>
                        <strong>Regions:</strong>
                     </Text>
                  </Col>
                  <Col span={9}>
                     {ImpactAssessmentsStore.taxonomies?.regions}{' '}
                  </Col>

                  <Col style={{ marginLeft: '3%' }} span={4}>
                     <Text>
                        <strong>Rule Type:</strong>
                     </Text>
                  </Col>
                  <Col span={7}>
                     {ImpactAssessmentsStore.selectedAssessmentRule.ruleType}{' '}
                  </Col>
               </Row>
               <Row style={{ border: 2 }}>
                  <Col span={3}>
                     <Text>
                        <strong>Rule Date:</strong>
                     </Text>
                  </Col>
                  <Col span={9}>
                     <Text>{ImpactAssessmentsStore.auditData?.ruleDate} </Text>
                  </Col>

                  <Col style={{ marginLeft: '3%' }} span={4}>
                     <Text>
                        <strong>Effective Date:</strong>
                     </Text>
                  </Col>
                  <Col span={7}>
                     <Text>
                        {ImpactAssessmentsStore.auditData?.effectiveDate}{' '}
                     </Text>
                  </Col>
               </Row>
               <Row style={{ border: 2 }}>
                  <Col span={3}>
                     <Text>
                        <strong>Last Update Date:</strong>
                     </Text>
                  </Col>
                  <Col span={9}>
                     <Text>
                        {ImpactAssessmentsStore.auditData?.lastUpdateDate}{' '}
                     </Text>
                  </Col>

                  <Col style={{ marginLeft: '3%' }} span={4}>
                     <Text>
                        <strong>Reg. Last Update:</strong>
                     </Text>
                  </Col>
                  <Col span={7}>
                     <Text>
                        {ImpactAssessmentsStore.auditData?.reportIssueDate}{' '}
                     </Text>
                  </Col>
               </Row>
            </Card>
            <Divider
               style={{ backgroundColor: 'rgb(250, 140, 22)', opacity: '0.3' }}
            />{' '}
            <Table
               size="small"
               columns={reportTableColumns}
               dataSource={ImpactAssessmentsStore.reportAudit}
               pagination={{
                  position: ['bottomCenter'],
               }}
            ></Table>
            <Row
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2%',
               }}
            ></Row>
            <>
               <Row></Row>
               <Form
                  form={form}
                  name="advanced_assessment_form"
                  className="ant-advanced-search-form"
                  onFinish={onSaveAssessment}
               >
                  {' '}
                  <Row>
                     <Col span={3} style={{ marginTop: '0.5%' }}>
                        Is comment only ?
                     </Col>
                     <Col span={3} style={{ marginTop: '0.5%' }}>
                        <Checkbox
                           checked={
                              ImpactAssessmentsStore.assessmentDetail
                                 .isCommentOnly
                           }
                           onChange={(e: any) => {
                              ImpactAssessmentsStore.setIsCommentOnly(
                                 e.target.checked
                              )
                           }}
                        ></Checkbox>
                     </Col>
                     <Col
                        span={2}
                        style={{ marginTop: '0.5%', textAlign: 'right' }}
                     >
                        {!ImpactAssessmentsStore.isCommentOnly && (
                           <Text
                              className="mandatoryAsterix"
                              style={{ marginRight: '5%' }}
                           >
                              {' '}
                              <strong>Due Date:</strong>{' '}
                           </Text>
                        )}
                     </Col>
                     <Col span={6}>
                        {!ImpactAssessmentsStore.isCommentOnly && (
                           <Form.Item
                              name="datePickerField"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Field is required!',
                                 },
                              ]}
                           >
                              <DatePicker
                                 style={{ width: '80%' }}
                                 allowClear={true}
                                 format={
                                    ImpactAssessmentsStore.localeDateFormat
                                 }
                                 onChange={(val: any) => {
                                    ImpactAssessmentsStore.setAssessmentDetailDueDate(
                                       val
                                    )
                                 }}
                              />
                           </Form.Item>
                        )}
                     </Col>
                     <Col
                        span={2}
                        style={{
                           marginTop: '0.5%',
                           textAlign: 'right',
                        }}
                     >
                        <Text
                           className="mandatoryAsterix"
                           style={{ marginRight: '5%' }}
                        >
                           {' '}
                           <strong>Risk Level:</strong>{' '}
                        </Text>
                     </Col>
                     <Col span={6}>
                        <Select
                           style={{ width: '94%' }}
                           placeholder="Select Risk Level"
                           size="middle"
                           value={
                              ImpactAssessmentsStore.assessmentDetail
                                 .riskLevelId
                           }
                           onChange={(e: any) =>
                              ImpactAssessmentsStore.setAssessmentDetailRiskLevelId(
                                 e
                              )
                           }
                           className={
                              ImpactAssessmentsStore.riskLevelValidator
                                 ? 'mandatory-border'
                                 : undefined
                           }
                           options={getRiskLevels}
                           showSearch
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              (option?.label ?? '')
                                 .toLowerCase()
                                 .includes(input.toLowerCase())
                           }
                        />
                        {ImpactAssessmentsStore.riskLevelValidator && (
                           <span style={{ color: 'red', fontSize: '13px' }}>
                              Risk level is required!{' '}
                           </span>
                        )}
                     </Col>
                  </Row>
                  <Row style={{ marginBottom: '-2%' }}>
                     <Col span={6}></Col>
                     <Col
                        span={2}
                        style={{
                           marginTop: '0.5%',
                           textAlign: 'right',
                        }}
                     >
                        <Text style={{ marginRight: '5%' }}>
                           {' '}
                           <strong>Topic:</strong>{' '}
                        </Text>
                     </Col>
                     <Col span={6}>
                        <Form.Item name="topicField">
                           <Select
                              style={{ width: '80%' }}
                              mode="multiple"
                              placeholder="Select Topics"
                              size="middle"
                              value={
                                 ImpactAssessmentsStore.assessmentDetail.topicId
                              }
                              onChange={(e: any) =>
                                 ImpactAssessmentsStore.setAssessmentDetailTopicId(
                                    e
                                 )
                              }
                              optionLabelProp="label"
                              showSearch
                              optionFilterProp="children"
                              options={getTopics}
                              filterOption={(input, option) =>
                                 (option?.label ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                              }
                           />
                        </Form.Item>
                     </Col>
                     <Col
                        span={2}
                        style={{
                           marginTop: '0.5%',
                           textAlign: 'right',
                        }}
                     >
                        <Text
                           className="mandatoryAsterix"
                           style={{ marginRight: '5%' }}
                        >
                           {' '}
                           <strong>Status:</strong>{' '}
                        </Text>
                     </Col>
                     <Col span={7} style={{ display: 'inline-grid' }}>
                        <Select
                           style={{ width: '80%' }}
                           placeholder="Select Status"
                           size="middle"
                           value={
                              ImpactAssessmentsStore.assessmentDetail.statusId
                           }
                           onChange={(e: any) =>
                              ImpactAssessmentsStore.setAssessmentDetailStatusId(
                                 e
                              )
                           }
                           optionLabelProp="label"
                           showSearch
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              (option?.label ?? '')
                                 .toLowerCase()
                                 .includes(input.toLowerCase())
                           }
                           options={getStatus}
                        />
                        {ImpactAssessmentsStore.statusValidator && (
                           <span style={{ color: 'red', fontSize: '13px' }}>
                              Status is required!{' '}
                           </span>
                        )}
                     </Col>
                  </Row>
                  {ImpactAssessmentsStore.filesToDisplay !== null &&
                     ImpactAssessmentsStore.filesToDisplay.length > 0 && (
                        <>
                           <Row>
                              <Text style={{ marginBottom: '10px' }}>
                                 {' '}
                                 <strong>Attached files:</strong>{' '}
                              </Text>
                           </Row>
                           <Row
                              wrap
                              style={{
                                 rowGap: '10px',
                                 columnGap: '10px',
                              }}
                           >
                              {ImpactAssessmentsStore.filesToDisplay?.map(
                                 (f) => (
                                    <Col>
                                       <FileItem item={f} key={f.id} />
                                    </Col>
                                 )
                              )}
                           </Row>
                        </>
                     )}
                  <Row
                     style={{
                        marginTop: 20,
                     }}
                  >
                     <Text style={{ marginBottom: '10px' }}>
                        {' '}
                        <strong>Attach a new file</strong>{' '}
                     </Text>
                  </Row>
                  <Row>
                     <DragAndDrop
                        onFileChange={handleDragFileChange}
                        onFileDelete={handleDragFileDeleted}
                        fileList={files}
                     />
                  </Row>
                  <Row style={{ marginTop: '2%' }}>
                     <Col span={24}>
                        <Form.Item
                           name="editor"
                           rules={[
                              {
                                 required: true,
                                 message: 'Field is required!',
                              },
                           ]}
                        >
                           {isLayoutReady && useCKEditor}
                        </Form.Item>
                     </Col>
                  </Row>{' '}
                  <Row
                     style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '2%',
                     }}
                  >
                     <Col>
                        {isUpdate ? (
                           <Button
                              onClick={onUpdateAssessment}
                              icon={<SaveOutlined />}
                              style={{
                                 borderRadius: '10px',
                                 backgroundColor: 'rgb(250, 140, 22)',
                                 borderColor: 'rgb(250, 140, 22)',
                                 color: 'white',
                                 marginLeft: '-2%',
                              }}
                           >
                              Update
                           </Button>
                        ) : (
                           <Button
                              htmlType="submit"
                              icon={<SaveOutlined />}
                              style={{
                                 borderRadius: '10px',
                                 backgroundColor: 'rgb(250, 140, 22)',
                                 borderColor: 'rgb(250, 140, 22)',
                                 color: 'white',
                                 marginLeft: '-2%',
                              }}
                           >
                              Save
                           </Button>
                        )}
                        <Button
                           onClick={() => onCancelButton()}
                           style={{
                              borderRadius: '10px',
                              backgroundColor: 'white',
                              borderColor: 'rgb(250, 140, 22)',
                              color: 'rgb(250, 140, 22)',
                              marginLeft: '2%',
                           }}
                        >
                           Cancel
                        </Button>
                     </Col>
                  </Row>
               </Form>
            </>
         </Card>
      </Spin>
   )
})
export default AssessmentForm
