/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import {
   Alert,
   Button,
   Col,
   DatePicker,
   Divider,
   Input,
   Modal,
   Row,
   Select,
   Spin,
   Switch,
   Tree,
   Typography,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { ReactComponent as Clear } from '../../../assets/clear.svg'
import moment from 'moment'
import { DragAndDrop } from '../../utils'
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/es/upload/interface'
import { urlToServer } from '../../../helpers'
import { AssessmentAttachedFileType } from '../../../types'

const { TextArea } = Input
const { Text } = Typography

const UPDATE_FILE = '__UPDATE_FILE__'

export interface IAssessmentModal {
   initializeValuesFromStore: boolean
   title: string
   selected?: any[]
   action?: () => void
}

const AssessmentModal = observer((props: IAssessmentModal) => {
   const [isCommentVisible, setIsCommentVisible] = useState(false)
   const [isAttachedVisible, setIsAttachedVisible] = useState(false)
   const [files, setFiles] = useState<RcFile[]>([])
   const { ImpactAssessmentsStore } = useStore()
   const [filesToRemove, setFileToRemove] = useState<number[]>([])
   const [filesToAdd, setFileToAdd] = useState<RcFile[]>([])

   const nonExistDepartmentMessage =
      'It appears that you do not have a user associated to a department. From the “Company User Page” assign users to the department and try again.'

   useEffect(() => {
      if (props.initializeValuesFromStore) {
         let assessment: any = {}

         const index = ImpactAssessmentsStore.impactAssessments?.findIndex(
            (e) =>
               e.assessmentId === ImpactAssessmentsStore.selectedAssessmentId
         )
         if (index > -1) {
            assessment = ImpactAssessmentsStore.impactAssessments[index]

            ImpactAssessmentsStore.initializeAssessorTree(
               getSelectedAssessorIds(assessment),
               getAssessmentDepartmentIds(assessment)
            )
            // TODO : TOPIC
            ImpactAssessmentsStore.setTopicKeys(
               getSelectedTopicKeysById(assessment)
            )
            ImpactAssessmentsStore.setRiskKey(
               getSelectedRiskKeyById(assessment)
            )
            ImpactAssessmentsStore.setStatusKey(
               getSelectedStatusKeyById(assessment)
            )
            // ImpactAssessmentsStore.setDepartmentIds(
            //    getSelectedStatusKeyById(assessment)
            // )
            ImpactAssessmentsStore.setResDueDate(
               moment(
                  assessment.dateResponseDue,
                  ImpactAssessmentsStore.localeDateFormat
               )
            )
            setIsCommentVisible(assessment.comment !== '')
            ImpactAssessmentsStore.setComment(assessment.comment)
            ImpactAssessmentsStore.setInternalNumber(assessment.internalNumber)
            ImpactAssessmentsStore.setAttachedFiles(assessment.attachedFile)
            setIsAttachedVisible(assessment.attachedFilesOut?.length > 0)
            setFiles(
               assessment.attachedFilesOut?.map(
                  (f: AssessmentAttachedFileType) => ({
                     uid: f.id,
                     name: f.fileName,
                     status: 'done',
                     url: urlToServer(f.filePath),
                     type: UPDATE_FILE,
                     fullName: f,
                  })
               )
            )
            setFileToAdd([])
            setFileToRemove([])
         }
      } else {
         ImpactAssessmentsStore.clearAssessmentModalState()
         setIsCommentVisible(false)
      }
   }, [
      ImpactAssessmentsStore.selectedAssessmentId,
      props.initializeValuesFromStore,
   ])

   useEffect(() => {
      ImpactAssessmentsStore.getUsersByDepartment()
   }, [])

   const handleDragFileChange = useCallback(
      (data: RcFile) => {
         setFiles((fs) => {
            return [...fs, data]
         })
         if (ImpactAssessmentsStore.selectedAssessmentId)
            setFileToAdd((files) => [...files, data])
      },
      [ImpactAssessmentsStore.selectedAssessmentId]
   )

   const handleDragFileDeleted = useCallback(
      (data: UploadFile) => {
         setFiles((fs) => {
            return [...fs.filter((f) => f.uid !== data.uid)]
         })
         setFileToAdd((fs) => {
            return [...fs.filter((f) => f.uid !== data.uid)]
         })
         if (
            ImpactAssessmentsStore.selectedAssessmentId &&
            data?.type?.toLowerCase() === UPDATE_FILE.toLowerCase()
         )
            setFileToRemove((files) => [...files, (data as any).uid as number])
      },
      [ImpactAssessmentsStore.selectedAssessmentId]
   )

   const getSelectedAssessorIds = (assessment: any) => {
      let selectedUserIds = [] as any

      const selectedAssessors = assessment.allocatedToUser
      selectedUserIds = selectedAssessors.map((e: any) => {
         return e.id
      })
      return selectedUserIds
   }
   const getAssessmentDepartmentIds = (assessment: any) => {
      let selectedDepartmentIds: number[] = []

      const selectedDepartments = assessment.departments
      selectedDepartmentIds = selectedDepartments.map((e: any) => {
         return e.key
      })
      return selectedDepartmentIds
   }
   const getSelectedTopicKeysById = (assessment: any) => {
      let selectedValues = []
      selectedValues = assessment.topic?.map((e: any) => e.key)
      return selectedValues
   }

   const getSelectedRiskKeyById = (assessment: any) => {
      let selectedValue = null
      selectedValue = assessment.riskLevel?.key
      return selectedValue
   }

   const getSelectedStatusKeyById = (assessment: any) => {
      let selectedValue = null
      selectedValue = assessment.status?.key
      return selectedValue
   }

   const onAssessorModalOk = (params: any) => {
      if (
         ImpactAssessmentsStore.selectedAssesorKeys.length === 0 ||
         ImpactAssessmentsStore.riskKey === null ||
         ImpactAssessmentsStore.statusKey === null ||
         ImpactAssessmentsStore.resDueDate === null
      ) {
         ImpactAssessmentsStore.modalErrorMessage =
            'Complete the form. All mandatory fields must be selected. '
         ImpactAssessmentsStore.hasModalError = true
         return
      }
      ImpactAssessmentsStore.hasModalError = false
      ImpactAssessmentsStore.setAttachedFiles(files)

      props.initializeValuesFromStore
         ? ImpactAssessmentsStore.updateAssessment(
              filesToAdd,
              filesToRemove,
              props.action
           )
         : ImpactAssessmentsStore.sendAssessment(props.action, props.selected)

      ImpactAssessmentsStore.clearAssessmentModalState()
      setIsCommentVisible(false)
      setFiles([])
      setIsAttachedVisible(false)
      setFileToAdd([])
      setFileToRemove([])
   }

   const onAssessorModalCancel = (params: any) => {
      ImpactAssessmentsStore.setIsAssessmentModalVisible(false)
      ImpactAssessmentsStore.setSelectedAssessmentId('')
      setIsAttachedVisible(false)
      setFiles([])
   }

   const onCheck = (checkedKeys: any, obj: any) => {
      ImpactAssessmentsStore.setAssessorsKey(checkedKeys)
      obj.checked
         ? ImpactAssessmentsStore.addToAssignedDepartmentIds(
              obj.node.departmentId
           )
         : ImpactAssessmentsStore.removeAssignedDepartmentId(
              obj.node.departmentId
           )
   }

   const onCommentSwitch = (event: any) => {
      ImpactAssessmentsStore.setComment('')
      setIsCommentVisible(event)
   }

   const onAttachmentSwitch = useCallback((event: boolean) => {
      setIsAttachedVisible(event)
   }, [])

   const onCommentChange = (params: any) => {
      ImpactAssessmentsStore.setComment(params.target.value)
   }

   const getRiskLevels = ImpactAssessmentsStore.riskLevels?.map((e: any) => {
      return {
         value: e.key,
         label: e.description,
      }
   })

   const getStatus = ImpactAssessmentsStore.status?.map((e: any) => {
      return {
         value: e.key,
         label: e.value,
      }
   })

   const getTopics = ImpactAssessmentsStore.topics?.map((e: any) => {
      return {
         value: e.key,
         label: e.description,
      }
   })

   const footerButtons = ImpactAssessmentsStore.isTreeViewExist
      ? [
           <Button
              key="back"
              style={{ borderRadius: '10px' }}
              icon={<Clear />}
              onClick={onAssessorModalCancel}
           >
              Cancel
           </Button>,
           <Button
              key="submit"
              type="primary"
              onClick={onAssessorModalOk}
              style={{
                 backgroundColor: 'rgb(250, 140, 22)',
                 borderColor: 'rgb(250, 140, 22)',
                 borderRadius: '10px',
                 color: 'white',
              }}
              icon={<SaveOutlined />}
           >
              {props.initializeValuesFromStore ? 'Update' : 'Allocate'}
           </Button>,
        ]
      : [
           <Button
              key="back"
              style={{ borderRadius: '10px' }}
              icon={<Clear />}
              onClick={onAssessorModalCancel}
           >
              Cancel
           </Button>,
        ]

   return (
      <Modal
         title={props.title}
         // style={{ backgroundColor: 'grey' }}
         open={ImpactAssessmentsStore.isAssessmentModalVisible}
         onCancel={onAssessorModalCancel}maskClosable={false}
         footer={footerButtons}
         destroyOnClose={true}
         width="55%"
         
      >
         <Spin spinning={ImpactAssessmentsStore.isModalLoading}>
            {ImpactAssessmentsStore.isTreeViewExist && (
               <>
                  {ImpactAssessmentsStore.hasModalError && (
                     <>
                        <Alert
                           message={ImpactAssessmentsStore.modalErrorMessage}
                           type="error"
                           showIcon
                        />
                        <br />
                     </>
                  )}{' '}
                  {props.initializeValuesFromStore && (
                     <>
                        <Alert
                           message="Change assessors and other settings on this items"
                           type="info"
                           showIcon
                        />
                        <br />
                     </>
                  )}
                  <Tree
                     checkable
                     autoExpandParent={false}
                     onCheck={onCheck}
                     checkedKeys={ImpactAssessmentsStore.selectedAssesorKeys}
                     treeData={ImpactAssessmentsStore.usersByDepartment}
                  />
                  <Divider
                     style={{
                        backgroundColor: 'rgb(250, 140, 22)',
                        opacity: '0.3',
                     }}
                  />
                  <Row style={{ marginTop: '4%' }}>
                     <Col
                        span={6}
                        style={{
                           marginTop: '1%',
                        }}
                     >
                        <Text className="mandatoryAsterix">
                           {' '}
                           Assessment Status:{' '}
                        </Text>
                     </Col>
                     <Col span={8}>
                        <Select
                           style={{ width: '70%' }}
                           placeholder="Select Status Types"
                           size="middle"
                           value={ImpactAssessmentsStore.statusKey}
                           onChange={(e: any) =>
                              ImpactAssessmentsStore.setStatusKey(e)
                           }
                           optionLabelProp="label"
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              (option?.label ?? '')
                                 .toLowerCase()
                                 .includes(input.toLowerCase())
                           }
                           showSearch
                           options={getStatus}
                        />
                     </Col>{' '}
                     <Col
                        span={4}
                        style={{
                           marginTop: '1%',
                        }}
                     >
                        <Text className="mandatoryAsterix"> Risk Level: </Text>
                     </Col>
                     <Col span={6}>
                        <Select
                           style={{ width: '90%' }}
                           placeholder="Select Risk Level"
                           size="middle"
                           value={ImpactAssessmentsStore.riskKey}
                           onChange={(e: any) =>
                              ImpactAssessmentsStore.setRiskKey(e)
                           }
                           optionLabelProp="label"
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              (option?.label ?? '')
                                 .toLowerCase()
                                 .includes(input.toLowerCase())
                           }
                           showSearch
                           options={getRiskLevels}
                        />
                     </Col>{' '}
                  </Row>
                  <Row style={{ marginTop: '4%' }}>
                     <Col
                        span={6}
                        style={{
                           marginTop: '1%',
                        }}
                     >
                        <Text className="mandatoryAsterix">
                           {' '}
                           Response Due Date:{' '}
                        </Text>
                     </Col>
                     <Col span={8}>
                        <DatePicker
                           value={moment(ImpactAssessmentsStore.resDueDate)}
                           onChange={(e: any) =>
                              ImpactAssessmentsStore.setResDueDate(e)
                           }
                           format={ImpactAssessmentsStore.localeDateFormat}
                        />
                     </Col>{' '}
                     <Col
                        span={4}
                        style={{
                           marginTop: '1%',
                        }}
                     >
                        <Text> Topic: </Text>
                     </Col>
                     <Col span={6}>
                        <Select
                           style={{ width: '90%' }}
                           showSearch
                           mode="multiple"
                           placeholder="Select Topics"
                           size="middle"
                           value={ImpactAssessmentsStore.topicKeys}
                           optionFilterProp="children"
                           filterOption={(input, option) =>
                              (option?.label ?? '')
                                 .toLowerCase()
                                 .includes(input.toLowerCase())
                           }
                           onChange={(e: any) =>
                              ImpactAssessmentsStore.setTopicKeys(e)
                           }
                           optionLabelProp="label"
                           options={getTopics}
                        ></Select>
                     </Col>{' '}
                  </Row>
                  <Row style={{ marginTop: '4%' }}>
                     <Col span={6}>
                        <Text> Comment: </Text>
                     </Col>
                     <Col span={8}>
                        <Switch
                           title="Comment"
                           onChange={onCommentSwitch}
                           defaultChecked={isCommentVisible}
                        />
                     </Col>{' '}
                  </Row>
                  {isCommentVisible && (
                     <Row style={{ marginTop: '2%' }}>
                        <TextArea
                           rows={4}
                           onChange={(e: any) => onCommentChange(e)}
                           value={ImpactAssessmentsStore.comment}
                        />
                     </Row>
                  )}
                  <Row style={{ marginTop: '4%' }}>
                     <Col span={6}>
                        <Text> Attached files: </Text>
                     </Col>
                     <Col span={8}>
                        <Switch
                           title="Comment"
                           onChange={onAttachmentSwitch}
                           defaultChecked={isCommentVisible}
                           checked={isAttachedVisible}
                        />
                     </Col>{' '}
                  </Row>
                  {isAttachedVisible && (
                     <Row style={{ marginTop: '2%' }}>
                        <DragAndDrop
                           onFileChange={handleDragFileChange}
                           onFileDelete={handleDragFileDeleted}
                           fileList={files as any}
                        />
                     </Row>
                  )}
               </>
            )}
            {!ImpactAssessmentsStore.isTreeViewExist && (
               <Row style={{ marginBottom: '1%' }}>
                  <Col span={24}>
                     <Alert
                        message={nonExistDepartmentMessage}
                        type="error"
                        showIcon
                     />
                  </Col>
               </Row>
            )}
         </Spin>
      </Modal>
   )
})

export default AssessmentModal
