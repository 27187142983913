import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import type { Moment } from 'moment'
import { disabledDate } from '../../../../utils/Helpers'

const { RangePicker } = DatePicker
export type RangeValue = [Moment | null, Moment | null] | null

interface ICustomRangePicker {
   value?: RangeValue
   dateFormat?: string
   onChange: (val: RangeValue) => void
   maxDays?: number   
   clearOnOpen?: boolean
}

const CustomRangePicker = ({
   value = null,
   dateFormat = 'YYYY/MM/DD',
   onChange,
   maxDays = 365,
   clearOnOpen = false,
}: ICustomRangePicker) => {
   const [dates, setDates] = useState<RangeValue>(null)
   const [currentValue, setValue] = useState<RangeValue>(null)

   useEffect(() => {
      setDates(value)
   }, [value])

   const onOpenChange = (open: boolean) => {
      if (clearOnOpen) {
         if (open) {
            setDates([null, null])
         } else {
            setDates(null)
         }
      }
   }

   const handleChange = (val: RangeValue) => {
      setValue(val)
      onChange(val)
   }

   return (
      <RangePicker
         value={dates || currentValue}
         disabledDate={(e) => disabledDate(e, dates, maxDays)}
         onCalendarChange={(val) => setDates(val)}
         onChange={handleChange}
         onOpenChange={onOpenChange}
         format={dateFormat}
         autoComplete="none"
         style={{ width: '100%' }}
      />
   )
}

export default CustomRangePicker
