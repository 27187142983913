import { createContext, useContext } from 'react'
import { DailyRulesStore } from '../features/dailyRules/DailyRulesStore'
import { FilterStore } from '../features/defaultFilters/FilterStore'
import { SetttingsStore } from '../features/settings/SettingsStore'
import { DepartmentsStore } from '../features/departments/DepartmentsStore'
import { CompanyUsersStore } from '../features/companyUsers/CompanyUsersStore'
import { ImpactAssessmentsStore } from '../features/impactAssessments/ImpactAssessmentsStore'
import { LoginStore } from '../features/login/LoginStore'
import { DashboardStore } from '../features/dashboard/DashboardStore'
import { CommonStore } from './CommonStore'
import { MainLayoutStore } from '../components/MainLayoutStore'
import { AuthStore } from '../auth/AuthStore'
import { NewsletterStore } from '../features/newsletter/NewsletterStore'

const commonStore = new CommonStore()

export const storeContexts = createContext({
   AuthStore: new AuthStore(commonStore),
   DailyRulesStore: new DailyRulesStore(commonStore),
   FilterStore: new FilterStore(commonStore),
   SetttingsStore: new SetttingsStore(),
   commonStore,
   DepartmentsStore: new DepartmentsStore(),
   CompanyUsersStore: new CompanyUsersStore(),
   ImpactAssessmentsStore: new ImpactAssessmentsStore(),
   LoginStore: new LoginStore(commonStore),
   DashboardStore: new DashboardStore(),
   MainLayoutStore: new MainLayoutStore(commonStore),
   NewsletterStore: new NewsletterStore(commonStore),
})

export const useStore = () => useContext(storeContexts)
