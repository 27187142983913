import {
   Badge,
   Button,
   Card,
   Col,
   ConfigProvider,
   Divider,
   Input,
   Modal,
   Row,
   Skeleton,
   Space,
   Spin,
   Switch,
   Table,
   Typography,
} from 'antd'
import { observer } from 'mobx-react'
import { RangeValue } from '../CustomRangePicker'
import Widget from './Widget'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useStore } from '../../../../../context'
import {
   CloseCircleFilled,
   FileExcelOutlined,
   SearchOutlined,
} from '@ant-design/icons'

interface IWidget {
   dates: RangeValue
   admin: string | null | undefined
}

const { Text, Paragraph } = Typography

const widgetData = [
   {
      id: 'createdEvent',
      title: 'New Events',
   },
   {
      id: 'updatedEvent',
      title: 'Updated Events',
   },
   {
      id: 'deletedEvent',
      title: 'Deleted Events',
   },
   {
      id: 'allocatedEvent',
      title: 'Allocated Events',
   },
]

const getValue = (id: string, widgets: any): number => {
   if (
      typeof widgets !== 'undefined' &&
      widgets != null &&
      widgets.hasOwnProperty(id)
   ) {
      return widgets[id]
   }
   return 0
}

const WidgetSkeleton = () => {
   return (
      <>
         {widgetData.map((_, idx) => {
            return (
               <Col
                  md={12}
                  sm={24}
                  lg={6}
                  xl={6}
                  key={`widget-skeleton-${idx}`}
               >
                  <Skeleton.Input active={true} size="large" block={true} />
               </Col>
            )
         })}
      </>
   )
}

const WidgetData = ({ widgets, openModal }: any) => {
   return (
      <>
         {widgetData.map((widget, idx) => {
            return (
               <Col md={12} sm={24} lg={6} xl={6} key={`widget-${idx}`}>
                  <Widget
                     id={widget.id}
                     title={widget.title}
                     openModal={openModal}
                     value={getValue(widget.id, widgets)}
                  />
               </Col>
            )
         })}
      </>
   )
}

const Widgets = observer(({ dates, admin }: IWidget) => {
   const { DashboardStore, DailyRulesStore } = useStore()
   const startDate = moment().subtract(7, 'days')
   const endDate = moment()
   const [open, setOpen] = useState(false)
   const [searchText, setSearchText] = useState<string>('')
   const [modalTitle, setModalTitle] = useState<string>('New Event')
   const [eventType, setEventType] = useState<string>('createdEvent')
   const [isRuleDetailsModalVisible, setIsRuleDetailsModalVisible] = useState(
      false
   )

   useEffect(() => {
      const sD = dates != null && dates.length > 1 ? dates[0] : startDate
      const eD = dates != null && dates.length > 1 ? dates[1] : endDate

      DashboardStore.getEventsStatistics(sD, eD, admin)

      return () => {
         // Clean components
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dates, admin])

   const capitalize = (text: string) => {
      return text[0].toUpperCase() + text.slice(1)
   }

   const openModal = (eventType: string, eventTitle: string) => {
      setModalTitle(eventTitle)
      setEventType(eventType)
      setOpen(true)
      
      const sD = dates != null && dates.length > 1 ? dates[0] : startDate
      const eD = dates != null && dates.length > 1 ? dates[1] : endDate

      DashboardStore.getEvents(capitalize(eventType), sD, eD, admin)
   }

   const ruleListSearchboxOnChange = (target: any) => {
      let targetValue = target?.target?.value
      setSearchText(targetValue)
   }

   const onExportToExcel = (e: any) => {
      e.preventDefault()
      DashboardStore.exportEventsToExcel(capitalize(eventType), dates, admin)
   }

   const columns = [
      {
         title: 'Rule ID',
         dataIndex: 'mainRuleId',
         key: 'mainRuleId',
         render: (text: string) => (
            <a
               href={'https://reg-track.com/rules/search/Details/' + text}
               target="_blank"
               rel="noreferrer"
               style={{ cursor: 'pointer' }}
            >
               {text}
            </a>
         ),
      },
      {
         title: 'Title',
         dataIndex: 'shortTitle',
         key: 'shortTitle',
         width: 'auto',
         render: (text: string, record: any) => {
            return (
               <div style={{ display: 'flex' }}>
                  <div
                     className="ant-btn-link"
                     onClick={(e) => onShortTitleClick(record)}
                     style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        cursor: 'pointer',
                        float: 'left',
                     }}
                  >
                     {text}
                  </div>
                  {record.changeType > 1 && (
                     <Badge
                        status="processing"
                        className="versionBadge"
                     ></Badge>
                  )}
               </div>
            )
         },
      },
      {
         title: 'Introduction Line',
         dataIndex: 'introductionLine',
         key: 'introductionLine',
         render: (text: string, record: any) => {
            return (
               <div style={{ display: 'flex' }}>
                  <span
                     dangerouslySetInnerHTML={{ __html: text }}
                     style={{ marginTop: '2%' }}
                  ></span>
                  <Button
                     type="link"
                     shape="circle"
                     style={{ marginLeft: '1%' }}
                  >
                     <Badge
                        count={record.assessmentCount ?? 1}
                        //count={1}
                        style={{ backgroundColor: '#52c41a' }}
                     ></Badge>
                  </Button>
               </div>
            )
         },
      },
      {
         title: 'Type',
         dataIndex: 'ruleType',
         key: 'ruleType',
      },
   ]

   const onShortTitleClick = (params: any) => {
      DailyRulesStore.setSelectedRuleType(params.ruleType)
      DailyRulesStore.getRuleDetails(
         params.mainRuleId,
         params.mainRuleAuditCoreId
      )
      setIsRuleDetailsModalVisible(true)
   }

   const footerButtons = [
      <Button
         type="primary"
         onClick={() => setIsRuleDetailsModalVisible(false)}
         style={{
            backgroundColor: 'rgb(250, 140, 22)',
            borderColor: 'rgb(250, 140, 22)',
            borderRadius: '10px',
            color: 'white',
         }}
         // icon={<SaveOutlined />}
      >
         Ok
      </Button>,
   ]

   const onRuleDetailModal = () => {
      setIsRuleDetailsModalVisible(false)
      DailyRulesStore.setFullVersion(true)
   }

   const onVersionSwitchChange = (event: any) => {
      DailyRulesStore.setFullVersion(event)
   }

   return (
      <Row gutter={[12, 12]}>
         {DashboardStore.isWidgetLoading ? (
            <WidgetSkeleton />
         ) : (
            <WidgetData
               widgets={DashboardStore.eventsStatistics}
               openModal={openModal}
            />
         )}
         <Modal
            title={DailyRulesStore.selectedRuleDetails.shortTitle}
            open={isRuleDetailsModalVisible}
            onCancel={onRuleDetailModal}
            footer={footerButtons}
            destroyOnClose={true}
            width={670}
            bodyStyle={{ overflowY: 'scroll', height: '400px' }}
         >
            <Spin spinning={DailyRulesStore.isLoading}>
               {!DailyRulesStore.connectionErrorMessageVisible && (
                  <>
                     <Row>
                        <Col span={20}>
                           <p
                              style={{
                                 fontSize: 'medium',
                                 color: '#f57f01',
                                 textAlign: 'left',
                                 marginLeft: '1%',
                              }}
                              dangerouslySetInnerHTML={{
                                 __html: DailyRulesStore.fullVersion
                                    ? DailyRulesStore.selectedRuleDetails
                                         .introductionLine
                                    : DailyRulesStore
                                         .selectedRuleDetailsAuditData
                                         .introductionLine,
                              }}
                           ></p>
                        </Col>
                        <Col span={4}>
                           <Switch
                              checkedChildren="Full Version"
                              unCheckedChildren="Last Update Version"
                              checked={DailyRulesStore.fullVersion}
                              onChange={(event) => onVersionSwitchChange(event)}
                           />
                        </Col>
                     </Row>

                     <Divider
                        style={{
                           backgroundColor: 'rgb(250, 140, 22)',
                           opacity: '0.3',
                           marginBottom: '2%',
                           marginTop: '0%',
                        }}
                     />
                     <Paragraph>
                        {
                           <p
                              // style={{ color: '#874d00' }}
                              className="summaryRow"
                              dangerouslySetInnerHTML={{
                                 __html: DailyRulesStore.fullVersion
                                    ? DailyRulesStore.selectedRuleDetails
                                         .summary
                                    : DailyRulesStore
                                         .selectedRuleDetailsAuditData.summary,
                              }}
                           ></p>
                        }
                     </Paragraph>
                     {/* <Divider />{' '} */}
                     <Card style={{ backgroundColor: '#fffbe6' }}>
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong> Regulators</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {' '}
                              {
                                 DailyRulesStore.selectedRuleTaxonomies
                                    .regulators
                              }
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Entity Type:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {' '}
                              {
                                 DailyRulesStore.selectedRuleTaxonomies
                                    .entityTypes
                              }
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Functions:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {' '}
                              {DailyRulesStore.selectedRuleTaxonomies.functions}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              <strong> Countries:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {' '}
                              {DailyRulesStore.selectedRuleTaxonomies.countries}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Products:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {DailyRulesStore.selectedRuleTaxonomies.products}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Regions:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {DailyRulesStore.selectedRuleTaxonomies.regions}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Rule Type:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {DailyRulesStore.selectedRuleType}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Reference:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {DailyRulesStore.selectedRuleDetails.reference}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Rule Date:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {DailyRulesStore.selectedRuleDetails.ruleDate}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Effective Date:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {
                                 DailyRulesStore.selectedRuleDetails
                                    .effectiveDate
                              }
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Last Update Date:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {
                                 DailyRulesStore.selectedRuleDetails
                                    .lastUpdateDate
                              }
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Reg. Last Update:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {
                                 DailyRulesStore.selectedRuleDetails
                                    .reportIssueDate
                              }
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Rule Id:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {DailyRulesStore.selectedRuleDetails.mainRuleId}
                           </Col>
                        </Row>{' '}
                        <Row>
                           <Col span={6}>
                              {' '}
                              <strong>Linked to:</strong>{' '}
                           </Col>
                           <Col span={18}>
                              {
                                 DailyRulesStore.selectedRuleDetails
                                    .linkToOtherRules
                              }
                           </Col>
                        </Row>{' '}
                     </Card>
                  </>
               )}
            </Spin>
         </Modal>
         <Modal
            title={modalTitle}
            bodyStyle={{ height: '80%' }}
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={'90%'}
            okButtonProps={{
               style: {
                  backgroundColor: '#fa8c16',
                  color: 'white',
                  borderRadius: '5px',
                  borderColor: '#fa8c16',
               },
            }}
         >
            <Spin spinning={DashboardStore.isGetEventLoading}>
               <Space direction="vertical" className="space">
                  <Row className="table-search-row">
                     <Col span={17}>
                        <div
                           style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                           }}
                        >
                           <Button
                              style={{
                                 backgroundColor: '#fa8c16',
                                 color: 'white',
                                 borderRadius: '5px',
                                 borderColor: '#fa8c16',
                              }}
                              icon={<FileExcelOutlined />}
                              onClick={onExportToExcel}
                              loading={
                                 DashboardStore.isExportEventsToExcelLoading
                              }
                           >
                              Export to excel
                           </Button>
                        </div>
                     </Col>
                     <Col
                        span={7}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                     >
                        <Input
                           placeholder="Search table"
                           style={{ width: '80%' }}
                           value={searchText}
                           onChange={(e: any) => ruleListSearchboxOnChange(e)}
                           suffix={
                              <>
                                 <CloseCircleFilled
                                    className="close-circle"
                                    onClick={() => setSearchText('')}
                                 />
                              </>
                           }
                        />
                        <div className="search-icon-div">
                           <SearchOutlined className="search-icon" />
                        </div>
                     </Col>
                  </Row>
                  <ConfigProvider>
                     <Table
                        rowKey={(record) => record.mainRuleId}
                        columns={columns}
                        dataSource={DashboardStore.events}
                        size="middle"
                        pagination={{
                           position: ['bottomCenter'],
                           defaultPageSize: 100,
                           showSizeChanger: true,
                           pageSizeOptions: [
                              '10',
                              '20',
                              '30',
                              '50',
                              '100',
                              '500',
                              '1000',
                           ],
                        }}
                     />
                  </ConfigProvider>
                  <Row style={{ display: 'flex', justifyContent: 'center' }}>
                     <Col>
                        <Text>
                           <strong>Rule Count :</strong>
                           {DashboardStore.events.length}
                        </Text>
                     </Col>
                  </Row>
               </Space>
            </Spin>
         </Modal>
      </Row>
   )
})

export default Widgets
