import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react' // Or "mobx-react".
import { Form, Input, Button, Checkbox, Card, Row, Spin } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { ReactComponent as Logo } from '../../assets/reg-impact-transp.svg'
import { useStore } from '../../context'
import SendNewPassword from './SendNewPassword'
import { MainLayoutStore } from '../../components/MainLayoutStore'

interface ILogin {
   location: any
}

const Login = observer((props: ILogin) => {
   const { LoginStore } = useStore()
   const history = useHistory()

   const onFinish = async (values: any) => {
      LoginStore.setLoginCredentials(values.email, values.password)
      await LoginStore.login()
   }

   useEffect(() => {
      if (
         LoginStore.isUserValid &&
         !LoginStore.isLoading &&
         localStorage.getItem('userId') !== '' &&
         localStorage.getItem('jwt_token') !== ''
      ) {
         if (LoginStore.isAdmin || LoginStore.isDev) {
            history.push('/Dashboard')
         } else {
            history.push('/ImpactAssessments')
         }
           
      }
   }, [LoginStore.isUserValid, LoginStore.isLoading, history])

   const [isForgotPassword, setIsForgotPassword] = useState(false)
   const onForgotPasswordClick = () => {
      LoginStore.setIsPasswordRenewed(false)
      LoginStore.setShowLoginErrorMessage(false)
      setIsForgotPassword(true)
   }

   useEffect(() => {
      if (LoginStore.isPasswordRenewed) setIsForgotPassword(false)
   }, [LoginStore.isPasswordRenewed])

   return (
      <Spin spinning={LoginStore.isLoading}>
         {isForgotPassword && (
            <SendNewPassword setIsForgotPassword={setIsForgotPassword} />
         )}
         {!isForgotPassword && (
            <Card style={{ minHeight: '100vh', backgroundColor: '#ffd591' }}>
               <Row style={{ height: '100px', justifyContent: 'center' }}>
                  <Logo height="70%" style={{ marginLeft: '1.5%' }} />
               </Row>
               <Row style={{ height: '10ch' }} />
               <Row style={{ justifyContent: 'center' }}>
                  <Card style={{ width: '70ch' }}>
                     {LoginStore.showLoginErrorMessage ? (
                        <span
                           style={{
                              color: LoginStore.messageColor,
                              display: 'flex',
                              justifyContent: 'center',
                              marginBottom: '2%',
                           }}
                        >
                           {LoginStore.errorMessage}
                        </span>
                     ) : (
                        ''
                     )}
                     <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                     >
                        <Form.Item
                           name="email"
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your e-mail!',
                              },
                           ]}
                        >
                           <Input
                              prefix={
                                 <UserOutlined className="site-form-item-icon" />
                              }
                              placeholder="Email"
                           />
                        </Form.Item>
                        <Form.Item
                           name="password"
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your Password!',
                              },
                           ]}
                        >
                           <Input
                              prefix={
                                 <LockOutlined className="site-form-item-icon" />
                              }
                              type="password"
                              placeholder="Password"
                           />
                        </Form.Item>
                        <Form.Item>
                           <Form.Item
                              name="remember"
                              valuePropName="checked"
                              noStyle
                           >
                              <Checkbox>Remember me</Checkbox>
                           </Form.Item>

                           <Button
                              type="link"
                              onClick={onForgotPasswordClick}
                              className="login-form-forgot"
                              
                           >
                              Forgot password
                           </Button>
                        </Form.Item>
                        <Form.Item>
                           <Button
                              type="primary"
                              htmlType="submit"
                              className="login-form-button"
                              style={{
                                 backgroundColor: 'rgb(250, 140, 22)',
                                 borderColor: 'rgb(250, 140, 22)',
                                 borderRadius: '10px',
                                 color: 'white',
                                 width: '100%',
                              }}
                           >
                              Log in
                           </Button>
                           Don't have account yet?{' '}
                           <a href="!#">register now!</a>
                        </Form.Item>
                     </Form>
                  </Card>
               </Row>
            </Card>
         )}
      </Spin>
   )
})

export default Login
