import React from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../../context/index'
import { Row, Modal, Input } from 'antd'
import { ReactComponent as Clear } from '../../../assets/clear.svg'
import { SaveOutlined } from '@ant-design/icons'

const { TextArea } = Input

export interface IDeleteConfirmModal {}

const DeleteConfirmModal = observer((props: IDeleteConfirmModal) => {
   const { ImpactAssessmentsStore } = useStore()

   const onConfirm = (params: any) => {
      ImpactAssessmentsStore.deleteAssessment()
      ImpactAssessmentsStore.setIsDeleteConfirmModalVisible(false)
   }

   const onCancel = (params: any) => {
      ImpactAssessmentsStore.setIsDeleteConfirmModalVisible(false)
   }

   const oncommentForRemovedRulesChange = (params: any) => {
      ImpactAssessmentsStore.setCommentForRemovedAssessment(params.target.value)
   }

   return (
      <Modal
         title="Enter the reason why this rule is Non applicable"
         open={ImpactAssessmentsStore.isDeleteConfirmModalVisible}
         onOk={onConfirm}
         onCancel={onCancel}
         okText="Delete"
         cancelButtonProps={{
            style: {
               borderRadius: '10px',
            },
            icon: <Clear />,
         }}
         okButtonProps={{
            style: {
               backgroundColor: '#ff4d4f',
               borderColor: 'rgb(250, 140, 22)',
               borderRadius: '10px',
               color: 'white',
            },
            icon: <SaveOutlined />,
         }}
         destroyOnClose={true}
         width="40%"
      >
         <Row>{/* <Text> Please give a comment before delete </Text> */}</Row>
         <Row style={{ marginTop: '2%' }}>
            <TextArea
               rows={4}
               placeholder="Comment..."
               onChange={(e: any) => oncommentForRemovedRulesChange(e)}
            />
         </Row>
      </Modal>
   )
})

export default DeleteConfirmModal
