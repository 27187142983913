import { makeAutoObservable } from 'mobx'
import { DailyRulesApi } from './DailyRulesApi'
import {
   getNextWeekDate,
   addClassToAllDrawerLi,
   warningStyle,
} from '../../utils/Helpers'
import { CommonStore } from '../../context/CommonStore'
import moment from 'moment'
import { b64toBlob } from '../../utils/Helpers'
import { toast } from 'react-hot-toast'

export class DailyRulesStore {
   localeDateFormat: string = 'DD/MM/YYYY'
   name: string | undefined
   surname: string | undefined
   searchValue: string = ''
   isFiltered: boolean = false
   dailyRuleList: any[] = [] // TODO.
   nonAssessedRuleList: any[] = [] // TODO.
   dailyRuleListFiltered: any[] = [] // TODO.
   dateType: string = 'LastUpdateDate'
   startDate: moment.Moment = moment()
      .subtract(1, 'days')
      .locale(this.localeDateFormat) // Date
   endDate: moment.Moment = moment().endOf('day').locale(this.localeDateFormat) // Date
   isDateRangeValid: boolean = true
   selectedRowKeys: any[] = []
   connectionErrorMessageVisible: boolean = false
   isLoading: boolean = false
   errorType: any = 'error'
   alertMessage: string = ''
   newAssessment: any = {}
   selectedRuleIds: number[] | undefined
   selectedAuditCoreIds: string[] = []
   selectedUserIds: any[] = []
   selectedAssesorKeys: any[] = []
   comment: string = ''
   allocatedByUserId: number | undefined
   selectedAssessmentId: string = ''
   riskLevels: any[] = []
   isAssessmentModalVisible: boolean = false
   riskKey: number | undefined
   statusKey: number | undefined
   topicKeys: number[] | undefined
   status: any[] = []
   topics: any[] = []
   resDueDate: moment.Moment = moment()
   resDueDatePreference: moment.Moment = moment(this.resDueDate).locale(
      this.localeDateFormat
   )
   selectedAssessorsOnModal: React.Key[] = []
   receiveEmails: boolean = false
   isDeleteConfirmModalVisible: boolean = false
   commentForRemovedRules: string = ''
   isNonAssessed: string = 'false'
   multipleSelected: boolean = false
   fullVersion: boolean = true
   ruleCount: number = 0
   miniAssessmentModalVisible: boolean = false
   buttonProps: any = {
      type: 'link',
   }

   private root: CommonStore
   constructor(root: CommonStore) {
      makeAutoObservable(this)
      this.root = root
      this.localeDateFormat = 'DD/MM/YYYY'
   }

   setLocaleDateFormat = (value: string) => {
      this.localeDateFormat = value
   }
   setFullVersion(isFullVersion: boolean) {
      this.fullVersion = isFullVersion
   }

   setMultipleSelected(selected: boolean) {
      this.multipleSelected = selected
   }
   setAffixButtonsType(val: string) {
      this.buttonProps.type = val
   }

   setMainRuleIds(ruleIds: number[]) {
      this.selectedRuleIds = ruleIds
   }
   setSelectedAuditCoreIds(AuditIds: string[]) {
      this.selectedAuditCoreIds = AuditIds
   }

   setDateType(dateType: string) {
      this.dateType = dateType
   }

   setDeletionComment(comment: any) {
      this.commentForRemovedRules = comment
   }

   setIsNonAssessed(isNonAssessed: string) {
      this.isNonAssessed = isNonAssessed
   }

   setMiniAssessmentModalVisible(val: boolean) {
      this.miniAssessmentModalVisible = val
   }

   IsModalVisible: boolean = false
   setIsModalVisible(val: boolean) {
      this.IsModalVisible = val
   }

   removeUnselectedRules() {
      const newData = [...this.dailyRuleList]
      let index = -1
      // newData.forEach()
      this.dailyRuleList.forEach((rule) => {
         index = this.selectedRowKeys.findIndex((e) => e === rule.RuleId)
         if (index === -1) {
            newData.splice(
               newData.findIndex((e) => e.RuleId === rule.RuleId),
               1
            )
         }
         index = -1
      })
      this.dailyRuleList = newData
   }
   // TODO: Check this method
   removeRuleFromTheList() {
      const newData = [...this.dailyRuleList]
      const filteredRuleList = newData.filter((rule: any) => {
         const index = this.selectedRowKeys.findIndex(
            (e) => e === rule.mainRuleId
         )
         if (index < 0) return rule
      })
      this.dailyRuleList = filteredRuleList
      this.ruleCount = this.dailyRuleList.length
   }

   removeSelectedRules() {
      toast.promise(
         DailyRulesApi.removeRules(this.removeRulesJson()),
         {
            loading: 'Removing...',
            success: (data) => {
               if (!(data.name === 'Error')) {
                  this.removeRuleFromTheList()
               }
               return `SelectedRules have been removed successfully`
            },
            error: () => `An error occurred while removing selected rules`,
         },
         {
            success: {
               duration: 5000,
               icon: '✅',
            },
         }
      )
   }

   removeRulesJson() {
      return {
         mainRuleId: this.selectedRowKeys,
         comment: this.commentForRemovedRules,
         userId: localStorage.getItem('userId'),
      }
   }
   setSelectedRowKeys(selectedKeys: any[]) {
      this.selectedRowKeys = selectedKeys
   }

   removeSelectedRowKeys(selectedKeys: any[]) {
      this.selectedRowKeys.filter((e) => e === selectedKeys)
   }

   setSearchValue(value: string) {
      this.searchValue = value
   }

   setDailyRuleList(rules: any[]) {
      this.dailyRuleList = rules
   }

   setDailyRuleListFiltered(rules: any[]) {
      this.dailyRuleListFiltered = rules
   }

   selectedRuleType: string = ''
   setSelectedRuleType(val: string) {
      this.selectedRuleType = val
   }
   // @Computed
   clearSearchBox() {
      this.searchValue = ''
      this.isFiltered = false
   }
   setCalanderDate(startDate: moment.Moment, endDate: moment.Moment) {
      this.startDate = startDate.locale(this.localeDateFormat)
      this.endDate = endDate.locale(this.localeDateFormat)
   }
   selectedRuleDetails: any = {}
   selectedRuleDetailsAuditData: any = {}
   selectedRuleTaxonomies: any = {} // TODO: Create object types for all objects...
   getRuleDetails(ruleId: number, auditId: string) {
      this.isLoading = true
      this.selectedRuleDetails = {}
      this.selectedRuleDetailsAuditData = {}
      this.selectedRuleTaxonomies = {}
      this.connectionErrorMessageVisible = false

      DailyRulesApi.getRuleDetails(ruleId, auditId)
         .then((result) => {
            if (result.errorCode === 0) {
               this.selectedRuleDetails = result.mainRule
               this.selectedRuleDetails.summary = addClassToAllDrawerLi(
                  result.mainRule.summary
               )
               this.selectedRuleDetailsAuditData = result.mainRuleAuditData
               this.selectedRuleDetailsAuditData.summary = addClassToAllDrawerLi(
                  result.mainRuleAuditData.summary
               )
               this.selectedRuleTaxonomies = result.taxonomies ?? {}
            } else {
               toast.error(result.additionalMessage)
            }
            this.isLoading = false
         })
         .catch((err) => {
            this.isLoading = false
         })
   }

   getDailyRules() {
      this.isLoading = true
      DailyRulesApi.getDailyRuleList(
         this.dateType,
         this.startDate.format('YYYY-MM-DD'),
         this.endDate.format('YYYY-MM-DD'),
         this.isNonAssessed,
         this.selectedFilters
      )
         .then((result) => {
            if (result.errorCode !== 0) {
               this.connectionErrorMessageVisible = true
               this.alertMessage = result.additionalMessage
            } else if (result.dailyRules.length === 0) {
               this.connectionErrorMessageVisible = false

               toast(
                  'There is no rules to display. Please check your filters, the date type or the date period.',
                  { ...warningStyle, position: 'top-right' }
               )
               this.dailyRuleList = []
            } else {
               this.ruleCount = result.dailyRules.length
               this.dailyRuleList = result.dailyRules
               this.connectionErrorMessageVisible = false
            }

            this.isLoading = false
         })
         .catch((err) => {
            this.connectionErrorMessageVisible = true
            this.alertMessage =
               'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
            this.isLoading = false
         })
   }

   getNonAssessedRules() {
      this.isLoading = true
      DailyRulesApi.getNonAssessedRuleList(
         this.startDate.format('YYYY-MM-DD'),
         this.endDate.format('YYYY-MM-DD')
      )
         .then((result) => {
            if (result.errorCode !== 0) {
               toast(result.additionalMessage, warningStyle)
            } else if (result.removedDailyRules.length === 0) {
               toast(
                  'No found any Rule, please try to change your criteria!',
                  warningStyle
               )
               this.dailyRuleList = []
            } else {
               this.dailyRuleList = result.removedDailyRules
               this.connectionErrorMessageVisible = false
            }

            this.isLoading = false
         })
         .catch((_) => {
            this.connectionErrorMessageVisible = true
            this.alertMessage =
               'There has been a connection error. This often happens because your session has expired. Use the "Logout" button at the upper right-hand corner of your screen to log out and then, log back in again. If the issue persists, inform us at info@reg-track.com.'
            this.isLoading = false
         })
   }

   validateInputs() {
      if (this.startDate === null || this.dateType === '')
         this.isDateRangeValid = false
      return this.isDateRangeValid
   }

   setIsDateRangeValid = (isValid: boolean) => {
      this.isDateRangeValid = isValid
   }

   deleteDailyRule(ruleId: number) {
      const newArray = [...this.dailyRuleList]
      const index = newArray.findIndex((e) => e.RuleId === ruleId)
      newArray.splice(index, 1)
      this.dailyRuleList = newArray
      // this.dailyRuleListFiltered.splice(index, 1)
   }

   setStatusKey(key: number | undefined) {
      this.statusKey = key
   }
   setRiskKey(key: number | undefined) {
      this.riskKey = key
   }
   setTopicKeys(key: number[] | undefined) {
      this.topicKeys = key
   }
   setResDueDate(date: any) {
      this.resDueDate = date
   }
   setAssessorsKey(keys: any) {
      this.selectedAssesorKeys = keys
   }
   setSelectedAssessmentId(id: string) {
      this.selectedAssessmentId = id
   }
   setIsAssessmentModalVisible(isVisible: boolean) {
      this.isAssessmentModalVisible = isVisible
   }
   setDeleteConfirmModalVisible(isVisible: boolean) {
      this.isDeleteConfirmModalVisible = isVisible
   }
   onReceiveEmails(checked: any) {
      this.receiveEmails = checked
   }
   setComment(comment: any) {
      this.comment = comment
   }
   onCommentChange(element: any) {
      this.comment = element.target.value
   }

   assignedDepartmentIds: number[] = []
   addToAssignedDepartmentIds(id: number) {
      this.assignedDepartmentIds.push(id)
   }

   usersByDepartment: any[] = []
   isTreeViewExist: boolean = false
   getUsersByDepartment() {
      DailyRulesApi.getUsersByDepartment()
         .then((result: any) => {
            this.usersByDepartment = result.usersByDepartment
            this.isTreeViewExist = result.isTreeViewExist
         })
         .catch((err) => {})
   }

   preferencesRiskKey: number | undefined
   preferencesStatusKey: number | undefined
   preferencesResDueDate: moment.Moment = moment()

   getDefaultPreferences = () => {
      DailyRulesApi.getPreferences(
         '/settings/GetPreferences/' + localStorage.getItem('userId')
      )
         .then((result) => {
            this.riskKey =
               result.preferences.riskLevel === 0
                  ? undefined
                  : result.preferences.riskLevel
            this.statusKey =
               result.preferences.assessmentStatus === 0
                  ? undefined
                  : result.preferences.assessmentStatus

            this.preferencesRiskKey = this.riskKey
            this.preferencesStatusKey = this.statusKey
            this.preferencesResDueDate = moment(
               getNextWeekDate(result.preferences.responseDueDateNumberOfWeek)
            )
            this.localeDateFormat =
               result.preferences.languagePreference === 'en-US'
                  ? 'MM/DD/YYYY'
                  : 'DD/MM/YYYY'
         })
         .catch((err) => {})
   }

   popupInitData() {
      DailyRulesApi.getInitData()
         .then((result: any) => {
            this.riskLevels = result.riskLevels
            this.status = result.assessmentStatus
            this.topics = result.topics
         })
         .catch((_) => {})
   }

   sendAssessment() {
      this.isLoading = true
      toast.promise(DailyRulesApi.sendAssessment(this.newAssessmentJson()), {
         loading: 'Sending...',
         success: (data) => {
            let message = 'Assessment sent successfully!'
            if (data.errorCode === 0) {
               this.removeRuleFromTheList()
            } else {
               message = `The rule you are trying to allocated could not be found. Refresh the page and try again. If the problem persists, contact your Administrator. Code: ${data.errorCode}`
            }
            this.clearAssessmentModalState()

            this.setMultipleSelected(false)

            this.isLoading = false
            window.scrollTo({
               top: 0,
               left: 0,
               behavior: 'smooth',
            })
            return message
         },
         error: (_) => {
            this.setSelectedRowKeys([])
            this.isLoading = false
            window.scrollTo({
               top: 0,
               left: 0,
               behavior: 'smooth',
            })
            return 'Error sending assessment!'
         },
      })
   }

   assessmentByRuleList: any = {}

   getAssessments(ruleId: number) {
      this.isLoading = true

      DailyRulesApi.getAssessments(ruleId)
         .then((result: any) => {
            this.isLoading = false
            this.setMiniAssessmentModalVisible(true)
            this.assessmentByRuleList = result.assessments
         })
         .catch((err) => {
            // Show error message.
            this.isLoading = false
         })
   }

   activeFilterNames: string = ''
   getActiveFilterNames() {
      DailyRulesApi.getActiveFilterNames()
         .then((result: any) => {
            this.activeFilterNames = result.filterNames
         })
         .catch((err) => {})
   }

   findSelectedGroupFilters() {
      let selectedGroupFilterObjects = this.filterList?.filter(
         (filter: any) =>
            this.selectedFilters.includes(filter.id) &&
            filter.filterLevel.id === 1
      )

      return selectedGroupFilterObjects.map((e: any) => e.filterName)
   }

   findSelectedUnitFilters() {
      let selectedGroupFilterObjects = this.filterList.filter(
         (filter: any) =>
            this.selectedFilters.includes(filter.id) &&
            filter.filterLevel.id === 2
      )

      return selectedGroupFilterObjects.map((e: any) => e.filterName)
   }

   findSelectedDepartmentFilters() {
      let selectedGroupFilterObjects = this.filterList.filter(
         (filter: any) =>
            this.selectedFilters.includes(filter.id) &&
            filter.filterLevel.id === 3
      )

      return selectedGroupFilterObjects.map((e: any) => e.filterName)
   }

   findSelectedUserFilters() {
      let selectedGroupFilterObjects = this.filterList.filter(
         (filter: any) =>
            this.selectedFilters.includes(filter.id) &&
            filter.filterLevel.id === 4
      )

      return selectedGroupFilterObjects.map((e: any) => e.filterName)
   }

   calculateAppliedFilterLogic() {
      let groupFilters = this.findSelectedGroupFilters()
      let unitFilters = this.findSelectedUnitFilters()
      let departmentFilters = this.findSelectedDepartmentFilters()
      let userFilters = this.findSelectedUserFilters()

      // TODO: Use concatObject and improve this logic.
      let groupFilterLogic =
         groupFilters.length > 0 ? groupFilters.join(' OR ') : ''
      let unitFilterLogic =
         unitFilters.length > 0 ? unitFilters.join(' OR ') : ''
      let departmentFilterLogic =
         departmentFilters.length > 0 ? departmentFilters.join(' OR ') : ''
      let userFilterLogic =
         userFilters.length > 0 ? userFilters.join(' OR ') : ''

      return this.concatObject(
         {
            groupFilterLogic,
            unitFilterLogic,
            departmentFilterLogic,
            userFilterLogic,
         },
         ' OR '
      )
   }

   concatObject = (obj: any, separator: string) =>
      Object.values(obj)
         .filter((val) => val)
         .join(separator)

   newAssessmentJson(): any {
      const json = {
         ruleIds: this.selectedRowKeys, // Create a relation between mainruleid and assessment with mainruleid.
         mainRuleAuditCoreIds: this.selectedAuditCoreIds,
         status: this.statusKey,
         riskLevel: this.riskKey,
         topic: this.topicKeys,
         responseDueDate: this.resDueDate,
         comment: this.comment,
         receiveEmails: this.receiveEmails,
         assignedUserIds: this.findSelectedAssessorIdsByKeys(),
         assignedDepartmentIds: this.assignedDepartmentIds.filter(
            (v, i, a) => a.indexOf(v) === i
         ),
      }

      return json
   }

   selectedAssessorUserIds: any[] = []
   findSelectedAssessorIdsByKeys() {
      this.selectedAssessorUserIds = []
      this.usersByDepartment?.forEach((element: any) => {
         element.children?.forEach((element: any) => {
            if (this.selectedAssesorKeys.includes(element.key))
               this.selectedAssessorUserIds.push(element.userId)
         })
      })
      // let removedDuplicates = [...new Set(this.selectedAssessorUserIds)];
      let removedDuplicates = Array.from(new Set(this.selectedAssessorUserIds))
      var userDto = removedDuplicates.map((e: any) => {
         return { id: e }
      })
      return userDto
   }

   filterList: any[] = []
   selectedFilters: number[] = []

   getFilters() {
      DailyRulesApi.getFilters()
         .then((result) => {
            this.isLoading = false
            this.filterList = result.filters
            this.selectedFilters = result.filters
               .filter((e: any) => e.isActive === true)
               .map((e: any) => e.id)

            if (this.validateInputs()) {
               this.getDailyRules()
               this.getDefaultPreferences()
            }
         })
         .catch((err) => {
            console.error(err)
            this.isLoading = false
         })
   }

   setSelectedFilters(e: any) {
      this.selectedFilters = e
   }

   downloadNonAssessedRules = () => {
      toast.promise(
         DailyRulesApi.downloadNonAssessedRules(this.selectedRowKeys),
         {
            loading: 'Downloading...',
            success: (response) => {
               if (response.errorCode === 0) {
                  const blob = b64toBlob(
                     response.content,
                     'application/octet-stream'
                  )
                  const blobUrl = URL.createObjectURL(blob)
                  var a = window.document.createElement('a')

                  a.href = blobUrl
                  a.download = response.fileName

                  // Append anchor to body.
                  document.body.appendChild(a)
                  a.click()

                  // Remove anchor from body
                  document.body.removeChild(a)
               }
               return 'Downloaded successfully'
            },
            error: (err) => {
               this.isLoading = false
               return 'Error downloading rules'
            },
         }
      )
      /* DailyRulesApi.downloadNonAssessedRules(this.selectedRowKeys)
         .then((response) => {
            this.isLoading = false
            if (response.errorCode === 0) {
               const blob = b64toBlob(
                  response.content,
                  'application/octet-stream'
               )
               const blobUrl = URL.createObjectURL(blob)
               var a = window.document.createElement('a')

               a.href = blobUrl
               a.download = response.fileName

               // Append anchor to body.
               document.body.appendChild(a)
               a.click()

               // Remove anchor from body
               document.body.removeChild(a)

               return response
            }
         })
         .catch((err) => {
            console.log(err)
            this.isLoading = false
         }) */
   }

   clearAssessmentModalState() {
      this.setAssessorsKey([])
      this.setTopicKeys(undefined)
      this.setRiskKey(this.preferencesRiskKey)
      this.setStatusKey(this.preferencesStatusKey)
      this.setComment('')
      this.onReceiveEmails(false)
      this.setResDueDate(this.preferencesResDueDate)
      this.selectedUserIds = []
      this.selectedAssesorKeys = []
      this.assignedDepartmentIds = []

      this.setSelectedRowKeys([])
      this.setSelectedAuditCoreIds([])
      this.setAssessorsKey([])
   }

   ClearState() {
      this.dailyRuleList = []
      this.nonAssessedRuleList = []
      this.selectedRowKeys = []
      this.ruleCount = 0
      this.connectionErrorMessageVisible = false
      this.multipleSelected = false
      this.clearSearchBox()
   }
}
