import { Switch, Route } from 'react-router-dom'
import { PrivateRoute } from './RouteWithAuth'

import { DailyRulesScreen } from './features/dailyRules/DailyRulesScreen'
import NonAssessedRules from './features/dailyRules/NonAssessedRules'
import { FilterResultScreen } from './features/defaultFilters/FilterResultScreen'
import FilterScreen from './features/defaultFilters/CreateFilter'
import MyFiltersScreen from './features/defaultFilters/Filters'
import Risks from './features/settings/Risks'
import BusinessEntity from './features/settings/BusinessEntity'
import Groups from './features/settings/Groups'
import Topics from './features/settings/Topics'
import AssessmentStatus from './features/settings/AssessmentStatus'
import Preferences from './features/settings/Preferences'
import Departments from './features/departments/Departments'
import CompanyUsers from './features/companyUsers/CompanyUsers'
import ImpactAssessments from './features/impactAssessments/ImpactAssessments'
import AssessmentForm from './features/impactAssessments/AssessmentForm'
import User from './features/companyUsers/User'
import RenewPassword from './features/companyUsers/RenewPassword'
import { UserUpdateResultPage } from './features/companyUsers/UserUpdateResultPage'
import DeletedRules from './features/dailyRules/DeletedRules'
import { observer } from 'mobx-react' // Or "mobx-react".
import NotFound from './NotFound.js'
import { Newsletter } from './features/newsletter/newsletterScreen'
import Dashboard from './features/dashboard/Dashboard'
import { Roles } from './utils/Consts'

const Router = observer(() => {
   return (
      <Switch>
         <PrivateRoute roles={[]} component={Dashboard} path="/Dashboard" />
         <PrivateRoute
            roles={[Roles.Admin, Roles.SuperAdmin, Roles.Dev]}
            path="/DailyRules"
            redirectPath="/ImpactAssessments"
            component={DailyRulesScreen}
         />
         <PrivateRoute
            roles={[]}
            path="/NonAssessedDailyRules"
            component={NonAssessedRules}
         />
         <PrivateRoute
            roles={[]}
            path="/AddNewFilter"
            component={FilterScreen}
         />
         <PrivateRoute roles={[]} path="/EditFilter" component={FilterScreen} />
         <PrivateRoute
            roles={[]}
            path="/FilterResult"
            component={FilterResultScreen}
         />
         <PrivateRoute
            roles={[]}
            path="/MyFilters"
            component={MyFiltersScreen}
            key={"MyFilters"}
         />
         <PrivateRoute
            roles={[]}
            path="/Filters"
            component={MyFiltersScreen}
            isAll={true}
            key={"Filters"}
         />
         <PrivateRoute
            roles={[]}
            path="/RiskLevels"
            component={Risks}
            endpoint="/settings/risklevels"
         />
         <PrivateRoute
            roles={[]}
            path="/BusinessEntity"
            component={BusinessEntity}
            endpoint="/settings/BusinessEntity"
         />
         <PrivateRoute
            roles={[]}
            path="/Topics"
            component={Topics}
            endpoint="/settings/topics"
         />
         <PrivateRoute
            roles={[]}
            path="/AssessmentStatus"
            component={AssessmentStatus}
            endpoint="/settings/assessmentStatus"
         />
         <PrivateRoute
            roles={[]}
            path="/Departments"
            component={Departments}
            endpoint="/settings/departments"
            isRestrict={false}
         />
         <PrivateRoute
            roles={[]}
            path="/Groups"
            component={Groups}
            endpoint="/settings/groups"
         />

         <PrivateRoute
            roles={[]}
            path="/CompanyUsers"
            component={CompanyUsers}
            endpoint="/settings/companyUsers"
            isRestrict={false}
         />

         <PrivateRoute roles={[]} component={User} path="/User/:id" />

         <PrivateRoute
            roles={[]}
            component={UserUpdateResultPage}
            path="/UserUpdateResultPage/:isNewUser"
         />

         <PrivateRoute
            roles={[]}
            component={ImpactAssessments}
            path="/ImpactAssessments"
            isRestrict={false}
         />
         <PrivateRoute
            roles={[]}
            component={AssessmentForm}
            path="/AssessmentForm/:assessmentId/:mainRuleAuditId"
            isRestrict={false}
         />
         <PrivateRoute
            roles={[]}
            component={Preferences}
            path="/Preferences"
            endpoint="/settings"
            isRestrict={false}
         />
         <PrivateRoute path="/Newsletters" component={Newsletter} roles={[]} />

         <PrivateRoute
            roles={[]}
            component={RenewPassword}
            path="/RenewPassword"
         />
         <PrivateRoute
            roles={[]}
            component={DeletedRules}
            path="/DeletedRules"
         />
         <PrivateRoute
            roles={[]}
            component={User}
            path="/NewUser"
         ></PrivateRoute>
         <Route component={NotFound}></Route>
      </Switch>
   )
})

export default Router
