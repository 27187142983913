import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import { Helmet } from 'react-helmet'
import { Row, Col, Spin, Alert, Button } from 'antd'
import { Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import BusinessEntityTable from './components/BusinessEntityTable'
import { ReactComponent as Department } from '../../assets/department.svg'

const { Title } = Typography
interface IBusinessEntity {
   endpoint: string
}

const BusinessEntity = observer((props: IBusinessEntity) => {
   const { SetttingsStore } = useStore()
   const pageTitle = 'Business Entities'
   //    const validationMessage = `Your Risk Level will be deleted permanently, would you like to continue?`
   const history = useHistory()
   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)
      SetttingsStore.endpoint = props.endpoint
      SetttingsStore.getPageData()
      SetttingsStore.getGroups(history)
      return () => {
         SetttingsStore.clearStore()
      }
   }, [])

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <Spin spinning={SetttingsStore.isLoading}>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            <Row>
               <Col span={8}></Col>
               <Col style={{ textAlign: 'center' }} span={8}>
                  <Title level={3} style={{}}>
                     {pageTitle}
                  </Title>
               </Col>
            </Row>

            {SetttingsStore.isGroupExist && (
               <BusinessEntityTable
                  firstColumnTitle="Group"
                  secondColumnTitle="Business Entity"
                  deleteMessage="Item will be deleted permanently, would you like to continue?"
                  buttonName="Add New Entity"
                  isDraggable={false}
               />
            )}

            <br />
            <br />
         </Spin>
      )
})
export default BusinessEntity
