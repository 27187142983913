import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react' // Or "mobx-react".
import { useStore } from '../../context/index'
import {
   Table,
   ConfigProvider,
   Space,
   DatePicker,
   Alert,
   Spin,
   Modal,
   Tag,
   Popconfirm,
} from 'antd'
import { Card, Divider } from 'antd'
import { Row, Col, Input, Button, Typography } from 'antd'
import {
   SearchOutlined,
   CloseCircleFilled,
   DeleteOutlined,
   ReadOutlined, FilePdfOutlined
} from "@ant-design/icons";
import { Tooltip } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
import '../newsletter/cssViewReport.css'
import '../newsletter/newsletterViewer.css'
import '../newsletter/Premium.css'
import '../newsletter/MainCss.css'
import '../newsletter/AssessmentComment.css'

import enUS from 'antd/lib/locale/en_US'
import { Helpers } from '../../api/Helpers'

const { RangePicker } = DatePicker
const { Title, Text, Paragraph } = Typography

export interface NewsletterProps {}

export const Newsletter: React.FC<NewsletterProps> = observer((props) => {
   const { NewsletterStore } = useStore()
   // const [multipleSelected, setMultipleSelected] = useState(false)
   const [locale, setLocale] = useState(enUS)

   const pageTitle = 'Newsletter'
   const [
      isNewsletterDetailsModalVisible,
      setIsNewsletterDetailsModalVisible,
   ] = useState(false)

   const onNewsletterNameClick = (newsletterId: number) => {
      NewsletterStore.getNewsletterDetails(newsletterId)
      setIsNewsletterDetailsModalVisible(true)
   }
   const onNewsletterOpenClick = (newsletterId: number) => {
      NewsletterStore.getNewsletterDetails(newsletterId)
   }
   const onDeleteConfirm = (id: number) => {
      NewsletterStore.deleteNewsletter(id)
   }

   const onShareConfirm = (id: number) => {
      NewsletterStore.shareNewsletter(id)
   }
   const columns = [
      {
         title: 'Newsletter Name',
         dataIndex: 'name',
         key: 'name',
         render: (text: string, record: any) => {
            return (
               <>
                  <Button
                     type="link"
                     onClick={() => onNewsletterNameClick(record.newsletterId)}
                  >
                     {text}
                  </Button>
               </>
            )
         },
      },
      {
         title: 'Created On',
         dataIndex: 'createdOn',
         key: 'createdOn',
      },
      {
         title: 'Public link',
         dataIndex: 'isPublic',
         key: 'publicLink',
         render: (text: string, record: any) => {
            return (
               <>
                  {record.isPublic === true && (
                     <a
                        style={{
                           color: 'rgb(250, 140, 22)',
                           borderColor: 'white',
                           display: 'flex',
                           marginLeft: '5%',
                        }}
                        // ghost
                        type="link"
                        href={
                           Helpers.getBaseApiUrl() +
                           '/NewsletterViewer/' +
                           record.token
                        }
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                           onNewsletterOpenClick(record.newsletterId)
                        }
                     >
                        <ReadOutlined style={{ margin: '2%' }} />
                        Open
                     </a>
                  )}
                  {record.isPublic !== true && (
                     <Popconfirm
                        title={
                           'Anyone with the link will have access to this newsletter. ' +
                           'Do you want to share it?'
                        }
                        okText="Yes, create the public link"
                        cancelText="No, I will keep it private"
                        onConfirm={() => onShareConfirm(record.newsletterId)}
                        style={{ width: '150px' }}
                     >
                        {!record.isPublic && (
                           <Button
                              type="link"
                              icon={<ShareAltOutlined />}
                              style={{
                                 color: '#52c41a',
                              }}
                           >
                              Share{''}
                           </Button>
                        )}
                     </Popconfirm>
                  )}
               </>
            )
         },
      },
      {
         title: '',
         dataIndex: 'isPublic',
         key: 'publicLink',
         render: (text: string, record: any) => {
            return (
            <>
               {record.isPublic && <Tooltip title="Export as a pdf">
                  <Button type="text" icon={<FilePdfOutlined />} href={
                    Helpers.getBaseApiUrl() +
                    '/NewsletterViewer/export/' +
                    record.token
                  } />
               </Tooltip>}
               {!record.isPublic && <Tooltip title="Export as a pdf">
                  <Popconfirm
                    title={
                      'Only shared newsletter can be exported. Please share the newsletter first.'
                    }
                    style={{ width: '150px' }}
                    showCancel={false}
                  >
                     <Button type="text" icon={<FilePdfOutlined />}  />
                  </Popconfirm>
               </Tooltip>}
            </>
            )
         },
      },
      {
         title: 'Action',
         key: 'action',
         render: (record: any) => (
            <Row>
               <Col>
                  <Tooltip
                     placement="top"
                     title="Delete this newsletter permanently"
                  >
                     <Popconfirm
                        title={
                           'This newsletter will be permanently deleted.' +
                           'Would you like to continue?'
                        }
                        okText="Yes, delete it"
                        cancelText="No"
                        onConfirm={(event) =>
                           onDeleteConfirm(record.newsletterId)
                        }
                     >
                        {
                           <Button
                              type="link"
                              icon={<DeleteOutlined />}
                              style={{
                                 marginLeft: '5%',
                                 marginTop: '-3%',
                                 color: '#ff4d4f',
                              }}
                           >
                              Delete
                           </Button>
                        }
                     </Popconfirm>
                  </Tooltip>
               </Col>
            </Row>
         ),
      },
   ]

   const searchNewsletter = () => {
      if (NewsletterStore.validateInputs()) NewsletterStore.getNewsletter()
   }

   const [isAdmin, setIsAdmin] = useState(false)
   useEffect(() => {
      if (localStorage.getItem('isAdmin') === 'false') {
         setIsAdmin(false)
         return
      } else setIsAdmin(true)

      NewsletterStore.getNewsletter()
      return NewsletterStore.ClearState()
   }, [])

   const NewsletterListSearchboxOnChange = (target: any) => {
      let targetValue = target?.target?.value
      if (targetValue === '' || targetValue === undefined) {
         NewsletterStore.isFiltered = false
      } else {
         NewsletterStore.setNewsletterListFiltered(
            NewsletterStore.newsletterList?.filter((e: any) => {
               e.newsletterId.toPrecision().match(targetValue) ||
                  e.name.match(targetValue) ||
                  e.createdOn.match(targetValue)
            })
         )
         NewsletterStore.isFiltered = true
         NewsletterStore.newsletterCount =
            NewsletterStore.newsletterList?.length
      }
      NewsletterStore.setSearchValue(targetValue)
   }

   const onCalenderChange = (e: any, dateRange: any) => {
      if (e !== null && e[0] !== null && e[1] !== null) {
         NewsletterStore.setCalanderDate(e[0], e[1])
         NewsletterStore.setIsDateRangeValid(true)
      }
   }

   const footerButtons = [
      <Button
         type="primary"
         onClick={() => setIsNewsletterDetailsModalVisible(false)}
         style={{
            backgroundColor: 'rgb(250, 140, 22)',
            borderColor: 'rgb(250, 140, 22)',
            borderRadius: '10px',
            color: 'white',
         }}
      >
         Ok
      </Button>,
   ]

   const onVersionSwitchChange = (event: any) => {
      NewsletterStore.setFullVersion(event)
   }

   const onNewsletterdetailModal = () => {
      setIsNewsletterDetailsModalVisible(false)
      NewsletterStore.setFullVersion(true)
   }

   if (!isAdmin)
      // TODO: Need to refactor. Solve at the router level.
      return (
         <Alert
            message="You do not have sufficient permission to access this page. Get in thouch with an Administrator."
            type="error"
            showIcon
         />
      )
   else
      return (
         <>
            <Helmet>
               <title>{pageTitle}</title>
            </Helmet>
            <Row>
               <Col style={{ textAlign: 'center' }} span={24}>
                  <Title level={3}>{pageTitle}</Title>
               </Col>
            </Row>
            <Modal
               open={isNewsletterDetailsModalVisible}
               onCancel={onNewsletterdetailModal}
               footer={footerButtons}
               destroyOnClose={true}
               width={'100vw'}
               style={{ top: '0px' }}
               bodyStyle={{
                  overflowY: 'auto',
                  height: '90vh',
                  color: '#000',
                  backgroundColor: '#c8dde8',
               }}
               wrapClassName="test-ant-modal-content"
            >
               <Spin spinning={NewsletterStore.isLoading}>
                  {/* {NewsletterStore.connectionErrorMessageVisible && (
                     <Alert
                        message={NewsletterStore.alertMessage}
                        type="error"
                     />
                  )} */}
                  {!NewsletterStore.connectionErrorMessageVisible && (
                     <>
                        <Paragraph>
                           {
                              <div
                                 dangerouslySetInnerHTML={{
                                    __html: NewsletterStore.fullVersion
                                       ? NewsletterStore.newsletterContent
                                       : 'This is the summary minimized version',
                                 }}
                              ></div>
                           }
                        </Paragraph>
                     </>
                  )}
               </Spin>
            </Modal>
            <Spin spinning={NewsletterStore.isLoading}>
              {/*  {NewsletterStore.connectionErrorMessageVisible && (
                  <Row style={{ marginBottom: '1%' }}>
                     <Col span={24}>
                        <Alert
                           message={NewsletterStore.alertMessage}
                           type={NewsletterStore.errorType}
                           showIcon
                        />
                     </Col>
                  </Row>
               )} */}
               <Card bordered={true} style={{ backgroundColor: '#fafafa' }}>
                  <Row>
                     <Col span={3}>Newsletter date range</Col>
                     <Col span={10} style={{ textAlign: 'left' }}>
                        <ConfigProvider locale={locale}>
                           <RangePicker
                              style={{ width: '100%' }}
                              // locale={locale.DatePicker}
                              format={NewsletterStore.localeDateFormat}
                              onCalendarChange={onCalenderChange}
                              defaultValue={[
                                 NewsletterStore.startDate,
                                 NewsletterStore.endDate,
                              ]}
                              className={
                                 !NewsletterStore.isDateRangeValid
                                    ? 'dateRange-validator'
                                    : ''
                              }
                           />
                           {!NewsletterStore.isDateRangeValid && (
                              <span style={{ color: 'red', fontSize: '13px' }}>
                                 Date Range is required!{' '}
                              </span>
                           )}
                        </ConfigProvider>
                     </Col>
                     <Col span={3} style={{ textAlign: 'right' }}>
                        <Button
                           htmlType="submit"
                           onClick={searchNewsletter}
                           icon={<SearchOutlined />}
                           style={{
                              backgroundColor: 'rgb(250, 140, 22)',
                              borderColor: 'rgb(250, 140, 22)',
                              borderRadius: '10px',
                              color: 'white',
                           }}
                        >
                           Search{' '}
                        </Button>
                     </Col>
                  </Row>
               </Card>
               {/* <Card bordered={false} className="card"> */}
               <Space direction="vertical" className="space"></Space>
               <Row
                  style={{
                     // marginLeft: '3%',
                     marginTop: '3%',
                     marginBottom: '-2%',
                  }}
               >
                  <Col
                     style={{
                        margin: '0 auto 0 auto',
                     }}
                  >
                     <Text>
                        <Tag
                           color="geekblue"
                           key={NewsletterStore.newsletterCount}
                        >
                           <strong>{NewsletterStore.newsletterCount} </strong>
                        </Tag>
                        <Tag color="green">
                           <strong>Newsletters</strong>{' '}
                        </Tag>
                     </Text>
                  </Col>
               </Row>
               <Space
                  direction="vertical"
                  className="space, clearfix"
                  style={{}}
               >
                  &nbsp;
               </Space>
               <Divider
                  type="horizontal"
                  style={{
                     // marginTop: '4%',
                     marginBottom: '3%',
                     backgroundColor: 'rgb(250, 140, 22)',
                     opacity: '0.3',
                  }}
               />
               <Row className="table-search-row, hide">
                  <Col
                     span={24}
                     style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                     <Input
                        placeholder="Search table"
                        style={{ width: '20%' }}
                        value={NewsletterStore.searchValue}
                        onChange={(e: any) =>
                           NewsletterListSearchboxOnChange(e)
                        }
                        suffix={
                           <>
                              <CloseCircleFilled
                                 className="close-circle"
                                 onClick={() =>
                                    NewsletterStore.clearSearchBox()
                                 } //TODO : USE STORE
                              />{' '}
                           </>
                        }
                     />
                     <div className="search-icon-div">
                        <SearchOutlined className="search-icon" />
                     </div>
                  </Col>
               </Row>
               <ConfigProvider>
                  <Table
                     sticky={true}
                     rowKey={(record) => record.newsletterId}
                     columns={columns}
                     dataSource={
                        NewsletterStore.isFiltered
                           ? NewsletterStore.newsletterListFiltered
                           : NewsletterStore.newsletterList
                     }
                     size="middle"
                     pagination={{
                        position: ['bottomCenter'],
                        defaultPageSize: 100,
                        showSizeChanger: true,
                        pageSizeOptions: [
                           '10',
                           '20',
                           '30',
                           '50',
                           '100',
                           '500',
                           '1000',
                        ],
                     }}
                  />
               </ConfigProvider>
               <Space
                  direction="vertical"
                  className="space, clearfix"
                  style={{}}
               >
                  &nbsp;
               </Space>
               <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col>
                     <Text>
                        <Tag
                           color="geekblue"
                           key={NewsletterStore.newsletterCount}
                        >
                           <strong>{NewsletterStore.newsletterCount} </strong>
                        </Tag>
                        <Tag color="green">
                           <strong>Newsletters</strong>{' '}
                        </Tag>
                     </Text>
                  </Col>
               </Row>
            </Spin>
         </>
      )
})
