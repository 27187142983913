import {MyObject} from "../types";

export type Difference<T> = {
    changed: (keyof T)[];
    unchanged: (keyof T)[];
    isDifferent: boolean;
};

export const objectToFormData = (obj: object): FormData => {
    const data = new FormData();
    for (const [key, value] of Object.entries(toFlat(obj)))
        data.append(key, value);
    return data;
};

const flattenObject = (ob: MyObject) => {
    let toReturn: MyObject = {};

    for (let i in ob) {
        if (!ob.hasOwnProperty(i) || ob[i] === null) continue;

        if ((typeof ob[i]) == 'object' && !(ob[i] instanceof File) && !(ob[i] instanceof Date)) {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '\t' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

export const toFlat = (ob: MyObject): MyObject => {
    const ft = flattenObject(ob);

    let result = {}
    for (const [key, value] of Object.entries(ft)){
        result = {
            ...result,
            [formatFlat(key, value)]: value
        }
    }

    return result;
}

const formatFlat = (key: string, value: any) => {
    const split = key.split('\t')
    let result = "";

    split.forEach((v,k) => {
        if (!isNaN(Number(v))) result += value instanceof File ?? value instanceof Array ? '': `[${v}]`
        else result += `${v}`
        result = `${result}${k === split.length - 1 || value instanceof File ? '' : '.'}`
    })

    return result.replace('.[', '[');
}
export const objectToFormDataAndExclude = (
    obj: object,
    excluded: (keyof object)[] = []
): FormData => objectToFormData(excludeParamsToObject(obj, excluded));


export const excludeParamsToObject = <T extends object>(
    obj: T,
    excluded: (keyof T)[] = []
): Partial<T> => {
    excluded.forEach((str) => {
        if (obj.hasOwnProperty(str)) delete obj[str];
    });
    return obj;
};

export const differentPropValue = <T extends object>(
    toObj: T,
    withObj: T
): Difference<T> => {
    const keys = Object.keys(toObj) as (keyof T)[];
    const changed: (keyof T)[] = [];
    const unchanged: (keyof T)[] = [];
    keys.forEach((v) => {
        if (toObj[v] !== withObj[v]) changed.push(v);
        else unchanged.push(v);
    });
    return {
        changed,
        unchanged,
        isDifferent: changed.length > 0,
    };
};

export function intersection<T>(a: T[], b: T[]) {
    const setA = new Set(a);
    return b.filter(value => setA.has(value));
  }

