import { Button } from 'antd'
import { makeAutoObservable } from 'mobx'
import toast from 'react-hot-toast'
import { Item } from '../../types/Types'
import { warningStyle } from '../../utils/Helpers'
import { DepartmentsApi } from './DepartmentsApi'

export class DepartmentsStore {
   pageData: any
   users: any[] = []
   businessEntities: any[] = []
   endpoint: string = ''
   isLoading: boolean = false
   isFiltered: boolean = false
   searchValue: string = ''
   isBusinessEntityExist: boolean = false

   isError: boolean = false
   errorMessage: string = ''
   errorType: any = 'error'

   getPageData(history: any) {
      this.isLoading = true
      this.isError = false
      DepartmentsApi.getPageData(this.endpoint)
         .then((result: any) => {
            if (result === null || result === undefined) return

            this.pageData = result.departments
            this.users = result.users
            this.businessEntities = result.businessEntities
            this.isLoading = false

            this.isBusinessEntityExist =
               result.businessEntities !== null &&
               result.businessEntities.length > 0

            if (!this.isBusinessEntityExist) {
               toast(
                  <span>
                     In order to create Department, You must have Business
                     Entity first. Click below{' '}
                     <Button
                        type="default"
                        style={{
                           borderRadius: '10px',
                           marginTop: '1rem',
                        }}
                        onClick={() => history.push('/businessEntity')}
                     >
                        Create Business Entity
                     </Button>
                  </span>,
                  { ...warningStyle, position: 'top-right', duration: 5000 }
               )
            }
         })
         .catch((err) => {
            this.isLoading = false
         })
   }

   save(record: any, isUpdate: boolean, history: any) {
      // if (this.isItemValid(record))
      record.key = parseInt(record.key)
      toast.promise(
         DepartmentsApi.save(record, this.endpoint, isUpdate),
         {
            loading: 'Saving department ...',
            success: () => {
               this.getPageData(history)
               return 'Successfully save department'
            },
            error: 'An error occurred when saving department',
         },
         {}
      )
   }

   delete(record: Item) {
      this.isError = false
      return DepartmentsApi.delete(record.key, this.endpoint)
         .then((result: any) => {
            if (result === null || result === undefined)
               this.showErrorMessage(
                  'No result, Get in touch with Administrator.'
               )
            else if (result.errorCode === 0) {
               const newData = [...this.pageData]
               const index = newData.findIndex((e) => e.key === record.key)
               newData.splice(index, 1)
               this.updatePageData(newData)
               toast.success('successfully deleted department')
            } else {
               this.showErrorMessage(
                  result.additionalMessage + ' Code: ' + result.errorCode
               )
            }
         })
         .catch((err) => {
            // this.isSuccess = false
            // this.errorDescription(err.description)
            // this.errorCode = err.code
            // setTimeout(() => setLoading(false), 1000);
         })
      // else return false
   }

   showErrorMessage(message: string) {
      toast.error(message, { id: 'error' })
   }

   pageDataFiltered: any[] = []
   setDailyRuleListFiltered(pageData: any) {
      this.pageDataFiltered = pageData
   }

   setSearchValue(value: string) {
      this.searchValue = value
   }

   clearStore() {
      this.endpoint = ''
   }

   updatePageData(pageData: any[]) {
      this.pageData = pageData
   }

   isItemValid(item: any) {
      return item.description !== '' && item.key !== '' && item.value !== ''
   }

   // Validator for input boxes, it works inputs onChange event,
   checkInput = (item: any, value: string, record: any) => {
      if (value === '' || value === null)
         return Promise.reject('Cannot be empty')

      const tempData = [...this.pageData]
      const currentItemIndex = tempData.findIndex((x) => x.key === record.key)
      tempData.splice(currentItemIndex, 1)

      if (item.field === 'name') {
         const index = tempData.findIndex((x) => x.name === value)
         if (index > -1)
            return Promise.reject('The Department Name already exist.')
         else return Promise.resolve()
      }

      // if (item.field === 'value') {
      //    const index = tempData.findIndex((x) => x.user.id === value.toString())
      //    if (index > -1) return Promise.reject('there is same value')
      //    else return Promise.resolve()
      // }

      return Promise.resolve()
   }

   constructor() {
      makeAutoObservable(this)
   }
}
