import Layout from 'antd/lib/layout'
import Menu from 'antd/lib/menu'
import {
   DashboardOutlined,
   FundProjectionScreenOutlined,
   UnorderedListOutlined,
   UsergroupAddOutlined,
   AimOutlined,
   FileTextOutlined,
} from '@ant-design/icons'
import { observer } from 'mobx-react' // Or "mobx-react".
import { RouteComponentProps, NavLink, withRouter } from 'react-router-dom'
import { useStore } from '../../context'

import './LayoutStyle.scss'
const { Sider } = Layout

type ISiderMenu = {}

type PropsType = RouteComponentProps<ISiderMenu> & {
   collapsed: boolean
   handleOnCollapse: any
}

const SiderMenu = observer(({ collapsed, handleOnCollapse }: PropsType) => {
   const { MainLayoutStore } = useStore()

   const menuItemOnClick = (event: any) => {
      handleOnCollapse()
      MainLayoutStore.setLocationPath(event.key)
   }

   return (
      <Sider
         breakpoint="lg"
         collapsedWidth="80"
         collapsed={collapsed}
         width="256"
         data-testid="sider-menu"
         className="sider"
      >
         {!collapsed && (
            <div>
               <div className="menu-logo" data-testid="menu-logo">
                  {' '}
               </div>
            </div>
         )}
         {collapsed && (
            <div style={{ marginLeft: '15%' }}>
               <div
                  className="collapsed-menu-logo"
                  data-testid="collapsed-menu-logo"
               >
                  {' '}
               </div>
            </div>
         )}
         <Menu className="sider-menu">
            {(MainLayoutStore.commonStore.isAdmin ||
               MainLayoutStore.commonStore.isDev) && (
               <Menu.Item key="DailyRules" onClick={menuItemOnClick}>
                  <NavLink to="/DailyRules">
                     <UnorderedListOutlined className="sider-menu-icon" />
                     <span className="default-menu-text">Daily Rules</span>
                  </NavLink>
               </Menu.Item>
            )}
            <Menu.Item key="ImpactAssessments" onClick={menuItemOnClick}>
               <NavLink to="/ImpactAssessments">
                  <AimOutlined className="sider-menu-icon" />
                  <span className="default-menu-text">Impact Assessments</span>
               </NavLink>
            </Menu.Item>
            {(MainLayoutStore.commonStore.isAdmin ||
               MainLayoutStore.commonStore.isDev) && (
               <Menu.Item key="NonAssessedRules" onClick={menuItemOnClick}>
                  <NavLink to="/NonAssessedDailyRules">
                     <FundProjectionScreenOutlined className="sider-menu-icon" />
                     <span className="default-menu-text">
                        Non-Assessed Rules
                     </span>
                  </NavLink>
               </Menu.Item>
            )}
            {(MainLayoutStore.commonStore.isAdmin ||
               MainLayoutStore.commonStore.isDev) && (
               <Menu.Item
                  key="Dashboard"
                  className="sider-menu-item"
                  onClick={menuItemOnClick}
               >
                  <NavLink to="/Dashboard">
                     <DashboardOutlined className="sider-menu-icon" />
                     <span className="default-menu-text">Dashboard</span>
                  </NavLink>
               </Menu.Item>
            )}
            <Menu.Item key="CompanyUsers" onClick={menuItemOnClick}>
               <NavLink to="/CompanyUsers">
                  <UsergroupAddOutlined className="sider-menu-icon" />
                  <span className="default-menu-text">Manage Users</span>
               </NavLink>
            </Menu.Item>
            {(MainLayoutStore.commonStore.isAdmin ||
               MainLayoutStore.commonStore.isDev) && (
               <Menu.Item key="Newsletters" onClick={menuItemOnClick}>
                  <NavLink to="/Newsletters">
                     <FileTextOutlined className="sider-menu-icon" />
                     <span className="default-menu-text">My Newsletters</span>
                  </NavLink>
               </Menu.Item>
            )}
         </Menu>
      </Sider>
   )
})

export default withRouter(SiderMenu)
